import React from 'react';
import {
    Container,
    Heading,
    Text,
    VStack,
    Box,
    Flex
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import MenuListDropdown from './MenuListDropdown';

const FAQPage = () => {
    const { t } = useTranslation();

    return (
        <Container maxW="container.md" py={4} centerContent overflowY="auto" height="150vh">
            <Flex
                direction="row"
                justify="space-between"
                align="center"
                width="100%"
                mb="5"
            >
                <Heading as="h1">{t('faqPageTitle')}</Heading>
                <MenuListDropdown />
            </Flex>
            <Box maxW="md" width="100%" overflowY="auto">
                <VStack spacing={4} align="start" width="100%">
                    <Box width="100%">
                        <Heading as="h2" size="md" mb={2}>{t('faqWhyMaximoTitle')}</Heading>
                        <Text>{t('faqWhyMaximoText')}</Text>
                    </Box>
                    <Box width="100%">
                        <Heading as="h2" size="md" mb={2}>{t('faqWhoIsItForTitle')}</Heading>
                        <Text>{t('faqWhoIsItForText')}</Text>
                    </Box>
                    <Box width="100%">
                        <Heading as="h2" size="md" mb={2}>{t('faqStandOutTitle')}</Heading>
                        <Text>{t('faqStandOutText')}</Text>
                    </Box>
                    <Box width="100%">
                        <Heading as="h2" size="md" mb={2}>{t('faqJoinPartyTitle')}</Heading>
                        <Text>{t('faqJoinPartyText')}</Text>
                    </Box>
                </VStack>
            </Box>
        </Container>
    );
};

export default FAQPage;
