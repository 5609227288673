import React, { useEffect, useState } from 'react';
import { 
  Box, 
  VStack, 
  Text, 
  useColorModeValue, 
  Button, 
  Heading, 
  Container, 
  Flex, 
  Icon,
  useToast,
  Avatar,
  Image,
  IconButton,
  Tooltip,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Link,
  UnorderedList,
  OrderedList,
  ListItem,
  Code,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendarAlt, faClock, faCopy, faUser, faRobot, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MotionBox = motion(Box);

const ChatConversationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const toast = useToast();
  const session = location.state?.session;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const boxShadow = useColorModeValue('lg', 'dark-lg');
  const accentColor = useColorModeValue('teal.500', 'teal.300');
  const userBgColor = useColorModeValue('blue.50', 'blue.900');
  const aiBgColor = useColorModeValue('gray.50', 'gray.800');
  const codeBgColor = useColorModeValue('gray.100', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    const fetchChatMessages = async (sessionId) => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const authToken = localStorage.getItem('token');
        const response = await axios.get(`${apiUrl}/api/chat-history/${sessionId}`, {
          headers: { Authorization: `Bearer ${authToken}` }
        });
        setMessages(response.data.chatMessages);
      } catch (error) {
        console.error("Error fetching chat messages:", error);
        toast({
          title: t('error'),
          description: t('errorFetchingMessages'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    if (session) {
      fetchChatMessages(session.session_id);
    }
  }, [session, t, toast]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: t('copied'),
        description: t('messageCopied'),
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  const renderMessageText = (text) => {
    if (typeof text === 'string') {
      return text;
    }
    if (typeof text === 'object' && text !== null) {
      return text.text;
    }
    return '';
  };

  const CodeBlock = ({ language, value }) => {
    return (
      <Box position="relative" my={4}>
        <SyntaxHighlighter
          language={language}
          style={vscDarkPlus}
          customStyle={{
            padding: '1rem',
            borderRadius: '0.5rem',
            backgroundColor: codeBgColor,
            fontSize: '0.9em',
          }}
        >
          {value}
        </SyntaxHighlighter>
        <Tooltip label={t('copyCode')} placement="top">
          <IconButton
            icon={<FontAwesomeIcon icon={faCopy} />}
            onClick={() => handleCopy(value)}
            position="absolute"
            top={2}
            right={2}
            colorScheme="teal"
            size="sm"
            borderRadius="full"
            aria-label={t('copyCode')}
          />
        </Tooltip>
      </Box>
    );
  };

  const MarkdownComponents = {
    p: (props) => <Text mb={4} {...props} />,
    h1: (props) => <Heading as="h1" size="xl" mt={6} mb={4} {...props} />,
    h2: (props) => <Heading as="h2" size="lg" mt={5} mb={3} {...props} />,
    h3: (props) => <Heading as="h3" size="md" mt={4} mb={2} {...props} />,
    ul: (props) => <UnorderedList mt={2} mb={4} pl={4} {...props} />,
    ol: (props) => <OrderedList mt={2} mb={4} pl={4} {...props} />,
    li: (props) => <ListItem mb={1} {...props} />,
    a: (props) => <Link color={accentColor} isExternal {...props} />,
    blockquote: (props) => (
      <Box
        borderLeftWidth={4}
        borderLeftColor={accentColor}
        pl={4}
        py={2}
        my={4}
        fontStyle="italic"
        {...props}
      />
    ),
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <CodeBlock
          language={match[1]}
          value={String(children).replace(/\n$/, '')}
          {...props}
        />
      ) : (
        <Code fontSize="sm" px={2} py={1} {...props}>
          {children}
        </Code>
      );
    },
  };

  const SessionInfoDrawer = () => (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Chat Session Info</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch">
            <Box>
              <Text fontWeight="bold">{t('title')}:</Text>
              <Text>{session?.title || t('untitledChat')}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">{t('date')}:</Text>
              <Text>{format(new Date(session?.last_activity), 'PPP')}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">{t('time')}:</Text>
              <Text>{format(new Date(session?.last_activity), 'p')}</Text>
            </Box>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  return (
    <Box bg={bgColor} color={textColor} minH="100vh">
      <Container maxW="container.xl" py={{ base: 4, md: 8 }} px={{ base: 4, md: 6 }}>
        <Flex direction="column" align="stretch">
          <Flex justify="space-between" align="center" mb={{ base: 4, md: 8 }}>
            <Button
              leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={handleGoBack}
              colorScheme="teal"
              variant="outline"
              borderRadius="full"
              px={6}
              py={3}
              _hover={{ bg: accentColor, color: 'white' }}
              transition="all 0.3s"
            >
              {t('goBack')}
            </Button>
            <IconButton
              icon={<FontAwesomeIcon icon={faInfoCircle} />}
              onClick={onOpen}
              colorScheme="teal"
              variant="ghost"
              borderRadius="full"
              aria-label={t('sessionInfo')}
            />
          </Flex>

          {session && (
            <Box mb={{ base: 4, md: 6 }}>
              <Heading fontSize={{ base: '2xl', md: '3xl' }} textAlign="center" mb={2}>
                {session.title || t('untitledChat')}
              </Heading>
              <Flex justify="center" align="center" fontSize="sm" color={accentColor}>
                <Icon as={FontAwesomeIcon} icon={faCalendarAlt} mr={2} />
                <Text mr={4}>
                  {format(new Date(session.last_activity), 'PPP')}
                </Text>
                <Icon as={FontAwesomeIcon} icon={faClock} mr={2} />
                <Text>
                  {format(new Date(session.last_activity), 'p')}
                </Text>
              </Flex>
            </Box>
          )}

          <AnimatePresence>
            {messages.length === 0 ? (
              <MotionBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Text 
                  textAlign="center" 
                  fontSize="lg" 
                  fontStyle="italic"
                >
                  {t('noChatMessagesAvailable')}
                </Text>
              </MotionBox>
            ) : (
              <VStack spacing={{ base: 4, md: 6 }} align="stretch">
                {messages.map((message, index) => {
                  const isUserMessage = index % 2 === 0;
                  const messageText = renderMessageText(message.message?.text);
                  const imageUrl = message.message?.imageUrl || '';

                  return (
                    <MotionBox
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      layout
                    >
                      <Box
                        alignSelf={isUserMessage ? "flex-end" : "flex-start"}
                        maxW={{ base: '100%', md: '100%' }}
                        bg={isUserMessage ? userBgColor : aiBgColor}
                        borderRadius="2xl"
                        boxShadow={boxShadow}
                        borderWidth="1px"
                        borderColor={borderColor}
                        position="relative"
                      >
                        <Flex p={4} alignItems="center">
                          <Avatar
                            icon={<FontAwesomeIcon icon={isUserMessage ? faUser : faRobot} />}
                            bg={isUserMessage ? 'blue.500' : 'green.500'}
                            color="white"
                            mr={3}
                          />
                          <Text fontWeight="bold">
                            {isUserMessage ? t('You') : t('Maximo AI')}
                          </Text>
                          <Tooltip label={t('copyMessage')} placement="top">
                            <IconButton
                              icon={<FontAwesomeIcon icon={faCopy} />}
                              onClick={() => handleCopy(messageText)}
                              position="absolute"
                              top={2}
                              right={2}
                              colorScheme="teal"
                              variant="ghost"
                              size="sm"
                              borderRadius="full"
                              aria-label={t('copyMessage')}
                            />
                          </Tooltip>
                        </Flex>
                        <Box px={4} pb={4}>
                          <Box className="markdown-content">
                            <ReactMarkdown components={MarkdownComponents}>
                              {messageText}
                            </ReactMarkdown>
                          </Box>
                          {imageUrl && (
                            <Image
                              src={imageUrl}
                              alt="Chat Image"
                              mt={4}
                              borderRadius="md"
                              maxW="100%"
                              h="auto"
                            />
                          )}
                        </Box>
                      </Box>
                    </MotionBox>
                  );
                })}
              </VStack>
            )}
          </AnimatePresence>
        </Flex>
      </Container>
      <SessionInfoDrawer />
    </Box>
  );
};

export default ChatConversationPage;