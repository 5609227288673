import React from 'react';
import {
    Box,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    Image,
    Button,
    Container,
    Flex,
    keyframes,
    useBreakpointValue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faRobot, faHome, faBrain } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../images/maximo-ai-logo-circle.png';

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const MaximoComingSoonPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const accentColor = useColorModeValue('teal.500', 'teal.300');
    const cardBg = useColorModeValue('white', 'gray.800');

    const isDesktop = useBreakpointValue({ base: false, md: true });

    return (
        <Box
            bg={bgColor}
            color={textColor}
            minH="100vh"
            py={{ base: 16, md: 24 }}
            px={4}
            overflow="hidden"
        >
            <Container maxW="container.xl">
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                    justify="space-between"
                >
                    <VStack
                        spacing={8}
                        textAlign={{ base: 'center', md: 'left' }}
                        align={{ base: 'center', md: 'flex-start' }}
                        flex={1}
                    >
                        <MotionBox
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Image 
                                src={logo} 
                                alt="Maximo AI Logo" 
                                boxSize="150px"
                                animation={`${pulse} 3s ease-in-out infinite`}
                            />
                        </MotionBox>
                        <MotionHeading
                            size="3xl"
                            bgGradient={`linear(to-r, ${accentColor}, blue.400)`}
                            bgClip="text"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2, duration: 0.5 }}
                        >
                            {t('Coming Soon')}
                        </MotionHeading>
                        <MotionText
                            fontSize="xl"
                            maxW="600px"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.4, duration: 0.5 }}
                        >
                            {t('We are working hard to bring you new and exciting features for Maximo AI. Stay tuned for updates as we expand our capabilities to offer you more intelligent solutions and innovations.')}
                        </MotionText>
                        <MotionBox
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ delay: 0.6, duration: 0.5 }}
                        >
                            <FontAwesomeIcon icon={faTools} size="3x" color={accentColor} />
                        </MotionBox>
                        <MotionText
                            fontSize="2xl"
                            fontWeight="bold"
                            color={accentColor}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.8, duration: 0.5 }}
                        >
                            {t('Thank you for your patience and support!')}
                        </MotionText>
                        <MotionButton
                            colorScheme="teal"
                            size="lg"
                            rightIcon={<FontAwesomeIcon icon={faHome} />}
                            onClick={() => navigate('/chat')}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1, duration: 0.5 }}
                            _hover={{
                                transform: 'translateY(-5px)',
                                boxShadow: 'xl',
                            }}
                        >
                            {t('Go to Home')}
                        </MotionButton>
                    </VStack>
                    {isDesktop && (
                        <MotionBox
                            flex={1}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.5, duration: 0.8 }}
                        >
                            <Box
                                w="full"
                                h="400px"
                                bg={cardBg}
                                borderRadius="2xl"
                                boxShadow="2xl"
                                p={8}
                                position="relative"
                                overflow="hidden"
                            >
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    animation={`${float} 3s ease-in-out infinite`}
                                >
                                    <FontAwesomeIcon icon={faRobot} size="6x" color={accentColor} />
                                </Box>
                                {[...Array(5)].map((_, index) => (
                                    <Box
                                        key={index}
                                        position="absolute"
                                        top={`${Math.random() * 100}%`}
                                        left={`${Math.random() * 100}%`}
                                        w={`${Math.random() * 10 + 5}px`}
                                        h={`${Math.random() * 10 + 5}px`}
                                        borderRadius="full"
                                        bg={accentColor}
                                        opacity={0.6}
                                        animation={`${pulse} ${Math.random() * 2 + 2}s ease-in-out infinite`}
                                    />
                                ))}
                                <Box
                                    position="absolute"
                                    bottom="10%"
                                    right="10%"
                                    animation={`${float} 4s ease-in-out infinite`}
                                >
                                    <FontAwesomeIcon icon={faBrain} size="4x" color={accentColor} opacity={0.7} />
                                </Box>
                            </Box>
                        </MotionBox>
                    )}
                </Flex>
            </Container>
        </Box>
    );
};

export default MaximoComingSoonPage;