import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    VStack,
    Text,
    Container,
    Heading,
    Flex,
    Image,
    Link,
    List,
    ListItem,
    ListIcon,
    CloseButton,
    Button,
    useToast,
    useColorModeValue,
    ScaleFade,
    SlideFade,
    Collapse,
    useDisclosure,
    Badge,
    Icon,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Grid,
    GridItem,
    IconButton,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckIcon, CopyIcon, ExternalLinkIcon, InfoIcon, ChevronUpIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { FaTwitter, FaTelegram, FaGlobe, FaChartLine } from 'react-icons/fa';
import defaultImage from '../images/default-coin.png';

const CoinPage = () => {
    const { coinAddress } = useParams();
    const { t } = useTranslation();
    const [coinDetails, setCoinDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen: isDisclaimerOpen, onToggle: toggleDisclaimer } = useDisclosure({ defaultIsOpen: true });
    const { isOpen: isChartOpen, onOpen: onChartOpen, onClose: onChartClose } = useDisclosure();
    const [showScrollTop, setShowScrollTop] = useState(false);
    const containerRef = useRef(null);

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.700', 'gray.200');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const newColor = useColorModeValue('blue.50', 'blue.900');

    // const isMobile = useBreakpointValue({ base: true, md: false });

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({
                description: t('copied'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        });
    };

    useEffect(() => {
        const fetchCoinDetails = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/coin/${coinAddress}`);
                setCoinDetails(response.data);
            } catch (error) {
                console.error('Error fetching coin details:', error);
                toast({
                    title: t('error'),
                    description: t('fetchCoinError'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                navigate('/');
            } finally {
                setIsLoading(false);
            }
        };

        fetchCoinDetails();
    }, [coinAddress, toast, t, navigate]);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setShowScrollTop(containerRef.current.scrollTop > 300);
            }
        };

        const currentContainer = containerRef.current;
        if (currentContainer) {
            currentContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentContainer) {
                currentContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const scrollToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const renderTextAnalysis = () => {
        if (!coinDetails.textAnalysis || !coinDetails.tokenInfo) return null;

        try {
            const analysis = JSON.parse(coinDetails.textAnalysis);

            if (!Array.isArray(analysis.mentions) || !Array.isArray(coinDetails.tokenInfo.socials)) return null;

            return (
                <ScaleFade initialScale={0.9} in={!isLoading}>
                    <Box mt={6} bg={cardBg} p={6} borderRadius="xl" boxShadow="lg">
                        <Heading size="md" mb={4}>{t('Maximo AI Analysis')}</Heading>
                        <List spacing={4}>
                            {analysis.mentions.map((mention, index) => (
                                <ListItem key={index} p={4} bg={newColor} borderRadius="md">
                                    <Flex align="center">
                                        <ListIcon as={CheckIcon} color="green.500" boxSize={5} />
                                        <VStack align="start" spacing={1}>
                                            <Text fontWeight="bold">
                                                {t('Mentioned ticker')}: <Badge colorScheme="blue">{mention.ticker}</Badge>
                                            </Text>
                                            <Text>{t('Mentioned name')}: {mention.name}</Text>
                                            <Text>{t('Contract address')}: {mention.contract_address}</Text>
                                            <Text>{t('Blockchain')}: {mention.blockchain}</Text>
                                        </VStack>
                                    </Flex>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </ScaleFade>
            );
        } catch (error) {
            console.error('Error parsing text analysis:', error);
            return null;
        }
    };

    const MotionBox = motion(Box);

    return (
        <Container 
            maxW="container.xl" 
            py={4} 
            px={4} 
            bg={bgColor} 
            h="calc(100vh - 60px)" 
            overflow="auto" 
            ref={containerRef}
            position="relative"
        >
            <IconButton
                icon={<ArrowBackIcon />}
                onClick={handleGoBack}
                position="absolute"
                top={4}
                left={4}
                zIndex={2}
                colorScheme="blue"
                variant="ghost"
                size="lg"
                aria-label={t('Go back')}
                _hover={{ bg: 'blue.100' }}
            />
            <ScaleFade initialScale={0.9} in={!isLoading}>
                <MotionBox
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    bg={cardBg}
                    borderRadius="2xl"
                    boxShadow="2xl"
                    overflow="hidden"
                    mb={20} // Add bottom margin to account for nav bar
                    mt={16} // Add top margin to account for back button
                >
                    <Flex
                        justify="space-between"
                        alignItems="center"
                        p={6}
                        borderBottom="1px"
                        borderColor={borderColor}
                    >
                        <Heading size="lg" color={textColor}>{coinDetails.name}</Heading>
                        <Badge colorScheme="blue" fontSize="md" p={2} borderRadius="md">
                            {coinDetails.symbol}
                        </Badge>
                    </Flex>

                    <Collapse in={isDisclaimerOpen} animateOpacity>
                        <Box p={4} bg={newColor} position="relative">
                            <Flex align="center">
                                <Icon as={InfoIcon} color="blue.500" boxSize={5} mr={2} />
                                <Text color={textColor} fontSize="sm">
                                    {t('Disclaimer')}
                                </Text>
                            </Flex>
                            <CloseButton
                                position="absolute"
                                right="8px"
                                top="8px"
                                onClick={toggleDisclaimer}
                            />
                        </Box>
                    </Collapse>

                    <Box p={8}>
                        <Grid templateColumns={{ base: "1fr", md: "150px 1fr" }} gap={8}>
                            <GridItem>
                                <Image
                                    src={coinDetails.tokenInfo?.imageUrl || defaultImage}
                                    alt={coinDetails.name}
                                    borderRadius="full"
                                    boxSize="150px"
                                    objectFit="cover"
                                    boxShadow="lg"
                                />
                            </GridItem>
                            <GridItem>
                                <VStack align="start" spacing={4}>
                                    <Text fontWeight="bold" fontSize="xl" color={textColor}>
                                        {t('Blockchain')}: <Badge colorScheme="purple">{coinDetails.blockchain}</Badge>
                                    </Text>
                                    <Text fontWeight="bold" fontSize="xl" color={textColor}>
                                        {t('Exchange')}: <Badge colorScheme="green">{coinDetails.dex}</Badge>
                                    </Text>
                                </VStack>
                            </GridItem>
                        </Grid>

                        <SlideFade in={!isLoading} offsetY="20px">
                            <Box mt={6}>
                                <Heading size="md" mb={2} color={textColor}>{t('ContractAddress')}</Heading>
                                <Flex alignItems="center" bg={newColor} p={3} borderRadius="lg" flexWrap="wrap">
                                    <Text isTruncated maxWidth={{ base: "100%", md: "70%" }} color={textColor} mb={{ base: 2, md: 0 }}>
                                        {coinDetails.address}
                                    </Text>
                                    <Button
                                        ml={{ base: 0, md: 4 }}
                                        size="sm"
                                        onClick={() => copyToClipboard(coinDetails.address)}
                                        leftIcon={<CopyIcon />}
                                        colorScheme="blue"
                                    >
                                        {t('Copy')}
                                    </Button>
                                </Flex>
                            </Box>

                            <Box mt={6}>
                                <Heading size="md" mb={2} color={textColor}>{t('Website')}</Heading>
                                <Link
                                    href={coinDetails.tokenInfo?.websites[0]?.url}
                                    isExternal
                                    color="blue.500"
                                    fontWeight="bold"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Icon as={FaGlobe} mr={2} />
                                    {coinDetails.tokenInfo?.websites[0]?.label}
                                    <ExternalLinkIcon mx={2} />
                                </Link>
                            </Box>

                            <Box mt={6}>
                                <Heading size="md" mb={2} color={textColor}>{t('Dexscreener')}</Heading>
                                <Link
                                    href={coinDetails.url}
                                    isExternal
                                    color="blue.500"
                                    fontWeight="bold"
                                    display="flex"
                                    alignItems="center"
                                >
                                    {coinDetails.url}
                                    <ExternalLinkIcon mx={2} />
                                </Link>
                            </Box>

                            <Box mt={6}>
                                <Button
                                    colorScheme="blue"
                                    onClick={onChartOpen}
                                    leftIcon={<Icon as={FaChartLine} />}
                                    size="lg"
                                    borderRadius="full"
                                    boxShadow="md"
                                    _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                    transition="all 0.2s"
                                >
                                    {t('View Charts')}
                                </Button>
                            </Box>

                            <Box mt={6}>
                                <Heading size="md" mb={4} color={textColor}>{t('Social Media')}</Heading>
                                <Flex flexWrap="wrap" gap={4}>
                                    {coinDetails.tokenInfo?.socials && coinDetails.tokenInfo.socials.map((social, index) => (
                                        <Link
                                            key={index}
                                            href={social.url}
                                            isExternal
                                        >
                                            <Button
                                                leftIcon={social.type === 'twitter' ? <FaTwitter /> : <FaTelegram />}
                                                colorScheme={social.type === 'twitter' ? 'twitter' : 'telegram'}
                                                variant="outline"
                                            >
                                                {social.type}
                                            </Button>
                                        </Link>
                                    ))}
                                </Flex>
                            </Box>

                            {renderTextAnalysis()}
                        </SlideFade>
                    </Box>
                </MotionBox>
            </ScaleFade>

            <Drawer
                isOpen={isChartOpen}
                placement="bottom"
                onClose={onChartClose}
                size="full"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{t('Chart for')} {coinDetails.symbol}</DrawerHeader>
                    <DrawerBody p={0}>
                        <iframe
                            src={`https://dexscreener.com/${coinDetails.blockchain}/${coinDetails.address}?embed=1&theme=dark`}
                            title={`Chart for ${coinDetails.symbol}`}
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            <AnimatePresence>
                {showScrollTop && (
                    <MotionBox
                        position="fixed"
                        bottom="80px"
                        right="20px"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.2 }}
                    >
                        <Button
                            onClick={scrollToTop}
                            colorScheme="blue"
                            borderRadius="full"
                            w="50px"
                            h="50px"
                            boxShadow="lg"
                        >
                            <ChevronUpIcon boxSize={6} />
                        </Button>
                    </MotionBox>
                )}
            </AnimatePresence>
        </Container>
    );
};

export default CoinPage;