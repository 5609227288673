import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Box, Button, FormControl, FormLabel, NumberInput, NumberInputField,
    Text, VStack, Container, useToast, Heading, CloseButton, Flex,
    useColorModeValue, keyframes, useDisclosure, Modal, ModalOverlay, 
    ModalContent, ModalHeader, ModalBody, ModalCloseButton, useMediaQuery,
    ScaleFade, Alert, AlertIcon
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faWallet, faNetworkWired, faDollarSign, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CustomSelect from './CustomSelect';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const MotionBox = motion(Box);

const BuyPointsPage = () => {
    const { t } = useTranslation();
    const [network, setNetwork] = useState('');
    const [paymentToken, setPaymentToken] = useState('');
    const [pointsToBuy, setPointsToBuy] = useState('');
    const [cost, setCost] = useState(0);
    const [supportedTokens, setSupportedTokens] = useState([]);
    const [userWalletAddress, setUserWalletAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCostFetching, setIsCostFetching] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(true);
    const [pointsBalance, setPointsBalance] = useState(0);
    const toast = useToast();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const [isMobile] = useMediaQuery("(max-width: 48em)");

    // const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    const networks = useMemo(() => ["Ethereum", "Binance Smart Chain", "Polygon", "Base", "OneLedger"], []);
    const authToken = localStorage.getItem('token');

    const fetchUserPointsBalance = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-alerts`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setPointsBalance(response.data.points);
            }
        } catch (error) {
            console.error('Error fetching user points balance:', error);
        }
    }, []);

    useEffect(() => {
        fetchUserPointsBalance();
    }, [fetchUserPointsBalance]);

    const onCloseDisclaimer = () => {
        setShowDisclaimer(false);
    };

    const handleFetchError = useCallback(() => {
        setIsCostFetching(false);
        toast({
            title: t("error"),
            description: t("fetchError"),
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }, [toast, t]);

    const fetchCost = useCallback(async (points, token) => {
        if (!points || !token) return;
        setIsCostFetching(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/calculate-cost`, {
                params: { points, paymentToken: token },
                headers: { Authorization: `Bearer ${authToken}` }
            });
            setCost(parseFloat(response.data.costInToken));
        } catch (error) {
            handleFetchError();
        } finally {
            setIsCostFetching(false);
        }
    }, [authToken, handleFetchError]);

    const fetchSupportedTokens = useCallback(async (selectedNetwork) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/supported-tokens/${selectedNetwork}`, {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            setSupportedTokens(response.data.supportedTokens);
        } catch (error) {
            handleFetchError();
        }
    }, [authToken, handleFetchError]);

    const fetchUserWalletAddress = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            setUserWalletAddress(response.data.walletAddress);
        } catch (error) {
            console.error('Error fetching user wallet address:', error);
        }
    }, [authToken]);

    useEffect(() => {
        fetchUserWalletAddress();
    }, [fetchUserWalletAddress]);

    useEffect(() => {
        if (network) {
            fetchSupportedTokens(network);
        }
    }, [network, fetchSupportedTokens]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (pointsToBuy && paymentToken) {
                fetchCost(pointsToBuy, paymentToken);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [pointsToBuy, paymentToken, fetchCost]);

    const handleBuyPoints = async () => {
        setIsLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/buy-points`, {
                network,
                paymentToken,
                pointsToBuy,
                fromAddress: userWalletAddress
            }, {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            toast({
                title: t("purchaseSuccess"),
                description: t("pointsPurchaseMessage", { points: pointsToBuy }),
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setPointsToBuy('');
            onModalOpen();
        } catch (error) {
            toast({
                title: t("purchaseFailed"),
                description: t("transactionFailed"),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
            fetchUserPointsBalance();
        }
    };

    const handleNetworkChange = (selectedOption) => {
        setNetwork(selectedOption.value);
        setPaymentToken('');
        setPointsToBuy('');
        setCost(0);
        fetchSupportedTokens(selectedOption.value);
    };

    const handlePaymentTokenChange = (selectedOption) => {
        setPaymentToken(selectedOption.value);
        if (pointsToBuy) {
            fetchCost(pointsToBuy, selectedOption.value);
        }
    };

    return (
        <Container maxW="container.md" centerContent py={8} mb={isMobile ? "60px" : "0"}>
            <MotionBox
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                mb={8}
            >
                <Heading as="h1" size="2xl" textAlign="center" color={textColor}>
                    <FontAwesomeIcon icon={faCoins} /> {t('buyPoints')}
                </Heading>
            </MotionBox>

            <ScaleFade initialScale={0.9} in={showDisclaimer}>
                <Box
                    position="relative"
                    p={4}
                    bg="blue.500"
                    color="white"
                    borderRadius="xl"
                    boxShadow="xl"
                    mb={6}
                    animation={`${fadeIn} 1s`}
                    width="full"
                >
                    <Text fontSize="sm" fontWeight="medium">
                        <FontAwesomeIcon icon={faInfoCircle} /> {t('temDiscountText')}
                    </Text>
                    <CloseButton
                        position="absolute"
                        right="8px"
                        top="8px"
                        onClick={onCloseDisclaimer}
                        color="white"
                    />
                </Box>
            </ScaleFade>

            <Box
                width="full"
                p={6}
                boxShadow="2xl"
                borderRadius="2xl"
                bg={cardBgColor}
                border="1px"
                borderColor={borderColor}
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)', boxShadow: '3xl' }}
            >
                <VStack spacing={6} align="stretch" width="full">
                    <FormControl isRequired>
                        <FormLabel>
                            <FontAwesomeIcon icon={faNetworkWired} /> {t('selectNetwork')}
                        </FormLabel>
                        <CustomSelect
                            options={networks.map(net => ({ value: net, label: net }))}
                            selectedOption={network ? { value: network, label: network } : null}
                            setSelectedOption={handleNetworkChange}
                            placeholder={t('selectNetwork')}
                        />
                    </FormControl>

                    {!network && (
                        <Alert status="info" borderRadius="md">
                            <AlertIcon />
                            {t('pleaseSelectBlockchainNetwork')}
                        </Alert>
                    )}

                    <AnimatePresence>
                        {network && (
                            <MotionBox
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.3 }}
                                width="full"
                            >
                                <VStack spacing={4} align="stretch" width="full">
                                    <FormControl isRequired>
                                        <FormLabel>
                                            <FontAwesomeIcon icon={faWallet} /> {t('paymentToken')}
                                        </FormLabel>
                                        <CustomSelect
                                            options={supportedTokens.map(token => ({ value: token, label: token }))}
                                            selectedOption={paymentToken ? { value: paymentToken, label: paymentToken } : null}
                                            setSelectedOption={handlePaymentTokenChange}
                                            placeholder={t('select')}
                                        />
                                    </FormControl>

                                    <FormControl isRequired>
                                        <FormLabel>
                                            <FontAwesomeIcon icon={faCoins} /> {t('pointsToBuy')}
                                        </FormLabel>
                                        <NumberInput
                                            min={1}
                                            onChange={(valueString) => {
                                                setPointsToBuy(parseFloat(valueString));
                                                fetchCost(parseFloat(valueString), paymentToken);
                                            }}
                                            value={pointsToBuy}
                                            width="full"
                                        >
                                            <NumberInputField
                                                placeholder={t('enterPoints')}
                                                borderRadius="full"
                                                boxShadow="md"
                                                _focus={{ boxShadow: 'outline' }}
                                            />
                                        </NumberInput>
                                    </FormControl>

                                    <Flex justify="space-between" w="full" alignItems="center">
                                        <Text fontSize="lg" fontWeight="bold">
                                            <FontAwesomeIcon icon={faDollarSign} /> {t('cost')}:{' '}
                                            {isCostFetching ? 'Fetching...' : (cost ? parseFloat(cost).toFixed(5) : '0.00')}{' '}
                                            {paymentToken}
                                        </Text>
                                        <Text color="teal.500" fontWeight="bold">
                                            {t('pointsBalance')} {pointsBalance}
                                        </Text>
                                    </Flex>

                                    <Button
                                        colorScheme="teal"
                                        onClick={handleBuyPoints}
                                        isLoading={isLoading}
                                        loadingText={t('processing')}
                                        spinnerPlacement="start"
                                        leftIcon={<FontAwesomeIcon icon={faCoins} />}
                                        size="lg"
                                        borderRadius="full"
                                        boxShadow="md"
                                        _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                        _active={{ transform: 'translateY(0)', boxShadow: 'md' }}
                                        isDisabled={!network || !paymentToken || !pointsToBuy}
                                        width="full"
                                    >
                                        {t('buyPoints')}
                                    </Button>
                                </VStack>
                            </MotionBox>
                        )}
                    </AnimatePresence>
                </VStack>
            </Box>

            <Modal isOpen={isModalOpen} onClose={onModalClose}>
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBgColor}>
                    <ModalHeader>{t('purchaseComplete')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t('thankYouPurchase')}</Text>
                        <Text mt={4}>{t('enjoyNewPoints')}</Text>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default BuyPointsPage;