import React, { useState, useEffect } from 'react';
import {
    Box, Button, Text, VStack, Image, useToast, Heading, HStack, Modal, ModalOverlay,
    ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useBreakpointValue,
    Input, FormControl, FormLabel, SimpleGrid, Flex, Drawer, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Badge, useDisclosure, useOutsideClick,
    Divider, IconButton, Progress, Tooltip
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faLandmark, faFlag, faMoneyBill, faCoins, faBalanceScale, faBuilding, faSyncAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CustomSelect from './CustomSelect';
// import gbgImage from '../images/background.png';


const MotionBox = motion(Box);

const DashboardPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userCountries, setUserCountries] = useState([]);
    const [resources, setResources] = useState({});
    const [userProfile, setUserProfile] = useState({});
    const [isBuyLandModalOpen, setIsBuyLandModalOpen] = useState(false);
    const [selectedCountryForLandPurchase, setSelectedCountryForLandPurchase] = useState(null);
    const [landArea, setLandArea] = useState('');
    const [excrCost, setExcrCost] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [planets, setPlanets] = useState([]);
    const [selectedView, setSelectedView] = useState(null);
    const [editCountryData, setEditCountryData] = useState({});
    const [editCurrencyData, setEditCurrencyData] = useState({});
    const isMobile = useBreakpointValue({ base: true, md: false });
    const apiUrl = process.env.REACT_APP_API_URL;

    const containerRef = React.useRef();

    useOutsideClick({
        ref: containerRef,
        handler: () => setSelectedCountry(null),
    });

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            try {
                const userProfileResponse = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUserProfile(userProfileResponse.data);
                const userId = userProfileResponse.data.id;

                const [userCountriesResponse, planetsResponse] = await Promise.all([
                    axios.get(`${apiUrl}/api/planets/user-countries/${userId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    }),
                    axios.get(`${apiUrl}/api/planets/planet-areas`, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                ]);

                setUserCountries(userCountriesResponse.data);
                setPlanets(planetsResponse.data);

                const resourcesData = {};
                for (const country of userCountriesResponse.data) {
                    const resourcesResponse = await axios.get(`${apiUrl}/api/planets/countries/${country.id}/resources`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    resourcesData[country.id] = resourcesResponse.data;
                }
                setResources(resourcesData);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast({
                    title: t('Error fetching data'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchData();
    }, [toast, t, apiUrl]);

    const fetchCountryData = async (countryId, type) => {
        const token = localStorage.getItem('token');
        try {
            let response;
            switch (type) {
                case 'currency':
                    response = await axios.get(`${apiUrl}/api/planets/currencies/${countryId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setSelectedView({ type, data: response.data });
                    break;
                case 'resources':
                    response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/resources`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setSelectedView({ type, data: response.data });
                    break;
                case 'buildings':
                    response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/buildings`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setSelectedView({ type, data: response.data });
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(`Error fetching ${type} data:`, error);
            toast({
                title: t(`Error fetching ${type} data`),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleBuyLandClick = () => {
        setIsBuyLandModalOpen(true);
    };

    const handleCloseBuyLandModal = () => {
        setIsBuyLandModalOpen(false);
        setSelectedCountryForLandPurchase(null);
        setLandArea('');
        setExcrCost(0);
    };

    const handleCountryChange = (selectedOption) => {
        const countryId = selectedOption.value;
        const country = userCountries.find((c) => c.id === countryId);
        setSelectedCountryForLandPurchase(country);
    };

    const handleLandAreaChange = (event) => {
        const landAreaValue = parseFloat(event.target.value);
        setLandArea(landAreaValue);
        const calculatedCost = landAreaValue * 100;
        setExcrCost(calculatedCost);
    };

    const handleBuyLand = async () => {
        if (!selectedCountryForLandPurchase || !landArea || excrCost > totalExcrBalance) {
            toast({
                title: t('Invalid input or insufficient balance'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const token = localStorage.getItem('token');
        try {
            await axios.post(
                `${apiUrl}/api/planets/buy-land`,
                { countryId: selectedCountryForLandPurchase.id, landArea },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast({
                title: t('Land purchased successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            handleCloseBuyLandModal();
            // Refresh country data after purchase
            const userCountriesResponse = await axios.get(`${apiUrl}/api/planets/user-countries/${userProfile.id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserCountries(userCountriesResponse.data);
        } catch (error) {
            console.error('Error purchasing land:', error);
            toast({
                title: t('Error purchasing land'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleEditCountryClick = (country) => {
        setEditCountryData(country);
        setSelectedView({ type: 'editCountry' });
    };

    const handleEditCurrencyClick = (country) => {
        const token = localStorage.getItem('token');
        axios.get(`${apiUrl}/api/planets/currencies/${country.id}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(response => {
                if (response.data && response.data.length > 0) {
                    const currency = response.data[0];
                    setEditCurrencyData({
                        id: currency.id,
                        name: currency.name,
                        ticker: currency.ticker,
                        image: currency.image,
                        countryId: country.id
                    });
                    setSelectedView({ type: 'editCurrency' });
                } else {
                    setEditCurrencyData({
                        id: null,
                        name: '',
                        ticker: '',
                        image: null,
                        countryId: country.id
                    });
                    setSelectedView({ type: 'editCurrency' });
                }
            })
            .catch(error => {
                console.error('Error fetching currency data:', error);
                toast({
                    title: t('Error loading currency data'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    const handleEditCountrySubmit = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('countryName', editCountryData.name);
        formData.append('governmentType', editCountryData.governmentType);
        formData.append('capital', editCountryData.capital);
        formData.append('states', editCountryData.states);
        formData.append('language', editCountryData.language);
        if (editCountryData.flag) {
            formData.append('flag', editCountryData.flag);
        }

        try {
            await axios.put(`${apiUrl}/api/planets/edit-country/${editCountryData.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            toast({
                title: t('Country details updated successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setSelectedView(null);
            // Refresh user countries data
            const userCountriesResponse = await axios.get(`${apiUrl}/api/planets/user-countries/${userProfile.id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserCountries(userCountriesResponse.data);
        } catch (error) {
            console.error('Error updating country details:', error);
            toast({
                title: t('Error updating country details'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleEditCurrencySubmit = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('currencyName', editCurrencyData.name);
        formData.append('ticker', editCurrencyData.ticker);
        if (editCurrencyData.image) {
            formData.append('image', editCurrencyData.image);
        }

        try {
            await axios.put(`${apiUrl}/api/planets/edit-currency/${editCurrencyData.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            toast({
                title: t('Currency details updated successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setSelectedView(null);
            // Refresh currency data
            fetchCountryData(editCurrencyData.countryId, 'currency');
        } catch (error) {
            console.error('Error updating currency details:', error);
            toast({
                title: t('Error updating currency details'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const totalExcrBalance = Object.values(resources).reduce((acc, countryResources) => {
        const excrResource = countryResources.find(resource => resource.type === 'EXCR');
        return acc + (excrResource ? parseFloat(excrResource.quantity) : 0);
    }, 0).toFixed(3);

    const refreshTotalExcrBalance = async () => {
        const token = localStorage.getItem('token');
        const resourcesData = {};
        try {
            for (const country of userCountries) {
                const resourcesResponse = await axios.get(`${apiUrl}/api/planets/countries/${country.id}/resources`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                resourcesData[country.id] = resourcesResponse.data;
            }
            setResources(resourcesData);
        } catch (error) {
            console.error('Error fetching resources:', error);
            toast({
                title: t('Error refreshing EXCR balance'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const calculateBuildingTotals = (buildings) => {
        return buildings.reduce((acc, building) => {
            if (acc[building.type]) {
                acc[building.type] += 1;
            } else {
                acc[building.type] = 1;
            }
            return acc;
        }, {});
    };

    const CountryCard = ({ country }) => (
        <MotionBox
            bg="rgba(255, 255, 255, 0.1)"
            p={4}
            borderRadius="xl"
            boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
            backdropFilter="blur(10px)"
            border="1px solid rgba(255, 255, 255, 0.3)"
            whileHover={{ scale: 1.05, boxShadow: "0 8px 30px rgba(0, 0, 0, 0.2)" }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setSelectedCountry(country)}
            transition={{ duration: 0.3 }}
        >
            <HStack spacing={4} align="start">
                <Image src={`${apiUrl}${country.flag}`} alt={`${country.name} flag`} boxSize="60px" borderRadius="md" objectFit="cover" />
                <VStack align="start" spacing={2}>
                    <Text fontSize="xl" fontWeight="bold"><FontAwesomeIcon icon={faGlobe} /> {country.name}</Text>
                    <Text fontSize="sm">{t('Planet')}: {country.planetName}</Text>
                    <Text fontSize="sm"><FontAwesomeIcon icon={faLandmark} /> {t('Government')}: {country.governmentType}</Text>
                    <Text fontSize="sm">{t('Capital')}: {country.capital}</Text>
                    <HStack>
                        <Badge colorScheme="green">{t('Land')}: {country.landPercentage.toFixed(2)}%</Badge>
                        <Badge colorScheme="blue">{t('Water')}: {country.waterPercentage.toFixed(2)}%</Badge>
                    </HStack>
                </VStack>
            </HStack>
        </MotionBox>
    );

    const ActionButton = ({ icon, label, onClick }) => {
        const isMobile = useBreakpointValue({ base: true, md: false });
    
        return isMobile ? (
            <Button
                leftIcon={<FontAwesomeIcon icon={icon} />}
                onClick={onClick}
                bg="rgba(255, 255, 255, 0.1)"
                color="white"
                _hover={{ bg: "rgba(255, 255, 255, 0.2)" }}
                borderRadius="full"
                boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                backdropFilter="blur(5px)"
                size="lg"
            >
                {label}
            </Button>
        ) : (
            <Tooltip label={label} hasArrow>
                <IconButton
                    icon={<FontAwesomeIcon icon={icon} />}
                    onClick={onClick}
                    bg="rgba(255, 255, 255, 0.1)"
                    color="white"
                    _hover={{ bg: "rgba(255, 255, 255, 0.2)" }}
                    borderRadius="full"
                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                    backdropFilter="blur(5px)"
                    size="lg"
                    aria-label={label}
                />
            </Tooltip>
        );
    };    

    const PlanetCard = ({ planet }) => (
        <MotionBox
            bg="rgba(255, 255, 255, 0.1)"
            p={4}
            borderRadius="xl"
            boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
            backdropFilter="blur(10px)"
            border="1px solid rgba(255, 255, 255, 0.3)"
            whileHover={{ scale: 1.05, boxShadow: "0 8px 30px rgba(0, 0, 0, 0.2)" }}
            transition={{ duration: 0.3 }}
        >
            <VStack align="start" spacing={2}>
                <Text fontSize="lg" fontWeight="bold"><FontAwesomeIcon icon={faGlobe} /> {planet.name}</Text>
                <Text fontSize="sm">{t('Total Surface Area')}: {planet.totalSurfaceArea}</Text>
                <Text fontSize="sm">{t('Land Area')}: {planet.landArea}</Text>
                <Text fontSize="sm">{t('Water Area')}: {planet.waterArea}</Text>
                <Progress value={(planet.usedLandArea / planet.landArea) * 100} colorScheme="green" w="full" />
                <Text fontSize="sm">{t('Used Land Area')}: {planet.usedLandArea}</Text>
                <Progress value={(planet.usedWaterArea / planet.waterArea) * 100} colorScheme="blue" w="full" />
                <Text fontSize="sm">{t('Used Water Area')}: {planet.usedWaterArea}</Text>
            </VStack>
        </MotionBox>
    );    

    return (
        <Box
            bgImage="{gbgImage}"
            bgSize="cover"
            bgPosition="center"
            bgAttachment="fixed"
            minH={{ base: "480vh", md: "210vh" }}
            color="white"
            p={6}
            ref={containerRef}
        >
            <VStack spacing={8} align="stretch">
                <Flex justify="space-between" align="center" bg="rgba(0, 0, 0, 0.5)" p={4} borderRadius="xl" backdropFilter="blur(10px)">
                    <HStack>
                        <Image
                            src={`${apiUrl}/${userProfile.profilePhoto}`}
                            alt="Profile"
                            boxSize="60px"
                            borderRadius="full"
                            border="2px solid white"
                        />
                        <VStack align="start" spacing={0}>
                            <Text fontSize="2xl" fontWeight="bold">@{userProfile.username}</Text>
                            <HStack>
                                <Text fontSize="lg" fontWeight="bold" color="yellow.400">
                                    {t('EXCR')}: {totalExcrBalance}
                                </Text>
                                <IconButton
                                    icon={<FontAwesomeIcon icon={faSyncAlt} />}
                                    size="sm"
                                    variant="ghost"
                                    colorScheme="yellow"
                                    onClick={refreshTotalExcrBalance}
                                />
                            </HStack>
                        </VStack>
                    </HStack>
                    {isMobile ? (
                        <IconButton
                            icon={<FontAwesomeIcon icon={faChevronRight} />}
                            onClick={onOpen}
                            colorScheme="teal"
                            size="lg"
                        />
                    ) : (
                        <HStack spacing={4}>
                            <ActionButton icon={faGlobe} label="Create Country" onClick={() => navigate('/create-country')} />
                            <ActionButton icon={faBuilding} label="Create Buildings" onClick={() => navigate('/create-buildings')} />
                            <ActionButton icon={faCoins} label="Mining" onClick={() => navigate('/mining')} />
                            <ActionButton icon={faFlag} label="Alliance" onClick={() => navigate('/create-alliance')} />
                            <ActionButton icon={faLandmark} label="Buy Land" onClick={handleBuyLandClick} />
                            <ActionButton icon={faMoneyBill} label="Earn EXCR" onClick={() => navigate('/earn')} />
                            <ActionButton icon={faBalanceScale} label="How to Play" onClick={() => navigate('/exocrest-tutorial')} />
                        </HStack>
                    )}
                </Flex>

                <Heading size="xl" textAlign="center" textShadow="0 0 10px rgba(255,255,255,0.5)">
                    {t('Your Galactic Empire')}
                </Heading>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                    <AnimatePresence>
                        {userCountries.map((country) => (
                            <CountryCard key={country.id} country={country} />
                        ))}
                    </AnimatePresence>
                </SimpleGrid>

                {selectedCountry && (
                    <MotionBox
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        bg="rgba(0, 0, 0, 0.7)"
                        p={6}
                        borderRadius="xl"
                        backdropFilter="blur(20px)"
                        boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
                        border="1px solid rgba(255, 255, 255, 0.3)"
                    >
                        <Heading size="lg" mb={4}>{selectedCountry.name} Details</Heading>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                            <VStack align="start">
                                <Text><b>{t('Planet')}:</b> {selectedCountry.planetName}</Text>
                                <Text><b>{t('Government')}:</b> {selectedCountry.governmentType}</Text>
                                <Text><b>{t('Capital')}:</b> {selectedCountry.capital}</Text>
                                <Text><b>{t('States')}:</b> {selectedCountry.states}</Text>
                            </VStack>
                            <VStack align="start">
                                <Text><b>{t('Size')}:</b> {selectedCountry.totalArea} sq km</Text>
                                <Text><b>{t('Land')}:</b> {selectedCountry.landPercentage.toFixed(2)}%</Text>
                                <Progress value={selectedCountry.landPercentage} colorScheme="green" w="full" />
                                <Text><b>{t('Water')}:</b> {selectedCountry.waterPercentage.toFixed(2)}%</Text>
                                <Progress value={selectedCountry.waterPercentage} colorScheme="blue" w="full" />
                                <Text><b>{t('Language')}:</b> {selectedCountry.language}</Text>
                            </VStack>
                        </SimpleGrid>
                        <HStack mt={6} spacing={4} flexWrap="wrap" justifyContent="center">
                            <ActionButton icon={faMoneyBill} label="Currency" onClick={() => fetchCountryData(selectedCountry.id, 'currency')} />
                            <ActionButton icon={faCoins} label="Resources" onClick={() => fetchCountryData(selectedCountry.id, 'resources')} />
                            <ActionButton icon={faBuilding} label="Buildings" onClick={() => fetchCountryData(selectedCountry.id, 'buildings')} />
                            <ActionButton icon={faLandmark} label="Edit Country" onClick={() => handleEditCountryClick(selectedCountry)} />
                            <ActionButton icon={faMoneyBill} label="Edit Currency" onClick={() => handleEditCurrencyClick(selectedCountry)} />
                        </HStack>
                    </MotionBox>
                )}

                <Box textAlign="center" mt={6}>
                    <Button
                        colorScheme="teal"
                        variant="solid"
                        borderRadius="full"
                        onClick={() => navigate('/countries')}
                        size="lg"
                        leftIcon={<FontAwesomeIcon icon={faGlobe} />}
                        _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
                    >
                        {t('View All Countries in Exocrest')}
                    </Button>
                </Box>

                <Box mt={8}>
                    <Heading size="lg" mb={4} textAlign="center">{t('Planets Overview')}</Heading>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                        <AnimatePresence>
                            {planets.map((planet) => (
                                <PlanetCard key={planet.id} planet={planet} />
                            ))}
                        </AnimatePresence>
                    </SimpleGrid>
                </Box>
            </VStack>

            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent bg="rgba(0, 0, 0, 0.8)" color="white">
                    <DrawerCloseButton />
                    <DrawerHeader>Actions</DrawerHeader>
                    <HStack spacing={4} flexWrap="wrap" justifyContent="center">
                        <ActionButton icon={faGlobe} label="Create Country" onClick={() => navigate('/create-country')} />
                        <ActionButton icon={faBuilding} label="Create Buildings" onClick={() => navigate('/create-buildings')} />
                        <ActionButton icon={faCoins} label="Mining" onClick={() => navigate('/mining')} />
                        <ActionButton icon={faFlag} label="Alliance" onClick={() => navigate('/create-alliance')} />
                        <ActionButton icon={faLandmark} label="Buy Land" onClick={handleBuyLandClick} />
                        <ActionButton icon={faMoneyBill} label="Earn EXCR" onClick={() => navigate('/earn')} />
                        <ActionButton icon={faBalanceScale} label="How to Play" onClick={() => navigate('/exocrest-tutorial')} />
                    </HStack>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isBuyLandModalOpen} onClose={handleCloseBuyLandModal} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent bg="rgba(0, 0, 0, 0.8)" color="white" borderRadius="xl">
                    <ModalHeader>{t('Buy Land')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <FormControl>
                                <FormLabel>{t('Select Country')}</FormLabel>
                                <CustomSelect
                                    name="country"
                                    options={userCountries.map((country) => ({
                                        value: country.id,
                                        label: country.name,
                                    }))}
                                    selectedOption={selectedCountryForLandPurchase ? {
                                        value: selectedCountryForLandPurchase.id,
                                        label: selectedCountryForLandPurchase.name,
                                    } : null}
                                    setSelectedOption={handleCountryChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('Land Area (sq km)')}</FormLabel>
                                <Input
                                    type="number"
                                    placeholder={t('Enter land area')}
                                    value={landArea}
                                    onChange={handleLandAreaChange}
                                />
                            </FormControl>
                            <Box>
                                <Text>{t('EXCR Cost')}: {excrCost}</Text>
                                <Text>{t('EXCR Balance')}: {totalExcrBalance}</Text>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="green" onClick={handleBuyLand}>{t('Buy Land')}</Button>
                        <Button variant="ghost" onClick={handleCloseBuyLandModal}>{t('Cancel')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={selectedView && selectedView.type === 'editCountry'} onClose={() => setSelectedView(null)} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent bg="rgba(0, 0, 0, 0.8)" color="white" borderRadius="xl">
                    <ModalHeader>{t('Edit Country')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <FormControl>
                                <FormLabel>{t('Country Name')}</FormLabel>
                                <Input
                                    value={editCountryData.name}
                                    onChange={(e) => setEditCountryData({ ...editCountryData, name: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('Government Type')}</FormLabel>
                                <Input
                                    value={editCountryData.governmentType}
                                    onChange={(e) => setEditCountryData({ ...editCountryData, governmentType: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('Capital')}</FormLabel>
                                <Input
                                    value={editCountryData.capital}
                                    onChange={(e) => setEditCountryData({ ...editCountryData, capital: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('States')}</FormLabel>
                                <Input
                                    value={editCountryData.states}
                                    onChange={(e) => setEditCountryData({ ...editCountryData, states: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('Language')}</FormLabel>
                                <Input
                                    value={editCountryData.language}
                                    onChange={(e) => setEditCountryData({ ...editCountryData, language: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('Flag')}</FormLabel>
                                <Input
                                    type="file"
                                    onChange={(e) => setEditCountryData({ ...editCountryData, flag: e.target.files[0] })}
                                />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="green" onClick={handleEditCountrySubmit}>{t('Save')}</Button>
                        <Button variant="ghost" onClick={() => setSelectedView(null)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={selectedView && selectedView.type === 'editCurrency'} onClose={() => setSelectedView(null)} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent bg="rgba(0, 0, 0, 0.8)" color="white" borderRadius="xl">
                    <ModalHeader>{t('Edit Currency')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <FormControl>
                                <FormLabel>{t('Currency Name')}</FormLabel>
                                <Input
                                    value={editCurrencyData.name}
                                    onChange={(e) => setEditCurrencyData({ ...editCurrencyData, name: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('Ticker')}</FormLabel>
                                <Input
                                    value={editCurrencyData.ticker}
                                    onChange={(e) => setEditCurrencyData({ ...editCurrencyData, ticker: e.target.value })}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('Currency Image')}</FormLabel>
                                <Input
                                    type="file"
                                    onChange={(e) => setEditCurrencyData({ ...editCurrencyData, image: e.target.files[0] })}
                                />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="green" onClick={handleEditCurrencySubmit}>{t('Save')}</Button>
                        <Button variant="ghost" onClick={() => setSelectedView(null)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={selectedView && selectedView.type === 'currency'} onClose={() => setSelectedView(null)} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent bg="rgba(0, 0, 0, 0.8)" color="white" borderRadius="xl">
                    <ModalHeader>{t('Currency Details')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedView?.data?.length > 0 ? (
                            <VStack spacing={4} align="stretch">
                                {selectedView.data.map((currency) => (
                                    <Box key={currency.id} p={4} bg="rgba(255, 255, 255, 0.1)" borderRadius="md">
                                        <Text><b>{t('Name')}:</b> {currency.name}</Text>
                                        <Text><b>{t('Ticker')}:</b> {currency.ticker}</Text>
                                        <Text><b>{t('Total Supply')}:</b> {currency.totalSupply}</Text>
                                        <Text><b>{t('Total Balance')}:</b> {currency.totalBalance}</Text>
                                        {currency.image && (
                                            <Image src={`${apiUrl}${currency.image}`} alt={currency.name} boxSize="50px" mt={2} />
                                        )}
                                    </Box>
                                ))}
                            </VStack>
                        ) : (
                            <Text>{t('No currency data available')}</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={() => setSelectedView(null)}>{t('Close')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={selectedView && selectedView.type === 'resources'} onClose={() => setSelectedView(null)} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent bg="rgba(0, 0, 0, 0.8)" color="white" borderRadius="xl">
                    <ModalHeader>{t('Resources')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedView?.data?.length > 0 ? (
                            <VStack spacing={4} align="stretch">
                                {selectedView.data.map((resource) => (
                                    <Box key={resource.type} p={4} bg="rgba(255, 255, 255, 0.1)" borderRadius="md">
                                        <Text><b>{t('Resource')}:</b> {resource.type}</Text>
                                        <Text><b>{t('Quantity')}:</b> {parseFloat(resource.quantity).toFixed(3)}</Text>
                                    </Box>
                                ))}
                            </VStack>
                        ) : (
                            <Text>{t('No resources data available')}</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={() => setSelectedView(null)}>{t('Close')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={selectedView && selectedView.type === 'buildings'} onClose={() => setSelectedView(null)} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent bg="rgba(0, 0, 0, 0.8)" color="white" borderRadius="xl" maxW="600px">
                    <ModalHeader>{t('Buildings')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedView?.data?.length > 0 ? (
                            <>
                                <Text fontSize="lg" fontWeight="bold" mb={4}>{t('Building Types and Totals')}</Text>
                                <SimpleGrid columns={2} spacing={4} mb={6}>
                                    {Object.entries(calculateBuildingTotals(selectedView.data)).map(([type, total]) => (
                                        <Box key={type} p={3} bg="rgba(255, 255, 255, 0.1)" borderRadius="md">
                                            <Text><b>{t('Building Type')}:</b> {type}</Text>
                                            <Text><b>{t('Total Owned')}:</b> {total}</Text>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                                <Divider my={4} />
                                <Text fontSize="lg" fontWeight="bold" mb={4}>{t('Building Details')}</Text>
                                <VStack spacing={4} align="stretch">
                                    {selectedView.data.map((building) => (
                                        <Box key={building.id} p={4} bg="rgba(255, 255, 255, 0.1)" borderRadius="md">
                                            <Text><b>{t('Type')}:</b> {building.type}</Text>
                                            <Text><b>{t('Level')}:</b> {building.level}</Text>
                                            <Text><b>{t('Country')}:</b> {building.countryName}</Text>
                                        </Box>
                                    ))}
                                </VStack>
                            </>
                        ) : (
                            <Text>{t('No buildings data available')}</Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={() => setSelectedView(null)}>{t('Close')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default DashboardPage;
