import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Button, Text, VStack, Input, useToast, IconButton, Flex, Heading, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    Switch, useColorModeValue, FormControl, Spinner, useDisclosure, Alert, AlertIcon
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { RepeatIcon, AddIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import tokenAddresses from './tokens/tokenAddresses.json';
import Web3 from 'web3';
import ERC20_ABI from './abis/ERC20ABI.json';
import { useNavigate } from 'react-router-dom';
import CustomSelect from './CustomSelect';

const MotionBox = motion(Box);

const SwapBox = ({ children }) => (
    <MotionBox
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        p={6}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius="2xl"
        boxShadow="xl"
        w="full"
        maxW="md"
    >
        {children}
    </MotionBox>
);

const networks = ["Ethereum", "Binance Smart Chain", "OneLedger", "Base", "Polygon"];
const tokens = Object.keys(tokenAddresses).reduce((acc, network) => {
    acc[network] = Object.keys(tokenAddresses[network]);
    return acc;
}, {});

const blockExplorerURLs = {
    "Ethereum": "https://etherscan.io/tx/",
    "Binance Smart Chain": "https://bscscan.com/tx/",
    "Polygon": "https://polygonscan.com/tx/",
    "OneLedger": "https://mainnet-explorer.oneledger.network/tx/",
    "Base": "https://basescan.org/",
};

const rpcUrls = {
    Ethereum: process.env.REACT_APP_ETH_RPC_URL || "https://mainnet.infura.io/v3/8dc36f09afad40ef9084206ce9ddae5c",
    "Binance Smart Chain": process.env.REACT_APP_BSC_RPC_URL || "https://bsc-dataseed.binance.org/",
    "OneLedger": process.env.REACT_APP_OLT_RPC_URL || "https://mainnet-rpc.oneledger.network",
    Polygon: process.env.REACT_APP_POLYGON_RPC_URL || "https://polygon-rpc.com/",
    Base: process.env.REACT_APP_BASE_RPC_URL || "https://mainnet.base.org/",
};

const BuyPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [fromToken, setFromToken] = useState('');
    const [toToken, setToToken] = useState('');
    const [amount, setAmount] = useState('');
    const [fromBalance, setFromBalance] = useState('...');
    const [toBalance, setToBalance] = useState('...');
    const [isFirstVisit, setIsFirstVisit] = useState(true);
    const [walletPassword, setWalletPassword] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [userId, setUserId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userAddress, setUserAddress] = useState('');
    const [customTokens, setCustomTokens] = useState({});
    const { isOpen: isCustomTokenModalOpen, onOpen: onCustomTokenModalOpen, onClose: onCustomTokenModalClose } = useDisclosure();
    const [customTokenAddress, setCustomTokenAddress] = useState('');
    const [fromTokenSearch, setFromTokenSearch] = useState('');
    const [toTokenSearch, setToTokenSearch] = useState('');
    const [filteredFromTokens, setFilteredFromTokens] = useState([]);
    const [filteredToTokens, setFilteredToTokens] = useState([]);
    const [fromTokenFocused, setFromTokenFocused] = useState(false);
    const [toTokenFocused, setToTokenFocused] = useState(false);
    const [isV3, setIsV3] = useState(false);

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const hoverBg = useColorModeValue('gray.100', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'white');

    const fetchTokenBalance = useCallback(async (tokenAddress, setBalance, userAddress, selectedNetwork) => {
        if (!userAddress || !selectedNetwork) return;

        try {
            const web3 = new Web3(rpcUrls[selectedNetwork]);
            if (tokenAddress === "native") {
                const balance = await web3.eth.getBalance(userAddress);
                setBalance(web3.utils.fromWei(balance, 'ether'));
            } else {
                const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress);
                const balance = await tokenContract.methods.balanceOf(userAddress).call();
                setBalance(web3.utils.fromWei(balance, 'ether'));
            }
        } catch (error) {
            console.error("Failed to fetch token balance:", error);
            setBalance('Error');
        }
    }, []);

    const fetchAndSetTokenBalance = useCallback(async (tokenSymbol, setBalanceFn) => {
        const allCustomTokens = JSON.parse(localStorage.getItem('customTokens')) || {};
        const networkCustomTokens = allCustomTokens[selectedNetwork] || {};
        const isCustomToken = Object.hasOwnProperty.call(networkCustomTokens, tokenSymbol);
        let tokenAddress = isCustomToken
            ? networkCustomTokens[tokenSymbol]
            : tokenAddresses[selectedNetwork]?.[tokenSymbol];

        if (!tokenAddress) {
            console.error(`Address not found for token: ${tokenSymbol}`);
            setBalanceFn('Error');
            return;
        }

        await fetchTokenBalance(tokenAddress, setBalanceFn, userAddress, selectedNetwork);
    }, [selectedNetwork, userAddress, fetchTokenBalance]);

    useEffect(() => {
        if (fromTokenSearch) {
            const searchLower = fromTokenSearch.toLowerCase();
            const allTokens = [...(tokens[selectedNetwork] || []), ...(Object.keys(customTokens[selectedNetwork] || {}) || [])];
            const filtered = allTokens.filter(token => token.toLowerCase().includes(searchLower));
            setFilteredFromTokens(filtered);
        } else {
            setFilteredFromTokens(tokens[selectedNetwork] || []);
        }
    }, [fromTokenSearch, selectedNetwork, customTokens]);

    useEffect(() => {
        if (toTokenSearch) {
            const searchLower = toTokenSearch.toLowerCase();
            const allTokens = [...(tokens[selectedNetwork] || []), ...(Object.keys(customTokens[selectedNetwork] || {}) || [])];
            const filtered = allTokens.filter(token => token.toLowerCase().includes(searchLower));
            setFilteredToTokens(filtered);
        } else {
            setFilteredToTokens(tokens[selectedNetwork] || []);
        }
    }, [toTokenSearch, selectedNetwork, customTokens]);

    useEffect(() => {
        if (selectedNetwork && fromToken) {
            fetchAndSetTokenBalance(fromToken, setFromBalance);
        }

        if (selectedNetwork && toToken) {
            fetchAndSetTokenBalance(toToken, setToBalance);
        }
    }, [selectedNetwork, fromToken, toToken, fetchAndSetTokenBalance]);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setUserId(response.data.id);
                setUserAddress(response.data.walletAddress);
            } catch (error) {
                console.error('Failed to fetch user profile:', error);
            }
        };

        if (!userId) {
            fetchUserProfile();
        }
    }, [userId, apiUrl]);

    useEffect(() => {
        const storedCustomTokens = JSON.parse(localStorage.getItem('customTokens') || '{}');
        const updatedTokens = { ...tokens };
        Object.keys(storedCustomTokens).forEach(network => {
            if (!updatedTokens[network]) updatedTokens[network] = [];
            const customTokenSymbols = Object.keys(storedCustomTokens[network]);
            updatedTokens[network] = [...new Set([...updatedTokens[network], ...customTokenSymbols])];
        });
        setCustomTokens(storedCustomTokens);
    }, []);

    useEffect(() => {
        const checkFirstVisit = () => {
            const hasVisited = localStorage.getItem('hasVisited');
            setIsFirstVisit(!hasVisited);
        };
        checkFirstVisit();
    }, []);

    const handleWalletPasswordSubmit = async () => {
        if (!userId) {
            toast({
                title: t('error'),
                description: t('userIdNotFound'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`${apiUrl}/api/decrypt-reencrypt-wallet`, {
                userId,
                walletPassword,
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            if (response.data.message) {
                setIsFirstVisit(false);
                localStorage.setItem('hasVisited', 'true');
                toast({
                    title: t('walletDecryptionSuccess'),
                    description: t(response.data.message),
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Failed to decrypt wallet:', error);
            toast({
                title: t('error'),
                description: t('failedToDecryptWallet'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSwap = async () => {
        if (!selectedNetwork || !fromToken || !toToken || !amount) {
            toast({
                title: t('error'),
                description: t('pleaseFillAllFields'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (isNaN(amount) || amount <= 0) {
            toast({
                title: t('error'),
                description: t('invalidAmount'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const amountNum = parseFloat(amount);
        const fromBalanceNum = parseFloat(fromBalance);
        if (amountNum > fromBalanceNum) {
            toast({
                title: t('error'),
                description: t('insufficientFunds'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        let fromTokenAddress;
        let toTokenAddress;

        if (fromToken === 'Custom' || customTokens[selectedNetwork]?.hasOwnProperty(fromToken)) {
            fromTokenAddress = customTokens[selectedNetwork][fromToken];
        } else {
            fromTokenAddress = tokenAddresses[selectedNetwork][fromToken];
        }

        if (toToken === 'Custom' || customTokens[selectedNetwork]?.hasOwnProperty(toToken)) {
            toTokenAddress = customTokens[selectedNetwork][toToken];
        } else {
            toTokenAddress = tokenAddresses[selectedNetwork][toToken];
        }

        if (!fromTokenAddress || !toTokenAddress) {
            toast({
                title: t('error'),
                description: t('tokenAddressNotFound'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);

        try {
            const authToken = localStorage.getItem('token');
            const apiEndpoint = isV3 ? `${apiUrl}/api/v3/swap-tokens` : `${apiUrl}/api/swap-tokens`;
            const response = await axios.post(apiEndpoint, {
                network: selectedNetwork,
                fromTokenAddress,
                toTokenAddress,
                amount,
                userId,
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            await fetchAndSetTokenBalance(fromToken, setFromBalance);
            await fetchAndSetTokenBalance(toToken, setToBalance);

            setIsLoading(false);

            toast({
                title: t('swapSuccess'),
                description: (
                    <Text>
                        {t('swapExecutedSuccessfully')}
                        <Text as="a"
                            href={`${blockExplorerURLs[selectedNetwork]}${response.data.txHash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={response.data.txHash}
                            color="blue.500"
                            fontWeight="bold"
                            ml={1}
                        >
                            {response.data.txHash.slice(0, 10) + '...'}
                        </Text>
                    </Text>
                ),
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        } catch (error) {
            setIsLoading(false);
            console.error('Swap failed:', error);
            toast({
                title: t('error'),
                description: t('swapFailed', { error: error.response?.data?.message || error.message }),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleCustomTokenSubmit = async () => {
        if (!customTokenAddress) {
            toast({
                title: t('error'),
                description: t('customTokenAddressRequired'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const web3 = new Web3(rpcUrls[selectedNetwork]);
        const tokenContract = new web3.eth.Contract(ERC20_ABI, customTokenAddress);

        try {
            const symbol = await tokenContract.methods.symbol().call();
            updateCustomTokens(symbol, customTokenAddress);

            onCustomTokenModalClose();
            setCustomTokenAddress('');

            toast({
                title: t('customTokenAdded'),
                description: `${t('customTokenAddressAddedSuccessfully')} (${symbol})`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error fetching token symbol:', error);
            toast({
                title: t('error'),
                description: t('failedToFetchTokenSymbol'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const updateCustomTokens = (symbol, address) => {
        const updatedCustomTokens = { ...customTokens };

        if (!updatedCustomTokens[selectedNetwork]) {
            updatedCustomTokens[selectedNetwork] = {};
        }

        updatedCustomTokens[selectedNetwork][symbol] = address;

        setCustomTokens(updatedCustomTokens);
        localStorage.setItem('customTokens', JSON.stringify(updatedCustomTokens));
    };

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    if (!token) {
        navigate('/login');
        return null;
    }

    return (
        <Flex direction="column" align="center" justify="flex-start" minH="100vh" bg={bgColor} p={4} pb="80px">
            <MotionBox
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                mb={8}
            >
                <Heading as="h1" size="2xl" textAlign="center" color={textColor}>
                    {t('swapTokens')}
                </Heading>
            </MotionBox>

            <SwapBox>
                <VStack spacing={6} align="stretch">
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="lg" fontWeight="bold" color={textColor}>
                            {t('version')}
                        </Text>
                        <Flex alignItems="center">
                            <Box
                                w="24px"
                                h="24px"
                                borderRadius="full"
                                bg={isV3 ? "blue.500" : "green.500"}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                mr={2}
                            >
                                <Text fontSize="xs" color="white" fontWeight="bold">{isV3 ? 'V3' : 'V2'}</Text>
                            </Box>
                            <Switch
                                isChecked={isV3}
                                onChange={() => setIsV3(!isV3)}
                                colorScheme="blue"
                                size="md"
                                mt={1}
                                mb={1}
                                px={2}
                                py={1}
                                borderRadius="full"
                                borderColor="gray.200"
                                _checked={{
                                    bg: "blue.500",
                                    borderColor: "blue.500",
                                }}
                                _focus={{
                                    boxShadow: "0 0 0 3px blue.500",
                                }}
                            />
                        </Flex>
                    </Flex>

                    <FormControl>
                        <CustomSelect
                            options={
                                isV3
                                    ? ["Ethereum", "Binance Smart Chain", "Base"].map(network => ({ value: network, label: network }))
                                    : networks.map(network => ({ value: network, label: network }))
                            }
                            selectedOption={selectedNetwork ? { value: selectedNetwork, label: selectedNetwork } : null}
                            setSelectedOption={option => setSelectedNetwork(option.value)}
                            placeholder={t('selectNetwork')}
                        />
                    </FormControl>

                    {!selectedNetwork && (
                        <Alert status="info" borderRadius="md">
                            <AlertIcon />
                            {t('pleaseSelectBlockchainNetwork')}
                        </Alert>
                    )}

                    <AnimatePresence>
                        {selectedNetwork && (
                            <MotionBox
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <VStack spacing={4}>
                                    <Box w="full">
                                        <FormControl>
                                            <Flex alignItems="center" justifyContent="space-between" mb={2}>
                                                <Text fontWeight="bold" color={textColor}>{t('from')}</Text>
                                                <Text fontSize="sm" color={textColor}>
                                                    {t('balance')}: {parseFloat(fromBalance).toFixed(5)} {fromToken}
                                                </Text>
                                            </Flex>
                                            <Input
                                                placeholder={t('fromToken')}
                                                value={fromTokenSearch}
                                                onChange={(e) => setFromTokenSearch(e.target.value)}
                                                onFocus={() => setFromTokenFocused(true)}
                                                onBlur={() => setTimeout(() => setFromTokenFocused(false), 200)}
                                                bg={cardBg}
                                                border="1px"
                                                borderColor={borderColor}
                                                _hover={{ borderColor: 'blue.500' }}
                                                _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
                                            />
                                            {fromTokenFocused && (
                                                <Box
                                                    position="absolute"
                                                    w="full"
                                                    bg={cardBg}
                                                    mt={1}
                                                    shadow="lg"
                                                    zIndex="dropdown"
                                                    maxH="200px"
                                                    overflowY="auto"
                                                    borderRadius="md"
                                                >
                                                    {filteredFromTokens.map((token) => (
                                                        <Box
                                                            key={token}
                                                            p={2}
                                                            _hover={{ bg: hoverBg }}
                                                            cursor="pointer"
                                                            onClick={() => {
                                                                setFromToken(token);
                                                                setFromTokenSearch(token);
                                                                setFromTokenFocused(false);
                                                            }}
                                                        >
                                                            {token}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            )}
                                        </FormControl>
                                        <Input
                                            mt={2}
                                            placeholder={t('amount')}
                                            value={amount}
                                            onChange={e => setAmount(e.target.value)}
                                            bg={cardBg}
                                            border="1px"
                                            borderColor={borderColor}
                                            _hover={{ borderColor: 'blue.500' }}
                                            _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
                                        />
                                    </Box>

                                    <Flex justifyContent="center" w="full">
                                        <IconButton
                                            aria-label={t('flip')}
                                            icon={<RepeatIcon />}
                                            onClick={() => {
                                                const tempToken = fromToken;
                                                const tempSearch = fromTokenSearch;
                                                setFromToken(toToken);
                                                setToToken(tempToken);
                                                setFromTokenSearch(toTokenSearch);
                                                setToTokenSearch(tempSearch);
                                            }}
                                            size="lg"
                                            colorScheme="blue"
                                            isRound
                                        />
                                    </Flex>

                                    <Box w="full">
                                        <FormControl>
                                            <Flex alignItems="center" justifyContent="space-between" mb={2}>
                                                <Text fontWeight="bold" color={textColor}>{t('to')}</Text>
                                                <Text fontSize="sm" color={textColor}>
                                                    {t('balance')}: {parseFloat(toBalance).toFixed(5)} {toToken}
                                                </Text>
                                            </Flex>
                                            <Input
                                                placeholder={t('toToken')}
                                                value={toTokenSearch}
                                                onChange={(e) => setToTokenSearch(e.target.value)}
                                                onFocus={() => setToTokenFocused(true)}
                                                onBlur={() => setTimeout(() => setToTokenFocused(false), 200)}
                                                bg={cardBg}
                                                border="1px"
                                                borderColor={borderColor}
                                                _hover={{ borderColor: 'blue.500' }}
                                                _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
                                            />
                                            {toTokenFocused && (
                                                <Box
                                                    position="absolute"
                                                    w="full"
                                                    bg={cardBg}
                                                    mt={1}
                                                    shadow="lg"
                                                    zIndex="dropdown"
                                                    maxH="200px"
                                                    overflowY="auto"
                                                    borderRadius="md"
                                                >
                                                    {filteredToTokens.map((token) => (
                                                        <Box
                                                            key={token}
                                                            p={2}
                                                            _hover={{ bg: hoverBg }}
                                                            cursor="pointer"
                                                            onClick={() => {
                                                                setToToken(token);
                                                                setToTokenSearch(token);
                                                                setToTokenFocused(false);
                                                            }}
                                                        >
                                                            {token}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            )}
                                        </FormControl>
                                    </Box>

                                    <Button
                                        colorScheme="blue"
                                        w="full"
                                        onClick={handleSwap}
                                        isLoading={isLoading}
                                        loadingText={t('processing')}
                                        leftIcon={isLoading ? <Spinner size="sm" /> : null}
                                    >
                                        {t('swap')}
                                    </Button>

                                    <Button
                                        colorScheme="green"
                                        w="full"
                                        onClick={onCustomTokenModalOpen}
                                        leftIcon={<AddIcon />}
                                    >
                                        {t('addCustomToken')}
                                    </Button>
                                </VStack>
                            </MotionBox>
                        )}
                    </AnimatePresence>
                </VStack>
            </SwapBox>

            <Modal isOpen={isFirstVisit} isCentered onClose={() => setIsFirstVisit(false)}>
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBg} shadow="xl"
                    mx={[4, 0]}
                    maxW="400px">
                    <ModalHeader color={textColor}>{t('enterWalletPassword')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            type="password"
                            placeholder={t('walletPassword')}
                            value={walletPassword}
                            onChange={(e) => setWalletPassword(e.target.value)}
                            bg={cardBg}
                            border="1px"
                            borderColor={borderColor}
                            _hover={{ borderColor: 'blue.500' }}
                            _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleWalletPasswordSubmit}>
                            {t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isCustomTokenModalOpen} isCentered onClose={onCustomTokenModalClose}>
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBg} shadow="xl"
                    mx={[4, 0]}
                    maxW="400px">
                    <ModalHeader color={textColor}>{t('customTokenAddress')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder={t('enterTokenAddress')}
                            value={customTokenAddress}
                            onChange={(e) => setCustomTokenAddress(e.target.value)}
                            bg={cardBg}
                            border="1px"
                            borderColor={borderColor}
                            _hover={{ borderColor: 'blue.500' }}
                            _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleCustomTokenSubmit}>
                            {t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default BuyPage;