import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Center,
    Text,
    VStack,
    useToast,
    FormControl,
    FormLabel,
    Input,
    useColorModeValue,
    SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import CustomSelect from './CustomSelect';

const CreateAlliancePage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const [userId, setUserId] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [alliances, setAlliances] = useState([]);
    const [selectedAlliance, setSelectedAlliance] = useState(null);
    const [requirements, setRequirements] = useState([]);
    const [missingRequirements, setMissingRequirements] = useState([]);
    const [allianceType, setAllianceType] = useState(null);
    const [allianceName, setAllianceName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRequirementsModalOpen, setIsRequirementsModalOpen] = useState(false);
    const [isMissingRequirementsModalOpen, setIsMissingRequirementsModalOpen] = useState(false);
    const [isLeaveAllianceModalOpen, setIsLeaveAllianceModalOpen] = useState(false);
    const [currencyBalance, setCurrencyBalance] = useState(null);
    const [currencyTicker, setCurrencyTicker] = useState('');
    const navigate = useNavigate();

    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('gray.700', 'gray.900');
    const borderColor = useColorModeValue('gray.300', 'gray.500');
    const accentColor = useColorModeValue('teal.500', 'teal.300');

    useEffect(() => {
        const fetchUserId = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserId(response.data.id);
            } catch (error) {
                toast({
                    id: 'user-id-error',
                    title: t('Error fetching user ID'),
                    description: error.response?.data?.message || error.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchUserId();
    }, [toast, t]);

    useEffect(() => {
        const fetchCountries = async () => {
            if (!userId) return;

            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/api/planets/user-countries/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCountries(response.data);
                if (response.data.length > 0) {
                    setSelectedCountry({ value: response.data[0].id, label: response.data[0].name });
                }
            } catch (error) {
                toast({
                    id: 'countries-error',
                    title: t('Error fetching countries'),
                    description: error.response?.data?.message || error.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchCountries();
    }, [toast, t, userId]);

    const fetchAlliances = useCallback(async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiUrl}/api/alliances/alliances`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAlliances(response.data);
        } catch (error) {
            toast({
                id: 'fetch-alliances-error',
                title: t('Error fetching alliances'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    const fetchCurrencyDetails = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiUrl}/api/planets/currencies/${countryId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data.length > 0) {
                const currency = response.data[0];
                setCurrencyBalance(currency.totalBalance.toFixed(3));
                setCurrencyTicker(currency.ticker);
            }
        } catch (error) {
            toast({
                id: 'fetch-currency-error',
                title: t('Error fetching currency details'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    const checkRequirements = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiUrl}/api/alliances/check-alliance-requirements/${countryId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setRequirements(response.data.metRequirements);
            setMissingRequirements(response.data.missingRequirements || []);
            setIsMissingRequirementsModalOpen(response.data.missingRequirements && response.data.missingRequirements.length > 0);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.missing) {
                setMissingRequirements([error.response.data.missing]);
                setIsMissingRequirementsModalOpen(true);
            } else {
                toast({
                    id: 'check-requirements-error',
                    title: t('Error checking requirements'),
                    description: error.response?.data?.message || error.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    const handleCreateAlliance = async () => {
        if (!selectedCountry || !allianceType || !allianceName.trim()) {
            toast({
                id: 'missing-fields',
                title: t('Missing Fields'),
                description: t('Please fill out all fields to create an alliance'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            await axios.post(
                `${apiUrl}/api/alliances/create-alliance`,
                { countryId: selectedCountry.value, allianceType: allianceType.value, allianceName: allianceName.trim() },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: t('Alliance created successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchAlliances();
            setIsModalOpen(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.missingRequirements) {
                setMissingRequirements(error.response.data.missingRequirements);
                setIsMissingRequirementsModalOpen(true);
            } else {
                toast({
                    id: 'create-alliance-error',
                    title: t('Error creating alliance'),
                    description: error.response?.data?.message || error.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleJoinAlliance = async () => {
        if (!selectedCountry || !selectedAlliance) {
            toast({
                id: 'missing-fields',
                title: t('Missing Fields'),
                description: t('Please select both a country and an alliance'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            await axios.post(
                `${apiUrl}/api/alliances/join-alliance`,
                { countryId: selectedCountry.value, allianceId: selectedAlliance.value },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: t('Joined alliance successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchAlliances();
        } catch (error) {
            toast({
                id: 'join-alliance-error',
                title: t('Error joining alliance'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleLeaveAlliance = async () => {
        if (!selectedCountry || !selectedAlliance) {
            toast({
                id: 'missing-fields-leave',
                title: t('Missing Fields'),
                description: t('Please select both a country and an alliance to leave'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            await axios.post(
                `${apiUrl}/api/alliances/leave-alliance`,
                { countryId: selectedCountry.value, allianceId: selectedAlliance.value },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: t('Left alliance successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchAlliances();
            setIsLeaveAllianceModalOpen(false);
        } catch (error) {
            toast({
                id: 'leave-alliance-error',
                title: t('Error leaving alliance'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const allianceOptions = [
        { value: 'military', label: 'Military Alliance' },
        { value: 'economic', label: 'Economic Alliance' },
    ];

    useEffect(() => {
        if (selectedCountry && selectedCountry.value) {
            checkRequirements(selectedCountry.value);
            fetchCurrencyDetails(selectedCountry.value);
        }
    }, [selectedCountry, checkRequirements, fetchCurrencyDetails]);

    useEffect(() => {
        fetchAlliances();
    }, [fetchAlliances]);

    return (
        <Box bg={bgColor} color={textColor} p={6} borderRadius="2xl" boxShadow="2xl" minH={{ base: "350vh", md: "250vh" }} overflow="hidden">
            <VStack spacing={5} alignItems="stretch">
                <Center>
                    <Box as={FontAwesomeIcon} icon={faHandshake} boxSize="2rem" color={accentColor} />
                </Center>
                <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                    {t('Create or Join an Alliance')}
                </Text>

                {/* Create Alliance Section */}
                <Box p={4} bg={cardBg} borderRadius="lg" boxShadow="md" border="1px" borderColor={borderColor}>
                    <Text fontSize="xl" fontWeight="bold" mb={4}>
                        {t('Create an Alliance')}
                    </Text>
                    <FormControl mb={4}>
                        <FormLabel>{t('Select Country')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="country"
                                options={countries.map((country) => ({ value: country.id, label: country.name }))}
                                selectedOption={selectedCountry}
                                setSelectedOption={setSelectedCountry}
                            />
                        </Box>
                    </FormControl>
                    {currencyTicker && currencyBalance && (
                        <Text fontSize="xl" fontWeight="bold" color={accentColor}>
                            {currencyTicker} {t('Balance')}: {currencyBalance}
                        </Text>
                    )}
                    <FormControl mb={4}>
                        <FormLabel>{t('Select Alliance Type')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="allianceType"
                                options={allianceOptions}
                                selectedOption={allianceType}
                                setSelectedOption={setAllianceType}
                            />
                        </Box>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>{t('Alliance Name')}</FormLabel>
                        <Input
                            value={allianceName}
                            onChange={(e) => setAllianceName(e.target.value)}
                            placeholder={t('Enter Alliance Name')}
                            borderColor={borderColor}
                        />
                    </FormControl>
                    {missingRequirements.length > 0 && (
                        <Alert status="warning" borderRadius="lg" mb={4}>
                            <AlertIcon />
                            <AlertTitle mr={2}>{t('Missing Requirements')}</AlertTitle>
                            <AlertDescription>{t('Please check the requirements to create an alliance.')}</AlertDescription>
                        </Alert>
                    )}
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => setIsModalOpen(true)}
                        colorScheme="blue"
                        variant="solid"
                        borderRadius="2xl"
                        isLoading={isLoading}
                        isDisabled={!allianceName.trim() || !allianceType || !selectedCountry}
                    >
                        {t('Create Alliance')}
                    </Button>
                    <Button
                        onClick={() => setIsRequirementsModalOpen(true)}
                        colorScheme="teal"
                        variant="outline"
                        borderRadius="2xl"
                        mt={4}
                    >
                        {t('View Requirements')}
                    </Button>
                </Box>

                {/* Join Alliance Section */}
                <Box p={4} bg={cardBg} borderRadius="lg" boxShadow="md" border="1px" borderColor={borderColor}>
                    <Text fontSize="xl" fontWeight="bold" mb={4}>
                        {t('Join Existing Alliance')}
                    </Text>
                    <FormControl mb={4}>
                        <FormLabel>{t('Select Country')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="country"
                                options={countries.map((country) => ({ value: country.id, label: country.name }))}
                                selectedOption={selectedCountry}
                                setSelectedOption={setSelectedCountry}
                            />
                        </Box>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>{t('Select Alliance')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="alliance"
                                options={alliances.map((alliance) => ({ value: alliance.id, label: alliance.name }))}
                                selectedOption={selectedAlliance}
                                setSelectedOption={setSelectedAlliance}
                            />
                        </Box>
                    </FormControl>
                    <Button
                        onClick={handleJoinAlliance}
                        colorScheme="green"
                        variant="solid"
                        borderRadius="2xl"
                        isLoading={isLoading}
                    >
                        {t('Join Alliance')}
                    </Button>
                </Box>

                {/* Leave Alliance Section */}
                <Box p={4} bg={cardBg} borderRadius="lg" boxShadow="md" border="1px" borderColor={borderColor}>
                    <Text fontSize="xl" fontWeight="bold" mb={4}>
                        {t('Leave Alliance')}
                    </Text>
                    <FormControl mb={4}>
                        <FormLabel>{t('Select Country')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="country"
                                options={countries.map((country) => ({ value: country.id, label: country.name }))}
                                selectedOption={selectedCountry}
                                setSelectedOption={setSelectedCountry}
                            />
                        </Box>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>{t('Select Alliance')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="alliance"
                                options={alliances.map((alliance) => ({ value: alliance.id, label: alliance.name }))}
                                selectedOption={selectedAlliance}
                                setSelectedOption={setSelectedAlliance}
                            />
                        </Box>
                    </FormControl>
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faMinus} />}
                        onClick={() => setIsLeaveAllianceModalOpen(true)}
                        colorScheme="red"
                        variant="solid"
                        borderRadius="2xl"
                        isLoading={isLoading}
                        isDisabled={!selectedAlliance || !selectedCountry}
                    >
                        {t('Leave Alliance')}
                    </Button>
                </Box>

                <Button
                    onClick={() => navigate('/dashboard')}
                    colorScheme="teal"
                    variant="outline"
                    borderRadius="2xl"
                >
                    {t('Back to Dashboard')}
                </Button>

                {/* Requirements Section */}
                {requirements.length > 0 && (
                    <Box mt={6}>
                        <Text fontSize="xl" fontWeight="bold">
                            {t('Requirements to Create an Alliance')}
                        </Text>
                        <Box
                            p={4}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <SimpleGrid columns={[1, 2]} spacing={4}>
                                {requirements.map((requirement, index) => (
                                    <Box
                                        key={index}
                                        p={4}
                                        shadow="lg"
                                        borderWidth="1px"
                                        borderRadius="lg"
                                        bg='green.500'
                                        color="white"
                                    >
                                        <Text>{requirement.type}: {t('Required')} {requirement.required}, {t('Current')} {requirement.current}</Text>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        </Box>
                    </Box>
                )}
            </VStack>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent mx={[4, 0]} maxW="400px" borderRadius="2xl" boxShadow="2xl" shadow="xl">
                    <ModalHeader>{t('Confirm Alliance Creation')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t('Are you sure you want to create this alliance?')}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleCreateAlliance}>
                            {t('Create')}
                        </Button>
                        <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isRequirementsModalOpen} onClose={() => setIsRequirementsModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent mx={[4, 0]} maxW="400px" borderRadius="2xl" boxShadow="2xl" shadow="xl">
                    <ModalHeader>{t('Requirements to Create an Alliance')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t('The requirements to create an alliance are as follows:')}</Text>
                        <Box mt={4}>
                            <Text>{t('Minimum Country Area: 1000')}</Text>
                            <Text>{t('Mining Stations: 3 at level 1')}</Text>
                            <Text>{t('Military Bases: 3 at level 3')}</Text>
                            <Text>{t('Factories: 3 at level 3')}</Text>
                            <Text>{t('Houses: 3 at level 1')}</Text>
                            <Text>{t('Farms: 3 at level 1')}</Text>
                            <Text>{t('Hospital: 1 at level 1')}</Text>
                            <Text>{t('University: 1 at level 1')}</Text>
                            <Text>{t('Resources Required:')}</Text>
                            <Text>{t('Currency: 1000000')}</Text>
                            <Text>{t('Gold: 100')}</Text>
                            <Text>{t('Silver: 100')}</Text>
                            <Text>{t('Gas: 100')}</Text>
                            <Text>{t('Diamond: 50')}</Text>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={() => setIsRequirementsModalOpen(false)}>
                            {t('Close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isMissingRequirementsModalOpen} onClose={() => setIsMissingRequirementsModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent mx={[4, 0]} maxW="400px" borderRadius="2xl" boxShadow="2xl" shadow="xl">
                    <ModalHeader>{t('Missing Requirements')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t('The following requirements are missing to create an alliance:')}</Text>
                        {missingRequirements.map((requirement, index) => (
                            <Box key={index} p={4} mb={2} bg='red.500' borderRadius="lg" color="white">
                                <Text>{requirement.type}: {t('Required')} {requirement.required}, {t('Current')} {requirement.current}</Text>
                            </Box>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" onClick={() => setIsRequirementsModalOpen(true)}>
                            {t('View Full Requirements')}
                        </Button>
                        <Button variant="ghost" onClick={() => setIsMissingRequirementsModalOpen(false)}>
                            {t('Close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isLeaveAllianceModalOpen} onClose={() => setIsLeaveAllianceModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent mx={[4, 0]} maxW="400px" borderRadius="2xl" boxShadow="2xl" shadow="xl">
                    <ModalHeader>{t('Confirm Leaving Alliance')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t('Are you sure you want to leave this alliance?')}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={handleLeaveAlliance}>
                            {t('Leave')}
                        </Button>
                        <Button variant="ghost" onClick={() => setIsLeaveAllianceModalOpen(false)}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default CreateAlliancePage;
