import React, { useState } from 'react';
import {
    Box,
    VStack,
    Text,
    Button,
    useColorModeValue,
    Collapse,
    Divider,
    IconButton,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const ExocrestTutorialPage = () => {
    const { t } = useTranslation();
    const [showBuildingDetails, setShowBuildingDetails] = useState(false);
    const [showUpgradeCosts, setShowUpgradeCosts] = useState(false);
    const [showProductionDetails, setShowProductionDetails] = useState(false);
    const [showHowToPlay, setShowHowToPlay] = useState(false);

    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('gray.700', 'gray.900');
    const accentColor = useColorModeValue('teal.500', 'teal.300');

    const toggleShowBuildingDetails = () => setShowBuildingDetails(!showBuildingDetails);
    const toggleShowUpgradeCosts = () => setShowUpgradeCosts(!showUpgradeCosts);
    const toggleShowProductionDetails = () => setShowProductionDetails(!showProductionDetails);
    const toggleShowHowToPlay = () => setShowHowToPlay(!showHowToPlay);

    return (
        <Box bg={bgColor} color={textColor} p={6} borderRadius="2xl" boxShadow="2xl" minH="210vh" overflow="hidden">
            <VStack spacing={5} alignItems="stretch">
                <Text fontSize="3xl" fontWeight="bold" textAlign="center" color={accentColor}>
                    {t('Exocrest Tutorial')}
                </Text>

                <Box bg={cardBg} p={4} borderRadius="lg" boxShadow="md">
                    <Button onClick={toggleShowBuildingDetails} w="full" textAlign="left" justifyContent="space-between" bg={cardBg}>
                        <Text>{t('Building Details')}</Text>
                        <IconButton icon={showBuildingDetails ? <ChevronUpIcon /> : <ChevronDownIcon />} />
                    </Button>
                    <Collapse in={showBuildingDetails}>
                        <Text mt={4}>
                            {t('Learn about the various buildings you can construct in Exocrest.')}
                            <br /><br />
                            <b>{t('House')}</b>: {t('Provides shelter for your citizens.')}
                            <br />
                            <b>{t('Factory')}</b>: {t('Produces goods and resources.')}
                            <br />
                            <b>{t('Farm')}</b>: {t('Produces food to sustain your population.')}
                            <br />
                            <b>{t('Hospital')}</b>: {t('Provides medical care for your citizens.')}
                            <br />
                            <b>{t('University')}</b>: {t('Increases knowledge and research.')}
                            <br />
                            <b>{t('Military Base')}</b>: {t('Trains and equips your military forces.')}
                            <br />
                            <b>{t('Mining Station')}</b>: {t('Produces various resources such as wood, stone, gold, silver, oil, gas, and diamond.')}
                        </Text>
                    </Collapse>
                </Box>

                <Divider />

                <Box bg={cardBg} p={4} borderRadius="lg" boxShadow="md">
                    <Button onClick={toggleShowUpgradeCosts} w="full" textAlign="left" justifyContent="space-between" bg={cardBg}>
                        <Text>{t('Upgrade Costs')}</Text>
                        <IconButton icon={showUpgradeCosts ? <ChevronUpIcon /> : <ChevronDownIcon />} />
                    </Button>
                    <Collapse in={showUpgradeCosts}>
                        <Text mt={4}>
                            {t('Understand the costs associated with upgrading your buildings.')}
                            <br /><br />
                            <b>{t('House')}</b>:
                            <br /> Level 1: Currency: 500
                            <br /> Level 2: Wood: 50, Stone: 25, Currency: 1000, EXCR: 10
                            <br /> Level 3: Wood: 100, Stone: 50, Currency: 2000, EXCR: 20
                            <br /> Level 4: Wood: 200, Stone: 100, Currency: 4000, EXCR: 40
                            <br /> Level 5: Wood: 400, Stone: 200, Currency: 8000, EXCR: 80
                            <br /> Level 6: Wood: 800, Stone: 400, Currency: 16000, EXCR: 160
                            <br /> Level 7: Wood: 1600, Stone: 800, Currency: 32000, EXCR: 320
                            <br /> Level 8: Wood: 3200, Stone: 1600, Currency: 64000, EXCR: 640
                            <br /> Level 9: Wood: 6400, Stone: 3200, Currency: 128000, EXCR: 1280
                            <br />
                            <b>{t('Factory')}</b>:
                            <br /> Level 1: Currency: 1000
                            <br /> Level 2: Metal: 100, Energy: 50, Currency: 2000, EXCR: 20
                            <br /> Level 3: Metal: 200, Energy: 100, Currency: 4000, EXCR: 40
                            <br /> Level 4: Metal: 400, Energy: 200, Currency: 8000, EXCR: 80
                            <br /> Level 5: Metal: 800, Energy: 400, Currency: 16000, EXCR: 160
                            <br /> Level 6: Metal: 1600, Energy: 800, Currency: 32000, EXCR: 320
                            <br /> Level 7: Metal: 3200, Energy: 1600, Currency: 64000, EXCR: 640
                            <br /> Level 8: Metal: 6400, Energy: 3200, Currency: 128000, EXCR: 1280
                            <br /> Level 9: Metal: 12800, Energy: 6400, Currency: 256000, EXCR: 2560
                            <br />
                            <b>{t('Farm')}</b>:
                            <br /> Level 1: Currency: 750
                            <br /> Level 2: Water: 75, Minerals: 40, Currency: 1500, EXCR: 15
                            <br /> Level 3: Water: 150, Minerals: 80, Currency: 3000, EXCR: 30
                            <br /> Level 4: Water: 300, Minerals: 160, Currency: 6000, EXCR: 60
                            <br /> Level 5: Water: 600, Minerals: 320, Currency: 12000, EXCR: 120
                            <br /> Level 6: Water: 1200, Minerals: 640, Currency: 24000, EXCR: 240
                            <br /> Level 7: Water: 2400, Minerals: 1280, Currency: 48000, EXCR: 480
                            <br /> Level 8: Water: 4800, Minerals: 2560, Currency: 96000, EXCR: 960
                            <br /> Level 9: Water: 9600, Minerals: 5120, Currency: 192000, EXCR: 1920
                            <br />
                            <b>{t('Hospital')}</b>:
                            <br /> Level 1: Currency: 1200
                            <br /> Level 2: Medical Supplies: 60, Technology: 30, Currency: 2400, EXCR: 24
                            <br /> Level 3: Medical Supplies: 120, Technology: 60, Currency: 4800, EXCR: 48
                            <br /> Level 4: Medical Supplies: 240, Technology: 120, Currency: 9600, EXCR: 96
                            <br /> Level 5: Medical Supplies: 480, Technology: 240, Currency: 19200, EXCR: 192
                            <br /> Level 6: Medical Supplies: 960, Technology: 480, Currency: 38400, EXCR: 384
                            <br /> Level 7: Medical Supplies: 1920, Technology: 960, Currency: 76800, EXCR: 768
                            <br /> Level 8: Medical Supplies: 3840, Technology: 1920, Currency: 153600, EXCR: 1536
                            <br /> Level 9: Medical Supplies: 7680, Technology: 3840, Currency: 307200, EXCR: 3072
                            <br />
                            <b>{t('University')}</b>:
                            <br /> Level 1: Currency: 1000
                            <br /> Level 2: Books: 50, Research Materials: 25, Currency: 2000, EXCR: 20
                            <br /> Level 3: Books: 100, Research Materials: 50, Currency: 4000, EXCR: 40
                            <br /> Level 4: Books: 200, Research Materials: 100, Currency: 8000, EXCR: 80
                            <br /> Level 5: Books: 400, Research Materials: 200, Currency: 16000, EXCR: 160
                            <br /> Level 6: Books: 800, Research Materials: 400, Currency: 32000, EXCR: 320
                            <br /> Level 7: Books: 1600, Research Materials: 800, Currency: 64000, EXCR: 640
                            <br /> Level 8: Books: 3200, Research Materials: 1600, Currency: 128000, EXCR: 1280
                            <br /> Level 9: Books: 6400, Research Materials: 3200, Currency: 256000, EXCR: 2560
                            <br />
                            <b>{t('Military Base')}</b>:
                            <br /> Level 1: Currency: 1500
                            <br /> Level 2: Weapons: 75, Armor: 40, Currency: 3000, EXCR: 30
                            <br /> Level 3: Weapons: 150, Armor: 80, Currency: 6000, EXCR: 60
                            <br /> Level 4: Weapons: 300, Armor: 160, Currency: 12000, EXCR: 120
                            <br /> Level 5: Weapons: 600, Armor: 320, Currency: 24000, EXCR: 240
                            <br /> Level 6: Weapons: 1200, Armor: 640, Currency: 48000, EXCR: 480
                            <br /> Level 7: Weapons: 2400, Armor: 1280, Currency: 96000, EXCR: 960
                            <br /> Level 8: Weapons: 4800, Armor: 2560, Currency: 192000, EXCR: 1920
                            <br /> Level 9: Weapons: 9600, Armor: 5120, Currency: 384000, EXCR: 3840
                            <br />
                            <b>{t('Mining Station')}</b>:
                            <br /> Creation Cost: People: 100, Food: 100, EXCR: 100
                            <br /> Level 1: People: 100, Food: 100, EXCR: 100
                            <br /> Level 2: People: 200, Food: 200, EXCR: 200
                            <br /> Level 3: People: 400, Food: 400, EXCR: 400
                            <br /> Level 4: People: 800, Food: 800, EXCR: 800
                            <br /> Level 5: People: 1600, Food: 1600, EXCR: 1600
                            <br /> Level 6: People: 3200, Food: 3200, EXCR: 3200
                            <br /> Level 7: People: 6400, Food: 6400, EXCR: 6400
                            <br /> Level 8: People: 12800, Food: 12800, EXCR: 12800
                            <br /> Level 9: People: 25600, Food: 25600, EXCR: 25600
                        </Text>
                    </Collapse>
                </Box>

                <Divider />

                <Box bg={cardBg} p={4} borderRadius="lg" boxShadow="md">
                    <Button onClick={toggleShowProductionDetails} w="full" textAlign="left" justifyContent="space-between" bg={cardBg}>
                        <Text>{t('Building Production Details')}</Text>
                        <IconButton icon={showProductionDetails ? <ChevronUpIcon /> : <ChevronDownIcon />} />
                    </Button>
                    <Collapse in={showProductionDetails}>
                        <Text mt={4}>
                            {t('Detailed information about the production capabilities of each building.')}
                            <br /><br />
                            <b>{t('House')}</b>: {t('Increases population capacity.')}
                            <br /> Level 1: People: 100
                            <br /> Level 2: People: 200
                            <br /> Level 3: People: 400
                            <br /> Level 4: People: 800
                            <br /> Level 5: People: 1600
                            <br /> Level 6: People: 3200
                            <br /> Level 7: People: 6400
                            <br /> Level 8: People: 12800
                            <br /> Level 9: People: 25600
                            <br />
                            <b>{t('Factory')}</b>: {t('Produces goods at a rate determined by its level.')}
                            <br /> Level 1: Metal: 50, Energy: 50, Technology: 30, EXCR: 30
                            <br /> Level 2: Metal: 100, Energy: 100, Technology: 60, EXCR: 60
                            <br /> Level 3: Metal: 200, Energy: 200, Technology: 120, EXCR: 120
                            <br /> Level 4: Metal: 400, Energy: 400, Technology: 240, EXCR: 240
                            <br /> Level 5: Metal: 800, Energy: 800, Technology: 480, EXCR: 480
                            <br /> Level 6: Metal: 1600, Energy: 1600, Technology: 960, EXCR: 960
                            <br /> Level 7: Metal: 3200, Energy: 3200, Technology: 1920, EXCR: 1920
                            <br /> Level 8: Metal: 6400, Energy: 6400, Technology: 3840, EXCR: 3840
                            <br /> Level 9: Metal: 12800, Energy: 12800, Technology: 7680, EXCR: 7680
                            <br />
                            <b>{t('Farm')}</b>: {t('Produces food based on the level and available resources.')}
                            <br /> Level 1: Food: 100, Water: 100, EXCR: 10
                            <br /> Level 2: Food: 200, Water: 200, EXCR: 20
                            <br /> Level 3: Food: 400, Water: 400, EXCR: 40
                            <br /> Level 4: Food: 800, Water: 800, EXCR: 80
                            <br /> Level 5: Food: 1600, Water: 1600, EXCR: 160
                            <br /> Level 6: Food: 3200, Water: 3200, EXCR: 320
                            <br /> Level 7: Food: 6400, Water: 6400, EXCR: 640
                            <br /> Level 8: Food: 12800, Water: 12800, EXCR: 1280
                            <br /> Level 9: Food: 25600, Water: 25600, EXCR: 2560
                            <br />
                            <b>{t('Hospital')}</b>: {t('Improves health and reduces disease outbreak.')}
                            <br /> Level 1: Medical Supplies: 60, Technology: 30, EXCR: 15
                            <br /> Level 2: Medical Supplies: 120, Technology: 60, EXCR: 30
                            <br /> Level 3: Medical Supplies: 240, Technology: 120, EXCR: 60
                            <br /> Level 4: Medical Supplies: 480, Technology: 240, EXCR: 120
                            <br /> Level 5: Medical Supplies: 960, Technology: 480, EXCR: 240
                            <br /> Level 6: Medical Supplies: 1920, Technology: 960, EXCR: 480
                            <br /> Level 7: Medical Supplies: 3840, Technology: 1920, EXCR: 960
                            <br /> Level 8: Medical Supplies: 7680, Technology: 3840, EXCR: 1920
                            <br /> Level 9: Medical Supplies: 15360, Technology: 7680, EXCR: 3840
                            <br />
                            <b>{t('University')}</b>: {t('Enhances research capabilities and technological advancement.')}
                            <br /> Level 1: Books: 50, Research Materials: 25, EXCR: 10
                            <br /> Level 2: Books: 100, Research Materials: 50, EXCR: 20
                            <br /> Level 3: Books: 200, Research Materials: 100, EXCR: 40
                            <br /> Level 4: Books: 400, Research Materials: 200, EXCR: 80
                            <br /> Level 5: Books: 800, Research Materials: 400, EXCR: 160
                            <br /> Level 6: Books: 1600, Research Materials: 800, EXCR: 320
                            <br /> Level 7: Books: 3200, Research Materials: 1600, EXCR: 640
                            <br /> Level 8: Books: 6400, Research Materials: 3200, EXCR: 1280
                            <br /> Level 9: Books: 12800, Research Materials: 6400, EXCR: 2560
                            <br />
                            <b>{t('Military Base')}</b>: {t('Trains soldiers and produces military equipment.')}
                            <br /> Level 1: Weapons: 75, Armor: 40, EXCR: 15
                            <br /> Level 2: Weapons: 150, Armor: 80, EXCR: 30
                            <br /> Level 3: Weapons: 300, Armor: 160, EXCR: 60
                            <br /> Level 4: Weapons: 600, Armor: 320, EXCR: 120
                            <br /> Level 5: Weapons: 1200, Armor: 640, EXCR: 240
                            <br /> Level 6: Weapons: 2400, Armor: 1280, EXCR: 480
                            <br /> Level 7: Weapons: 4800, Armor: 2560, EXCR: 960
                            <br /> Level 8: Weapons: 9600, Armor: 5120, EXCR: 1920
                            <br /> Level 9: Weapons: 19200, Armor: 10240, EXCR: 3840
                            <br />
                            <b>{t('Mining Station')}</b>: {t('Produces various resources such as wood, stone, gold, silver, oil, gas, and diamond.')}
                            <br /> Level 1: Wood: 10, Stone: 10, Gold: 15, Silver: 15, Oil: 10, Gas: 12, Diamond: 5
                            <br /> Level 2: Wood: 20, Stone: 20, Gold: 30, Silver: 30, Oil: 20, Gas: 24, Diamond: 10
                            <br /> Level 3: Wood: 40, Stone: 40, Gold: 60, Silver: 60, Oil: 40, Gas: 48, Diamond: 20
                            <br /> Level 4: Wood: 80, Stone: 80, Gold: 120, Silver: 120, Oil: 80, Gas: 96, Diamond: 40
                            <br /> Level 5: Wood: 160, Stone: 160, Gold: 240, Silver: 240, Oil: 160, Gas: 192, Diamond: 80
                            <br /> Level 6: Wood: 320, Stone: 320, Gold: 480, Silver: 480, Oil: 320, Gas: 384, Diamond: 160
                            <br /> Level 7: Wood: 640, Stone: 640, Gold: 960, Silver: 960, Oil: 640, Gas: 768, Diamond: 320
                            <br /> Level 8: Wood: 1280, Stone: 1280, Gold: 1920, Silver: 1920, Oil: 1280, Gas: 1536, Diamond: 640
                            <br /> Level 9: Wood: 2560, Stone: 2560, Gold: 3840, Silver: 3840, Oil: 2560, Gas: 3072, Diamond: 1280
                        </Text>
                    </Collapse>
                </Box>

                <Divider />

                <Box bg={cardBg} p={4} borderRadius="lg" boxShadow="md">
                    <Button onClick={toggleShowHowToPlay} w="full" textAlign="left" justifyContent="space-between" bg={cardBg}>
                        <Text>{t('How to Play')}</Text>
                        <IconButton icon={showHowToPlay ? <ChevronUpIcon /> : <ChevronDownIcon />} />
                    </Button>
                    <Collapse in={showHowToPlay}>
                        <Text mt={4}>
                            {t('Follow this guide to get started with Exocrest.')}
                            <br /><br />
                            <b>{t('Step 1')}</b>: {t('Create your account and login.')}
                            <br />
                            <b>{t('Step 2')}</b>: {t('Begin by constructing basic buildings like Houses and Farms.')}
                            <br />
                            <b>{t('Step 3')}</b>: {t('Gather resources and upgrade your buildings.')}
                            <br />
                            <b>{t('Step 4')}</b>: {t('Expand your territory by purchasing new land.')}
                            <br />
                            <b>{t('Step 5')}</b>: {t('Train your military to protect your empire.')}
                            <br />
                            <b>{t('Step 6')}</b>: {t('Engage in research to advance your civilization.')}
                            <br />
                            <b>{t('Step 7')}</b>: {t('Keep your citizens happy and healthy with Hospitals and Universities.')}
                        </Text>
                    </Collapse>
                </Box>

                <Divider />
            </VStack>
        </Box>
    );
};

export default ExocrestTutorialPage;
