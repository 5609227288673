import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Button,
  Flex,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faUserLock, faExchangeAlt, faCog, faDatabase, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 48em)");
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.200');
  const headingColor = useColorModeValue('blue.600', 'blue.300');

  const sections = [
    {
      title: t('privacy.sections.collect.title'),
      icon: faDatabase,
      content: t('privacy.sections.collect.content')
    },
    {
      title: t('privacy.sections.use.title'),
      icon: faCog,
      content: t('privacy.sections.use.content')
    },
    {
      title: t('privacy.sections.security.title'),
      icon: faShieldAlt,
      content: t('privacy.sections.security.content')
    },
    {
      title: t('privacy.sections.sharing.title'),
      icon: faExchangeAlt,
      content: t('privacy.sections.sharing.content')
    },
    {
      title: t('privacy.sections.rights.title'),
      icon: faUserLock,
      content: t('privacy.sections.rights.content')
    },
    {
      title: t('privacy.sections.contact.title'),
      icon: faEnvelope,
      content: t('privacy.sections.contact.content')
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const handleBackClick = () => {
    navigate(-1); // This will navigate back to the previous page
  };

  return (
    <Box bg={bgColor} minHeight="100vh" py={10}>
      <Container maxW="container.xl">
        <VStack spacing={10} as={motion.div} variants={containerVariants} initial="hidden" animate="visible">
          <MotionBox variants={itemVariants}>
            <Heading as="h1" size="2xl" textAlign="center" color={headingColor} mb={4}>
              {t('privacy.title')}
            </Heading>
            <Text fontSize="xl" textAlign="center" color={textColor}>
              {t('privacy.subtitle')}
            </Text>
          </MotionBox>

          {sections.map((section, index) => (
            <MotionBox
              key={index}
              variants={itemVariants}
              width="full"
              bg={cardBgColor}
              p={6}
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: 'translateY(-5px)', boxShadow: '2xl' }}
            >
              <Flex align="center" mb={4}>
                <Icon as={FontAwesomeIcon} icon={section.icon} boxSize={8} color={headingColor} mr={4} />
                <Heading as="h2" size="lg" color={headingColor}>
                  {section.title}
                </Heading>
              </Flex>
              <Text color={textColor}>{section.content}</Text>
            </MotionBox>
          ))}

          <MotionBox variants={itemVariants} width="full">
            <Heading as="h2" size="lg" mb={4} color={headingColor}>
              {t('privacy.changes.title')}
            </Heading>
            <Text color={textColor} mb={4}>
              {t('privacy.changes.content')}
            </Text>
            <Text color={textColor} fontWeight="bold">
              {t('privacy.lastUpdated', { date: 'July 21, 2024' })}
            </Text>
          </MotionBox>

          <MotionBox variants={itemVariants} width="full">
            <Button
              colorScheme="blue"
              size="lg"
              width={isMobile ? "full" : "auto"}
              boxShadow="md"
              _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
              onClick={handleBackClick}
            >
              {t('privacy.backButton')}
            </Button>
          </MotionBox>
        </VStack>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage;