import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, VStack, Text, Button, Input, useToast,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    useDisclosure, Flex, Icon, SimpleGrid, Spinner, Progress,
    useColorModeValue, useMediaQuery,
    Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
    AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
    Link, Heading, Container
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faUpload, faTrash, faEye, faPlus, faBars } from '@fortawesome/free-solid-svg-icons';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const AnimatedSVG = () => (
    <Box as="svg" width="200px" height="200px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <motion.path
            d="M10,50 C10,30 30,10 50,10 C70,10 90,30 90,50 C90,70 70,90 50,90 C30,90 10,70 10,50"
            fill="none"
            stroke="#3182CE"
            strokeWidth="4"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
        />
        <motion.path
            d="M30,50 L45,65 L70,40"
            fill="none"
            stroke="#3182CE"
            strokeWidth="4"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1, delay: 1, repeat: Infinity, ease: "easeInOut" }}
        />
    </Box>
);

const WorkSpace = () => {
    const [folders, setFolders] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { isOpen: isNewFolderOpen, onOpen: onNewFolderOpen, onClose: onNewFolderClose } = useDisclosure();
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isDeleteFolderOpen, onOpen: onDeleteFolderOpen, onClose: onDeleteFolderClose } = useDisclosure();
    const { isOpen: isViewDocumentOpen, onOpen: onViewDocumentOpen, onClose: onViewDocumentClose } = useDisclosure();
    const [newFolderName, setNewFolderName] = useState('');
    const [folderToDelete, setFolderToDelete] = useState(null);
    const [documentToView, setDocumentToView] = useState(null);
    const toast = useToast();
    const { t } = useTranslation();
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    const cancelRef = React.useRef();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');

    const fetchFolders = useCallback(async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/api/document-folders`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setFolders(response.data);
        } catch (error) {
            console.error('Error fetching folders:', error);
            toast({
                title: t('Error'),
                description: t('Failed to fetch folders'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    }, [t, toast]);

    useEffect(() => {
        fetchFolders();
    }, [fetchFolders]);

    const createFolder = async () => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            await axios.post(`${apiUrl}/api/document-folders`, { name: newFolderName }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            onNewFolderClose();
            setNewFolderName('');
            toast({
                title: t('Success'),
                description: t('Folder created successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchFolders();
        } catch (error) {
            console.error('Error creating folder:', error);
            toast({
                title: t('Error'),
                description: t('Failed to create folder'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const deleteFolder = async () => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            await axios.delete(`${apiUrl}/api/document-folders/${folderToDelete}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            onDeleteFolderClose();
            setFolderToDelete(null);
            toast({
                title: t('Success'),
                description: t('Folder deleted successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            if (selectedFolder === folderToDelete) {
                setSelectedFolder(null);
                setDocuments([]);
            }
            fetchFolders();
        } catch (error) {
            console.error('Error deleting folder:', error);
            toast({
                title: t('Error'),
                description: t('Failed to delete folder'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const uploadDocument = async (file, folderId) => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const formData = new FormData();
            formData.append('file', file);

            await axios.post(`${apiUrl}/api/document-folders/${folderId}/documents`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });

            toast({
                title: t('Success'),
                description: t('Document uploaded successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchDocuments(folderId);
        } catch (error) {
            console.error('Error uploading document:', error);
            toast({
                title: t('Error'),
                description: t('Failed to upload document'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setUploadProgress(0);
        }
    };

    const deleteDocument = async (documentId) => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            await axios.delete(`${apiUrl}/api/documents/${documentId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            toast({
                title: t('Success'),
                description: t('Document deleted successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchDocuments(selectedFolder);
        } catch (error) {
            console.error('Error deleting document:', error);
            toast({
                title: t('Error'),
                description: t('Failed to delete document'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchDocuments = async (folderId) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/api/document-folders/${folderId}/documents`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setDocuments(response.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
            toast({
                title: t('Error'),
                description: t('Failed to fetch documents'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    };

    const handleFolderClick = (folderId) => {
        setSelectedFolder(folderId);
        fetchDocuments(folderId);
        if (!isLargerThan768) {
            onDrawerClose();
        }
    };

    const handleFileUpload = (event, folderId) => {
        const file = event.target.files[0];
        if (file) {
            uploadDocument(file, folderId);
        }
    };

    const viewDocument = async (documentId) => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/api/documents/${documentId}/view`, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            setDocumentToView(url);
            onViewDocumentOpen();
        } catch (error) {
            console.error('Error viewing document:', error);
            toast({
                title: t('Error'),
                description: t('Failed to view document'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const FolderList = () => (
        <VStack align="stretch" w="full" spacing={4}>
            <Button
                leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} />}
                onClick={onNewFolderOpen}
                colorScheme="blue"
                w="full"
                borderRadius="full"
                boxShadow="md"
                _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                transition="all 0.2s"
            >
                {t('New Folder')}
            </Button>
            <AnimatePresence>
                {folders.map((folder) => (
                    <MotionBox
                        key={folder.id}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <MotionFlex
                            align="center"
                            p={4}
                            bg={selectedFolder === folder.id ? 'blue.500' : cardBgColor}
                            color={selectedFolder === folder.id ? 'white' : textColor}
                            borderRadius="lg"
                            cursor="pointer"
                            boxShadow="md"
                            _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                            onClick={() => handleFolderClick(folder.id)}
                            transition="all 0.2s"
                        >
                            <Icon as={FontAwesomeIcon} icon={faFolder} mr={3} fontSize="xl" />
                            <Text flex={1} fontWeight="medium">{folder.name}</Text>
                            <Button
                                size="sm"
                                colorScheme="red"
                                leftIcon={<Icon as={FontAwesomeIcon} icon={faTrash} />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFolderToDelete(folder.id);
                                    onDeleteFolderOpen();
                                }}
                                borderRadius="full"
                                _hover={{ transform: 'scale(1.05)' }}
                                transition="all 0.2s"
                            >
                                {t('Delete')}
                            </Button>
                        </MotionFlex>
                    </MotionBox>
                ))}
            </AnimatePresence>
        </VStack>
    );

    const DocumentGrid = () => (
        <Box>
            {selectedFolder ? (
                <>
                    <Flex justify="space-between" align="center" mb={5}>
                        <Heading size="lg">{t('Documents')}</Heading>
                        <Button
                            as="label"
                            htmlFor="file-upload"
                            leftIcon={<Icon as={FontAwesomeIcon} icon={faUpload} />}
                            colorScheme="green"
                            borderRadius="full"
                            boxShadow="md"
                            _hover={{ boxShadow: 'lg', transform: 'translateY(-2px)' }}
                            transition="all 0.2s"
                        >
                            {t('Upload Document')}
                            <input
                                id="file-upload"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileUpload(e, selectedFolder)}
                            />
                        </Button>
                    </Flex>

                    {uploadProgress > 0 && (
                        <Progress value={uploadProgress} size="sm" colorScheme="green" mb={5} borderRadius="full" />
                    )}

                    {isLoading ? (
                        <Flex justify="center" align="center" h="200px">
                            <Spinner size="xl" color="blue.500" thickness="4px" />
                        </Flex>
                    ) : (
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                            <AnimatePresence>
                                {documents.map((doc) => (
                                    <MotionBox
                                        key={doc.id}
                                        initial={{ opacity: 0, scale: 0.9 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.9 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <MotionFlex
                                            direction="column"
                                            p={5}
                                            borderRadius="xl"
                                            bg={cardBgColor}
                                            boxShadow="lg"
                                            _hover={{ boxShadow: 'xl', transform: 'translateY(-4px)' }}
                                            transition="all 0.3s"
                                        >
                                            <Icon as={FontAwesomeIcon} icon={faFile} fontSize="3xl" mb={3} color="blue.500" />
                                            <Text fontWeight="bold" mb={3} isTruncated>{doc.name}</Text>
                                            <Flex justify="space-between">
                                                <Button
                                                    size="sm"
                                                    colorScheme="blue"
                                                    leftIcon={<Icon as={FontAwesomeIcon} icon={faEye} />}
                                                    onClick={() => viewDocument(doc.id)}
                                                    borderRadius="full"
                                                    _hover={{ transform: 'scale(1.05)' }}
                                                    transition="all 0.2s"
                                                >
                                                    {t('View')}
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    colorScheme="red"
                                                    leftIcon={<Icon as={FontAwesomeIcon} icon={faTrash} />}
                                                    onClick={() => deleteDocument(doc.id)}
                                                    borderRadius="full"
                                                    _hover={{ transform: 'scale(1.05)' }}
                                                    transition="all 0.2s"
                                                >
                                                    {t('Delete')}
                                                </Button>
                                            </Flex>
                                        </MotionFlex>
                                    </MotionBox>
                                ))}
                            </AnimatePresence>
                        </SimpleGrid>
                    )}
                </>
            ) : (
                <Flex direction="column" align="center" justify="center" height="50vh">
                    <AnimatedSVG />
                    <Text mt={4} fontSize="xl" fontWeight="bold">
                        {t('No folder selected')}
                    </Text>
                    <Text mt={2} textAlign="center" maxW="400px">
                        {t('Select a folder to view its documents or create a new folder to get started.')}
                    </Text>
                    {!isLargerThan768 && (
                        <Button
                            mt={4}
                            colorScheme="blue"
                            onClick={onDrawerOpen}
                            leftIcon={<Icon as={FontAwesomeIcon} icon={faFolder} />}
                            borderRadius="full"
                        >
                            {t('Open Folders')}
                        </Button>
                    )}
                </Flex>
            )}
        </Box>
    );

    return (
        <Flex direction="column" minH="100vh" bg={bgColor} color={textColor}>
            <Box p={5} shadow="lg" bg={cardBgColor}>
                <Container maxW="container.xl">
                    <Flex justify="space-between" align="center">
                        <Heading as="h1" size="xl">{t('WorkSpace')}</Heading>
                        {!isLargerThan768 && (
                            <Button onClick={onDrawerOpen} variant="ghost" borderRadius="full">
                                <Icon as={FontAwesomeIcon} icon={faBars} fontSize="2xl" />
                            </Button>
                        )}
                    </Flex>
                </Container>
            </Box>

            <Container maxW="container.xl" py={8}>
                {isLargerThan768 ? (
                    <Flex>
                        <Box w="300px" mr={8}>
                            <FolderList />
                        </Box>
                        <Box flex={1}>
                            <DocumentGrid />
                        </Box>
                    </Flex>
                ) : (
                    <Box>
                        <DocumentGrid />
                    </Box>
                )}
            </Container>

            <Drawer isOpen={isDrawerOpen} placement="left" onClose={onDrawerClose} size="full">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{t('Folders')}</DrawerHeader>
                    <DrawerBody>
                        <FolderList />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isNewFolderOpen} onClose={onNewFolderClose} isCentered>
                <ModalOverlay />
                <ModalContent mx={4} borderRadius="xl">
                    <ModalHeader>{t('Create New Folder')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder={t('Folder Name')}
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                            borderRadius="full"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={createFolder} borderRadius="full">
                            {t('Create')}
                        </Button>
                        <Button variant="ghost" onClick={onNewFolderClose} borderRadius="full">{t('Cancel')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={isDeleteFolderOpen}
                leastDestructiveRef={cancelRef}
                onClose={onDeleteFolderClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent
                        borderRadius="xl"
                        mx={4}
                        my={2}
                        maxW={{ base: "90%", md: "400px" }}
                    >
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('Delete Folder')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t('Are you sure? This will delete the folder and all its contents.')}
                        </AlertDialogBody>

                        <AlertDialogFooter flexDirection={{ base: "column", sm: "row" }} alignItems="stretch">
                            <Button
                                ref={cancelRef}
                                onClick={onDeleteFolderClose}
                                borderRadius="full"
                                w={{ base: "full", sm: "auto" }}
                                mb={{ base: 2, sm: 0 }}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={deleteFolder}
                                borderRadius="full"
                                w={{ base: "full", sm: "auto" }}
                                ml={{ base: 0, sm: 3 }}
                            >
                                {t('Delete')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal isOpen={isViewDocumentOpen} onClose={onViewDocumentClose} size="full">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('View Document')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {documentToView && (
                            <iframe
                                src={documentToView}
                                style={{ width: '100%', height: '80vh', border: 'none', borderRadius: '8px' }}
                                title="Document Viewer"
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onViewDocumentClose} borderRadius="full">
                            {t('Close')}
                        </Button>
                        <Link href={documentToView} download>
                            <Button colorScheme="green" borderRadius="full">{t('Download')}</Button>
                        </Link>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default WorkSpace;