import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Link,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  VStack,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  MenuGroup,
  Badge,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUserGear, 
  faBars, 
  faChevronDown, 
  faGlobe,
  faWandMagicSparkles,
  faHatWizard,
  faPodcast,
} from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import huddlzLogo from '../images/maximo-ai-logo-circle.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserProfileContent from './UserProfileContent';
import '@fontsource/lexend';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionIconButton = motion(IconButton);
const MotionImage = motion(Image);

const Header = () => {
  const { isOpen: isMobileOpen, onOpen: onMobileOpen, onClose: onMobileClose } = useDisclosure();
  const { isOpen: isProfileOpen, onClose: onProfileClose } = useDisclosure();

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedVersion, setSelectedVersion] = useState(localStorage.getItem('endpoint') || 'v1');

  useEffect(() => {
    if (!localStorage.getItem('endpoint')) {
      localStorage.setItem('endpoint', 'v1');
    }
  }, []);

  const handleVersionChange = (version) => {
    setSelectedVersion(version);
    localStorage.setItem('endpoint', version);
    window.dispatchEvent(new Event('storage'));
  };

  const isAuthenticated = localStorage.getItem('token');

  const logout = () => {
    localStorage.clear();
    navigate('/');
    onMobileClose();
  };

  const login = () => {
    localStorage.removeItem('token');
    navigate('/login');
    onMobileClose();
  };

  const signup = () => {
    localStorage.removeItem('token');
    navigate('/signup');
    onMobileClose();
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const handleUserGearClick = () => {
    navigate('/menu');
  };

  const bg = useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(26, 32, 44, 0.8)');
  const color = useColorModeValue('gray.800', 'white');
  const menuBg = useColorModeValue('white', 'gray.700');
  const menuHoverBg = useColorModeValue('gray.100', 'gray.600');
  const shadowColor = useColorModeValue('rgba(0, 0, 0, 0.1)', 'rgba(255, 255, 255, 0.1)');

  return (
    <>
      <MotionBox
        as="nav"
        bg={bg}
        color={color}
        backdropFilter="blur(10px)"
        position="sticky"
        top="0"
        zIndex="10"
        fontFamily="Lexend"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <MotionFlex
          align="center"
          justify="space-between"
          wrap="nowrap"
          padding="1rem"
          maxWidth="1200px"
          margin="0 auto"
        >
          <MotionIconButton
            aria-label={isAuthenticated ? 'Profile Settings' : 'Open Menu'}
            icon={<FontAwesomeIcon icon={isAuthenticated ? faUserGear : faBars} />}
            variant="ghost"
            display={{ base: 'flex', md: 'flex' }}
            onClick={isAuthenticated ? handleUserGearClick : onMobileOpen}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            flexShrink={0}
          />

          <MotionFlex align="center" justify="center" flex={1} minWidth={0}>
            <Link href="/" display="flex" alignItems="center" mr={2}>
              <MotionImage
                src={huddlzLogo}
                boxSize={{ base: "30px", md: "40px" }}
                alt="Maximo"
                filter="drop-shadow(0 0 5px rgba(0,0,0,0.5))"
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
              />
            </Link>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
                variant="ghost"
                fontWeight="bold"
                fontSize={{ base: "md", md: "xl" }}
                letterSpacing="wide"
                _hover={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
                padding={2}
                minWidth={0}
              >
                {`Maximo ${selectedVersion === 'v2' ? '1b' : selectedVersion === 'v1' ? '1m' : 'z1'}`}
              </MenuButton>
              <MenuList
                zIndex={20}
                bg={menuBg}
                boxShadow={`0 4px 20px ${shadowColor}`}
                borderRadius="xl"
                overflow="hidden"
              >
                <MenuGroup title="">
                  <Text textAlign="left" fontSize="md" fontWeight="bold" p={2} mb={0} color={color}>
                    {t('Model')}
                  </Text>
                  <MenuItem
                    onClick={() => handleVersionChange('v1')}
                    _hover={{ bg: menuHoverBg }}
                    transition="background 0.2s"
                  >
                    <VStack align="start" spacing={0}>
                      <Text><FontAwesomeIcon icon={faWandMagicSparkles} /> Maximo 1 Mini <Badge ml="1" colorScheme="green">{t('New')}</Badge></Text>
                      <Text fontSize="sm" color="gray.500">
                        {t('Perfect for daily tasks')}
                      </Text>
                    </VStack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleVersionChange('v2')}
                    _hover={{ bg: menuHoverBg }}
                    transition="background 0.2s"
                  >
                    <VStack align="start" spacing={0}>
                      <Text><FontAwesomeIcon icon={faHatWizard} /> Maximo 1 Beta</Text>
                      <Text fontSize="sm" color="gray.500">
                        {t('Latest Features')}
                      </Text>
                    </VStack>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleVersionChange('z1')}
                    _hover={{ bg: menuHoverBg }}
                    transition="background 0.2s"
                  >
                    <VStack align="start" spacing={0}>
                      <Text><FontAwesomeIcon icon={faPodcast} /> Maximo Zest 1 <Badge ml="1" colorScheme="green">{t('New')}</Badge></Text>
                      <Text fontSize="sm" color="gray.500">
                        {t('zestModel')}
                      </Text>
                    </VStack>
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </MotionFlex>

          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
              variant="ghost"
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              fontSize={{ base: "sm", md: "md" }}
              padding={2}
              minWidth={0}
              flexShrink={0}
            >
              <FontAwesomeIcon icon={faGlobe} /> {(i18n.language || 'EN').toUpperCase()}
            </MenuButton>
            <MenuList
              zIndex={20}
              bg={menuBg}
              boxShadow={`0 4px 20px ${shadowColor}`}
              borderRadius="xl"
              overflow="hidden"
            >
              <MenuItem onClick={() => changeLanguage('en')} _hover={{ bg: menuHoverBg }}>EN 🇬🇧</MenuItem>
              <MenuItem onClick={() => changeLanguage('es')} _hover={{ bg: menuHoverBg }}>ES 🇪🇸</MenuItem>
              <MenuItem onClick={() => changeLanguage('ru')} _hover={{ bg: menuHoverBg }}>RU 🇷🇺</MenuItem>
              <MenuItem onClick={() => changeLanguage('zh')} _hover={{ bg: menuHoverBg }}>ZH 🇨🇳</MenuItem>
            </MenuList>
          </Menu>
        </MotionFlex>
      </MotionBox>

      <AnimatePresence>
        {!isAuthenticated && isMobileOpen && (
          <MotionBox
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            zIndex="20"
            bg={bg}
            boxShadow={`0 0 20px ${shadowColor}`}
          >
            <Drawer placement="left" onClose={onMobileClose} isOpen={true}>
              <DrawerOverlay />
              <DrawerContent bg={menuBg} color={color}>
                <DrawerHeader borderBottomWidth="1px">{t('Menu')}</DrawerHeader>
                <DrawerBody>
                  <VStack spacing={4} fontFamily="Lexend" alignItems="flex-start">
                    <Link href="/">{t('menu1')}</Link>
                    <Link href="https://about.maximoai.co" isExternal>
                      {t('About Maximo AI')}
                    </Link>
                    <Link href="https://dev.maximoai.co" isExternal>
                      {t('Dev Portal')}
                    </Link>
                    {isAuthenticated ? (
                      <Button colorScheme="red" onClick={logout}>
                        {t('logout')}
                      </Button>
                    ) : (
                      <>
                        <Button colorScheme="teal" onClick={login}>
                          {t('login')}
                        </Button>
                        <Button colorScheme="blue" onClick={signup}>
                          {t('sign_up')}
                        </Button>
                      </>
                    )}
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </MotionBox>
        )}
      </AnimatePresence>

      <Modal isOpen={isProfileOpen} onClose={onProfileClose} size="full">
        <ModalOverlay />
        <ModalContent bg={bg} color={color}>
          <ModalCloseButton />
          <ModalBody>
            <UserProfileContent closeModal={onProfileClose} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onProfileClose}>{t('close')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Header;