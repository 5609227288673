import React from 'react';
import { Box, VStack, Heading, Text, Button, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tweetText = encodeURIComponent(t('welcomeTweetText'));
    const shareUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

    // Function to navigate to the chat page
    const continueToChat = () => {
        navigate('/chat');
    };

    return (
        <Box p={8} maxWidth="500px" mx="auto">
            <VStack spacing={5}>
                <Heading>{t('welcomeTitle')}</Heading>
                <Text>{t('welcomeInstructions')}</Text>
                <Text>{t('maximoUses')}</Text>
                
                <Text>{t('areYouExcited')}</Text>
                {/* Optionally, you could add more interactive elements here */}
                
                <Text>{t('shareYourExcitement')}</Text>
                <Link href={shareUrl} isExternal>
                    <Button colorScheme="twitter" rightIcon={<ExternalLinkIcon />}>
                        {t('tweetAboutMaximo')}
                    </Button>
                </Link>

                {/* Continue button to navigate to the chat page */}
                <Button colorScheme="teal" onClick={continueToChat}>
                    {t('continue')}
                </Button>
            </VStack>
        </Box>
    );
};

export default WelcomePage;
