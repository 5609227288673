// useLocalStorage.js
import { useState, useEffect } from 'react';

function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? item : initialValue; // Return the item directly, no JSON.parse
        } catch (error) {
            console.error('Error reading local storage:', error);
            return initialValue;
        }
    });

    useEffect(() => {
        const handleStorageChange = () => {
            try {
                const item = window.localStorage.getItem(key);
                setStoredValue(item ? item : initialValue); // Set the item directly, no JSON.parse
            } catch (error) {
                console.error('Error reading local storage:', error);
                setStoredValue(initialValue);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [key, initialValue]);

    const setValue = value => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, valueToStore); // Store the value directly, no JSON.stringify
            window.dispatchEvent(new Event('storage')); // Manually dispatch storage event
        } catch (error) {
            console.error('Error setting local storage:', error);
        }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;
