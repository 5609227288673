import React, { useState, useEffect } from 'react';
import {
    Box, useToast, Image, VStack, Heading, Text, HStack, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, SimpleGrid,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
    useColorModeValue, Icon, Center, Flex
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faUser, faLandmark, faWater, faLandmark as faLand } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import logo from '../images/exocrest-logo.png';
import SpinnerSvg from './Spinner';

const MotionBox = motion(Box);

const UserCountriesPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [totalResources, setTotalResources] = useState([]);

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const accentColor = useColorModeValue('teal.500', 'teal.300');
    const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            try {
                const [countriesResponse, resourcesResponse] = await Promise.all([
                    axios.get(`${apiUrl}/api/planets/countries`, {
                        headers: { Authorization: `Bearer ${token}` },
                    }),
                    axios.get(`${apiUrl}/api/open/total-resources-balance`)
                ]);
                setCountries(countriesResponse.data);
                setTotalResources(resourcesResponse.data.totalBalances);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast({
                    title: t('Error fetching data'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [toast, t, apiUrl]);

    const fetchCurrencyDetails = async (countryId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/api/planets/currencies/${countryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCurrencies(response.data);
        } catch (error) {
            console.error('Error fetching currency details:', error);
            toast({
                title: t('Error fetching currency details'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleCountryClick = (country) => {
        setSelectedCountry(country);
        fetchCurrencyDetails(country.id);
    };

    const handleCloseModal = () => {
        setSelectedCountry(null);
        setCurrencies([]);
    };

    const ResourceCard = ({ resource }) => (
        <MotionBox
            bg={cardBg}
            p={4}
            borderRadius="lg"
            boxShadow="lg"
            textAlign="center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            <Text fontSize="lg" fontWeight="bold" color={accentColor}>{resource.type}</Text>
            <Text fontSize="xl">{parseFloat(resource.totalBalance).toFixed(3)}</Text>
        </MotionBox>
    );

    const FlagPlaceholder = () => (
        <Box as="svg" width="50px" height="50px" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" fill="#4A5568" />
            <path d="M25 15L35 25L25 35L15 25L25 15Z" fill="#A0AEC0" />
            <circle cx="25" cy="25" r="5" fill="#CBD5E0" />
        </Box>
    );

    const CountryCard = ({ country }) => (
        <MotionBox
            bg={cardBg}
            p={4}
            borderRadius="xl"
            boxShadow="xl"
            cursor="pointer"
            onClick={() => handleCountryClick(country)}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
        >
            <HStack spacing={4} align="start">
                {country.flag ? (
                    <Image src={`${apiUrl}${country.flag}`} alt={`${country.name} flag`} boxSize="50px" borderRadius="md" />
                ) : (
                    <FlagPlaceholder />
                )}
                <VStack align="start" spacing={2}>
                    <Text fontSize="lg" fontWeight="bold"><Icon as={FontAwesomeIcon} icon={faGlobe} /> {country.name}</Text>
                    <Text>{t('Planet')}: {country.planetName}</Text>
                    <Text><Icon as={FontAwesomeIcon} icon={faLandmark} /> {t('Government')}: {country.governmentType}</Text>
                    <Text>{t('Capital')}: {country.capital}</Text>
                    <Text>{t('Size')}: {country.totalArea.toFixed(2)} km²</Text>
                    <Text><Icon as={FontAwesomeIcon} icon={faLand} /> {t('Land')}: {country.landPercentage.toFixed(2)}%</Text>
                    <Text><Icon as={FontAwesomeIcon} icon={faWater} /> {t('Water')}: {country.waterPercentage.toFixed(2)}%</Text>
                    <Text><Icon as={FontAwesomeIcon} icon={faUser} /> {t('Owner')}: {country.ownerUsername}</Text>
                </VStack>
            </HStack>
        </MotionBox>
    );

    const CurrencyPlaceholder = () => (
        <VStack spacing={4} align="center">
            <Box as="svg" width="100px" height="100px" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="45" stroke="#4A5568" strokeWidth="5" />
                <path d="M50 20V80M35 35H65M35 65H65" stroke="#A0AEC0" strokeWidth="5" />
            </Box>
            <Text textAlign="center">{t('No currency data available')}</Text>
            <Button colorScheme="teal" onClick={() => navigate('/dashboard')}>
                {t('Go to Dashboard to Create Currency')}
            </Button>
        </VStack>
    );

    return (
        <Box bg={bgColor} color={textColor} p={6} minH={{ base: "1280vh", md: "980vh" }}>
            <VStack spacing={8}>
                <Box className="header-container">
                    <AnimatePresence>
                        <motion.span
                            key="exocrest"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Flex alignItems="center">
                                <Image src={logo} alt="Logo" boxSize="50px" mr={2} />
                                <Text className="header-title">{t('Exocrest Countries')}</Text>
                            </Flex>
                        </motion.span>
                    </AnimatePresence>
                </Box>

                {isLoading ? (
                    <Center w="full" h="50vh">
                        <SpinnerSvg size="xl" />
                    </Center>
                ) : (
                    <VStack spacing={8} w="full">
                        <Accordion allowToggle w="full">
                            <AccordionItem border="none">
                                <AccordionButton _expanded={{ bg: accentColor, color: 'white' }} borderRadius="xl">
                                    <Box flex="1" textAlign="left">
                                        {t('Total Resources')}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                                        <AnimatePresence>
                                            {totalResources.map((resource) => (
                                                <ResourceCard key={resource.type} resource={resource} />
                                            ))}
                                        </AnimatePresence>
                                    </SimpleGrid>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>

                        <Box w="full">
                            <Heading size="lg" mb={4}>
                                {t('Countries')}
                            </Heading>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                                <AnimatePresence>
                                    {countries.map((country) => (
                                        <CountryCard key={country.id} country={country} />
                                    ))}
                                </AnimatePresence>
                            </SimpleGrid>
                        </Box>
                    </VStack>
                )}
            </VStack>

            <Modal isOpen={selectedCountry !== null} onClose={handleCloseModal} isCentered>
                <ModalOverlay />
                <ModalContent
                    mx={[4, 0]}
                    maxW="400px"
                    borderRadius="2xl"
                    boxShadow="2xl"
                    bg={cardBg}
                >
                    <ModalHeader color={accentColor}>{selectedCountry?.name} {t('Currency Details')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {currencies.length > 0 ? (
                            <VStack spacing={4} align="start">
                                {currencies.map((currency) => (
                                    <MotionBox
                                        key={currency.id}
                                        w="full"
                                        bg={hoverBgColor}
                                        p={4}
                                        borderRadius="lg"
                                        boxShadow="md"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Text><b>{t('Currency Name')}:</b> {currency.name}</Text>
                                        <Text><b>{t('Ticker')}:</b> {currency.ticker}</Text>
                                        <Text><b>{t('Total Supply')}:</b> {currency.totalSupply}</Text>
                                        <Text><b>{t('Total Balance')}:</b> {currency.totalBalance}</Text>
                                        {currency.image && <Image src={`${apiUrl}${currency.image}`} alt={`${currency.name} image`} boxSize="50px" borderRadius="md" mt={2} />}
                                    </MotionBox>
                                ))}
                            </VStack>
                        ) : (
                            <CurrencyPlaceholder />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" onClick={handleCloseModal}>{t('Close')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default UserCountriesPage;