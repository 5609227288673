import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Text, Button, VStack, FormControl, FormLabel, Input, useToast, Container, IconButton,
    InputGroup, InputRightElement, Heading, Avatar, Center, Flex, ScaleFade, Drawer, DrawerBody,
    DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, Table, Tbody,
    Tr, Td
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon, EditIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import defaultProfileImage from '../images/user-avatar.png';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import SpinnerSvg from './Spinner';

const MotionBox = motion(Box);

const SettingsPage = () => {
    const [profilePic, setProfilePic] = useState('');
    const [twitterUsername, setTwitterUsername] = useState('');
    const [telegramUsername, setTelegramUsername] = useState('');
    const [bybitApiKey, setBybitApiKey] = useState('');
    const [showApiKey, setShowApiKey] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFilePreview, setSelectedFilePreview] = useState(null);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const toast = useToast();
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    // Night mode color scheme
    const bg = 'gray.900';
    const cardBg = 'gray.800';
    const textColor = 'gray.100';
    const borderColor = 'gray.700';
    const inputBg = 'gray.700';
    const hoverBg = 'gray.600';

    const fetchUserProfile = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            toast({
                title: t('authenticationError.title'),
                description: t('authenticationError.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            const profileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const {
                username,
                email,
                profilePhoto,
                twitterUsername,
                telegramUsername,
                bybitApiKey,
            } = profileResponse.data;

            setUsername(username);
            setEmail(email);
            setProfilePic(profilePhoto ? `${process.env.REACT_APP_API_URL}/${profilePhoto}` : defaultProfileImage);
            setTwitterUsername(twitterUsername);
            setTelegramUsername(telegramUsername);
            setBybitApiKey(bybitApiKey);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching user profile:', error);
            toast({
                title: t('fetchError-new.title'),
                description: t('fetchError-new.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setIsLoading(false);
        }
    }, [t, toast]);

    useEffect(() => {
        fetchUserProfile();
    }, [fetchUserProfile]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedFilePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedFilePreview(null);
        }
    };

    const handleSaveSettings = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            toast({
                title: t('authenticationError.title'),
                description: t('authenticationError.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('twitterUsername', twitterUsername);
        formData.append('telegramUsername', telegramUsername);
        formData.append('bybitApiKey', bybitApiKey);
        if (selectedFile) {
            formData.append('profilePhoto', selectedFile);
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/settings`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast({
                title: t('settings.saveSuccess'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            onClose();
            fetchUserProfile(); // Refresh the user profile after saving
        } catch (error) {
            console.error('Error saving settings:', error);
            toast({
                title: t('settings.saveError'),
                description: error.response?.data?.message || t('settings.saveErrorDescription'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const SettingsForm = () => (
        <VStack spacing={6} align="stretch">
            <FormControl>
                <FormLabel color={textColor}>{t('settings.profilePhoto')}</FormLabel>
                <Flex direction="column" align="center">
                    <Avatar
                        size="xl"
                        src={selectedFilePreview || profilePic}
                        mb={4}
                        border="4px solid"
                        borderColor={borderColor}
                        boxShadow="lg"
                    />
                    <Input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        p={1}
                        border="none"
                        hidden
                        id="file-upload"
                    />
                    <label htmlFor="file-upload">
                        <Button as="span" colorScheme="blue" cursor="pointer">
                            {t('settings.chooseFile')}
                        </Button>
                    </label>
                    <Text mt={2} fontSize="sm" color={textColor}>
                        {selectedFile ? selectedFile.name : t('settings.noFileChosen')}
                    </Text>
                </Flex>
            </FormControl>
            <FormControl>
                <FormLabel color={textColor}>{t('settings.twitterUsername')}</FormLabel>
                <Input
                    value={twitterUsername}
                    onChange={(e) => setTwitterUsername(e.target.value)}
                    placeholder={t('settings.twitterUsernamePlaceholder')}
                    bg={inputBg}
                    color={textColor}
                    borderColor={borderColor}
                    _hover={{ borderColor: 'blue.500', bg: hoverBg }}
                    _focus={{ borderColor: 'blue.500', boxShadow: 'none' }}
                />
            </FormControl>
            <FormControl>
                <FormLabel color={textColor}>{t('settings.telegramUsername')}</FormLabel>
                <Input
                    value={telegramUsername}
                    onChange={(e) => setTelegramUsername(e.target.value)}
                    placeholder={t('settings.telegramUsernamePlaceholder')}
                    bg={inputBg}
                    color={textColor}
                    borderColor={borderColor}
                    _hover={{ borderColor: 'blue.500', bg: hoverBg }}
                    _focus={{ borderColor: 'blue.500', boxShadow: 'none' }}
                />
            </FormControl>
            <FormControl>
                <FormLabel color={textColor}>{t('settings.bybitApiKey')}</FormLabel>
                <InputGroup>
                    <Input
                        type={showApiKey ? 'text' : 'password'}
                        value={bybitApiKey}
                        onChange={(e) => setBybitApiKey(e.target.value)}
                        placeholder={t('settings.bybitApiKeyPlaceholder')}
                        bg={inputBg}
                        color={textColor}
                        borderColor={borderColor}
                        _hover={{ borderColor: 'blue.500', bg: hoverBg }}
                        _focus={{ borderColor: 'blue.500', boxShadow: 'none' }}
                    />
                    <InputRightElement>
                        <IconButton
                            variant="ghost"
                            aria-label={t('settings.toggleApiKeyVisibility')}
                            icon={showApiKey ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={() => setShowApiKey(!showApiKey)}
                            color="gray.400"
                            _hover={{ color: 'white' }}
                        />
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSaveSettings} size="lg" width="full">
                {t('settings.save')}
            </Button>
        </VStack>
    );

    return (
        <Box bg={bg} minH="100vh" pb={20}>
            <Container maxW="container.md" pt={8} px={4}>
                {isLoading ? (
                    <Center h="100vh">
                        <SpinnerSvg />
                    </Center>
                ) : (
                    <ScaleFade initialScale={0.9} in={true}>
                        <MotionBox
                            bg={cardBg}
                            borderRadius="2xl"
                            p={6}
                            shadow="2xl"
                            w="full"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Flex direction="column" align="center" mb={6}>
                                <Avatar
                                    size="xl"
                                    src={profilePic || defaultProfileImage}
                                    mb={4}
                                    border="4px solid"
                                    borderColor={borderColor}
                                    boxShadow="lg"
                                />
                                <Heading as="h2" fontSize="2xl" mb={2} color={textColor}>
                                    @{username}
                                </Heading>
                                <Text fontSize="sm" color="gray.400" mb={4}>
                                    {email}
                                </Text>
                            </Flex>
                            
                            <Table variant="simple" color={textColor}>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight="bold">{t('settings.twitterUsername')}</Td>
                                        <Td>{twitterUsername || '-'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight="bold">{t('settings.telegramUsername')}</Td>
                                        <Td>{telegramUsername || '-'}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight="bold">{t('settings.bybitApiKey')}</Td>
                                        <Td>{bybitApiKey ? '••••••••' : '-'}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                            
                            <Button 
                                colorScheme="blue" 
                                onClick={onOpen} 
                                size="lg" 
                                width="full" 
                                mt={6}
                                leftIcon={<EditIcon />}
                            >
                                {t('settings.editProfile')}
                            </Button>
                        </MotionBox>
                    </ScaleFade>
                )}
            </Container>

            <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
                <DrawerOverlay />
                <DrawerContent bg={bg}>
                    <DrawerCloseButton color={textColor} />
                    <DrawerHeader color={textColor}>{t('settings.editProfile')}</DrawerHeader>
                    <DrawerBody>
                        <SettingsForm />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default SettingsPage;