// ./firebaseInit.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBl7r5ZHDYaV69-Irhq4F_LvuHNGCrkBTQ",
    authDomain: "huddlz.firebaseapp.com",
    projectId: "huddlz",
    storageBucket: "huddlz.appspot.com",
    messagingSenderId: "201777023602",
    appId: "1:201777023602:web:ed30604196a12852cfd0eb",
    measurementId: "G-F01PS29W21"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
