import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Flex,
    VStack,
    HStack,
    Text,
    Input,
    Button,
    IconButton,
    useToast,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useMediaQuery,
    FormControl,
    FormLabel,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Select,
    Badge
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faFolderOpen, faPlus, faPaperPlane, faCode, faEye, faCopy, faChevronLeft, faChevronRight, faCalendarAlt, faClock, faExpandAlt, faCompressAlt, faEdit, faTrash, faEllipsisV, faPlay } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Editor from '@monaco-editor/react';
import CustomSelect from './CustomSelect';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionIconButton = motion(IconButton);

const apiUrl = process.env.REACT_APP_API_URL;

const PlaygroundPage = () => {
    const { t } = useTranslation();
    const [projects, setProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState(null);
    const [files, setFiles] = useState([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [extractedFiles, setExtractedFiles] = useState([]);
    const [currentExtractedFileIndex, setCurrentExtractedFileIndex] = useState(0);
    const [userPrompt, setUserPrompt] = useState('');
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [newFileName, setNewFileName] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedAIModel, setSelectedAIModel] = useState({ value: 'maximo1m', label: 'Ask Maximo 1m' });
    const [codeOutput, setCodeOutput] = useState('');
    const [isRunning, setIsRunning] = useState(false);
    // const [userInput, setUserInput] = useState('');

    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const { isOpen: isNewProjectModalOpen, onOpen: onNewProjectModalOpen, onClose: onNewProjectModalClose } = useDisclosure();
    const { isOpen: isNewFileModalOpen, onOpen: onNewFileModalOpen, onClose: onNewFileModalClose } = useDisclosure();
    const { isOpen: isEditProjectModalOpen, onOpen: onEditProjectModalOpen, onClose: onEditProjectModalClose } = useDisclosure();
    const { isOpen: isEditFileModalOpen, onOpen: onEditFileModalOpen, onClose: onEditFileModalClose } = useDisclosure();
    const { isOpen: isOutputModalOpen, onOpen: onOutputModalOpen, onClose: onOutputModalClose } = useDisclosure();

    const toast = useToast();
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

    const bgColor = 'gray.900';
    const textColor = 'gray.100';
    const cardBgColor = 'gray.800';

    const fetchProjects = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/projects`, getAuthHeaders());
            setProjects(response.data);
        } catch (error) {
            console.error('Error fetching projects:', error);
            toast({
                title: t('Error fetching projects'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [t, toast]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const getAuthHeaders = () => {
        const token = localStorage.getItem('token');
        return {
            headers: { Authorization: `Bearer ${token}` }
        };
    };

    const createNewProject = async () => {
        if (newProjectName) {
            try {
                const response = await axios.post(`${apiUrl}/api/projects`, { name: newProjectName }, getAuthHeaders());
                const newProject = response.data;
                setCurrentProject(newProject);
                const initialFile = { name: 'index.html', content: '<!DOCTYPE html>\n<html lang="en">\n<head>\n    <meta charset="UTF-8">\n    <title>Hello World</title>\n</head>\n<body>\n    <h1>Hello, World!</h1>\n</body>\n</html>' };
                setFiles([initialFile]);
                setCurrentFileIndex(0);
                onNewProjectModalClose();
                toast({
                    title: t('Project created'),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });

                // Delay fetching the new project to ensure it's available on the server
                setTimeout(async () => {
                    try {
                        await fetchProjects();
                        await loadProject(newProject.projectId);
                    } catch (error) {
                        console.error('Error fetching new project:', error);
                        toast({
                            title: t('Error loading new project'),
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }, 1000);  // 1 second delay
            } catch (error) {
                console.error('Error creating project:', error);
                toast({
                    title: t('Error creating project'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const loadProject = async (projectId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/projects/${projectId}`, getAuthHeaders());
            setCurrentProject(response.data);
            setFiles(response.data.files || []);
            setCurrentFileIndex(0);
        } catch (error) {
            console.error('Error loading project:', error);
            toast({
                title: t('Error loading project'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const saveProject = async () => {
        if (!currentProject || files.length === 0) return;
        try {
            await axios.put(`${apiUrl}/api/projects/${currentProject.id}/files/${files[currentFileIndex].id}`,
                { content: files[currentFileIndex].content },
                getAuthHeaders()
            );
            toast({
                title: t('Project saved'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error saving project:', error);
            toast({
                title: t('Error saving project'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleCodeChange = (value) => {
        const newFiles = [...files];
        newFiles[currentFileIndex] = { ...newFiles[currentFileIndex], content: value };
        setFiles(newFiles);
    };

    const askMaximo = async () => {
        if (!userPrompt || !selectedAIModel) return;
        setIsLoading(true);
        try {
            let endpoint;
            switch (selectedAIModel.value) {
                case 'maximo1b':
                    endpoint = '/api/ask-maximo-code-1b';
                    break;
                case 'maximo1m':
                    endpoint = '/api/ask-maximo-code-1m';
                    break;
                case 'claude':
                    endpoint = '/api/ask-maximo-claude';
                    break;
                case 'llama':
                    endpoint = '/api/ask-maximo-llama';
                    break;
                default:
                    throw new Error('Invalid AI model selected');
            }

            const response = await axios.post(`${apiUrl}${endpoint}`, {
                prompt: userPrompt,
                code: files[currentFileIndex]?.content || '',
            }, getAuthHeaders());

            const responseText = response.data.response.text || '';

            const parsedFiles = parseFiles(responseText);
            setExtractedFiles(parsedFiles);
            setCurrentExtractedFileIndex(0);
            onModalOpen();
            setUserPrompt('');
        } catch (error) {
            console.error('Error asking AI:', error);
            toast({
                title: t('Error communicating with AI'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const parseFiles = (text) => {
        const fileRegex = /```(\w+)?\s*\n([\s\S]+?)```/g;
        const parsedFiles = [];
        let match;
        let index = 0;

        while ((match = fileRegex.exec(text)) !== null) {
            parsedFiles.push({
                name: `file${index + 1}.${match[1] || 'txt'}`,
                language: match[1] || 'plaintext',
                content: match[2].trim(),
            });
            index++;
        }

        return parsedFiles.length > 0 ? parsedFiles : [{
            name: 'response.txt',
            language: 'plaintext',
            content: text.trim(),
        }];
    };

    const applyMaximoResponse = () => {
        if (files.length === 0) return;
        const newFiles = [...files];
        newFiles[currentFileIndex] = { ...newFiles[currentFileIndex], content: extractedFiles[currentExtractedFileIndex].content };
        setFiles(newFiles);
        onModalClose();
    };

    const copyCode = () => {
        if (files.length === 0) return;
        navigator.clipboard.writeText(files[currentFileIndex]?.content || '').then(() => {
            toast({
                title: t('Code copied to clipboard'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        }, (err) => {
            console.error('Error copying code: ', err);
            toast({
                title: t('Error copying code'),
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        });
    };

    const createNewFile = async () => {
        if (!currentProject || !newFileName) return;
        try {
            const response = await axios.post(`${apiUrl}/api/projects/${currentProject.id}/files`,
                { name: newFileName, content: '// Your code here' },
                getAuthHeaders()
            );

            onNewFileModalClose();
            toast({
                title: t('File created'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });

            // Delay fetching the new file to ensure it's available on the server
            setTimeout(async () => {
                try {
                    const updatedProjectResponse = await axios.get(`${apiUrl}/api/projects/${currentProject.id}`, getAuthHeaders());
                    const updatedFiles = updatedProjectResponse.data.files || [];
                    setFiles(updatedFiles);
                    const newFileIndex = updatedFiles.findIndex(file => file.id === response.data.id);
                    if (newFileIndex !== -1) {
                        setCurrentFileIndex(newFileIndex);
                    } else {
                        setCurrentFileIndex(updatedFiles.length - 1);
                    }
                } catch (error) {
                    console.error('Error fetching updated project files:', error);
                    toast({
                        title: t('Error loading new file'),
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            }, 1000);  // 1 second delay

            setNewFileName('');
        } catch (error) {
            console.error('Error creating file:', error);
            toast({
                title: t('Error creating file'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const deleteFile = async () => {
        if (!currentProject || files.length === 0) return;
        try {
            await axios.delete(`${apiUrl}/api/projects/${currentProject.id}/files/${files[currentFileIndex].id}`, getAuthHeaders());
            const newFiles = files.filter((_, index) => index !== currentFileIndex);
            setFiles(newFiles);
            setCurrentFileIndex(Math.max(0, currentFileIndex - 1));
            toast({
                title: t('File deleted'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting file:', error);
            toast({
                title: t('Error deleting file'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const renameFile = async () => {
        if (!currentProject || files.length === 0 || !newFileName) return;
        try {
            await axios.put(`${apiUrl}/api/projects/${currentProject.id}/files/${files[currentFileIndex].id}/rename`,
                { newName: newFileName },
                getAuthHeaders()
            );
            const newFiles = [...files];
            newFiles[currentFileIndex] = { ...newFiles[currentFileIndex], name: newFileName };
            setFiles(newFiles);
            setNewFileName('');
            onEditFileModalClose();
            toast({
                title: t('File renamed'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error renaming file:', error);
            toast({
                title: t('Error renaming file'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const updateProjectName = async () => {
        if (!currentProject || !newProjectName) return;
        try {
            await axios.put(`${apiUrl}/api/projects/${currentProject.id}`,
                { newName: newProjectName },
                getAuthHeaders()
            );
            setCurrentProject({ ...currentProject, name: newProjectName });
            fetchProjects();
            setNewProjectName('');
            onEditProjectModalClose();
            toast({
                title: t('Project renamed'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error renaming project:', error);
            toast({
                title: t('Error renaming project'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const deleteProject = async () => {
        if (!currentProject) return;
        try {
            await axios.delete(`${apiUrl}/api/projects/${currentProject.id}`, getAuthHeaders());
            setCurrentProject(null);
            setFiles([]);
            fetchProjects();
            toast({
                title: t('Project deleted'),
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting project:', error);
            toast({
                title: t('Error deleting project'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const getFileLanguage = (fileName) => {
        if (!fileName) return 'plaintext';
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'html': return 'html';
            case 'css': return 'css';
            case 'js': return 'javascript';
            case 'json': return 'json';
            case 'py': return 'python';
            case 'rb': return 'ruby';
            case 'php': return 'php';
            case 'java': return 'java';
            case 'cpp': return 'cpp';
            case 'cs': return 'csharp';
            case 'go': return 'go';
            case 'rs': return 'rust';
            case 'ts': return 'typescript';
            case 'jsx': return 'javascript';
            case 'tsx': return 'typescript';
            default: return 'plaintext';
        }
    };

    const runCode = async () => {
        if (!currentProject || files.length === 0) return;
        setIsRunning(true);
        setCodeOutput('');
        try {
            const fileExtension = files[currentFileIndex].name.split('.').pop().toLowerCase();
            let language;
            if (fileExtension === 'py') {
                language = 'python';
            } else if (fileExtension === 'js') {
                language = 'javascript';
            } else {
                throw new Error('Unsupported file type. Only Python and JavaScript files can be executed.');
            }

            const response = await axios.post(`${apiUrl}/api/run-code`,
                {
                    code: files[currentFileIndex].content,
                    language: language
                },
                getAuthHeaders()
            );

            setCodeOutput(response.data.output);
            if (response.data.error) {
                setCodeOutput(prevOutput => prevOutput + '\nError: ' + response.data.error);
            }
            onOutputModalOpen();
        } catch (error) {
            console.error('Error running code:', error);
            toast({
                title: t('Error running code'),
                description: error.response?.data?.message || error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsRunning(false);
        }
    };

    const CodePreview = ({ file }) => {
        return (
            <Box height="100%" overflow="auto" borderRadius="xl" boxShadow="2xl">
                <iframe
                    srcDoc={file.content}
                    title="HTML Preview"
                    width="100%"
                    height="100%"
                    style={{ border: 'none', borderRadius: 'xl' }}
                    sandbox="allow-scripts"
                />
            </Box>
        );
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const NoProjectOpen = () => (
        <VStack spacing={8} align="center" justify="center" height="100%">
            <Box width="200px" height="200px">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <g fill="none" stroke="currentColor" strokeWidth="2">
                        <motion.path
                            d="M50 10 L90 30 L90 70 L50 90 L10 70 L10 30 Z"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{ duration: 2, repeat: Infinity, repeatType: "loop" }}
                        />
                        <motion.path
                            d="M10 30 L50 50 L90 30 M50 50 L50 90"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={{ duration: 2, delay: 1, repeat: Infinity, repeatType: "loop" }}
                        />
                    </g>
                </svg>
            </Box>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                {t("No project open")}
            </Text>
            <HStack spacing={4}>
                <Button
                    leftIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                    onClick={onDrawerOpen}
                    colorScheme="blue"
                    borderRadius="full"
                >
                    {t("Open Project")}
                </Button>
                <Button
                    leftIcon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={onNewProjectModalOpen}
                    colorScheme="green"
                    borderRadius="full"
                >
                    {t("Create New Project")}
                </Button>
            </HStack>
        </VStack>
    );

    return (
        <MotionFlex
            direction="column"
            minHeight="100vh"
            bg={bgColor}
            color={textColor}
            p={4}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <MotionFlex justify="space-between" mb={4} align="center">
                <MotionBox
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                >
                    <Text fontSize="2xl" fontWeight="bold">
                        {currentProject ? `${t('Code Playground')}: ${currentProject.name}` : t('Code Playground')}
                    </Text>
                </MotionBox>
                <MotionBox
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.3 }}
                >
                    <HStack spacing={2}>
                        <Tooltip label={t('Create new project')}>
                            <MotionIconButton
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                onClick={onNewProjectModalOpen}
                                aria-label={t('Create new project')}
                                colorScheme="blue"
                                borderRadius="full"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            />
                        </Tooltip>
                        <Tooltip label={t('Open project')}>
                            <MotionIconButton
                                icon={<FontAwesomeIcon icon={faFolderOpen} />}
                                onClick={onDrawerOpen}
                                aria-label={t('Open project')}
                                colorScheme="green"
                                borderRadius="full"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            />
                        </Tooltip>
                        {currentProject && (
                            <>
                                <Tooltip label={t('Save project')}>
                                    <MotionIconButton
                                        icon={<FontAwesomeIcon icon={faSave} />}
                                        onClick={saveProject}
                                        aria-label={t('Save project')}
                                        colorScheme="purple"
                                        borderRadius="full"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                    />
                                </Tooltip>
                                <Tooltip label={t('Copy code')}>
                                    <MotionIconButton
                                        icon={<FontAwesomeIcon icon={faCopy} />}
                                        onClick={copyCode}
                                        aria-label={t('Copy code')}
                                        colorScheme="orange"
                                        borderRadius="full"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                    />
                                </Tooltip>
                                <Tooltip label={t('Run code')}>
                                    <MotionIconButton
                                        icon={<FontAwesomeIcon icon={faPlay} />}
                                        onClick={runCode}
                                        aria-label={t('Run code')}
                                        colorScheme="green"
                                        borderRadius="full"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        isLoading={isRunning}
                                    />
                                </Tooltip>
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        icon={<FontAwesomeIcon icon={faEllipsisV} />}
                                        variant="outline"
                                        borderRadius="full"
                                    />
                                    <MenuList>
                                        <MenuItem icon={<FontAwesomeIcon icon={faEdit} />} onClick={onEditProjectModalOpen}>
                                            {t('Rename Project')}
                                        </MenuItem>
                                        <MenuItem icon={<FontAwesomeIcon icon={faTrash} />} onClick={deleteProject}>
                                            {t('Delete Project')}
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </>
                        )}
                    </HStack>
                </MotionBox>
            </MotionFlex>

            <MotionFlex
                flex={1}
                direction={isLargerThan768 ? "row" : "column"}
                gap={4}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
            >
                {currentProject ? (
                    <>
                        <VStack flex={isExpanded ? 1 : 2} spacing={4} align="stretch">
                            <HStack>
                                <Select
                                    value={currentFileIndex}
                                    onChange={(e) => setCurrentFileIndex(Number(e.target.value))}
                                    borderRadius="full"
                                    bg={cardBgColor}
                                >
                                    {files.map((file, index) => (
                                        <option key={index} value={index}>{file.name}</option>
                                    ))}
                                </Select>
                                <Tooltip label={t('Add new file')}>
                                    <MotionIconButton
                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                        onClick={onNewFileModalOpen}
                                        aria-label={t('Add new file')}
                                        colorScheme="green"
                                        borderRadius="full"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                    />
                                </Tooltip>
                                {files.length > 0 && (
                                    <Menu>
                                        <MenuButton
                                            as={IconButton}
                                            icon={<FontAwesomeIcon icon={faEllipsisV} />}
                                            variant="outline"
                                            borderRadius="full"
                                        />
                                        <MenuList>
                                            <MenuItem icon={<FontAwesomeIcon icon={faEdit} />} onClick={onEditFileModalOpen}>
                                                {t('Rename File')}
                                            </MenuItem>
                                            <MenuItem icon={<FontAwesomeIcon icon={faTrash} />} onClick={deleteFile}>
                                                {t('Delete File')}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                )}
                                <Tooltip label={isPreviewMode ? t('Show code') : t('Show preview')}>
                                    <MotionIconButton
                                        icon={<FontAwesomeIcon icon={isPreviewMode ? faCode : faEye} />}
                                        onClick={() => setIsPreviewMode(!isPreviewMode)}
                                        aria-label={isPreviewMode ? t('Show code') : t('Show preview')}
                                        colorScheme="teal"
                                        borderRadius="full"
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                    />
                                </Tooltip>
                                {isLargerThan768 && (
                                    <Tooltip label={isExpanded ? t('Collapse') : t('Expand')}>
                                        <MotionIconButton
                                            icon={<FontAwesomeIcon icon={isExpanded ? faCompressAlt : faExpandAlt} />}
                                            onClick={toggleExpand}
                                            aria-label={isExpanded ? t('Collapse') : t('Expand')}
                                            colorScheme="cyan"
                                            borderRadius="full"
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                        />
                                    </Tooltip>
                                )}
                            </HStack>
                            <Box flex={1} borderRadius="xl" overflow="hidden" boxShadow="2xl">
                                <AnimatePresence mode="wait">
                                    {isPreviewMode ? (
                                        <MotionBox
                                            key="preview"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.3 }}
                                            height="100%"
                                        >
                                            {files.length > 0 && <CodePreview file={files[currentFileIndex]} />}
                                        </MotionBox>
                                    ) : (
                                        <MotionBox
                                            key="editor"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.3 }}
                                            height="100%"
                                        >
                                            {files.length > 0 && (
                                                <Editor
                                                    height="100%"
                                                    defaultLanguage={getFileLanguage(files[currentFileIndex]?.name)}
                                                    value={files[currentFileIndex]?.content || ''}
                                                    onChange={handleCodeChange}
                                                    theme="vs-dark"
                                                    options={{
                                                        minimap: { enabled: false },
                                                        fontSize: 14,
                                                        wordWrap: 'on',
                                                        scrollBeyondLastLine: false,
                                                        automaticLayout: true,
                                                        tabSize: 2,
                                                        lineNumbers: 'on',
                                                        renderWhitespace: 'all',
                                                    }}
                                                />
                                            )}
                                        </MotionBox>
                                    )}
                                </AnimatePresence>
                            </Box>
                        </VStack>

                        {(!isExpanded || !isLargerThan768) && (
                            <VStack flex={1} spacing={4} align="stretch">
                                <Text fontSize="xl" fontWeight="bold">{t('Ask AI')}</Text>
                                <FormControl>
                                    <CustomSelect
                                        options={[
                                            { value: 'maximo1b', label: 'Ask Maximo 1b' },
                                            { value: 'maximo1m', label: 'Ask Maximo 1m' },
                                            { value: 'claude', label: 'Ask Claude 3.5 Sonnet' },
                                            {
                                                value: 'llama', label: (
                                                    <HStack>
                                                        <Text>Ask Llama 3.1 405B</Text>
                                                        <Badge colorScheme="green">{t('New')}</Badge>
                                                    </HStack>
                                                )
                                            },
                                        ]}
                                        selectedOption={selectedAIModel}
                                        setSelectedOption={setSelectedAIModel}
                                        placeholder={t('Select AI Model')}
                                    />
                                </FormControl>
                                <Textarea
                                    value={userPrompt}
                                    onChange={(e) => setUserPrompt(e.target.value)}
                                    placeholder={t('Enter your question or request for AI...')}
                                    resize="vertical"
                                    borderRadius="xl"
                                    boxShadow="md"
                                    bg={cardBgColor}
                                />
                                <Button
                                    leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    onClick={askMaximo}
                                    isLoading={isLoading}
                                    loadingText={t("Processing...")}
                                    colorScheme="blue"
                                    borderRadius="full"
                                    boxShadow="md"
                                    _hover={{ boxShadow: "lg" }}
                                    _active={{ boxShadow: "sm" }}
                                    isDisabled={!selectedAIModel}
                                >
                                    {t("Ask AI")}
                                </Button>
                            </VStack>
                        )}
                    </>
                ) : (
                    <NoProjectOpen />
                )}
            </MotionFlex>

            <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="md">
                <DrawerOverlay />
                <DrawerContent borderLeftRadius="2xl" bg={cardBgColor}>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth="1px">{t("Open Project")}</DrawerHeader>
                    <DrawerBody>
                        <VStack spacing={4} align="stretch">
                            {projects.map((project) => (
                                <MotionBox
                                    key={project.id}
                                    p={4}
                                    borderWidth={1}
                                    borderRadius="xl"
                                    boxShadow="md"
                                    _hover={{ boxShadow: "xl" }}
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    bg="gray.700"
                                >
                                    <VStack align="start" spacing={2}>
                                        <Text fontWeight="bold" fontSize="lg">{project.name}</Text>
                                        <HStack spacing={4}>
                                            <HStack>
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                <Text fontSize="sm" color="gray.400">
                                                    {new Date(project.created_at).toLocaleDateString()}
                                                </Text>
                                            </HStack>
                                            <HStack>
                                                <FontAwesomeIcon icon={faClock} />
                                                <Text fontSize="sm" color="gray.400">
                                                    {new Date(project.created_at).toLocaleTimeString()}
                                                </Text>
                                            </HStack>
                                        </HStack>
                                        <Button
                                            onClick={() => {
                                                loadProject(project.id);
                                                onDrawerClose();
                                            }}
                                            colorScheme="teal"
                                            size="sm"
                                            mt={2}
                                            borderRadius="full"
                                            leftIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                                        >
                                            {t("Open")}
                                        </Button>
                                    </VStack>
                                </MotionBox>
                            ))}
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isModalOpen} onClose={onModalClose} size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBgColor}>
                    <ModalHeader>{t("AI Response")}</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            {extractedFiles.length > 1 && (
                                <HStack>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faChevronLeft} />}
                                        onClick={() => setCurrentExtractedFileIndex((prev) => (prev - 1 + extractedFiles.length) % extractedFiles.length)}
                                        aria-label="Previous File"
                                        size="sm"
                                        borderRadius="full"
                                    />
                                    <Select
                                        value={currentExtractedFileIndex}
                                        onChange={(e) => setCurrentExtractedFileIndex(Number(e.target.value))}
                                        borderRadius="full"
                                    >
                                        {extractedFiles.map((file, index) => (
                                            <option key={index} value={index}>{file.name}</option>
                                        ))}
                                    </Select>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faChevronRight} />}
                                        onClick={() => setCurrentExtractedFileIndex((prev) => (prev + 1) % extractedFiles.length)}
                                        aria-label="Next File"
                                        size="sm"
                                        borderRadius="full"
                                    />
                                </HStack>
                            )}
                            <Box maxHeight="60vh" overflowY="auto">
                                <Editor
                                    height="60vh"
                                    defaultLanguage={extractedFiles[currentExtractedFileIndex]?.language.toLowerCase()}
                                    value={extractedFiles[currentExtractedFileIndex]?.content || ''}
                                    theme="vs-dark"
                                    options={{
                                        readOnly: true,
                                        minimap: { enabled: false },
                                        scrollBeyondLastLine: false,
                                        fontSize: 14,
                                        wordWrap: 'on',
                                        lineNumbers: 'on',
                                        renderWhitespace: 'all',
                                    }}
                                />
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={applyMaximoResponse} borderRadius="full">
                            {t("Apply Changes")}
                        </Button>
                        <Button variant="ghost" onClick={onModalClose} borderRadius="full">{t("Close")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isNewProjectModalOpen} onClose={onNewProjectModalClose}>
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBgColor} shadow="xl" mx={[4, 0]} maxW="400px">
                    <ModalHeader>{t("Create New Project")}</ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{t("Project Name")}</FormLabel>
                            <Input
                                value={newProjectName}
                                onChange={(e) => setNewProjectName(e.target.value)}
                                placeholder={t("Enter project name")}
                                borderRadius="full"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={createNewProject} borderRadius="full">
                            {t("Create")}
                        </Button>
                        <Button variant="ghost" onClick={onNewProjectModalClose} borderRadius="full">{t("Cancel")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isNewFileModalOpen} onClose={onNewFileModalClose}>
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBgColor} shadow="xl" mx={[4, 0]} maxW="400px">
                    <ModalHeader>{t("Create New File")}</ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{t("File Name")}</FormLabel>
                            <Input
                                value={newFileName}
                                onChange={(e) => setNewFileName(e.target.value)}
                                placeholder={t("Enter file name")}
                                borderRadius="full"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={createNewFile} borderRadius="full">
                            {t("Create")}
                        </Button>
                        <Button variant="ghost" onClick={onNewFileModalClose} borderRadius="full">{t("Cancel")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isEditProjectModalOpen} onClose={onEditProjectModalClose}>
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBgColor} shadow="xl" mx={[4, 0]} maxW="400px">
                    <ModalHeader>{t("Rename Project")}</ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{t("New Project Name")}</FormLabel>
                            <Input
                                value={newProjectName}
                                onChange={(e) => setNewProjectName(e.target.value)}
                                placeholder={t("Enter new project name")}
                                borderRadius="full"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={updateProjectName} borderRadius="full">
                            {t("Rename")}
                        </Button>
                        <Button variant="ghost" onClick={onEditProjectModalClose} borderRadius="full">{t("Cancel")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isEditFileModalOpen} onClose={onEditFileModalClose}>
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBgColor} shadow="xl" mx={[4, 0]} maxW="400px">
                    <ModalHeader>{t("Rename File")}</ModalHeader>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{t("New File Name")}</FormLabel>
                            <Input
                                value={newFileName}
                                onChange={(e) => setNewFileName(e.target.value)}
                                placeholder={t("Enter new file name")}
                                borderRadius="full"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={renameFile} borderRadius="full">
                            {t("Rename")}
                        </Button>
                        <Button variant="ghost" onClick={onEditFileModalClose} borderRadius="full">{t("Cancel")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isCentered isOpen={isOutputModalOpen} onClose={onOutputModalClose} size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="xl" bg={cardBgColor}>
                    <ModalHeader>{t("Code Output")}</ModalHeader>
                    <ModalBody>
                        <Box
                            bg="gray.700"
                            p={4}
                            borderRadius="md"
                            fontFamily="monospace"
                            whiteSpace="pre-wrap"
                            overflowX="auto"
                            height="60vh"
                            overflowY="auto"
                        >
                            {codeOutput}
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onOutputModalClose} borderRadius="full">{t("Close")}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </MotionFlex>
    );
};

export default PlaygroundPage;