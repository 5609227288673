import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Button, FormControl, FormLabel, Input, VStack, Heading, useToast,
  InputGroup, InputRightElement, IconButton, Text, Link, Flex, Image,
  Container, ScaleFade, useMediaQuery, Divider, Stack, SlideFade, Modal,
  ModalOverlay, ModalContent, ModalBody, keyframes, useClipboard
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ViewIcon, ViewOffIcon, CopyIcon, CheckIcon } from '@chakra-ui/icons';
import { FaTwitter, FaGoogle } from 'react-icons/fa';
import logo from '../images/maximo-ai-logo-circle.png';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const DomainChangePopup = ({ isOpen }) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { hasCopied, onCopy } = useClipboard("https://maximoai.co");

  return (
    <Modal isOpen={isOpen} onClose={() => { }} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="gray.800"
        borderRadius="2xl"
        boxShadow="dark-lg"
        p={8}
        maxW={isMobile ? "90%" : "500px"}
        m={4}
      >
        <ModalBody>
          <VStack spacing={6}>
            <Box as="svg" width="100%" height="150px" viewBox="0 0 200 100">
              <motion.circle
                cx="100"
                cy="50"
                r="40"
                fill="#4FD1C5"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              />
              <motion.path
                d="M85 50 L100 65 L115 50"
                stroke="#1A202C"
                strokeWidth="4"
                fill="none"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              />
            </Box>
            <Heading as="h2" size="xl" textAlign="center" color="teal.300">
              {t('important_update')}
            </Heading>
            <Text fontSize="lg" textAlign="center" color="white">
              {t('new_domain_message')}
            </Text>
            <Flex alignItems="center">
              <Link
                href="https://maximoai.co"
                isExternal
                color="teal.300"
                fontWeight="bold"
                fontSize="xl"
                mr={2}
                _hover={{ textDecoration: 'underline' }}
              >
                https://maximoai.co
              </Link>
              <IconButton
                icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
                onClick={onCopy}
                aria-label="Copy domain"
                colorScheme="teal"
                size="sm"
              />
            </Flex>
            <Text fontSize="md" textAlign="center" color="gray.300">
              {t('web_app_update_message')}
            </Text>
            <Button
              as="a"
              href="https://maximoai.co"
              colorScheme="teal"
              size="lg"
              width="full"
              height="60px"
              fontSize="xl"
              borderRadius="xl"
              boxShadow="md"
              _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
              animation={`${pulseAnimation} 2s infinite`}
            >
              {t('go_to_new_domain')}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [showDomainChangePopup] = useState(true);

  const handleAuthMessage = useCallback((event) => {
    if (event.origin !== process.env.REACT_APP_API_URL) return;
    if (event.data.token) {
      localStorage.setItem('token', event.data.token);
      toast({
        title: t('login_successful'),
        description: t('you_are_now_logged_in'),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate('/chat');
    }
  }, [navigate, toast, t]);

  useEffect(() => {
    window.addEventListener('message', handleAuthMessage);

    const token = localStorage.getItem('token');
    if (token) {
      navigate('/chat');
    }

    return () => {
      window.removeEventListener('message', handleAuthMessage);
    };
  }, [navigate, handleAuthMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/login`, formData);
      localStorage.setItem('token', response.data.token);
      toast({
        title: t('login_successful'),
        description: t('you_are_now_logged_in'),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate('/chat');
    } catch (error) {
      toast({
        title: t('login_failed'),
        description: error.response ? error.response.data.message : t('an_error_occurred'),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialSignIn = (provider) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      `${apiUrl}/auth/${provider}`,
      'Auth',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  return (
    <Box minHeight="100vh" bg="gray.900" py={isMobile ? 0 : 12} px={isMobile ? 0 : 4}>
      <Container maxW={isMobile ? "100%" : "md"} h={isMobile ? "100vh" : "auto"} p={0}>
        <AnimatePresence>
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            h="100%"
          >
            <ScaleFade initialScale={0.9} in={true}>
              <MotionBox
                bg="gray.800"
                p={isMobile ? 8 : 12}
                borderRadius={isMobile ? "0" : "3xl"}
                boxShadow="dark-lg"
                transition="all 0.3s"
                h={isMobile ? "100%" : "auto"}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <MotionFlex
                  direction="column"
                  align="center"
                  mb={isMobile ? 12 : 16}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                >
                  <Image
                    src={logo}
                    boxSize={isMobile ? "150px" : "180px"}
                    alt="Maximo AI Logo"
                    mb={6}
                    animate={{ rotate: 360 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                  />
                  <Heading as="h1" fontFamily="Lexend" fontSize={isMobile ? "4xl" : "5xl"} fontWeight="bold" textAlign="center" color="white">
                    MAXIMO AI
                  </Heading>
                  <Text fontSize={isMobile ? "2xl" : "3xl"} fontFamily="Lexend" fontWeight="bold" mt={6} color="teal.300">
                    {t('welcome_back')} 👋
                  </Text>
                  <Text fontSize={isMobile ? "md" : "lg"} fontFamily="Lexend" color="gray.400" mt={3}>
                    {t('login_to_your_account')}
                  </Text>
                </MotionFlex>
                <VStack spacing={8} as="form" onSubmit={handleSubmit}>
                  <Stack direction="column" spacing={6} width="100%">
                    <SlideFade in={true} offsetY="20px">
                      <Button
                        leftIcon={<FaTwitter />}
                        colorScheme="twitter"
                        onClick={() => handleSocialSignIn('twitter')}
                        width="full"
                        fontFamily="Lexend"
                        size="lg"
                        height={isMobile ? "60px" : "56px"}
                        fontSize={isMobile ? "xl" : "lg"}
                        borderRadius="2xl"
                        boxShadow="md"
                        _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                      >
                        {t('sign_in_with_twitter')}
                      </Button>
                    </SlideFade>
                    <SlideFade in={true} offsetY="20px" delay={0.1}>
                      <Button
                        leftIcon={<FaGoogle />}
                        colorScheme="red"
                        onClick={() => handleSocialSignIn('google')}
                        width="full"
                        fontFamily="Lexend"
                        size="lg"
                        height={isMobile ? "60px" : "56px"}
                        fontSize={isMobile ? "xl" : "lg"}
                        borderRadius="2xl"
                        boxShadow="md"
                        _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                      >
                        {t('sign_in_with_google')}
                      </Button>
                    </SlideFade>
                  </Stack>
                  <Divider />
                  <SlideFade in={true} offsetY="20px" delay={0.2}>
                    <FormControl isRequired>
                      <FormLabel fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"} color="gray.300">{t('email_address')}</FormLabel>
                      <Input
                        pr="4.5rem"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={t('email_address')}
                        fontFamily="Lexend"
                        size="lg"
                        height={isMobile ? "60px" : "56px"}
                        fontSize={isMobile ? "xl" : "lg"}
                        borderRadius="xl"
                        bg="gray.700"
                        color="white"
                        _placeholder={{ color: "gray.400" }}
                        _focus={{ borderColor: "teal.300", boxShadow: `0 0 0 1px teal.300` }}
                      />
                    </FormControl>
                  </SlideFade>
                  <SlideFade in={true} offsetY="20px" delay={0.3}>
                    <FormControl isRequired>
                      <FormLabel fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"} color="gray.300">{t('password')}</FormLabel>
                      <InputGroup size="lg">
                        <Input
                          pr="4.5rem"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          placeholder={t('password')}
                          fontFamily="Lexend"
                          height={isMobile ? "60px" : "56px"}
                          fontSize={isMobile ? "xl" : "lg"}
                          borderRadius="xl"
                          bg="gray.700"
                          color="white"
                          _placeholder={{ color: "gray.400" }}
                          _focus={{ borderColor: "teal.300", boxShadow: `0 0 0 1px teal.300` }}
                        />
                        <InputRightElement width="4.5rem" height={isMobile ? "60px" : "56px"}>
                          <IconButton
                            h="1.75rem"
                            size="lg"
                            onClick={handleShowClick}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                            borderRadius="lg"
                            bg="gray.600"
                            color="white"
                            _hover={{ bg: "gray.500" }}
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </SlideFade>
                  <SlideFade in={true} offsetY="20px" delay={0.4}>
                    <Button
                      mt={6}
                      colorScheme="teal"
                      type="submit"
                      fontFamily="Lexend"
                      size="lg"
                      height={isMobile ? "60px" : "56px"}
                      fontSize={isMobile ? "xl" : "lg"}
                      width="full"
                      borderRadius="xl"
                      isLoading={isLoading}
                      loadingText={t('logging_in')}
                      _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                      transition="all 0.2s"
                    >
                      {t('login')}
                    </Button>
                  </SlideFade>
                </VStack>
                <SlideFade in={true} offsetY="20px" delay={0.5}>
                  <Box pt={8}>
                    <Text align="center" fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"} color="gray.400">
                      {t('dont_have_account')}{' '}
                      <Link as={RouterLink} to="/signup" color="teal.300" fontWeight="medium" _hover={{ textDecoration: 'underline' }}>
                        {t('sign_up')}
                      </Link>
                    </Text>
                  </Box>
                </SlideFade>
              </MotionBox>
            </ScaleFade>
          </MotionBox>
        </AnimatePresence>
      </Container>
      <DomainChangePopup isOpen={showDomainChangePopup} />
    </Box>
  );
};

export default LoginPage;