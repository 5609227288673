import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Button, Input, VStack, Text, useToast, Flex, Heading,
    Tabs, TabList, TabPanels, Tab, TabPanel, SimpleGrid, AlertDialogOverlay,
    AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, AlertDialog,
    useColorModeValue, FormControl, useDisclosure, Collapse, Icon, Spinner,
    Container, Grid, GridItem
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Web3 from 'web3';
import ERC20_ABI from './abis/ERC20ABI.json';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaChevronDown, FaChevronUp, FaCoins, FaDollarSign } from 'react-icons/fa';
import TutorialModal from './TutorialModal';
import CustomSelect from './CustomSelect';

const USDT_CONTRACT_ADDRESSES = {
    'Ethereum': '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    'Binance Smart Chain': '0x55d398326f99059fF775485246999027B3197955',
    'Polygon': '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    'OneLedger': '0xED344b7C2deD6143e32B03ea905e59dC2010Ce74',
    'Base': '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
};

const rpcUrls = {
    Ethereum: process.env.REACT_APP_ETH_RPC_URL || "https://mainnet.infura.io/v3/8dc36f09afad40ef9084206ce9ddae5c",
    "Binance Smart Chain": process.env.REACT_APP_BSC_RPC_URL || "https://bsc-dataseed.binance.org/",
    "OneLedger": process.env.REACT_APP_OLT_RPC_URL || "https://mainnet-rpc.oneledger.network",
    Polygon: process.env.REACT_APP_POLYGON_RPC_URL || "https://polygon-rpc.com/",
    Base: process.env.REACT_APP_BASE_RPC_URL || "https://mainnet.base.org/",
};

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionButton = motion(Button);

const AutoSwap = () => {
    const { t } = useTranslation();
    const [tradePair, setTradePair] = useState('');
    const [duration, setDuration] = useState('24');
    const [stopLossPercentage, setStopLossPercentage] = useState('');
    const [capital, setCapital] = useState('');
    const [network, setNetwork] = useState('');
    const [autoSwaps, setAutoSwaps] = useState([]);
    const [pointsBalance, setPointsBalance] = useState(0);
    const [usdtBalance, setUsdtBalance] = useState(0);
    const [endTime, setEndTime] = useState('');
    const [isInsufficientPointsOpen, setIsInsufficientPointsOpen] = useState(false);
    const [isPLDataLoading, setIsPLDataLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isBalanceFetching, setIsBalanceFetching] = useState(false);
    const [supportedPairs, setSupportedPairs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayedPair, setDisplayedPair] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const { isOpen: isDisclaimerOpen, onToggle: onDisclaimerToggle } = useDisclosure({ defaultIsOpen: true });
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const hoverColor = useColorModeValue('gray.100', 'gray.700');

    const baseDurationOptions = ["24", "48", "72"];
    const firstTierDurationOptions = ["168", "336"];
    const secondTierDurationOptions = ["720", "2160"];

    let durationOptions = [...baseDurationOptions];
    if (pointsBalance >= 1000) {
        durationOptions = [...durationOptions, ...firstTierDurationOptions];
    }
    if (pointsBalance >= 50000) {
        durationOptions = [...durationOptions, ...secondTierDurationOptions];
    }

    const networks = ["Ethereum", "Binance Smart Chain", "Polygon", "Base", "OneLedger"];

    const fetchSupportedPairs = useCallback(async () => {
        if (!network) return;

        const token = localStorage.getItem('token');

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/supported-pairs/${network}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSupportedPairs(Object.keys(response.data.pairs));
        } catch (error) {
            console.error('Error fetching supported pairs:', error);
            setSupportedPairs([]);
        }
    }, [network]);

    useEffect(() => {
        fetchSupportedPairs();
    }, [fetchSupportedPairs]);

    const filteredPairs = searchTerm.length > 0
        ? supportedPairs.filter(pair =>
            pair.toLowerCase().includes(searchTerm.toLowerCase()))
        : [];

    const fetchUserProfitOrLoss = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        setIsPLDataLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-profit-or-loss`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const profitOrLossData = response.data.data;

            setAutoSwaps(currentSwaps => {
                return currentSwaps.map(swap => ({
                    ...swap,
                    profitOrLoss: profitOrLossData.find(pnl => pnl.tradeId === swap.id)?.profitOrLoss || null,
                }));
            });
            setIsPLDataLoading(false);
        } catch (error) {
            console.error('Failed to fetch user profit or loss:', error);
            setIsPLDataLoading(false);
        }
    }, []);

    const activeSwaps = autoSwaps.filter(swap => swap.is_active === 1)
        .sort((a, b) => new Date(b.end_time).getTime() - new Date(a.end_time).getTime());

    const inactiveSwaps = autoSwaps.filter(swap => swap.is_active === 0)
        .sort((a, b) => new Date(b.end_time).getTime() - new Date(a.end_time).getTime());

    const fetchAutoSwaps = useCallback(async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            toast({
                title: t('error'),
                description: t('notAuthenticated'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auto-swaps`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setAutoSwaps(response.data.autoSwaps);
            setEndTime(response.data.end_time);
        } catch (error) {
            console.error('Failed to fetch auto swaps:', error);
            toast({
                title: t('error'),
                description: t('failedToFetchAutoSwaps'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [toast, t]);

    const fetchUserAlerts = useCallback(async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            toast({
                title: t('error'),
                description: t('notAuthenticated'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-alerts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPointsBalance(response.data.points);
        } catch (error) {
            console.error('Failed to fetch user alerts:', error);
            toast({
                title: t('error'),
                description: t('failedToFetchUserAlerts'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [toast, t]);

    useEffect(() => {
        const fetchPLDataPeriodically = () => {
            fetchUserProfitOrLoss();
            const intervalId = setInterval(fetchUserProfitOrLoss, 120000);

            return () => clearInterval(intervalId);
        };

        fetchAutoSwaps();
        fetchUserAlerts();
        fetchPLDataPeriodically();
    }, [fetchAutoSwaps, fetchUserAlerts, fetchUserProfitOrLoss]);

    const fetchUsdtBalance = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token || !network) return;

        setIsBalanceFetching(true);

        try {
            const userProfileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const walletAddress = userProfileResponse.data.walletAddress;
            const networkRpcUrl = rpcUrls[network];
            const usdtContractAddress = USDT_CONTRACT_ADDRESSES[network];

            if (!networkRpcUrl || !usdtContractAddress) {
                console.error('Unsupported network or USDT contract address not found');
                setIsBalanceFetching(false);
                return;
            }

            const web3 = new Web3(new Web3.providers.HttpProvider(networkRpcUrl));
            const usdtContract = new web3.eth.Contract(ERC20_ABI, usdtContractAddress);

            const balance = await usdtContract.methods.balanceOf(walletAddress).call();
            const balanceBN = new BigNumber(balance);
            const decimalsBN = new BigNumber(10).pow(18);
            const balanceInUsdt = balanceBN.dividedBy(decimalsBN);

            const formattedBalance = balanceInUsdt.toFixed(2);

            setUsdtBalance(formattedBalance);
        } catch (error) {
            console.error('Failed to fetch USDT balance:', error);
            toast({
                title: t('error'),
                description: t('failedToFetchUsdtBalance'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
        setIsBalanceFetching(false);
    }, [network, toast, t]);

    const handleSubmit = async () => {
        if (!tradePair || !duration || !stopLossPercentage || !capital || !network) {
            toast({
                title: t('error'),
                description: t('fillAllFields'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const capitalAmount = parseFloat(capital);

        if (capitalAmount < 20 || capitalAmount > 10000) {
            toast({
                title: t('error'),
                description: t('capitalOutOfRange', { min: 20, max: 10000 }),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (parseFloat(stopLossPercentage) < 2 || parseFloat(stopLossPercentage) > 10) {
            toast({
                title: t('error'),
                description: t('stopLossPercentageOutOfRange', { min: 2, max: 10 }),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsSubmitting(true);

        if (parseFloat(capital) > parseFloat(usdtBalance)) {
            toast({
                title: t('error'),
                description: t('capitalLessThanBalance'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const token = localStorage.getItem('token');

        if (!token) {
            toast({
                title: t('error'),
                description: t('notAuthenticated'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auto-swap`, {
                tradePair: tradePair.toUpperCase(),
                duration,
                stopLossPercentage,
                capital,
                network,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log(response);

            toast({
                title: t('success'),
                description: t('autoSwapInitiatedSuccessfully'),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            fetchAutoSwaps();
        } catch (error) {
            console.error('Failed to submit auto swap:', error);
            if (error.response?.status === 400 && error.response?.data?.message === "Insufficient points for this operation.") {
                setIsInsufficientPointsOpen(true);
            } else {
                toast({
                    title: t('error'),
                    description: error.response?.data?.message || t('failedToInitiateAutoSwap'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        fetchUsdtBalance();
    }, [fetchUsdtBalance]);

    const onCloseInsufficientPoints = () => {
        setIsInsufficientPointsOpen(false);
    };

    const handlePurchaseMoreAlerts = async () => {
        navigate('/buypoints');
        setIsInsufficientPointsOpen(false);
    };

    function truncateToDecimalPlaces(num, decimalPlaces) {
        const numStr = num.toString();
        const decimalIndex = numStr.indexOf('.');
        if (decimalIndex === -1 || numStr.length - decimalIndex - 1 <= decimalPlaces) {
            return numStr;
        }
        return numStr.substring(0, decimalIndex + decimalPlaces + 1);
    }

    const fetchUserProfile = useCallback(async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            return null;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            return response.data.id;
        } catch (error) {
            console.error('Failed to fetch user profile:', error);
            return null;
        }
    }, []);

    const AutoSwapCard = ({ swap }) => {
        const cardVariants = {
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
        };

        const endTimeInSeconds = Math.floor((new Date(swap.end_time).getTime() - Date.now()) / 1000);
        const renderTime = ({ remainingTime }) => {
            const hours = Math.floor(remainingTime / 3600);
            const minutes = Math.floor((remainingTime % 3600) / 60);
            const seconds = remainingTime % 60;

            return (
                <Text style={{ color: 'white', textShadow: '0 0 8px #FFF' }}>
                    {`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
                </Text>
            );
        };

        const [isCancelling, setIsCancelling] = useState(false);

        const handleCancelSwap = async () => {
            setIsCancelling(true);
            const token = localStorage.getItem('token');
            const userID = await fetchUserProfile();

            if (!token || !userID) {
                toast({
                    title: t('error'),
                    description: t('notAuthenticated'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/cancel-auto-swap`, {
                    autoSwapId: swap.id,
                    userId: userID,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                console.log(response);

                toast({
                    title: t('success'),
                    description: t('swapCancelledSuccessfully'),
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                fetchAutoSwaps();
            } catch (error) {
                console.error('Failed to cancel swap:', error);
                toast({
                    title: t('error'),
                    description: error.response?.data?.message || t('failedToCancelSwap'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
            setIsCancelling(false);
        };

        return (
            <MotionBox
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                p={5}
                bg={cardBg}
                borderRadius="xl"
                boxShadow="xl"
                position="relative"
                borderWidth="1px"
                borderColor={borderColor}
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)', boxShadow: '2xl' }}
            >
                <Text fontWeight="bold" color="teal.300" fontSize="xl">{swap.trade_pair}</Text>
                {isPLDataLoading ? (
                    <Flex justify="center" mt="4">
                        <Spinner size="sm" color="teal.500" />
                    </Flex>
                ) : swap.profitOrLoss !== null && (
                    <Text color={parseFloat(swap.profitOrLoss) >= 0 ? "green.400" : "red.400"}>
                        {t('plPercentage')}:
                        <strong>{swap.profitOrLoss ? `${swap.profitOrLoss}%` : t('na')}</strong>
                    </Text>
                )}
                <Text color="gray.100">{t('openTrades')}: <strong>{swap.open_trade}</strong></Text>
                <Text color="gray.100">{t('capital')}: <strong>{swap.capital} USDT</strong></Text>
                {swap.is_active === 0 && (
                    <Text color={parseFloat(swap.last_profit_percentage) >= 0 ? "green.400" : "red.400"}>
                        {t('lastProfitPercentage')}: <strong>{(swap.last_profit_percentage ?? 0).toFixed(2)}%</strong>
                    </Text>
                )}
                <Text color="gray.100">{t('network')}: <strong>{swap.network}</strong></Text>
                <Text color={swap.is_active === 1 ? "green.400" : "red.400"}>
                    {t('status')}: <strong>{swap.is_active === 1 ? t('active') : t('inactive')}</strong>
                </Text>
                {swap.end_time && (
                    <Flex position="absolute" top="4" right="4">
                        <CountdownCircleTimer
                            isPlaying
                            duration={endTimeInSeconds > 0 ? endTimeInSeconds : 0}
                            strokeWidth={6}
                            size={100}
                            colors={[["#EF4444"]]}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </Flex>
                )}

                {swap.is_active === 1 && (
                    <Button
                        mt={3}
                        colorScheme="red"
                        onClick={handleCancelSwap}
                        isLoading={isCancelling}
                        loadingText={t('cancelling')}
                    >
                        {t('cancelSwap')}
                    </Button>
                )}
            </MotionBox>
        );
    };

    return (
        <Box minH="100vh" bg={bgColor} pb="70px"> {/* Added bottom padding to account for bottom nav */}
            <TutorialModal />
            <Container maxW="container.xl" pt={4}>
                <MotionFlex
                    direction="column"
                    align="center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Heading mb={6} color={textColor} fontSize={{ base: '2xl', md: '3xl' }}>
                        {t('autoSwap')}
                    </Heading>

                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4} w="full" mb={6}>
                        <GridItem>
                            <Flex
                                bg={cardBg}
                                p={4}
                                borderRadius="lg"
                                boxShadow="md"
                                align="center"
                                justify="space-between"
                            >
                                <Flex align="center">
                                    <Icon as={FaCoins} color="yellow.500" mr={2} />
                                    <Text fontWeight="bold">{t('pointsBalance')}</Text>
                                </Flex>
                                <Text fontSize="xl" fontWeight="bold" color="yellow.500">
                                    {pointsBalance}
                                </Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex
                                bg={cardBg}
                                p={4}
                                borderRadius="lg"
                                boxShadow="md"
                                align="center"
                                justify="space-between"
                            >
                                <Flex align="center">
                                    <Icon as={FaDollarSign} color="green.500" mr={2} />
                                    <Text fontWeight="bold">{t('usdtBalance')}</Text>
                                </Flex>
                                <Text fontSize="xl" fontWeight="bold" color="green.500">
                                    {truncateToDecimalPlaces(usdtBalance, 2)} USDT
                                </Text>
                            </Flex>
                        </GridItem>
                    </Grid>

                    <Collapse in={isDisclaimerOpen} animateOpacity>
                        <Box p={4} bg="blue.700" borderRadius="xl" mb={6} position="relative" w="full">
                            <Text color="white" fontSize="sm">
                                {t('disclaimerText')}
                            </Text>
                            <Button
                                size="sm"
                                position="absolute"
                                right="8px"
                                top="8px"
                                onClick={onDisclaimerToggle}
                                variant="ghost"
                                color="white"
                            >
                                <Icon as={isDisclaimerOpen ? FaChevronUp : FaChevronDown} />
                            </Button>
                        </Box>
                    </Collapse>

                    <VStack spacing={6} w="full" maxW="md">
                        <FormControl id="network" isRequired>
                            <CustomSelect
                                options={networks.map(network => ({ value: network, label: t(network) }))}
                                selectedOption={network ? { value: network, label: t(network) } : null}
                                setSelectedOption={option => setNetwork(option.value)}
                                label={t('selectNetwork')}
                            />
                        </FormControl>

                        <Box position="relative" w="full">
                            <Input
                                placeholder={t('searchTradePair')}
                                value={displayedPair}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setDisplayedPair(e.target.value);
                                }}
                                disabled={!supportedPairs.length}
                                pr="40px"
                            />
                            <Icon
                                as={FaSearch}
                                position="absolute"
                                right="12px"
                                top="50%"
                                transform="translateY(-50%)"
                                color="gray.400"
                            />
                        </Box>

                        <AnimatePresence>
                            {searchTerm && (
                                <MotionBox
                                    position="relative"
                                    width="100%"
                                    bg={cardBg}
                                    zIndex="dropdown"
                                    boxShadow="xl"
                                    borderRadius="md"
                                    overflow="hidden"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    maxHeight="200px"
                                    overflowY="auto"
                                    mt={2}
                                >
                                    {filteredPairs.map((pair) => (
                                        <Box
                                            key={pair}
                                            p={3}
                                            borderBottom="1px"
                                            borderColor={borderColor}
                                            _hover={{ bg: hoverColor }}
                                            cursor="pointer"
                                            onClick={() => {
                                                setTradePair(pair);
                                                setDisplayedPair(pair);
                                                setSearchTerm('');
                                            }}
                                        >
                                            <Text>{pair}</Text>
                                        </Box>
                                    ))}
                                    {filteredPairs.length === 0 && (
                                        <Text p={3}>{t('noMatchesFound')}</Text>
                                    )}
                                </MotionBox>
                            )}
                        </AnimatePresence>

                        <FormControl id="duration" isRequired>
                            <CustomSelect
                                options={durationOptions.map(option => ({ value: option, label: `${option} ${t('hours')}` }))}
                                selectedOption={duration ? { value: duration, label: `${duration} ${t('hours')}` } : null}
                                setSelectedOption={option => setDuration(option.value)}
                                label={t('selectDuration')}
                            />
                        </FormControl>

                        <Input
                            placeholder={t('stopLossPercentagePlaceholder')}
                            type="number"
                            value={stopLossPercentage}
                            onChange={(e) => setStopLossPercentage(e.target.value)}
                            min="2"
                            max="10"
                        />

                        <Input
                            placeholder={t('capitalInUSDTPlaceholder')}
                            type="number"
                            value={capital}
                            onChange={(e) => setCapital(e.target.value)}
                        />

                        <MotionButton
                            colorScheme="blue"
                            onClick={handleSubmit}
                            isLoading={isSubmitting || isBalanceFetching}
                            loadingText={isSubmitting ? t('processing') : t('fetchingBalance')}
                            disabled={isSubmitting || isBalanceFetching || !network}
                            w="full"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {t('startAutoSwap')}
                        </MotionButton>
                    </VStack>

                    <Tabs isFitted variant="soft-rounded" mt={12} w="full" colorScheme="blue">
                        <TabList mb="1em">
                            <Tab>{t('activeSwaps')}</Tab>
                            <Tab>{t('inactiveSwaps')}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} overflowY="auto" maxHeight="60vh">
                                    <AnimatePresence>
                                        {activeSwaps.length > 0 ? (
                                            activeSwaps.map(swap => <AutoSwapCard key={swap.id} swap={swap} />)
                                        ) : (
                                            <MotionBox initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                                <Text>{t('noActiveSwaps')}</Text>
                                            </MotionBox>
                                        )}
                                    </AnimatePresence>
                                </SimpleGrid>
                            </TabPanel>
                            <TabPanel>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} overflowY="auto" maxHeight="60vh">
                                    <AnimatePresence>
                                        {inactiveSwaps.length > 0 ? (
                                            inactiveSwaps.map(swap => <AutoSwapCard key={swap.id} swap={swap} />)
                                        ) : (
                                            <MotionBox initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                                <Text>{t('noInactiveSwaps')}</Text>
                                            </MotionBox>
                                        )}
                                    </AnimatePresence>
                                </SimpleGrid>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <AlertDialog
                        isOpen={isInsufficientPointsOpen}
                        onClose={onCloseInsufficientPoints}
                        motionPreset="slideInBottom"
                        isCentered
                    >
                        <AlertDialogOverlay />
                        <AlertDialogContent borderRadius="xl" shadow="2xl" mx={[4, 0]} maxW="400px">
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                {t('buyMorePoints')}
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                {t('buyMorePointsMessage').replace('{pointCost}', '2000').replace('{currentPoints}', pointsBalance)}
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button colorScheme="blue" onClick={handlePurchaseMoreAlerts} mr={3}>
                                    {t('buy')}
                                </Button>
                                <Button onClick={onCloseInsufficientPoints} variant="ghost">
                                    {t('cancel')}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>

                    {endTime && (
                        <MotionBox
                            position="fixed"
                            top="20px"
                            right="20px"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <CountdownCircleTimer
                                isPlaying
                                duration={Math.floor((new Date(endTime).getTime() - Date.now()) / 1000)}
                                colors={[["#3182CE", 0.33], ["#38A169", 0.33], ["#E53E3E"]]}
                                size={80}
                                strokeWidth={8}
                            >
                                {({ remainingTime }) => (
                                    <Text fontSize="xl" fontWeight="bold">
                                        {remainingTime}
                                    </Text>
                                )}
                            </CountdownCircleTimer>
                        </MotionBox>
                    )}
                </MotionFlex>
            </Container>
        </Box>
    );
};

export default AutoSwap;