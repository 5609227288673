import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Button, FormControl, FormLabel, Input, useToast, VStack, Text,
    SimpleGrid, useBreakpointValue, Container, AlertDialog, AlertDialogBody,
    AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Heading, Flex,
    IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
    ModalCloseButton, CloseButton, Badge, Tooltip, Stack
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { messaging } from './firebaseInit';
import { getToken } from "firebase/messaging";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CloseIcon, AddIcon } from '@chakra-ui/icons';
import { MdTrendingUp, MdTrendingDown, MdNotifications } from 'react-icons/md';
import { motion, AnimatePresence } from 'framer-motion';
import SpinnerSvg from './Spinner';

const MotionBox = motion(Box);

const AlertsPage = () => {
    const [alerts, setAlerts] = useState([]);
    const [currentTime, setCurrentTime] = useState(moment());
    const [newAlertPair, setNewAlertPair] = useState('');
    const [userPoints, setUserPoints] = useState(0);
    const [buyAlertDialogIsOpen, setBuyAlertDialogIsOpen] = useState(false);
    const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const cancelRef = React.useRef();
    const toast = useToast();
    const { t } = useTranslation();
    const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });
    const navigate = useNavigate();

    const analyzeAndSetAlerts = async (alerts) => {
        const activeAlerts = alerts.filter(alert => alert.is_active === 1);

        const analyzedAlerts = await Promise.all(activeAlerts.map(async (alert) => {
            try {
                const userLanguage = localStorage.getItem('language') || 'en';
                const analysisResponse = await axios.post(`${process.env.REACT_APP_API_URL}/ask-maximo`, {
                    question: `${alert.pair}?`,
                    language: userLanguage
                }, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                return {
                    ...alert,
                    recommendation: analysisResponse.data.recommendation,
                    rationale: analysisResponse.data.rationale,
                    currentPrice: analysisResponse.data.currentPrice,
                };
            } catch (error) {
                console.error('Error analyzing alert pair:', error);
                return alert;
            }
        }));

        setAlerts(analyzedAlerts);
        setIsLoading(false);
    };

    const fetchAlerts = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-alerts`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            const fetchedAlerts = response.data.alerts.map(alert => ({
                ...alert,
                expiration: moment(alert.expiration),
            }));
            await analyzeAndSetAlerts(fetchedAlerts);
            setUserPoints(response.data.points);
        } catch (error) {
            console.error('Error fetching alerts:', error);
            toast({
                title: t('errorFetchingAlerts'),
                description: t('tryAgainLater'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setIsLoading(false);
        }
    }, [toast, t]);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const isSubscribed = localStorage.getItem('isSubscribedToNotifications');
        if (!isSubscribed) {
            setIsSubscribeModalOpen(true);
        }
    }, []);

    const calculateRemainingTime = expiration => {
        const duration = moment.duration(expiration.diff(currentTime));
        return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
    };

    const handleAddAlert = async () => {
        if (newAlertPair === '') {
            toast({
                title: t('error'),
                description: t('alertPairRequired'),
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (alerts.length >= 1 && userPoints < 100) {
            setBuyAlertDialogIsOpen(true);
            return;
        }

        addAlert(newAlertPair);
    };

    const addAlert = async (pair) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/add-alert`, { pair }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            fetchAlerts();
            setNewAlertPair('');
            toast({
                title: t('alertAdded'),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            let errorMessage = t('errorAddingAlert');
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }

            toast({
                title: t('error'),
                description: errorMessage,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleButtonClick = () => {
        navigate('/buypoints');
    };

    const subscribeToNotifications = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                const token = await getToken(messaging, { vapidKey: "BKFphQkCrCM8hyZqovEvnrYXbwpnRwCqmfOL09TlxCrQIjDWIOb63mvb1f9tauMZlQOv7guER4UQzwnHNQd99lg" });
                if (token) {
                    console.log('Notification token received:', token);
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/register-device`, {
                        deviceToken: token
                    }, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                    });
                    localStorage.setItem('isSubscribedToNotifications', 'true');
                    toast({
                        title: t('notificationSubscriptionSuccess'),
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    setIsSubscribeModalOpen(false);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            } else {
                console.log('Unable to get permission to notify.');
            }
        } catch (error) {
            console.error('An error occurred during the subscription process:', error);
            toast({
                title: t('notificationSubscriptionError'),
                description: error.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const cancelAlert = async (alertId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/cancel-alert/${alertId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            const updatedAlerts = alerts.map(alert =>
                alert.id === alertId ? { ...alert, is_active: 0 } : alert
            );
            setAlerts(updatedAlerts);
            toast({
                title: t('alertCancelled'),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error cancelling alert:', error);
            toast({
                title: t('errorCancellingAlert'),
                description: t('tryAgainLater'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const token = localStorage.getItem('token');
    if (!token) {
        navigate('/login');
        return null;
    }

    return (
        <Container maxW="container.xl" py={8} bg="gray.900" color="gray.100" minHeight="130vh">
            <Flex justify="space-between" alignItems="center" mb={6}>
                <Heading size="lg">{t('setAlertsTitle')}</Heading>
                <IconButton
                    icon={<CloseIcon />}
                    onClick={() => navigate('/chat')}
                    aria-label="Close"
                    variant="ghost"
                    _hover={{ bg: 'gray.700' }}
                />
            </Flex>

            <AnimatePresence>
                {showDisclaimer && (
                    <MotionBox
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Box position="relative" p={4} bg="gray.800" borderRadius="md" mb={4}>
                            <Text color="gray.300" fontSize="sm">
                                {t('alertsDisclaimerText')}
                            </Text>
                            <CloseButton
                                position="absolute"
                                right="8px"
                                top="8px"
                                onClick={() => setShowDisclaimer(false)}
                            />
                        </Box>
                    </MotionBox>
                )}
            </AnimatePresence>

            <VStack spacing={8} align="stretch">
                <Box bg="gray.800" p={4} borderRadius="md">
                    <Stack direction={{ base: "column", md: "row" }} spacing={4} align={{ base: "stretch", md: "center" }} justify="space-between">
                        <Text fontWeight="bold">{t('yourPoints')} <Badge colorScheme="green">{userPoints}</Badge></Text>
                        <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={handleButtonClick} size="sm" width={{ base: "100%", md: "auto" }}>
                            {t('buyMorePoints')}
                        </Button>
                    </Stack>
                </Box>

                <FormControl id="alertPair" isRequired>
                    <FormLabel>{t('alertPair')}</FormLabel>
                    <Flex>
                        <Input
                            placeholder="BTC/USDT"
                            value={newAlertPair}
                            onChange={(e) => setNewAlertPair(e.target.value.toUpperCase())}
                            bg="gray.800"
                            color="gray.100"
                            borderColor="gray.600"
                            _hover={{ borderColor: "gray.500" }}
                            _focus={{ borderColor: "blue.300", boxShadow: "0 0 0 1px #63B3ED" }}
                            mr={2}
                        />
                        <Button
                            colorScheme="teal"
                            onClick={handleAddAlert}
                            leftIcon={<AddIcon />}
                            width={{ base: "100%", md: "auto" }}
                        >
                            {t('addAlert')}
                        </Button>
                    </Flex>
                </FormControl>
            </VStack>

            {isLoading ? (
                <Flex justify="center" align="center" height="200px">
                    <SpinnerSvg size="xl" color="blue.500" />
                </Flex>
            ) : (
                <SimpleGrid columns={columns} spacing={6} pt={8}>
                    <AnimatePresence>
                        {alerts
                            .filter(alert => alert.is_active)
                            .map((alert) => (
                                <MotionBox
                                    key={alert.id}
                                    layout
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.9 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Box
                                        p={5}
                                        shadow="lg"
                                        borderWidth="1px"
                                        borderRadius="lg"
                                        position="relative"
                                        bg="gray.800"
                                        borderColor="gray.700"
                                        bgGradient="linear(to-br, gray.800, gray.900)"
                                    >
                                        <Flex position="absolute" top={2} right={2} alignItems="center">
                                            {alert.recommendation === 'BUY' && (
                                                <Tooltip label={t('BUY')}>
                                                    <IconButton
                                                        aria-label={t('BUY')}
                                                        icon={<MdTrendingUp />}
                                                        colorScheme="green"
                                                        size="sm"
                                                        mr={2}
                                                    />
                                                </Tooltip>
                                            )}
                                            {alert.recommendation === 'SELL' && (
                                                <Tooltip label={t('SELL')}>
                                                    <IconButton
                                                        aria-label={t('SELL')}
                                                        icon={<MdTrendingDown />}
                                                        colorScheme="red"
                                                        size="sm"
                                                        mr={2}
                                                    />
                                                </Tooltip>
                                            )}
                                            <Badge
                                                colorScheme={
                                                    alert.recommendation === 'BUY' ? 'green' :
                                                        alert.recommendation === 'SELL' ? 'red' :
                                                            'blue'
                                                }
                                                fontSize="0.8em"
                                            >
                                                {alert.recommendation ? t(alert.recommendation).toUpperCase() : t('Analysis Pending')}
                                            </Badge>
                                        </Flex>
                                        <Button
                                            colorScheme="red"
                                            size="sm"
                                            position="absolute"
                                            bottom="2"
                                            right="2"
                                            onClick={() => cancelAlert(alert.id)}
                                        >
                                            {t('cancel')}
                                        </Button>
                                        <Text fontWeight="bold" fontSize="xl">{alert.pair}</Text>
                                        <Text fontSize="md" color="gray.400">
                                            {t('Expires in')}: {calculateRemainingTime(alert.expiration)}
                                        </Text>
                                        <Text mt={2} fontSize="lg" color="teal.300" fontWeight="bold">
                                            {alert.currentPrice ? `$${alert.currentPrice}` : t('Analysis Pending')}
                                        </Text>
                                    </Box>
                                </MotionBox>
                            ))}
                    </AnimatePresence>
                </SimpleGrid>
            )}

            <AlertDialog
                isOpen={buyAlertDialogIsOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setBuyAlertDialogIsOpen(false)}
                isCentered
                motionPreset="scale"
            >
                <AlertDialogOverlay>
                    <AlertDialogContent
                        borderRadius="lg"
                        shadow="xl"
                        mx={[4, 0]}
                        maxW="400px"
                        bg="gray.800"
                        color="gray.100"
                    >
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('buyMoreAlerts')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t('buyMoreAlertsMessage').replace('{pointCost}', '100').replace('{currentPoints}', userPoints)}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setBuyAlertDialogIsOpen(false)}>
                                {t('cancel')}
                            </Button>
                            <Button colorScheme="blue" onClick={handleButtonClick} ml={3}>
                                {t('buy')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal
                isOpen={isSubscribeModalOpen}
                onClose={() => setIsSubscribeModalOpen(false)}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    borderRadius="lg"
                    shadow="xl"
                    mx={[4, 0]}
                    maxW="400px"
                    bg="gray.800"
                    color="gray.100"
                >
                    <ModalHeader>{t('subscribeToNotifications')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {t('subscribeToNotificationsText')}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={subscribeToNotifications}
                            leftIcon={<MdNotifications />}
                        >
                            {t('subscribe')}
                        </Button>
                        <Button variant="ghost" onClick={() => setIsSubscribeModalOpen(false)}>
                            {t('later')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default AlertsPage;