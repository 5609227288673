import React, { useState, useEffect } from 'react';
import {
    Box, VStack, Text, Avatar, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
    ModalBody, ModalFooter, Link, Image, FormControl, FormLabel, Input, useDisclosure, Flex, IconButton,
    useToast, SimpleGrid, Divider, HStack, useColorModeValue, Container, Heading, Grid, Switch, Tooltip,
    useMediaQuery, Badge
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { SettingsIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import defaultProfileImage from '../images/user-avatar.png';
import { useNavigate } from 'react-router-dom';
import companyLogo from '../images/maximo-ai-logo-circle.png';
import DecryptWalletModal from './DecryptWalletModal';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faCog, faBell, faExchangeAlt, faRocket, faChartLine, faHammer, faTachometerAlt, faPlus, faSignal, faQuestionCircle, faCopy, faSignOutAlt, faUser, faMagic, faHistory, faComments, faCode, faBriefcase } from '@fortawesome/free-solid-svg-icons';

const MotionBox = motion(Box);
const MotionButton = motion(Button);

const WalletAddressSection = ({ address, onCopy }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const textColor = useColorModeValue("gray.700", "gray.200");

    const handleCopy = () => {
        onCopy();
        toast({
            title: t('copied'),
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    return (
        <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            mt={2}
        >
            <Text color={textColor} fontSize="sm" fontWeight="bold" mb={1}>
                {t('userProfile.walletAddress')}
            </Text>
            <Flex align="center" justify="space-between">
                <Text color={textColor} wordBreak="break-all" fontSize="xs" maxW="80%">
                    {address}
                </Text>
                <HStack>
                    <CopyToClipboard text={address} onCopy={handleCopy}>
                        <IconButton
                            icon={<FontAwesomeIcon icon={faCopy} />}
                            size="sm"
                            aria-label={t('userProfile.copyWalletAddress')}
                            variant="ghost"
                        />
                    </CopyToClipboard>
                    <DecryptWalletModal onDecryptSuccess={(privateKey) => console.log(privateKey)} />
                </HStack>
            </Flex>
        </MotionBox>
    );
};

const MenuButton = ({ icon, label, onClick, isActive, isNew }) => {
    const bgColor = useColorModeValue("white", "gray.800");
    const hoverBgColor = useColorModeValue("gray.100", "gray.700");
    const activeBgColor = useColorModeValue("blue.50", "blue.900");
    const iconColor = useColorModeValue("blue.500", "blue.300");
    const { t } = useTranslation();

    return (
        <MotionButton
            leftIcon={<FontAwesomeIcon icon={icon} color={iconColor} />}
            justifyContent="flex-start"
            variant="ghost"
            w="full"
            onClick={onClick}
            bg={isActive ? activeBgColor : bgColor}
            color={isActive ? "blue.500" : "inherit"}
            _hover={{ bg: hoverBgColor }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
            borderRadius="xl"
            p={4}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
        >
            <Flex alignItems="center" justifyContent="space-between" width="100%">
                {label}
                {isNew && (
                    <Badge colorScheme="green" ml={2}>
                        {t('New')}
                    </Badge>
                )}
            </Flex>
        </MotionButton>
    );
};

const MenuSection = ({ title, icon, children, isOpen, onToggle }) => {
    const bgColor = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const iconColor = useColorModeValue("blue.500", "blue.300");

    return (
        <MotionBox
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="xl"
            overflow="hidden"
            mb={4}
        >
            <MotionButton
                w="full"
                onClick={onToggle}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={4}
                bg={bgColor}
                _hover={{ bg: useColorModeValue("gray.50", "gray.700") }}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.2 }}
            >
                <HStack>
                    <FontAwesomeIcon icon={icon} color={iconColor} />
                    <Text fontWeight="bold">{title}</Text>
                </HStack>
                <Box transform={isOpen ? "rotate(180deg)" : "none"} transition="transform 0.2s">
                    ▼
                </Box>
            </MotionButton>
            {isOpen && (
                <Box p={4}>
                    {children}
                </Box>
            )}
        </MotionBox>
    );
};

const UserMenuModal = ({ onPromptOpen }) => {
    const { t } = useTranslation();
    const { isOpen: isCreateWalletOpen, onOpen: onCreateWalletOpen, onClose: onCreateWalletClose } = useDisclosure();
    const [userProfile, setUserProfile] = useState({
        username: '',
        walletAddress: '',
        profilePhoto: '',
    });
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();
    const apiUrl = process.env.REACT_APP_API_URL;

    const bgColor = useColorModeValue("gray.50", "gray.900");
    const cardBgColor = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("gray.800", "gray.100");
    const borderColor = useColorModeValue("gray.200", "gray.700");

    const [selectedModel, setSelectedModel] = useState(localStorage.getItem('selectedModel') || 'maximo');
    const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

    const [activeSection, setActiveSection] = useState('mainFeatures');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setUserProfile({
                    username: response.data.username,
                    walletAddress: response.data.walletAddress,
                    profilePhoto: response.data.profilePhoto ? `${apiUrl}/${response.data.profilePhoto}` : defaultProfileImage,
                });
            } catch (error) {
                console.error('Failed to fetch user profile:', error);
                toast({
                    title: t('fetchError.title'),
                    description: t('fetchError.description'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchUserProfile();
    }, [toast, t, apiUrl]);

    const createWallet = async () => {
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`${apiUrl}/create-wallet`, { password }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setUserProfile((prevState) => ({
                ...prevState,
                walletAddress: response.data.walletAddress,
            }));
            onCreateWalletClose();
        } catch (error) {
            console.error('Failed to create wallet:', error);
        }
    };

    const handleCopy = () => {
        // Your logic for handling copy event
    };

    const logout = () => {
        localStorage.clear();
        navigate('/');
    };

    const handleModelChange = () => {
        const newModel = selectedModel === 'maximo' ? 'claude' : 'maximo';
        setSelectedModel(newModel);
        localStorage.setItem('selectedModel', newModel);
    };

    const handleViewChatHistory = async () => {
        const authToken = localStorage.getItem('token');
        if (!authToken) {
            alert('Authentication token is missing.');
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/api/chat-histories`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            navigate('/chat-history', { state: { chatHistory: response.data.chatHistories } });
        } catch (error) {
            console.error('Error fetching chat history:', error);
            alert('Unable to fetch chat history.');
        }
    };

    const Footer = () => {
        return (
            <Flex align="center" justify="center" p={4} direction="column" mb={16}>
                <Text color="gray.500" fontSize="xs">App Version 0.35.02</Text>
                <HStack spacing={4} mt={2}>
                    <Link href="/terms-of-use" isExternal fontSize="xs" color={textColor}>{t('Terms of Use')}</Link>
                    <Link href="/privacy-policy" isExternal fontSize="xs" color={textColor}>{t('Privacy Policy')}</Link>
                </HStack>
            </Flex>
        );
    };

    const DesktopLayout = () => (
        <Grid templateColumns="250px 1fr" gap={8}>
            <VStack spacing={6} align="stretch" bg={cardBgColor} p={4} borderRadius="xl" boxShadow="lg">
                <Flex align="center" justify="space-between" mb={4}>
                    <HStack>
                        <Avatar
                            size="md"
                            src={userProfile.profilePhoto}
                            borderRadius="full"
                            border="2px solid"
                            borderColor={borderColor}
                        />
                        <VStack align="start" spacing={0}>
                            <Text fontWeight="bold" fontSize="md">@{userProfile.username || t('userProfile.defaultUsername')}</Text>
                            {userProfile.walletAddress && (
                                <Text fontSize="xs" color="gray.500" isTruncated maxW="150px">
                                    {userProfile.walletAddress}
                                </Text>
                            )}
                        </VStack>
                    </HStack>
                    <IconButton
                        icon={<SettingsIcon />}
                        aria-label="Settings"
                        onClick={() => navigate('/settings')}
                        variant="ghost"
                    />
                </Flex>
                <Divider />
                <VStack spacing={2} align="stretch">
                    <MenuButton
                        icon={faMagic}
                        label={t('Main Features')}
                        onClick={() => setActiveSection('mainFeatures')}
                        isActive={activeSection === 'mainFeatures'}
                    />
                    <MenuButton
                        icon={faChartLine}
                        label={t('Trading & Investments')}
                        onClick={() => setActiveSection('trading')}
                        isActive={activeSection === 'trading'}
                    />
                    <MenuButton
                        icon={faHammer}
                        label={t('Development & Tools')}
                        onClick={() => setActiveSection('development')}
                        isActive={activeSection === 'development'}
                    />
                    <MenuButton
                        icon={faQuestionCircle}
                        label={t('Support & Information')}
                        onClick={() => setActiveSection('support')}
                        isActive={activeSection === 'support'}
                    />
                </VStack>
                <Divider />
                <Box>
                    <Text fontWeight="bold" mb={2}>{t('Model Selection')}</Text>
                    <Text fontSize="sm" mb={2}>{t('chooseBetweenMaximoClaude')}</Text>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold" fontSize="sm">
                            {selectedModel === 'maximo' ? 'Maximo AI 1 Beta' : 'Claude 3.5 Sonnet'}
                        </Text>
                        <Switch
                            colorScheme="teal"
                            size="md"
                            isChecked={selectedModel === 'claude'}
                            onChange={handleModelChange}
                        />
                    </Flex>
                </Box>
                <Button
                    colorScheme="red"
                    leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
                    onClick={logout}
                    w="full"
                    mt="auto"
                    borderRadius="full"
                >
                    {t('logout')}
                </Button>
            </VStack>
            <Box>
                <Text fontSize="2xl" fontWeight="bold" mb={6}>
                    {activeSection === 'mainFeatures' && t('Main Features')}
                    {activeSection === 'trading' && t('Trading & Investments')}
                    {activeSection === 'development' && t('Development & Tools')}
                    {activeSection === 'support' && t('Support & Information')}
                </Text>
                <SimpleGrid columns={3} spacing={6}>
                    {activeSection === 'mainFeatures' && (
                        <>
                            <FeatureCard
                                icon={faMagic}
                                title={t('Generate a Prompt')}
                                description={t('featureDescriptions.generatePrompt')}
                                onClick={() => navigate('/prompt-generation')}
                            />
                            <FeatureCard
                                icon={faHistory}
                                title={t('View Chat History')}
                                description={t('featureDescriptions.viewChatHistory')}
                                onClick={handleViewChatHistory}
                            />
                            <FeatureCard
                                icon={faPlus}
                                title={t('CreateN')}
                                description={t('featureDescriptions.create')}
                                onClick={() => navigate('/create')}
                                isNew={true}
                            />
                            <FeatureCard
                                icon={faBell}
                                title={t('Alerts')}
                                description={t('featureDescriptions.alerts')}
                                onClick={() => navigate('/alerts')}
                            />
                            <FeatureCard
                                icon={faCode}
                                title={t('Code Playground')}
                                description={t('featureDescriptions.codePlayground')}
                                onClick={() => navigate('/playground')}
                                isNew={true}
                            />
                        </>
                    )}
                    {activeSection === 'trading' && (
                        <>
                            <FeatureCard
                                icon={faWallet}
                                title={t('Wallet')}
                                description={t('featureDescriptions.wallet')}
                                onClick={() => navigate('/wallet')}
                            />
                            <FeatureCard
                                icon={faChartLine}
                                title={t('Futures')}
                                description={t('featureDescriptions.futures')}
                                onClick={() => navigate('/futures')}
                            />
                            <FeatureCard
                                icon={faExchangeAlt}
                                title={t('Swap')}
                                description={t('featureDescriptions.swap')}
                                onClick={() => navigate('/swap')}
                            />
                            <FeatureCard
                                icon={faExchangeAlt}
                                title={t('Auto Swap')}
                                description={t('featureDescriptions.autoSwap')}
                                onClick={() => navigate('/auto-swap')}
                            />
                            <FeatureCard
                                icon={faRocket}
                                title={t('Alphas')}
                                description={t('featureDescriptions.alphas')}
                                onClick={() => navigate('/alpha')}
                            />
                            <FeatureCard
                                icon={faSignal}
                                title={t('Signals')}
                                description={t('featureDescriptions.signals')}
                                onClick={() => navigate('/comingsoon')}
                            />
                        </>
                    )}
                    {activeSection === 'development' && (
                        <>
                            <FeatureCard
                                icon={faHammer}
                                title={t('Build')}
                                description={t('featureDescriptions.build')}
                                onClick={() => navigate('/platform')}
                            />
                            <FeatureCard
                                icon={faRocket}
                                title={t('LaunchPad')}
                                description={t('featureDescriptions.launchPad')}
                                onClick={() => navigate('/launchpad')}
                            />
                            <FeatureCard
                                icon={faTachometerAlt}
                                title={t('ExocrestN')}
                                description={t('featureDescriptions.exocrestN')}
                                onClick={() => navigate('/dashboard')}
                                isNew={true}
                            />
                            <FeatureCard
                                icon={faBriefcase}
                                title={t('WorkSpace')}
                                description={t('featureDescriptions.workspace')}
                                onClick={() => navigate('/workspace')}
                                isNew={true}
                            />
                        </>
                    )}
                    {activeSection === 'support' && (
                        <>
                            <FeatureCard
                                icon={faQuestionCircle}
                                title={t('FAQ')}
                                description={t('featureDescriptions.faq')}
                                onClick={() => navigate('/faq')}
                            />
                            <FeatureCard
                                icon={faUser}
                                title={t('Profile')}
                                description={t('featureDescriptions.profile')}
                                onClick={() => navigate('/profile')}
                            />
                            <FeatureCard
                                icon={faCog}
                                title={t('Settings')}
                                description={t('featureDescriptions.settings')}
                                onClick={() => navigate('/settings')}
                            />
                        </>
                    )}
                </SimpleGrid>
            </Box>
        </Grid>
    );

    const FeatureCard = ({ icon, title, description, onClick, isNew }) => (
        <MotionBox
            bg={cardBgColor}
            p={6}
            borderRadius="xl"
            boxShadow="md"
            cursor="pointer"
            onClick={onClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
        >
            <VStack spacing={4} align="start">
                <Flex
                    w={12}
                    h={12}
                    bg={useColorModeValue("blue.100", "blue.900")}
                    color={useColorModeValue("blue.500", "blue.200")}
                    borderRadius="full"
                    justify="center"
                    align="center"
                >
                    <FontAwesomeIcon icon={icon} size="lg" />
                </Flex>
                <VStack align="start" spacing={1}>
                    <Flex alignItems="center">
                        <Text fontWeight="bold" fontSize="lg">{title}</Text>
                        {isNew && (
                            <Badge colorScheme="green" ml={2}>
                                {t('New')}
                            </Badge>
                        )}
                    </Flex>
                    <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>{description}</Text>
                </VStack>
            </VStack>
        </MotionBox>
    );

    const MobileLayout = () => (
        <VStack spacing={6} align="stretch">
            <Flex direction="column" align="center" bg={cardBgColor} p={6} borderRadius="xl">
                <Avatar
                    size="xl"
                    src={userProfile.profilePhoto}
                    borderRadius="full"
                    border="4px solid"
                    borderColor={borderColor}
                    mb={4}
                />
                <Text fontWeight="bold" fontSize="xl" color={textColor} mb={2}>@{userProfile.username || t('userProfile.defaultUsername')}</Text>
                {userProfile.walletAddress ? (
                    <WalletAddressSection
                        address={userProfile.walletAddress}
                        onCopy={handleCopy}
                    />
                ) : (
                    <Button
                        onClick={onCreateWalletOpen}
                        colorScheme="blue"
                        size="md"
                        borderRadius="full"
                        fontWeight="bold"
                        mt={2}
                    >
                        {t('userProfile.createWallet')}
                    </Button>
                )}
            </Flex>

            <MenuSection
                title={t('Main Features')}
                icon={faMagic}
                isOpen={activeSection === 'mainFeatures'}
                onToggle={() => setActiveSection(activeSection === 'mainFeatures' ? null : 'mainFeatures')}
            >
                <SimpleGrid columns={1} spacing={4}>
                    <MenuButton icon={faMagic} label={t('Generate a Prompt')} onClick={() => navigate('/prompt-generation')} />
                    <MenuButton icon={faHistory} label={t('View Chat History')} onClick={handleViewChatHistory} />
                    <MenuButton icon={faPlus} label={t('CreateN')} onClick={() => navigate('/create')} isNew={true} />
                    <MenuButton icon={faBell} label={t('Alerts')} onClick={() => navigate('/alerts')} />
                    <MenuButton icon={faCode} label={t('Code Playground')} onClick={() => navigate('/playground')} isNew={true} />
                </SimpleGrid>
            </MenuSection>

            <MenuSection
                title={t('Trading & Investments')}
                icon={faChartLine}
                isOpen={activeSection === 'trading'}
                onToggle={() => setActiveSection(activeSection === 'trading' ? null : 'trading')}
            >
                <SimpleGrid columns={1} spacing={4}>
                    <MenuButton icon={faWallet} label={t('Wallet')} onClick={() => navigate('/wallet')} />
                    <MenuButton icon={faChartLine} label={t('Futures')} onClick={() => navigate('/futures')} />
                    <MenuButton icon={faExchangeAlt} label={t('Swap')} onClick={() => navigate('/swap')} />
                    <MenuButton icon={faExchangeAlt} label={t('Auto Swap')} onClick={() => navigate('/auto-swap')} />
                    <MenuButton icon={faRocket} label={t('Alphas')} onClick={() => navigate('/alpha')} />
                    <MenuButton icon={faSignal} label={t('Signals')} onClick={() => navigate('/comingsoon')} />
                </SimpleGrid>
            </MenuSection>

            <MenuSection
                title={t('Development & Tools')}
                icon={faHammer}
                isOpen={activeSection === 'development'}
                onToggle={() => setActiveSection(activeSection === 'development' ? null : 'development')}
            >
                <SimpleGrid columns={1} spacing={4}>
                    <MenuButton icon={faHammer} label={t('Build')} onClick={() => navigate('/platform')} />
                    <MenuButton icon={faRocket} label={t('LaunchPad')} onClick={() => navigate('/launchpad')} />
                    <MenuButton icon={faTachometerAlt} label={t('ExocrestN')} onClick={() => navigate('/dashboard')} isNew={true} />
                    <MenuButton icon={faBriefcase} label={t('WorkSpace')} onClick={() => navigate('/workspace')} isNew={true} />
                </SimpleGrid>
            </MenuSection>

            <MenuSection
                title={t('Support & Information')}
                icon={faQuestionCircle}
                isOpen={activeSection === 'support'}
                onToggle={() => setActiveSection(activeSection === 'support' ? null : 'support')}
            >
                <SimpleGrid columns={1} spacing={4}>
                    <MenuButton icon={faQuestionCircle} label={t('FAQ')} onClick={() => navigate('/faq')} />
                    <MenuButton icon={faUser} label={t('Profile')} onClick={() => navigate('/profile')} />
                    <MenuButton icon={faCog} label={t('Settings')} onClick={() => navigate('/settings')} />
                </SimpleGrid>
            </MenuSection>
        </VStack>
    );

    return (
        <Box minH="100vh" bg={bgColor} pb={16}>
            <Container maxW="container.xl" py={8}>
                <Flex align="center" justify="space-between" mb={8}>
                    <Flex align="center">
                        <IconButton
                            icon={<ArrowBackIcon />}
                            onClick={() => navigate(-1)}
                            variant="ghost"
                            aria-label="Go back"
                        />
                        <Image src={companyLogo} boxSize="50px" alt="Company Logo" mr={3} />
                        <Heading fontSize="xl" fontFamily="Lexend" color={textColor}>Maximo AI</Heading>
                    </Flex>
                    <Button leftIcon={<FontAwesomeIcon icon={faComments} />} onClick={() => navigate('/chat')} colorScheme="blue" variant="outline">
                        {t('Go to Chats')}
                    </Button>
                </Flex>

                {isLargerThan1280 ? <DesktopLayout /> : <MobileLayout />}

                {!isLargerThan1280 && (
                    <Box bg={cardBgColor} p={4} borderRadius="xl" fontSize="sm" mt={8}>
                        <Flex alignItems="center" mb={2}>
                            <Text fontWeight="bold" mr={2}>{t('Model Selection')}</Text>
                            <Tooltip label={t('chooseBetweenMaximoClaude')}>
                                <Text fontSize="lg" cursor="pointer">ℹ️</Text>
                            </Tooltip>
                        </Flex>
                        <Text mb={4}>{t('chooseBetweenMaximoClaude')}</Text>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text fontWeight="bold">
                                {selectedModel === 'maximo' ? 'Maximo AI 1 Beta' : 'Claude 3.5 Sonnet'}
                            </Text>
                            <Switch
                                colorScheme="teal"
                                size="lg"
                                isChecked={selectedModel === 'claude'}
                                onChange={handleModelChange}
                            />
                        </Flex>
                    </Box>
                )}

                {!isLargerThan1280 && (
                    <Button
                        colorScheme="red"
                        leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
                        onClick={logout}
                        w="full"
                        mt={6}
                        borderRadius="full"
                    >
                        {t('logout')}
                    </Button>
                )}

                <Footer />
            </Container>

            <Modal isOpen={isCreateWalletOpen} onClose={onCreateWalletClose} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent borderRadius="xl" bg={cardBgColor} shadow="xl" mx={[4, 0]} maxW="400px">
                    <ModalHeader color={textColor}>{t('userProfile.createWallet')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel color={textColor}>{t('password')}</FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                borderRadius="full"
                                bg={useColorModeValue("white", "gray.700")}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={createWallet}
                            borderRadius="full"
                        >
                            {t('create')}
                        </Button>
                        <Button variant="ghost" onClick={onCreateWalletClose} borderRadius="full">{t('cancel')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default UserMenuModal;