// src/components/LogoutListener.js
import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { emitter } from '../contexts/eventEmitter';

const LogoutListener = () => {
  const auth = useAuth();

  useEffect(() => {
    const handleLogout = () => {
      auth.logout();
      console.log('Logging out due to 403 error');
    };

    emitter.on('logout', handleLogout);

    // Cleanup
    return () => emitter.removeListener('logout', handleLogout);
  }, [auth]); // Ensure auth is included if it has dependencies that could change

  return null; // This component doesn't render anything
};

export default LogoutListener;
