import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    Box,
    Button,
    Text,
    VStack,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    IconButton,
    Flex,
    useMediaQuery,
} from '@chakra-ui/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faGift, faCopy, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useClipboard } from '@chakra-ui/react';

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: '#0a0e17',
                color: '#e0e0e0',
            },
        },
        config: {
            initialColorMode: 'dark',
            useSystemColorMode: false,
        },
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: 'full',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                _hover: {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 0 15px rgba(66, 220, 219, 0.5)',
                },
                _active: {
                    transform: 'translateY(0)',
                },
            },
            variants: {
                solid: {
                    bg: 'rgba(66, 220, 219, 0.2)',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.4)',
                    },
                },
                outline: {
                    bg: 'transparent',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.1)',
                    },
                },
            },
        },
        Modal: {
            baseStyle: {
                dialog: {
                    bg: 'rgba(10, 14, 23, 0.8)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '2xl',
                    border: '1px solid rgba(66, 220, 219, 0.3)',
                },
            },
        },
    },
});

const MotionBox = motion(Box);

const EarnPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const [userId, setUserId] = useState(null);
    const [checkinDay, setCheckinDay] = useState(null);
    const [totalExcrClaimed, setTotalExcrClaimed] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckinModalOpen, setIsCheckinModalOpen] = useState(false);
    const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);
    const [referralCode, setReferralCode] = useState('');
    const [numReferrals, setNumReferrals] = useState(0);

    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const referralLink = `https://maximo.huddlz.xyz/ref?ref=${referralCode}`;
    const { onCopy: onCopyCode, hasCopied: hasCopiedCode } = useClipboard(referralCode);
    const { onCopy: onCopyLink, hasCopied: hasCopiedLink } = useClipboard(referralLink);

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

    const fetchCheckinStatus = useCallback(async (userId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/api/checkin/checkin-status`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: { userId },
            });
            setCheckinDay(response.data.checkinDay);
            setTotalExcrClaimed(response.data.totalExcrClaimed);
        } catch (error) {
            console.error('Error fetching check-in status:', error);
            toast({
                title: t('Error fetching check-in status'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [toast, t, apiUrl]);

    const fetchReferralData = useCallback(async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/api/referral`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setReferralCode(response.data.referral_code);
            setNumReferrals(response.data.referral_count);
        } catch (error) {
            console.error('Error fetching referral data:', error);
            toast({
                title: t('Error fetching referral data'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [toast, t, apiUrl]);

    useEffect(() => {
        const fetchUserId = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserId(response.data.id);
                fetchCheckinStatus(response.data.id);
                fetchReferralData();
            } catch (error) {
                toast({
                    id: 'user-id-error',
                    title: t('Error fetching user ID'),
                    description: error.response?.data?.message || error.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchUserId();
    }, [toast, t, apiUrl, fetchCheckinStatus, fetchReferralData]);

    const handleCheckin = async () => {
        const token = localStorage.getItem('token');
        setIsLoading(true);
        try {
            await axios.post(
                `${apiUrl}/api/checkin/checkin`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: t('Check-in successful'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchCheckinStatus(userId);
        } catch (error) {
            console.error('Error checking in:', error);
            toast({
                title: t('Error checking in'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenCheckinModal = () => setIsCheckinModalOpen(true);
    const handleCloseCheckinModal = () => setIsCheckinModalOpen(false);
    const handleOpenReferralModal = () => setIsReferralModalOpen(true);
    const handleCloseReferralModal = () => setIsReferralModalOpen(false);

    return (
        <ChakraProvider theme={theme}>
            <MotionBox
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                p={6}
                minH="105vh"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <VStack spacing={8} alignItems="stretch" maxWidth={isLargerThan768 ? "600px" : "100%"} width="100%">
                    <MotionBox
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                    >
                        <Text fontSize="4xl" fontWeight="bold" textAlign="center" color="#42dcdb" textShadow="0 0 10px rgba(66, 220, 219, 0.5)">
                            {t('Daily Check-in')}
                        </Text>
                    </MotionBox>

                    <MotionBox
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                        bg="rgba(66, 220, 219, 0.1)"
                        p={6}
                        borderRadius="2xl"
                        boxShadow="0 0 20px rgba(66, 220, 219, 0.2)"
                        border="1px solid rgba(66, 220, 219, 0.3)"
                    >
                        <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
                            {t('Check-in Day')}: <Text as="span" color="#42dcdb">{checkinDay !== null ? checkinDay + 1 : t('N/A')}</Text>
                        </Text>
                        <Text fontSize="xl" fontWeight="bold" textAlign="center">
                            {t('Total EXCR Claimed')}: <Text as="span" color="#42dcdb">{totalExcrClaimed}</Text>
                        </Text>
                    </MotionBox>

                    <MotionBox
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                    >
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faGift} />}
                            onClick={handleCheckin}
                            colorScheme="blue"
                            size="lg"
                            width="100%"
                            isLoading={isLoading}
                            mb={4}
                        >
                            {t('Check-in')}
                        </Button>

                        <Button
                            leftIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                            onClick={handleOpenCheckinModal}
                            variant="outline"
                            size="lg"
                            width="100%"
                            mb={4}
                        >
                            {t('Check-in Details')}
                        </Button>

                        <Button
                            leftIcon={<FontAwesomeIcon icon={faGift} />}
                            onClick={handleOpenReferralModal}
                            variant="outline"
                            size="lg"
                            width="100%"
                            mb={4}
                        >
                            {t('Refer to Earn')}
                        </Button>

                        <Button
                            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                            onClick={() => navigate('/dashboard')}
                            variant="outline"
                            size="lg"
                            width="100%"
                        >
                            {t('Back to Dashboard')}
                        </Button>
                    </MotionBox>
                </VStack>

                <AnimatePresence>
                    {isCheckinModalOpen && (
                        <Modal isOpen={isCheckinModalOpen} onClose={handleCloseCheckinModal} isCentered>
                            <ModalOverlay />
                            <MotionBox
                                as={ModalContent}
                                initial={{ scale: 0.9, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.9, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <ModalHeader>{t('Check-in Details')}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <VStack spacing={4} align="start">
                                        <Box w="full" bg="rgba(66, 220, 219, 0.1)" p={4} borderRadius="lg" boxShadow="0 0 10px rgba(66, 220, 219, 0.2)">
                                            <Text><b>{t('Day 1')}:</b> 50 EXCR</Text>
                                            <Text><b>{t('Day 2')}:</b> 100 EXCR</Text>
                                            <Text><b>{t('Day 3')}:</b> 250 EXCR</Text>
                                            <Text><b>{t('Day 4')}:</b> 500 EXCR</Text>
                                            <Text><b>{t('Day 5')}:</b> 1500 EXCR</Text>
                                            <Text><b>{t('Day 6')}:</b> 2500 EXCR</Text>
                                            <Text><b>{t('Day 7')}:</b> 10000 EXCR</Text>
                                            <Text><b>{t('Day 8')}:</b> 50000 EXCR</Text>
                                            <Text><b>{t('Day 9')}:</b> 100000 EXCR</Text>
                                            <Text><b>{t('Day 10')}:</b> 500000 EXCR</Text>
                                        </Box>
                                        <Box w="full" bg="rgba(66, 220, 219, 0.1)" p={4} borderRadius="lg" boxShadow="0 0 10px rgba(66, 220, 219, 0.2)">
                                            <Text fontWeight="bold" mb={2}>{t('Conditions')}:</Text>
                                            <Text>1. {t('Check in daily to earn EXCR.')}</Text>
                                            <Text>2. {t('Missing a day resets the streak to Day 1.')}</Text>
                                            <Text>3. {t('Completing Day 10 resets the streak to Day 1.')}</Text>
                                        </Box>
                                    </VStack>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={handleCloseCheckinModal}>{t('Close')}</Button>
                                </ModalFooter>
                            </MotionBox>
                        </Modal>
                    )}
                </AnimatePresence>

                <AnimatePresence>
                    {isReferralModalOpen && (
                        <Modal isOpen={isReferralModalOpen} onClose={handleCloseReferralModal} isCentered>
                            <ModalOverlay />
                            <MotionBox
                                as={ModalContent}
                                initial={{ scale: 0.9, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.9, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <ModalHeader>{t('Referral Details')}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <VStack spacing={4} align="start">
                                        <Box w="full" bg="rgba(66, 220, 219, 0.1)" p={4} borderRadius="lg" boxShadow="0 0 10px rgba(66, 220, 219, 0.2)">
                                            <Flex align="center" mb={2}>
                                                <Text mr={2}>{t('profile.referralCode')}:</Text>
                                                <Text fontWeight="semibold" color="#42dcdb">{referralCode}</Text>
                                                <IconButton
                                                    icon={<FontAwesomeIcon icon={faCopy} />}
                                                    size="sm"
                                                    onClick={onCopyCode}
                                                    ml={2}
                                                    aria-label={t('copy')}
                                                    variant="outline"
                                                />
                                                {hasCopiedCode && (
                                                    <Text color="#42dcdb" ml={2} fontSize="sm">
                                                        {t('copied')}
                                                    </Text>
                                                )}
                                            </Flex>
                                            <Flex align="center" mb={2}>
                                                <Text mr={2}>{t('profile.referralLink')}:</Text>
                                                <Text fontWeight="semibold" color="#42dcdb" isTruncated>
                                                    {referralLink}
                                                </Text>
                                                <IconButton
                                                    icon={<FontAwesomeIcon icon={faCopy} />}
                                                    size="sm"
                                                    onClick={onCopyLink}
                                                    ml={2}
                                                    aria-label={t('copy')}
                                                    variant="outline"
                                                />
                                                {hasCopiedLink && (
                                                    <Text color="#42dcdb" ml={2} fontSize="sm">
                                                        {t('copied')}
                                                    </Text>
                                                )}
                                            </Flex>
                                            <Text>{t('profile.numReferrals')}: <Text as="span" color="#42dcdb">{numReferrals}</Text></Text>
                                        </Box>
                                        <Box w="full" bg="rgba(66, 220, 219, 0.1)" p={4} borderRadius="lg" boxShadow="0 0 10px rgba(66, 220, 219, 0.2)">
                                            <Text fontWeight="bold" mb={2}>{t('referralBenefits.title')}</Text>
                                            <Text>{t('referralBenefits.description1N')}</Text>
                                            <Text>{t('referralBenefits.description2N')}</Text>
                                        </Box>
                                    </VStack>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={handleCloseReferralModal}>{t('Close')}</Button>
                                </ModalFooter>
                            </MotionBox>
                        </Modal>
                    )}
                </AnimatePresence>
            </MotionBox>
        </ChakraProvider>
    );
};

export default EarnPage;