import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Text, VStack, Flex, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, Input, Button, FormControl, FormLabel,
    Container, HStack, useToast, Image, List, ListItem, useMediaQuery
} from '@chakra-ui/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FiList, FiSend } from 'react-icons/fi';
import { RiGamepadLine } from 'react-icons/ri';
import CustomSelect from './CustomSelect';

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: '#0a0e17',
                color: '#e0e0e0',
            },
        },
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: 'full',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                _hover: {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 0 15px rgba(66, 220, 219, 0.5)',
                },
                _active: {
                    transform: 'translateY(0)',
                },
            },
            variants: {
                solid: {
                    bg: 'rgba(66, 220, 219, 0.2)',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.4)',
                    },
                },
                outline: {
                    bg: 'transparent',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.1)',
                    },
                },
            },
        },
        Modal: {
            baseStyle: {
                dialog: {
                    bg: 'rgba(10, 14, 23, 0.8)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '2xl',
                    border: '1px solid rgba(66, 220, 219, 0.3)',
                },
            },
        },
    },
});

const MotionBox = motion(Box);

const InventoryPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const [userCountries, setUserCountries] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [resources, setResources] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [recipientCountry, setRecipientCountry] = useState(null);
    const [sendAmount, setSendAmount] = useState('');
    const [selectedResource, setSelectedResource] = useState('');
    const [userProfile, setUserProfile] = useState({});
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [isTransactionHistoryOpen, setIsTransactionHistoryOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState([]);
    const navigate = useNavigate();

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

    const fetchResources = useCallback(async (countryId) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) return;

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/planets/countries/${countryId}/resources`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setResources(response.data);
        } catch (error) {
            console.error('Failed to fetch resources:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchUserCountries = useCallback(async (userId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/planets/user-countries/${userId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUserCountries(response.data);
            if (response.data.length > 0) {
                setSelectedCountry({ value: response.data[0].id, label: response.data[0].name });
                fetchResources(response.data[0].id);
            }
        } catch (error) {
            console.error('Error fetching user countries:', error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchResources]);

    const fetchAllCountries = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/planets/countries`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAllCountries(response.data);
        } catch (error) {
            console.error('Error fetching all countries:', error);
        }
    }, []);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const authToken = localStorage.getItem('token');
                if (!authToken) {
                    navigate('/login');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setUserProfile(response.data);
                fetchUserCountries(response.data.id);
                fetchAllCountries();
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, [navigate, fetchUserCountries, fetchAllCountries]);

    useEffect(() => {
        if (selectedCountry) {
            fetchResources(selectedCountry.value);
        }
    }, [selectedCountry, fetchResources]);

    const handleSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        if (term.trim() === '') {
            setFilteredCountries([]);
        } else {
            const filtered = allCountries.filter(country =>
                country.name.toLowerCase().includes(term) ||
                country.ownerUsername.toLowerCase().includes(term)
            );
            setFilteredCountries(filtered);
        }
    };

    const handleSelectRecipientCountry = (country) => {
        setRecipientCountry(country);
        setSearchTerm(country.name);
        setFilteredCountries([]);
    };

    const handleOpenModal = (resourceType) => {
        setSelectedResource(resourceType);
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setRecipientCountry(null);
        setSendAmount('');
        setSearchTerm('');
        setFilteredCountries([]);
    };

    const handleSendResources = async () => {
        if (!sendAmount || !selectedResource || !recipientCountry) {
            toast({
                title: t('All fields are required.'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const authToken = localStorage.getItem('token');
            if (!authToken) {
                console.error('Authentication token not found.');
                return;
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/api/planets/transfer-resources`, {
                sourceCountryId: selectedCountry.value,
                targetCountryId: recipientCountry.id,
                resourceType: selectedResource,
                quantity: sendAmount,
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            toast({
                title: t('Resources sent successfully.'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            fetchResources(selectedCountry.value);
            handleCloseModal();
        } catch (error) {
            console.error('Error sending resources:', error);
            toast({
                title: t('Failed to send resources.'),
                description: error.response?.data?.message || error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const fetchTransactionHistory = async () => {
        try {
            const authToken = localStorage.getItem('token');
            if (!authToken) {
                console.error('Authentication token not found.');
                return;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/planets/transaction-history`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            setTransactionHistory(response.data);
            setIsTransactionHistoryOpen(true);
        } catch (error) {
            console.error('Error fetching transaction history:', error);
            toast({
                title: t('Failed to fetch transaction history.'),
                description: error.response?.data?.message || error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const ResourceList = () => (
        <AnimatePresence>
            <VStack spacing={6} w="full" align="stretch">
                {resources.map((resource, index) => (
                    <MotionBox
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                        p={5}
                        bg="rgba(66, 220, 219, 0.1)"
                        borderRadius="2xl"
                        w="full"
                        shadow="2xl"
                        position="relative"
                        overflow="hidden"
                        cursor="pointer"
                        onClick={() => handleOpenModal(resource.type)}
                        _hover={{
                            bg: "rgba(66, 220, 219, 0.2)",
                            transform: "translateY(-5px)",
                            transition: "all 0.3s ease"
                        }}
                    >
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            bgGradient="linear(to-r, teal.500, green.500)"
                            opacity={0.1}
                        />
                        <HStack justifyContent="space-between" alignItems="center">
                            <VStack align="flex-start" spacing={0.5}>
                                <Text fontWeight="bold" fontSize="xl" color="#42dcdb">
                                    {resource.type}
                                </Text>
                                <Text fontSize="sm" color="gray.400">
                                    {`${t("balance")}: ${parseFloat(resource.quantity).toFixed(3)}`}
                                </Text>
                            </VStack>
                            <Box as={RiGamepadLine} size="24px" color="#42dcdb" />
                        </HStack>
                    </MotionBox>
                ))}
            </VStack>
        </AnimatePresence>
    );

    const TransactionHistoryModal = () => (
        <Modal isOpen={isTransactionHistoryOpen} onClose={() => setIsTransactionHistoryOpen(false)} isCentered size="xl">
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent
                bg="rgba(10, 14, 23, 0.9)"
                borderRadius="2xl"
                border="1px solid rgba(66, 220, 219, 0.3)"
                boxShadow="0 0 20px rgba(66, 220, 219, 0.2)"
            >
                <ModalHeader color="#42dcdb">{t('Transaction History')}</ModalHeader>
                <ModalCloseButton color="#42dcdb" />
                <ModalBody>
                    <VStack spacing={4} align="stretch" maxH="60vh" overflowY="auto">
                        <AnimatePresence>
                            {transactionHistory.map((transaction, index) => (
                                <MotionBox
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.3, delay: index * 0.05 }}
                                    p={4}
                                    bg="rgba(66, 220, 219, 0.1)"
                                    borderRadius="xl"
                                    w="full"
                                    shadow="md"
                                    border="1px solid rgba(66, 220, 219, 0.2)"
                                >
                                    <Text color="#42dcdb">{t('From')}: {transaction.sourceCountryName}</Text>
                                    <Text color="#42dcdb">{t('To')}: {transaction.targetCountryName}</Text>
                                    <Text color="#42dcdb">{t('Resource')}: {transaction.resourceType}</Text>
                                    <Text color="#42dcdb">{t('Quantity')}: {transaction.quantity}</Text>
                                    <Text color="#42dcdb">{t('Fee')}: {transaction.fee}</Text>
                                    <Text color="#42dcdb">{t('Date')}: {new Date(parseFloat(transaction.date)).toLocaleString()}</Text>
                                </MotionBox>
                            ))}
                        </AnimatePresence>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" onClick={() => setIsTransactionHistoryOpen(false)}>{t('Close')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );

    return (
        <ChakraProvider theme={theme}>
            <Container maxW={isLargerThan768 ? "container.xl" : "100%"} py={8} px={isLargerThan768 ? 8 : 4} minHeight="140vh">
                <MotionBox
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Flex direction="column" align="center" justify="center" w="full">
                        <MotionBox
                            initial={{ y: -50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.2, duration: 0.5 }}
                            p={6}
                            bgGradient="linear(to-r, teal.500, green.500)"
                            borderRadius="2xl"
                            w="full"
                            shadow="2xl"
                            textAlign="center"
                            color="white"
                            mb={8}
                        >
                            <Text fontSize="3xl" fontWeight="bold" textShadow="0 0 10px rgba(66, 220, 219, 0.5)">
                                {t('Inventory')}
                            </Text>
                            <HStack justifyContent="center" spacing={2} mt={2}>
                                <Text fontSize="md" noOfLines={1}>@{userProfile.username}</Text>
                            </HStack>
                        </MotionBox>

                        <MotionBox
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ delay: 0.4, duration: 0.5 }}
                            w="full"
                            mb={8}
                        >
                            <FormControl>
                                <FormLabel color="#42dcdb">{t('Select Country')}</FormLabel>
                                <CustomSelect
                                    options={userCountries.map((country) => ({ value: country.id, label: country.name }))}
                                    selectedOption={selectedCountry}
                                    setSelectedOption={(option) => setSelectedCountry(option)}
                                    placeholder={t('Select a country')}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            background: "rgba(66, 220, 219, 0.1)",
                                            borderColor: "#42dcdb",
                                            borderRadius: "full",
                                            padding: "2px",
                                            boxShadow: "none",
                                            "&:hover": {
                                                borderColor: "#42dcdb",
                                            },
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            background: "rgba(10, 14, 23, 0.9)",
                                            borderRadius: "xl",
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            background: state.isSelected ? "rgba(66, 220, 219, 0.2)" : "transparent",
                                            color: state.isSelected ? "#42dcdb" : "#e0e0e0",
                                            "&:hover": {
                                                background: "rgba(66, 220, 219, 0.1)",
                                            },
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#42dcdb",
                                        }),
                                    }}
                                />
                            </FormControl>
                        </MotionBox>

                        <MotionBox
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.6, duration: 0.5 }}
                            w="full"
                            mb={8}
                        >
                            <Button
                                leftIcon={<FiList />}
                                onClick={fetchTransactionHistory}
                                w="full"
                                size="lg"
                            >
                                {t('Transaction History')}
                            </Button>
                        </MotionBox>

                        {isLoading ? (
                            <MotionBox
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.5 }}
                                transition={{ duration: 0.5 }}
                            >
                                <Box as="span" color="#42dcdb" fontSize="4xl">
                                    ⚙️
                                </Box>
                            </MotionBox>
                        ) : (
                            <Box maxH="60vh" w="full" overflowY="auto" pr={2}>
                                <ResourceList />
                            </Box>
                        )}

                        <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size={isLargerThan768 ? "md" : "full"}>
                            <ModalOverlay backdropFilter="blur(10px)" />
                            <ModalContent
                                bg="rgba(10, 14, 23, 0.9)"
                                borderRadius="2xl"
                                border="1px solid rgba(66, 220, 219, 0.3)"
                                boxShadow="0 0 20px rgba(66, 220, 219, 0.2)"
                            >
                                <ModalHeader color="#42dcdb">{t('Send Resources')}</ModalHeader>
                                <ModalCloseButton color="#42dcdb" />
                                <ModalBody>
                                    <VStack spacing={4} align="stretch">
                                        <Text color="#42dcdb">{t('Resource')}: {selectedResource}</Text>
                                        <Text color="#42dcdb">
                                            {t('Balance')}: {resources.find(resource => resource.type === selectedResource)?.quantity.toFixed(3)}
                                        </Text>
                                        <FormControl>
                                            <FormLabel color="#42dcdb">{t('Search Country')}</FormLabel>
                                            <Input
                                                placeholder={t('Enter country name or owner')}
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                                bg="rgba(66, 220, 219, 0.1)"
                                                color="#42dcdb"
                                                border="1px solid #42dcdb"
                                                _hover={{
                                                    borderColor: "#42dcdb",
                                                    boxShadow: "0 0 0 1px #42dcdb"
                                                }}
                                                _focus={{
                                                    borderColor: "#42dcdb",
                                                    boxShadow: "0 0 0 1px #42dcdb"
                                                }}
                                            />
                                            {filteredCountries.length > 0 && (
                                                <List mt={2} spacing={2} maxH="200px" overflowY="auto">
                                                    <AnimatePresence>
                                                        {filteredCountries.map(country => (
                                                            <MotionBox
                                                                key={country.id}
                                                                initial={{ opacity: 0, y: -10 }}
                                                                animate={{ opacity: 1, y: 0 }}
                                                                exit={{ opacity: 0, y: 10 }}
                                                                transition={{ duration: 0.2 }}
                                                            >
                                                                <ListItem
                                                                    onClick={() => handleSelectRecipientCountry(country)}
                                                                    cursor="pointer"
                                                                    p={2}
                                                                    bg="rgba(66, 220, 219, 0.1)"
                                                                    borderRadius="md"
                                                                    _hover={{ bg: "rgba(66, 220, 219, 0.2)" }}
                                                                >
                                                                    <HStack>
                                                                        <Image
                                                                            boxSize="30px"
                                                                            borderRadius="full"
                                                                            src={`${process.env.REACT_APP_API_URL}${country.flag}`}
                                                                            alt={`${country.name} flag`}
                                                                        />
                                                                        <VStack align="flex-start" spacing={0}>
                                                                            <Text fontWeight="bold" color="#42dcdb">{country.name}</Text>
                                                                            <Text fontSize="sm" color="gray.400">@{country.ownerUsername}</Text>
                                                                        </VStack>
                                                                    </HStack>
                                                                </ListItem>
                                                            </MotionBox>
                                                        ))}
                                                    </AnimatePresence>
                                                </List>
                                            )}
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel color="#42dcdb">{t('Amount')}</FormLabel>
                                            <Input
                                                placeholder={t('Enter amount')}
                                                value={sendAmount}
                                                onChange={(e) => setSendAmount(e.target.value)}
                                                bg="rgba(66, 220, 219, 0.1)"
                                                color="#42dcdb"
                                                border="1px solid #42dcdb"
                                                _hover={{
                                                    borderColor: "#42dcdb",
                                                    boxShadow: "0 0 0 1px #42dcdb"
                                                }}
                                                _focus={{
                                                    borderColor: "#42dcdb",
                                                    boxShadow: "0 0 0 1px #42dcdb"
                                                }}
                                            />
                                        </FormControl>
                                    </VStack>
                                </ModalBody>
                                <ModalFooter>
                                    <Button leftIcon={<FiSend />} onClick={handleSendResources} mr={3}>
                                        {t('Send')}
                                    </Button>
                                    <Button variant="outline" onClick={handleCloseModal}>{t('Cancel')}</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>

                        <TransactionHistoryModal />
                    </Flex>
                </MotionBox>
            </Container>
        </ChakraProvider>
    );
};

export default InventoryPage;