import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useToast,
    IconButton,
    useClipboard,
} from '@chakra-ui/react';
import { UnlockIcon, CopyIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const DecryptWalletModal = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [decryptedKey, setDecryptedKey] = useState('');
    const toast = useToast();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { hasCopied, onCopy } = useClipboard(decryptedKey);
    const [userId, setUserId] = useState('');

    const openModal = () => setIsOpen(true);
    const closeModal = () => {
        setIsOpen(false);
        setIsKeyModalOpen(false)
        setDecryptedKey(''); // Clear decrypted key when modal is closed
    };

    useEffect(() => {
        // Fetch user profile to get the user ID
        const fetchUserProfile = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setUserId(response.data.id);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, [apiUrl]);

    const handleDecrypt = async () => {
        try {
            const authToken = localStorage.getItem('token');
            // Include the user ID and use the Authorization header for the token
            const response = await axios.post(`${apiUrl}/api/decrypt-private-key-for-frontend`, {
                password,
                userId
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            const reEncryptedPrivateKey = response.data.reEncryptedPrivateKey;
            const FRONTEND_ENCRYPTION_KEY = process.env.REACT_APP_FRONTEND_ENCRYPTION_KEY;
            const bytes = CryptoJS.AES.decrypt(reEncryptedPrivateKey, FRONTEND_ENCRYPTION_KEY);
            const originalPrivateKey = bytes.toString(CryptoJS.enc.Utf8);

            setDecryptedKey(originalPrivateKey);
            // console.log(reEncryptedPrivateKey);
            // console.log(originalPrivateKey);
            setIsOpen(false); // Close the password input modal
            setIsKeyModalOpen(true);
            toast({
                title: t('decryptionSuccessful'),
                description: t('yourPrivateKeyIsDecrypted'),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Decryption failed:', error);
            toast({
                title: t('decryptionFailed'),
                description: t('unableToDecryptYourPrivateKey'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button leftIcon={<UnlockIcon />} colorScheme="purple" onClick={openModal} size="sm">
                {t('decryptKey')}
            </Button>

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent borderRadius="lg"
                    shadow="xl"
                    mx={[4, 0]}
                    maxW="400px">
                    <ModalHeader>{t('decryptYourWallet')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{t('walletPassword')}</FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={t('enterYourWalletPassword')}
                            />
                        </FormControl>
                        {decryptedKey && (
                            <FormControl mt="4">
                                <FormLabel>{t('yourDecryptedPrivateKey')}:</FormLabel>
                                <Input type="text" value={decryptedKey} readOnly />
                            </FormControl>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleDecrypt}>
                            {t('decrypt')}
                        </Button>
                        <Button variant="ghost" onClick={closeModal}>
                            {t('cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* Decrypted Private Key Display Modal */}
            <Modal isOpen={isKeyModalOpen} onClose={() => setIsKeyModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent borderRadius="lg"
                    shadow="xl"
                    mx={[4, 0]}
                    maxW="400px" >
                    <ModalHeader>{t('yourDecryptedPrivateKey')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <Input type="text" value={decryptedKey} isReadOnly />
                            <IconButton icon={<CopyIcon />} onClick={onCopy} ml={2} colorScheme="teal" aria-label={t('copy')} />
                            {hasCopied ? <p>{t('copied')}</p> : null}
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => {
                            closeModal();
                        }}>
                            {t('close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DecryptWalletModal;
