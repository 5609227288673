import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Text, Button, FormControl, FormLabel,
    Container, SimpleGrid, useToast, useMediaQuery, VStack
} from '@chakra-ui/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer, faArrowUp, faGem, faChevronLeft, faRocket } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CustomSelect from './CustomSelect';

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: '#0a0e17',
                color: '#e0e0e0',
            },
        },
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: 'full',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                _hover: {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 0 15px rgba(66, 220, 219, 0.5)',
                },
                _active: {
                    transform: 'translateY(0)',
                },
            },
            variants: {
                solid: {
                    bg: 'rgba(66, 220, 219, 0.2)',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.4)',
                    },
                },
                outline: {
                    bg: 'transparent',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.1)',
                    },
                },
            },
        },
    },
});

const MotionBox = motion(Box);

const MiningStationPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const [userId, setUserId] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [miningStations, setMiningStations] = useState([]);
    const [resources, setResources] = useState([]);
    const [currencyBalance, setCurrencyBalance] = useState(null);
    const [currencyTicker, setCurrencyTicker] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        const fetchUserId = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserId(response.data.id);
            } catch (error) {
                toast({
                    title: t('Error fetching user ID'),
                    description: error.response?.data?.message || error.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchUserId();
    }, [toast, t]);

    useEffect(() => {
        const fetchCountries = async () => {
            if (!userId) return;

            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/api/planets/user-countries/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCountries(response.data);
                if (response.data.length > 0) {
                    setSelectedCountry({ value: response.data[0].id, label: response.data[0].name });
                }
            } catch (error) {
                toast({
                    title: t('Error fetching countries'),
                    description: error.response?.data?.message || error.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchCountries();
    }, [toast, t, userId]);

    const fetchResources = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/resources`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setResources(response.data);
        } catch (error) {
            toast({
                title: t('Error fetching resources'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    const fetchCurrencyDetails = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiUrl}/api/planets/currencies/${countryId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data.length > 0) {
                const currency = response.data[0];
                setCurrencyBalance(currency.totalBalance.toFixed(3));
                setCurrencyTicker(currency.ticker);
            }
        } catch (error) {
            toast({
                title: t('Error fetching currency details'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    const handleCreateMiningStation = async () => {
        if (!selectedCountry) {
            toast({
                title: t('Missing Fields'),
                description: t('Please select a country'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            await axios.post(
                `${apiUrl}/api/mining/create-mining-station`,
                { countryId: selectedCountry.value },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: t('Mining station created successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchMiningStations(selectedCountry.value);
            fetchResources(selectedCountry.value);
            fetchCurrencyDetails(selectedCountry.value);
        } catch (error) {
            toast({
                title: t('Error creating mining station'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpgradeMiningStation = async (miningStationId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            await axios.post(
                `${apiUrl}/api/mining/upgrade-mining-station`,
                { buildingId: miningStationId, countryId: selectedCountry.value },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: t('Mining station upgraded successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchMiningStations(selectedCountry.value);
            fetchResources(selectedCountry.value);
            fetchCurrencyDetails(selectedCountry.value);
        } catch (error) {
            toast({
                title: t('Error upgrading mining station'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpgradeMaxMiningStation = async (miningStationId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        let upgradeCount = 0;
        let canUpgrade = true;

        while (canUpgrade) {
            try {
                await axios.post(
                    `${apiUrl}/api/mining/upgrade-mining-station`,
                    { buildingId: miningStationId, countryId: selectedCountry.value },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                upgradeCount++;
            } catch (error) {
                canUpgrade = false;
                if (error.response && error.response.status === 400) {
                    // Assume 400 status means not enough resources or max level reached
                    toast({
                        title: t('Max upgrade reached'),
                        description: t(`Upgraded ${upgradeCount} times. ${error.response.data.message}`),
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: t('Error during max upgrade'),
                        description: error.response?.data?.message || error.message || t('An error occurred'),
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            }
        }

        fetchMiningStations(selectedCountry.value);
        fetchResources(selectedCountry.value);
        fetchCurrencyDetails(selectedCountry.value);
        setIsLoading(false);
    };

    const fetchMiningStations = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/buildings`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setMiningStations(response.data.filter(building => building.type === 'Mining Station'));
        } catch (error) {
            toast({
                title: t('Error fetching mining stations'),
                description: error.response?.data?.message || error.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    useEffect(() => {
        if (selectedCountry && selectedCountry.value) {
            fetchMiningStations(selectedCountry.value);
            fetchResources(selectedCountry.value);
            fetchCurrencyDetails(selectedCountry.value);
        }
    }, [selectedCountry, fetchMiningStations, fetchResources, fetchCurrencyDetails]);

    const excrBalance = (resources.find(resource => resource.type === 'EXCR')?.quantity || 0).toFixed(3);

    return (
        <ChakraProvider theme={theme}>
            <Container maxW={isLargerThan768 ? "container.xl" : "100%"} py={8} px={isLargerThan768 ? 8 : 4} minHeight="140vh">
                <VStack spacing={8} align="stretch">
                    <MotionBox
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                        p={6}
                        bgGradient="linear(to-r, teal.500, green.500)"
                        borderRadius="2xl"
                        shadow="2xl"
                        textAlign="center"
                        color="white"
                    >
                        <Box as={FontAwesomeIcon} icon={faGem} fontSize="4xl" mb={4} />
                        <Text fontSize="3xl" fontWeight="bold" textShadow="0 0 10px rgba(66, 220, 219, 0.5)">
                            {t('Mining Stations')}
                        </Text>
                    </MotionBox>

                    <MotionBox
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ delay: 0.4, duration: 0.5 }}
                    >
                        <FormControl>
                            <FormLabel color="#42dcdb">{t('Select Country')}</FormLabel>
                            <CustomSelect
                                options={countries.map((country) => ({ value: country.id, label: country.name }))}
                                selectedOption={selectedCountry}
                                setSelectedOption={(option) => setSelectedCountry(option)}
                                placeholder={t('Select a country')}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        background: "rgba(66, 220, 219, 0.1)",
                                        borderColor: "#42dcdb",
                                        borderRadius: "full",
                                        padding: "2px",
                                        boxShadow: "none",
                                        "&:hover": {
                                            borderColor: "#42dcdb",
                                        },
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        background: "rgba(10, 14, 23, 0.9)",
                                        borderRadius: "xl",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        background: state.isSelected ? "rgba(66, 220, 219, 0.2)" : "transparent",
                                        color: state.isSelected ? "#42dcdb" : "#e0e0e0",
                                        "&:hover": {
                                            background: "rgba(66, 220, 219, 0.1)",
                                        },
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: "#42dcdb",
                                    }),
                                }}
                            />
                        </FormControl>
                    </MotionBox>

                    <MotionBox
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                    >
                        <SimpleGrid columns={[1, null, 2]} spacing={4}>
                            <Box
                                p={4}
                                bg="rgba(66, 220, 219, 0.1)"
                                borderRadius="xl"
                                border="1px solid rgba(66, 220, 219, 0.3)"
                            >
                                <Text fontSize="xl" fontWeight="bold" color="#42dcdb">
                                    {t('EXCR Balance')}: {excrBalance}
                                </Text>
                            </Box>
                            {currencyTicker && currencyBalance && (
                                <Box
                                    p={4}
                                    bg="rgba(66, 220, 219, 0.1)"
                                    borderRadius="xl"
                                    border="1px solid rgba(66, 220, 219, 0.3)"
                                >
                                    <Text fontSize="xl" fontWeight="bold" color="#42dcdb">
                                        {currencyTicker} {t('Balance')}: {currencyBalance}
                                    </Text>
                                </Box>
                            )}
                        </SimpleGrid>
                    </MotionBox>

                    <MotionBox
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ delay: 0.8, duration: 0.5 }}
                    >
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faHammer} />}
                            onClick={handleCreateMiningStation}
                            w="full"
                            size="lg"
                            isLoading={isLoading}
                        >
                            {t('Create Mining Station')}
                        </Button>
                    </MotionBox>

                    <MotionBox
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 1, duration: 0.5 }}
                    >
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                            onClick={() => navigate('/dashboard')}
                            w="full"
                            size="lg"
                            variant="outline"
                        >
                            {t('Back to Dashboard')}
                        </Button>
                    </MotionBox>

                    {resources.length > 0 && (
                        <MotionBox
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.2, duration: 0.5 }}
                        >
                            <Text fontSize="2xl" fontWeight="bold" color="#42dcdb" mb={4}>
                                {t('Country Resources')}
                            </Text>
                            <SimpleGrid columns={[1, null, 2, 3]} spacing={4}>
                                <AnimatePresence>
                                    {resources.map((resource, index) => (
                                        <MotionBox
                                            key={resource.type}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -20 }}
                                            transition={{ duration: 0.3, delay: index * 0.1 }}
                                        >
                                            <Box
                                                p={4}
                                                bg="rgba(66, 220, 219, 0.1)"
                                                borderRadius="xl"
                                                border="1px solid rgba(66, 220, 219, 0.3)"
                                                _hover={{
                                                    bg: "rgba(66, 220, 219, 0.2)",
                                                    transform: "translateY(-5px)",
                                                    transition: "all 0.3s ease"
                                                }}
                                            >
                                                <Text fontWeight="bold" color="#42dcdb">{resource.type}</Text>
                                                <Text color="gray.300">{t('Quantity')}: {parseFloat(resource.quantity).toFixed(3)}</Text>
                                            </Box>
                                        </MotionBox>
                                    ))}
                                </AnimatePresence>
                            </SimpleGrid>
                        </MotionBox>
                    )}

                    {miningStations.length > 0 ? (
                        <MotionBox
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.4, duration: 0.5 }}
                        >
                            <Text fontSize="2xl" fontWeight="bold" color="#42dcdb" mb={4}>
                                {t('Existing Mining Stations')}
                            </Text>
                            <SimpleGrid columns={[1, null, 2]} spacing={4}>
                                <AnimatePresence>
                                    {miningStations.map((station, index) => (
                                        <MotionBox
                                            key={station.id}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -20 }}
                                            transition={{ duration: 0.3, delay: index * 0.1 }}
                                        >
                                            <Box
                                                p={4}
                                                bg="rgba(66, 220, 219, 0.1)"
                                                borderRadius="xl"
                                                border="1px solid rgba(66, 220, 219, 0.3)"
                                            >
                                                <Text fontWeight="bold" color="#42dcdb">{t('Mining Station Level')}: {station.level}</Text>
                                                <Text color="gray.300" mb={4}>{t('Country')}: {station.countryName}</Text>
                                                <VStack spacing={2}>
                                                    <Button
                                                        leftIcon={<FontAwesomeIcon icon={faArrowUp} />}
                                                        onClick={() => handleUpgradeMiningStation(station.id)}
                                                        w="full"
                                                        size="sm"
                                                        isLoading={isLoading}
                                                    >
                                                        {t('Upgrade Mining Station')}
                                                    </Button>
                                                    <Button
                                                        leftIcon={<FontAwesomeIcon icon={faRocket} />}
                                                        onClick={() => handleUpgradeMaxMiningStation(station.id)}
                                                        w="full"
                                                        size="sm"
                                                        isLoading={isLoading}
                                                        variant="outline"
                                                    >
                                                        {t('Upgrade Max')}
                                                    </Button>
                                                </VStack>
                                            </Box>
                                        </MotionBox>
                                    ))}
                                </AnimatePresence>
                            </SimpleGrid>
                        </MotionBox>
                    ) : (
                        <MotionBox
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1.4, duration: 0.5 }}
                        >
                            <Text fontSize="lg" textAlign="center" color="gray.500">
                                {t('No mining stations available.')}
                            </Text>
                        </MotionBox>
                    )}
                </VStack>
            </Container>
        </ChakraProvider>
    );
};

export default MiningStationPage;