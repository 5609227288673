import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Image,
  useColorModeValue,
  Container,
  Flex,
  ScaleFade,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Assuming logo is imported correctly
import logo from '../images/exocrest-logo.png';

const MotionBox = motion(Box);
const MotionImage = motion(Image);

const LaunchPadPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const accentColor = useColorModeValue('teal.500', 'teal.300');
  const cardBg = useColorModeValue('white', 'gray.800');

  // Height of the bottom nav bar (adjust as needed)
  const bottomNavHeight = "60px";

  return (
    <Box
      bg={bgColor}
      color={textColor}
      minH={`calc(100vh - ${bottomNavHeight})`}
      py={{ base: 8, md: 12 }}
      px={4}
      overflowY="auto"
      position="relative"
    >
      <Container maxW="container.xl" pb={`calc(${bottomNavHeight} + 2rem)`}>
        <VStack spacing={8} textAlign="center">
          <ScaleFade initialScale={0.9} in={true}>
            <Heading
              size={{ base: "xl", md: "2xl" }}
              color={accentColor}
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              {t('Maximo AI LaunchPad')}
            </Heading>
            <Text fontSize={{ base: "lg", md: "xl" }} mt={4} maxW="2xl" mx="auto">
              {t('Discover and support the latest projects powered by Maximo AI.')}
            </Text>
          </ScaleFade>

          <MotionBox
            bg={cardBg}
            p={{ base: 6, md: 8 }}
            borderRadius="2xl"
            boxShadow="2xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            w="full"
            maxW={{ base: "full", md: "3xl" }}
          >
            <Flex direction={{ base: "column", md: "row" }} align="center" justify="space-between">
              <MotionImage
                src={logo}
                alt="Exocrest Logo"
                boxSize={{ base: "120px", md: "150px" }}
                mb={{ base: 6, md: 0 }}
                mr={{ base: 0, md: 8 }}
                initial={{ rotate: -10, opacity: 0 }}
                animate={{ rotate: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              />
              <Box textAlign={{ base: "center", md: "left" }} flex={1}>
                <Heading size={{ base: "lg", md: "xl" }} color={accentColor} mb={4}>
                  {t('Exocrest')}
                </Heading>
                <Text fontSize={{ base: "md", md: "lg" }} mb={6}>
                  {t('An immersive strategy game where you build and manage your own country on a distant planet.')}
                </Text>
                <Button
                  as={motion.button}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  colorScheme="teal"
                  size={{ base: "md", md: "lg" }}
                  fontWeight="bold"
                  px={8}
                  onClick={() => navigate('/exocrest')}
                  boxShadow="md"
                  _hover={{ boxShadow: 'lg' }}
                >
                  {t('Learn More')}
                </Button>
              </Box>
            </Flex>
          </MotionBox>
        </VStack>
      </Container>
    </Box>
  );
};

export default LaunchPadPage;