import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
    Box, Text, VStack, Image, useToast, Button, Center, FormControl, FormLabel,
    Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactSVGPanZoom, TOOL_PAN } from 'react-svg-pan-zoom';
import CustomSelect from './CustomSelect';
import logo from '../images/exocrest-logo.png';
import '../styles/mapStyles.css';

import HouseSVG from '../svg/House.svg';
import FactorySVG from '../svg/Factory.svg';
import FarmSVG from '../svg/Farm.svg';
import HospitalSVG from '../svg/Hospital.svg';
import UniversitySVG from '../svg/University.svg';
import MilitaryBaseSVG from '../svg/MilitaryBase.svg';

const buildingIcons = {
    House: HouseSVG,
    Factory: FactorySVG,
    Farm: FarmSVG,
    Hospital: HospitalSVG,
    University: UniversitySVG,
    'Military Base': MilitaryBaseSVG,
};

const Building = React.memo(({ building, countryData, handleUpgradeBuilding, isLoading, t, x, y, cellSize }) => {
    const [isOpen, setIsOpen] = useState(false);
    const Icon = buildingIcons[building.type];

    return (
        <>
            <g onClick={() => setIsOpen(true)}>
                <motion.image
                    href={Icon}
                    x={x + cellSize * 0.1}
                    y={y + cellSize * 0.1}
                    width={cellSize * 0.8}
                    height={cellSize * 0.8}
                    className="building"
                    style={{
                        filter: `brightness(${100 + building.level * 10}%) hue-rotate(${building.level * 30}deg)`
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                />
                <text x={x + cellSize * 0.5} y={y + cellSize * 0.9} textAnchor="middle" fill="#fff" fontSize="12">
                    {building.level}
                </text>
            </g>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent
                    bg="#1e1e2e"
                    color="#e0e0e0"
                    borderRadius="lg"
                    shadow="xl"
                    mx={[4, 0]}
                    maxW="400px"
                >
                    <ModalHeader>{t(building.type)}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{t('Level')}: {building.level}</Text>
                        <Text>{t('Country')}: {countryData.name}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            handleUpgradeBuilding(building.id);
                            setIsOpen(false);
                        }} colorScheme="teal" isLoading={isLoading}>
                            {t('Upgrade Building')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
});

const LoadingAnimation = () => (
    <svg width="100" height="100" viewBox="0 0 100 100">
        <motion.circle
            cx="50"
            cy="50"
            r="20"
            stroke="#4A90E2"
            strokeWidth="4"
            fill="none"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
        />
    </svg>
);

const CountryMapPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryData, setCountryData] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const [resources, setResources] = useState([]);
    const [mapReady, setMapReady] = useState(false);
    const [isNewBuildingModalOpen, setIsNewBuildingModalOpen] = useState(false);
    const [newBuildingType, setNewBuildingType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tool, setTool] = useState(TOOL_PAN);
    const [value, setValue] = useState(null);
    const Viewer = useRef(null);

    useEffect(() => {
        const fetchUserId = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUserId(response.data.id);
            } catch (error) {
                toast({
                    title: t('Error fetching user ID'),
                    description: error.response?.data?.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };
        fetchUserId();
    }, [toast, t]);

    useEffect(() => {
        const fetchCountries = async () => {
            if (!userId) return;
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/api/planets/user-countries/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setCountries(response.data);
                if (response.data.length > 0) {
                    setSelectedCountry({ value: response.data[0].id, label: response.data[0].name });
                }
            } catch (error) {
                toast({
                    title: t('Error fetching countries'),
                    description: error.response?.data?.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };
        fetchCountries();
    }, [toast, t, userId]);

    const fetchCountryData = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/api/planets/user-countries/${userId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const selectedCountryData = response.data.find(country => country.id === countryId);
            if (selectedCountryData) {
                setCountryData(selectedCountryData);
            } else {
                setCountryData(null);
            }
        } catch (error) {
            toast({
                title: t('Error fetching country data'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [toast, t, userId]);

    const fetchBuildings = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/buildings`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setBuildings(response.data);
        } catch (error) {
            toast({
                title: t('Error fetching buildings'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [toast, t]);

    const fetchResources = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/resources`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setResources(response.data);
        } catch (error) {
            toast({
                title: t('Error fetching resources'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [toast, t]);

    useEffect(() => {
        if (selectedCountry && selectedCountry.value) {
            setMapReady(false);
            fetchCountryData(selectedCountry.value);
            fetchBuildings(selectedCountry.value);
            fetchResources(selectedCountry.value);
        }
    }, [selectedCountry, fetchCountryData, fetchBuildings, fetchResources]);

    useEffect(() => {
        if (countryData && buildings.length > 0) {
            setMapReady(true);
        }
    }, [countryData, buildings]);

    const handleUpgradeBuilding = useCallback(async (buildingId) => {
        setIsLoading(true);
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        try {
            await axios.post(
                `${apiUrl}/api/planets/upgrade-building`,
                { buildingId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast({
                title: t('Building upgraded successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchBuildings(selectedCountry.value);
            fetchResources(selectedCountry.value);
        } catch (error) {
            toast({
                title: t('Error upgrading building'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    }, [selectedCountry, fetchBuildings, fetchResources, t, toast]);

    const handleCreateBuilding = useCallback(async () => {
        if (!selectedCountry || !newBuildingType) {
            toast({
                title: t('Missing Fields'),
                description: t('Please select both a country and a building type'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        try {
            await axios.post(
                `${apiUrl}/api/planets/create-building`,
                { countryId: selectedCountry.value, type: newBuildingType.value },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast({
                title: t('Building created successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchBuildings(selectedCountry.value);
            fetchResources(selectedCountry.value);
            setIsNewBuildingModalOpen(false);
        } catch (error) {
            toast({
                title: t('Error creating building'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setIsLoading(false);
    }, [selectedCountry, newBuildingType, fetchBuildings, fetchResources, t, toast]);

    const generateMap = useMemo(() => {
        if (!countryData || !buildings.length) return null;

        const gridSize = 20;
        const cellSize = 50;
        const mapWidth = gridSize * cellSize;
        const mapHeight = gridSize * cellSize;

        // Create an array of all possible positions
        const allPositions = Array(gridSize * gridSize).fill(null);

        // Fill in the positions with existing buildings
        buildings.forEach((building, index) => {
            allPositions[index] = building;
        });

        return (
            <svg width={mapWidth} height={mapHeight} className="map-svg">
                <defs>
                    <pattern id="waterPattern" patternUnits="userSpaceOnUse" width="100" height="100">
                        <path d="M0 25 Q 25 0, 50 25 T 100 25" fill="none" stroke="#4a90e2" strokeWidth="2" />
                        <path d="M0 75 Q 25 50, 50 75 T 100 75" fill="none" stroke="#4a90e2" strokeWidth="2" />
                    </pattern>
                    <filter id="noise" x="0%" y="0%" width="100%" height="100%">
                        <feTurbulence type="fractalNoise" baseFrequency="0.01" numOctaves="3" result="noise" />
                        <feDisplacementMap in="SourceGraphic" in2="noise" scale="5" xChannelSelector="R" yChannelSelector="G" />
                    </filter>
                    <pattern id="emptyPlot" patternUnits="userSpaceOnUse" width="50" height="50">
                        <rect width="50" height="50" fill="#1e1e2e" />
                        <circle cx="25" cy="25" r="10" fill="#2b2b40" />
                    </pattern>
                </defs>

                <rect width={mapWidth} height={mapHeight} fill="url(#waterPattern)" />
                <rect
                    width={mapWidth * (countryData.landPercentage / 100)}
                    height={mapHeight}
                    fill="#2b2b40"
                    filter="url(#noise)"
                />

                <AnimatePresence>
                    {allPositions.map((building, index) => {
                        const x = (index % gridSize) * cellSize;
                        const y = Math.floor(index / gridSize) * cellSize;

                        if (building) {
                            return (
                                <Building
                                    key={building.id}
                                    building={building}
                                    countryData={countryData}
                                    handleUpgradeBuilding={handleUpgradeBuilding}
                                    isLoading={isLoading}
                                    t={t}
                                    x={x}
                                    y={y}
                                    cellSize={cellSize}
                                />
                            );
                        } else {
                            // Render an empty plot
                            return (
                                <rect
                                    key={`empty-${index}`}
                                    x={x}
                                    y={y}
                                    width={cellSize}
                                    height={cellSize}
                                    fill="url(#emptyPlot)"
                                    stroke="#4a4a5e"
                                    strokeWidth="1"
                                />
                            );
                        }
                    })}
                </AnimatePresence>

                <motion.g
                    className="capital-city"
                    transform={`translate(${mapWidth / 2 - cellSize / 2}, ${mapHeight / 2 - cellSize / 2})`}
                    animate={{ scale: [1, 1.1, 1] }}
                    transition={{ duration: 2, repeat: Infinity }}
                >
                    <rect width={cellSize} height={cellSize} fill="#4a0e0e" stroke="#4a4a5e" rx="5" ry="5" />
                    <text x={cellSize / 2} y={cellSize / 2} textAnchor="middle" fill="#ffffff" fontSize="12">
                        {countryData.capital}
                    </text>
                    <title>{t('Capital')}: {countryData.capital}</title>
                </motion.g>
            </svg>
        );
    }, [countryData, buildings, handleUpgradeBuilding, isLoading, t]);

    return (
        <Box className="page-container">
            <VStack spacing={4} alignItems="stretch">
                <Box className="header-container">
                    <Flex alignItems="center">
                        <Image src={logo} alt="Logo" boxSize="50px" mr={2} />
                        <Text className="header-title">{t('Exocrest')}</Text>
                    </Flex>
                </Box>

                <FormControl className="form-control">
                    <FormLabel>{t('Select Country')}</FormLabel>
                    <CustomSelect
                        name="country"
                        options={countries.map((country) => ({ value: country.id, label: country.name }))}
                        selectedOption={selectedCountry}
                        setSelectedOption={setSelectedCountry}
                    />
                </FormControl>
                <Button
                    className="custom-button"
                    onClick={() => navigate('/dashboard')}
                    variant="outline"
                    borderColor="#1e3a8a"
                >
                    {t('Back to Dashboard')}
                </Button>

                <Center>
                    {selectedCountry && selectedCountry.value && mapReady ? (
                        <Box className="map-container">
                            <ReactSVGPanZoom
                                width="100%"
                                height="100%"
                                ref={Viewer}
                                tool={tool}
                                onChangeTool={setTool}
                                value={value}
                                onChangeValue={setValue}
                                background="#0d0d19"
                                detectAutoPan={false}
                                miniaturePosition="none"
                                toolbarPosition="right"
                                SVGBackground="#1e1e2e"
                            >
                                {generateMap}
                            </ReactSVGPanZoom>
                            <Button
                                position="absolute"
                                bottom="10px"
                                left="10px"
                                onClick={() => setIsNewBuildingModalOpen(true)}
                                colorScheme="teal"
                                size="sm"
                            >
                                {t('Create New Building')}
                            </Button>
                        </Box>
                    ) : (
                        <Center h="400px">
                            <LoadingAnimation />
                        </Center>
                    )}
                </Center>

                {resources.length > 0 && (
                    <Box mt={6}>
                        <Text fontSize="xl" fontWeight="bold">
                            {t('Country Resources')}
                        </Text>
                        <Flex flexWrap="wrap" justifyContent="space-around">
                            {resources.map((resource) => (
                                <Box key={resource.type} p={3} bg="#1e1e2e" borderRadius="md" m={2}>
                                    <Text>{t(resource.type)}: {parseFloat(resource.quantity).toFixed(3)}</Text>
                                </Box>
                            ))}
                        </Flex>
                    </Box>
                )}
            </VStack>

            <Modal
                isOpen={isNewBuildingModalOpen}
                onClose={() => setIsNewBuildingModalOpen(false)}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    bg="#1e1e2e"
                    color="#e0e0e0"
                    borderRadius="lg"
                    shadow="xl"
                    mx={[4, 0]}
                    maxW="400px"
                >
                    <ModalHeader>{t('Create New Building')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{t('Building Type')}</FormLabel>
                            <CustomSelect
                                name="buildingType"
                                options={Object.keys(buildingIcons).map(type => ({ value: type, label: t(type) }))}
                                selectedOption={newBuildingType}
                                setSelectedOption={setNewBuildingType}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" mr={3} onClick={handleCreateBuilding} isLoading={isLoading}>
                            {t('Create')}
                        </Button>
                        <Button variant="ghost" onClick={() => setIsNewBuildingModalOpen(false)}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default React.memo(CountryMapPage);