import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MenuListDropdown = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigateToFAQ = () => {
        navigate('/faq');
    };

    const handleNavigateToAutoSwapGuide = () => {
        navigate('/autoswap-guide');
    };

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
            />
            <MenuList>
                <MenuItem onClick={handleNavigateToFAQ}>{t('faqMenuItem')}</MenuItem>
                <MenuItem>
                    <a href="https://docs.google.com/document/d/1Vy-ZSkbblmiXH0XTmayeA_OiTDhH0yJBAAn3f4uoNOc/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                        {t('gettingStartedAutoSwapMenuItem')}
                    </a>
                </MenuItem>
                <MenuItem>
                    <a href="https://docs.google.com/document/d/11uNoUw1OV09hpBHs8IaTQOX70RcY4tLI-IDLmTLnfQg/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                        {t('gettingStartedAlertsMenuItem')}
                    </a>
                </MenuItem>
                <MenuItem onClick={handleNavigateToAutoSwapGuide}>{t('autoSwapFAQMenuItem')}</MenuItem>
                <MenuItem>{t('commonMenuItem')}</MenuItem>
            </MenuList>
        </Menu>
    );
};

export default MenuListDropdown;
