import React from 'react';
import {
    Box,
    Button,
    VStack,
    Heading,
    Text,
    useColorModeValue,
    Image,
    Stack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faFlag, faLandmark, faUsers, faSpaceShuttle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../images/exocrest-logo.png';

const ExocrestWelcomePage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const bgColor = useColorModeValue('white', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('teal.500', 'teal.300');
    const cardBg = useColorModeValue('white', 'gray.700');

    return (
        <Box bg={bgColor} color={textColor} minH={{ base: "150vh", md: "100vh" }} p={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <VStack spacing={6} textAlign="center" mb={6} mt={{ base: -150, md: -180 }}>
                <Image src={logo} alt="Exocrest Logo" boxSize={{ base: "100px", md: "200px" }} />
                <Heading size={{ base: "lg", md: "2xl" }} color={accentColor}>
                    {t('Welcome to Exocrest')}
                </Heading>
                <Text fontSize={{ base: 'sm', md: 'lg' }} maxW="800px">
                    {t('Exocrest is an immersive strategy game where you build and manage your own country on a distant planet. Engage in diplomacy, manage resources, and conquer challenges to become the most powerful ruler in the galaxy. Create your utopia in the stars and shape the destiny of your people!')}
                </Text>
            </VStack>
            <Wrap spacing="20px" justify="center" mb={6}>
                <WrapItem>
                    <Box bg={cardBg} p={3} borderRadius="lg" boxShadow="lg" display="flex" flexDirection="column" alignItems="center">
                        <FontAwesomeIcon icon={faGlobe} size="2x" color={accentColor} />
                        <Text mt={2} fontSize={{ base: 'xs', md: 'sm' }}>{t('Explore Planets')}</Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box bg={cardBg} p={3} borderRadius="lg" boxShadow="lg" display="flex" flexDirection="column" alignItems="center">
                        <FontAwesomeIcon icon={faFlag} size="2x" color={accentColor} />
                        <Text mt={2} fontSize={{ base: 'xs', md: 'sm' }}>{t('Create Countries')}</Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box bg={cardBg} p={3} borderRadius="lg" boxShadow="lg" display="flex" flexDirection="column" alignItems="center">
                        <FontAwesomeIcon icon={faLandmark} size="2x" color={accentColor} />
                        <Text mt={2} fontSize={{ base: 'xs', md: 'sm' }}>{t('Build Monuments')}</Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box bg={cardBg} p={3} borderRadius="lg" boxShadow="lg" display="flex" flexDirection="column" alignItems="center">
                        <FontAwesomeIcon icon={faUsers} size="2x" color={accentColor} />
                        <Text mt={2} fontSize={{ base: 'xs', md: 'sm' }}>{t('Form Alliances')}</Text>
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box bg={cardBg} p={3} borderRadius="lg" boxShadow="lg" display="flex" flexDirection="column" alignItems="center">
                        <FontAwesomeIcon icon={faSpaceShuttle} size="2x" color={accentColor} />
                        <Text mt={2} fontSize={{ base: 'xs', md: 'sm' }}>{t('Conquer Galaxies')}</Text>
                    </Box>
                </WrapItem>
            </Wrap>
            <Stack spacing={4} direction={{ base: 'column', md: 'row' }} mt={4} width="full" justifyContent="center">
                <Button colorScheme="teal" variant="solid" size="lg" onClick={() => navigate('/create-country')}>
                    {t('Create Country')}
                </Button>
                <Button colorScheme="blue" variant="solid" size="lg" onClick={() => navigate('/dashboard')}>
                    {t('Go to Dashboard')}
                </Button>
            </Stack>
        </Box>
    );
};

export default ExocrestWelcomePage;
