import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Container, Heading, VStack, Text, useToast,
    Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, Alert, AlertIcon
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const PublicApiDetailsPage = () => {
    const { t } = useTranslation();
    const [allTimeRequests, setAllTimeRequests] = useState(0);
    const [last24HoursRequests, setLast24HoursRequests] = useState(0);
    const [last7DaysRequests, setLast7DaysRequests] = useState(0);
    const [last30DaysRequests, setLast30DaysRequests] = useState(0);
    const toast = useToast();

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchApiRequests = useCallback(async () => {
        try {
            const allTimeResponse = await axios.get(`${apiUrl}/api/api-requests/all-time`);
            setAllTimeRequests(allTimeResponse.data.totalRequests);

            const last24HoursResponse = await axios.get(`${apiUrl}/api/api-requests/last-24-hours`);
            setLast24HoursRequests(last24HoursResponse.data.totalRequests);

            const last7DaysResponse = await axios.get(`${apiUrl}/api/api-requests/last-7-days`);
            setLast7DaysRequests(last7DaysResponse.data.totalRequests);

            const last30DaysResponse = await axios.get(`${apiUrl}/api/api-requests/last-30-days`);
            setLast30DaysRequests(last30DaysResponse.data.totalRequests);
        } catch (error) {
            console.error('Failed to fetch API requests:', error);
            toast({
                title: t('publicApiDetailsPage.fetchFailed'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [apiUrl, toast, t]);

    useEffect(() => {
        fetchApiRequests();
    }, [fetchApiRequests]);

    const bgColor = useColorModeValue('gray.900', 'gray.800');
    const textColor = useColorModeValue('gray.100', 'gray.200');
    const tableBgColor = useColorModeValue('gray.800', 'gray.700');
    const tableBorderColor = useColorModeValue('gray.600', 'gray.500');

    // const isMobileView = useBreakpointValue({ base: true, md: false });

    return (
        <Container maxW="container.md" py={8} bg={bgColor} color={textColor} minH="100vh" borderRadius="md" boxShadow="xl">
            <Heading mb={6} textAlign="center" fontSize={{ base: '2xl', md: '4xl' }}>{t('publicApiDetailsPage.title')}</Heading>
            <Alert status="info" mb={6} borderRadius="md">
                <AlertIcon />
                {t('publicApiDetailsPage.introduction')}
            </Alert>
            <VStack spacing={6} align="start">
                <Box w="full" bg={tableBgColor} p={6} borderRadius="md" boxShadow="md" borderColor={tableBorderColor} borderWidth="1px">
                    <Text fontWeight="bold" mb={4}>{t('publicApiDetailsPage.apiStatistics')}</Text>
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th>{t('publicApiDetailsPage.period')}</Th>
                                <Th>{t('publicApiDetailsPage.totalRequests')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>{t('publicApiDetailsPage.allTime')}</Td>
                                <Td>{allTimeRequests}</Td>
                            </Tr>
                            <Tr>
                                <Td>{t('publicApiDetailsPage.last24Hours')}</Td>
                                <Td>{last24HoursRequests}</Td>
                            </Tr>
                            <Tr>
                                <Td>{t('publicApiDetailsPage.last7Days')}</Td>
                                <Td>{last7DaysRequests}</Td>
                            </Tr>
                            <Tr>
                                <Td>{t('publicApiDetailsPage.last30Days')}</Td>
                                <Td>{last30DaysRequests}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>
            </VStack>
        </Container>
    );
};

export default PublicApiDetailsPage;
