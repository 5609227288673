import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Box, Flex, Text, Button, IconButton, VStack, useToast,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure,
    Container, Heading, Grid, Spacer, useMediaQuery, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
    Avatar, AvatarBadge
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStop, faPaperPlane, faVolumeUp, faInfoCircle, faPlay, faPause, faCopy } from '@fortawesome/free-solid-svg-icons';
import maximoAvatar from '../images/maximo-ai-logo-circle.png';
import userAvatar from '../images/user-avatar.png';
import WaveSurfer from 'wavesurfer.js';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const WaveBackground = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #001F3F, #0A2E4E);
  overflow: hidden;
  z-index: -1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-repeat: repeat-x;
    aspect-ratio: 960/300;
    width: 200%;
  }

  &::before {
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 960 300'%3E%3Cpath fill='%23001F3F' fill-opacity='0.5' d='M0,192L60,202.7C120,213,240,235,360,229.3C480,224,600,192,720,176C840,160,960,160,1080,176C1200,192,1320,224,1380,240L1440,256L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
    animation: waveAnimation 20s linear infinite;
  }

  &::after {
    bottom: 15px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 960 300'%3E%3Cpath fill='%230A2E4E' fill-opacity='0.5' d='M0,288L60,282.7C120,277,240,267,360,240C480,213,600,171,720,176C840,181,960,235,1080,245.3C1200,256,1320,224,1380,208L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
    animation: waveAnimation 15s linear infinite;
  }

  @keyframes waveAnimation {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }
`;

const FloatingButton = styled(IconButton)`
  position: fixed;
  bottom: 140px;
  right: 20px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  }
`;

const CodeBlock = ({ language, value }) => {
    return (
        <SyntaxHighlighter language={language} style={atomDark}>
            {value}
        </SyntaxHighlighter>
    );
};

const Message = ({ text, author, avatar, isAudio, audioUrl, onPlay }) => {
    const { t } = useTranslation();
    const bgColor = author === 'user' ? 'blue.700' : 'teal.700';
    const textColor = 'white';
    const toast = useToast();

    const handleCopySuccess = () => {
        toast({
            title: t('copied'),
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    return (
        <MotionFlex
            alignSelf={author === 'user' ? 'flex-end' : 'flex-start'}
            mb={4}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
        >
            <Avatar src={avatar} size="sm" mr={2}>
                {author === 'user' && <AvatarBadge boxSize="1.25em" bg="green.500" />}
            </Avatar>
            <Box
                bg={bgColor}
                color={textColor}
                p={3}
                borderRadius="2xl"
                maxWidth="70%"
                boxShadow="lg"
            >
                {author === 'maximo' ? (
                    <Box className="markdown-content">
                        <ReactMarkdown
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '')
                                    return !inline && match ? (
                                        <CodeBlock
                                            language={match[1]}
                                            value={String(children).replace(/\n$/, '')}
                                            {...props}
                                        />
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    )
                                }
                            }}
                        >
                            {text}
                        </ReactMarkdown>
                    </Box>
                ) : (
                    <Text mb={2}>{text}</Text>
                )}
                {isAudio && (
                    <Button leftIcon={<FontAwesomeIcon icon={faVolumeUp} />} onClick={() => onPlay(audioUrl)} mt={2}>
                        {t('playAudio')}
                    </Button>
                )}
                {author === 'maximo' && (
                    <Flex justifyContent="flex-end" mt={2}>
                        <CopyToClipboard text={text} onCopy={handleCopySuccess}>
                            <IconButton
                                aria-label="Copy message"
                                icon={<FontAwesomeIcon icon={faCopy} />}
                                size="sm"
                                variant="ghost"
                            />
                        </CopyToClipboard>
                    </Flex>
                )}
            </Box>
        </MotionFlex>
    );
};

const ModernWaveform = ({ isRecording, audioBlob, duration }) => {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);

    const initWavesurfer = useCallback(() => {
        if (waveformRef.current) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#4F94CD',
                progressColor: '#0066CC',
                cursorColor: '#4682B4',
                barWidth: 2,
                barGap: 1,
                responsive: true,
                height: 60,
                barRadius: 2,
            });
        }
    }, []);

    useEffect(() => {
        initWavesurfer();

        return () => {
            if (wavesurfer.current) {
                wavesurfer.current.destroy();
            }
        };
    }, [initWavesurfer]);

    useEffect(() => {
        if (audioBlob && wavesurfer.current) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const blob = new window.Blob([new Uint8Array(e.target.result)]);
                wavesurfer.current.loadBlob(blob);
            };
            reader.readAsArrayBuffer(audioBlob);
        }
    }, [audioBlob]);

    return (
        <Box width="100%" height="60px">
            <div ref={waveformRef} />
            <Text textAlign="center" mt={2} fontSize="sm" fontWeight="bold" color="cyan.200">
                {isRecording ? `Recording: ${duration.toFixed(1)}s` : `Duration: ${duration.toFixed(1)}s`}
            </Text>
        </Box>
    );
};

const CircularAudioPlayer = ({ audioUrl, onClose }) => {
    const [isPlaying, setIsPlaying] = useState(true);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const audio = audioRef.current;
        const updateProgress = () => {
            if (audio.duration) {
                setProgress((audio.currentTime / audio.duration) * 100);
            }
        };

        const handleLoadedMetadata = () => {
            setDuration(audio.duration);
        };

        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        audio.addEventListener('timeupdate', updateProgress);
        audio.addEventListener('ended', () => {
            setIsPlaying(false);
            setProgress(0);
            onClose();
        });

        audio.play().catch(error => console.error('Error auto-playing audio:', error));

        return () => {
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            audio.removeEventListener('timeupdate', updateProgress);
            audio.removeEventListener('ended', onClose);
        };
    }, [onClose]);

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const remainingTime = duration - (progress / 100 * duration);

    const radius = 80;
    const circumference = 2 * Math.PI * radius;
    const dashOffset = circumference - (progress / 100) * circumference;

    return (
        <Box position="relative" width="200px" height="200px">
            <svg width="200" height="200" viewBox="0 0 200 200">
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    fill="none"
                    stroke="#001F3F"
                    strokeWidth="8"
                />
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    fill="none"
                    stroke="#0066CC"
                    strokeWidth="8"
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                    transform="rotate(-90 100 100)"
                    strokeLinecap="round"
                />
            </svg>
            <IconButton
                icon={<FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />}
                onClick={togglePlay}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                borderRadius="full"
                size="lg"
                colorScheme="blue"
            />
            <Text
                position="absolute"
                top="70%"
                left="50%"
                transform="translate(-50%, -50%)"
                fontWeight="bold"
                fontSize="lg"
            >
                {formatTime(remainingTime)}
            </Text>
            <audio ref={audioRef} src={audioUrl} />
        </Box>
    );
};

const MaximoZest = () => {
    const [messages, setMessages] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [recordingDuration, setRecordingDuration] = useState(0);
    const mediaRecorder = useRef(null);
    const { isOpen: isAudioOpen, onOpen: onAudioOpen, onClose: onAudioClose } = useDisclosure();
    const { isOpen: isInfoOpen, onOpen: onInfoOpen, onClose: onInfoClose } = useDisclosure();
    const [currentAudio, setCurrentAudio] = useState(null);
    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
    const [userProfile, setUserProfile] = useState({
        username: '',
        walletAddress: '',
        profilePhoto: userAvatar,
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const fetchUserProfile = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUserProfile(prevState => ({
                    ...prevState,
                    ...response.data,
                    profilePhoto: response.data.profilePhoto ? `${apiUrl}/${response.data.profilePhoto}` : userAvatar
                }));
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, [navigate]);

    useEffect(() => {
        let interval;
        if (isRecording) {
            interval = setInterval(() => {
                setRecordingDuration(prev => prev + 0.1);
            }, 100);
        } else {
            setRecordingDuration(0);
        }
        return () => clearInterval(interval);
    }, [isRecording]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            const chunks = [];

            recorder.ondataavailable = (e) => chunks.push(e.data);
            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: 'audio/wav' });
                setAudioBlob(blob);
                sendAudioMessage(blob);
            };

            recorder.start();
            setIsRecording(true);
            mediaRecorder.current = recorder;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            toast({
                title: t('error'),
                description: t('microphoneAccessError'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const stopRecording = () => {
        if (mediaRecorder.current && isRecording) {
            mediaRecorder.current.stop();
            setIsRecording(false);
            toast({
                title: t('info'),
                description: t('audioAutoSent'),
                status: 'info',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const sendAudioMessage = async (blob) => {
        if (!blob) {
            toast({
                title: t('error'),
                description: t('audioTooShort'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setIsProcessing(true);
        const formData = new FormData();
        formData.append('audio', blob, 'recording.wav');

        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/api/voice-input`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });

            const { transcribedText, chatResponse, audioResponse } = response.data;

            setMessages(prev => [
                ...prev,
                { author: 'user', text: transcribedText, avatar: userProfile.profilePhoto },
                { author: 'maximo', text: chatResponse, avatar: maximoAvatar, audioUrl: `${apiUrl}${audioResponse}`, isAudio: true }
            ]);

            setAudioBlob(null);
            setRecordingDuration(0);

            // Auto-play the response
            playAudio(`${apiUrl}${audioResponse}`);
        } catch (error) {
            console.error('Error sending audio message:', error);
            toast({
                title: t('error'),
                description: t('audioProcessingError'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const playAudio = (audioUrl) => {
        setCurrentAudio(audioUrl);
        onAudioOpen();
    };

    const handleRecordButtonClick = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const handleSendButtonClick = () => {
        if (isRecording) {
            stopRecording();
        } else if (audioBlob) {
            sendAudioMessage(audioBlob);
        }
    };

    const renderContent = () => (
        <>
            <Box p={6} textAlign="center">
                <Heading as="h1" size="2xl" mb={2} color="white">
                    {t('maximoZest')}
                </Heading>
                <Text fontSize="xl" color="whiteAlpha.800">
                    {t('aiVoiceAssistant')}
                </Text>
            </Box>

            <VStack
                flex={1}
                overflowY="auto"
                spacing={4}
                p={4}
                mb={isLargerThan1280 ? 0 : 180}
                css={css`
                    &::-webkit-scrollbar {
                        width: 8px;
                    }
                    &::-webkit-scrollbar-track {
                        background: rgba(255, 255, 255, 0.1);
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: rgba(255, 255, 255, 0.3);
                        border-radius: 10px;
                    }
                `}
            >
                <AnimatePresence>
                    {messages.map((message, index) => (
                        <Message key={index} {...message} onPlay={playAudio} />
                    ))}
                </AnimatePresence>
            </VStack>

            <Spacer />

            <Box
                position="fixed"
                bottom={70}
                left={0}
                right={0}
                p={4}
                bg="rgba(255, 255, 255, 0.1)"
                borderTopRadius="3xl"
                boxShadow="0 -4px 10px rgba(0, 0, 0, 0.3)"
                backdropFilter="blur(10px)"
            >
                <Grid templateColumns="repeat(3, 1fr)" gap={4} alignItems="center">
                    <MotionBox
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <IconButton
                            icon={<FontAwesomeIcon icon={isRecording ? faStop : faMicrophone} />}
                            onClick={handleRecordButtonClick}
                            colorScheme={isRecording ? "red" : "blue"}
                            size="lg"
                            isRound
                            w="full"
                            h="60px"
                            boxShadow="lg"
                        />
                    </MotionBox>
                    <ModernWaveform isRecording={isRecording} audioBlob={audioBlob} duration={recordingDuration} />
                    <MotionBox
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                            onClick={handleSendButtonClick}
                            colorScheme="green"
                            isLoading={isProcessing}
                            loadingText={t('processing')}
                            isDisabled={(!audioBlob && !isRecording) || isProcessing}
                            size="lg"
                            w="full"
                            h="60px"
                            borderRadius="full"
                            boxShadow="lg"
                        >
                            {t('send')}
                        </Button>
                    </MotionBox>
                </Grid>
            </Box>
        </>
    );

    return (
        <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            height="100vh"
            color="white"
            overflowY="hidden"
        >
            <WaveBackground />
            <Container maxW="container.xl" h="100%" position="relative">
                {isLargerThan1280 ? (
                    <Flex h="100%">
                        <Box flex={1} p={6} overflowY="auto">
                            {renderContent()}
                        </Box>
                        <Box width="400px" p={6} bg="rgba(255, 255, 255, 0.05)" borderLeftWidth={1} borderLeftColor="whiteAlpha.200">
                            <Heading as="h2" size="lg" mb={4}>
                                {t('recentInteractions')}
                            </Heading>
                            <VStack spacing={4} align="stretch">
                                {messages.slice(-5).map((message, index) => (
                                    <Box key={index} p={3} bg="whiteAlpha.100" borderRadius="md">
                                        <Text fontSize="sm" fontWeight="bold" mb={1}>
                                            {message.author === 'user' ? t('you') : t('maximo')}
                                        </Text>
                                        <Text fontSize="sm">{message.text.substring(0, 50)}...</Text>
                                    </Box>
                                ))}
                            </VStack>
                        </Box>
                    </Flex>
                ) : (
                    <Flex direction="column" h="100%">
                        {renderContent()}
                    </Flex>
                )}
            </Container>

            <FloatingButton
                icon={<FontAwesomeIcon icon={faInfoCircle} />}
                onClick={onInfoOpen}
                colorScheme="blue"
                size="lg"
                isRound
            />

            <Modal isOpen={isAudioOpen} onClose={onAudioClose} isCentered motionPreset="slideInBottom">
                <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
                <ModalContent
                    bg="rgba(0, 31, 63, 0.8)"
                    color="white"
                    borderRadius="2xl"
                    boxShadow="xl"
                >
                    <ModalHeader>{t('audioPlayback')}</ModalHeader>
                    <ModalBody display="flex" justifyContent="center" alignItems="center">
                        <CircularAudioPlayer audioUrl={currentAudio} onClose={onAudioClose} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Drawer isOpen={isInfoOpen} placement="right" onClose={onInfoClose}>
                <DrawerOverlay />
                <DrawerContent bg="rgba(0, 31, 63, 0.9)" color="white">
                    <DrawerCloseButton />
                    <DrawerHeader>{t('howToUse')}</DrawerHeader>
                    <DrawerBody>
                        <VStack spacing={4} align="stretch">
                            <Box>
                                <Heading size="md" mb={2}>{t('step1')}</Heading>
                                <Text>{t('step1Description')}</Text>
                            </Box>
                            <Box>
                                <Heading size="md" mb={2}>{t('step2')}</Heading>
                                <Text>{t('step2Description')}</Text>
                            </Box>
                            <Box>
                                <Heading size="md" mb={2}>{t('step3')}</Heading>
                                <Text>{t('step3Description')}</Text>
                            </Box>
                            <Box>
                                <Heading size="md" mb={2}>{t('step4')}</Heading>
                                <Text>{t('step4Description')}</Text>
                            </Box>
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </MotionBox>
    );
};

export default MaximoZest;