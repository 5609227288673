import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Container, Heading, VStack, Input, Button, Text, HStack, useToast,
    Table, Thead, Tbody, Tr, Th, Td, IconButton, useColorModeValue, Tooltip, useClipboard,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
    Alert, AlertIcon, Stack, Circle, Flex, SlideFade, ScaleFade,
    Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { DeleteIcon, CopyIcon, ViewIcon, AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);

const ApiKeysPage = () => {
    const { t } = useTranslation();
    const [keyName, setKeyName] = useState('');
    const [apiKeys, setApiKeys] = useState([]);
    const [selectedApiKey, setSelectedApiKey] = useState(null);
    const [freePoints, setFreePoints] = useState(0);
    const [requestedPoints, setRequestedPoints] = useState('');
    const [userPoints, setUserPoints] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isPointsOpen,
        onOpen: onPointsOpen,
        onClose: onPointsClose
    } = useDisclosure();
    const {
        isOpen: isCreateOpen,
        onOpen: onCreateOpen,
        onClose: onCreateClose
    } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchApiKeys = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/api-keys`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setApiKeys(response.data.apiKeys);
        } catch (error) {
            console.error('Failed to fetch API keys:', error);
        }
    }, [apiUrl]);

    const fetchFreePoints = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/total-free-points`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setFreePoints(response.data.totalFreePoints);
        } catch (error) {
            console.error('Failed to fetch free points:', error);
        }
    }, [apiUrl]);

    const fetchUserPoints = useCallback(async () => {
        try {
            const response = await axios.get(`${apiUrl}/user-alerts`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setUserPoints(response.data.points);
        } catch (error) {
            console.error('Failed to fetch user points:', error);
        }
    }, [apiUrl]);

    useEffect(() => {
        fetchApiKeys();
        fetchFreePoints();
        fetchUserPoints();
    }, [fetchApiKeys, fetchFreePoints, fetchUserPoints]);

    const handleCreateApiKey = async () => {
        if (!keyName) {
            toast({
                title: t('apiKeysPage.enterKeyName'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/api-keys`, { keyName }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            toast({
                title: t('apiKeysPage.apiKeyCreated'),
                description: response.data.apiKey,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setKeyName('');
            fetchApiKeys();
            onCreateClose();
        } catch (error) {
            console.error('Failed to create API key:', error);
            toast({
                title: t('apiKeysPage.apiKeyCreationFailed'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleDeleteApiKey = async (keyId) => {
        try {
            await axios.delete(`${apiUrl}/api/api-keys/${keyId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            toast({
                title: t('apiKeysPage.apiKeyDeleted'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchApiKeys();
        } catch (error) {
            console.error('Failed to delete API key:', error);
            toast({
                title: t('apiKeysPage.apiKeyDeletionFailed'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleViewApiKeyDetails = async (apiKey) => {
        try {
            const response = await axios.get(`${apiUrl}/api/api-key-requests?apiKey=${apiKey}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            const apiKeyRequests = response.data.apiKeyRequests[apiKey] || {};
            setSelectedApiKey({ apiKey, details: apiKeyRequests.requests || [] });
            onOpen();
        } catch (error) {
            console.error('Failed to fetch API key details:', error);
            toast({
                title: t('apiKeysPage.apiKeyDetailsFailed'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRequestFreePoints = async () => {
        if (!requestedPoints || isNaN(requestedPoints) || requestedPoints <= 0) {
            toast({
                title: t('apiKeysPage.invalidPointsRequest'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            await axios.post(`${apiUrl}/api/get-free-points`, { points: requestedPoints }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            toast({
                title: t('apiKeysPage.freePointsGranted'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setRequestedPoints('');
            fetchFreePoints();
            fetchUserPoints();
            onPointsClose();
        } catch (error) {
            console.error('Failed to request free points:', error);
            toast({
                title: t('apiKeysPage.freePointsRequestFailed'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    // const isMobileView = useBreakpointValue({ base: true, md: false });

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const inputBgColor = useColorModeValue('gray.100', 'gray.700');
    const inputBorderColor = useColorModeValue('gray.300', 'gray.600');
    const circleBorderColor = useColorModeValue('cyan.500', 'cyan.300');

    return (
        <Box bg={bgColor} minHeight="100vh" pb="60px"> {/* Added bottom padding to account for nav bar */}
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8}>
                    <Flex justifyContent="space-between" w="full" alignItems="center">
                        <Heading textAlign="center" fontSize={{ base: '2xl', md: '4xl' }} color={textColor}>
                            {t('apiKeysPage.title')}
                        </Heading>
                        <ScaleFade initialScale={0.9} in={true}>
                            <Circle
                                size="80px"
                                bg={cardBgColor}
                                border="4px solid"
                                borderColor={circleBorderColor}
                                boxShadow={`0 0 20px ${circleBorderColor}`}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="bold" color={textColor}>
                                    {userPoints}
                                </Text>
                            </Circle>
                        </ScaleFade>
                    </Flex>

                    <SlideFade in={true} offsetY="20px">
                        <Alert status="info" variant="solid" borderRadius="lg" boxShadow="md">
                            <AlertIcon />
                            {t('apiKeysPage.introduction')}
                        </Alert>
                    </SlideFade>

                    <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="full">
                        <Button
                            colorScheme="teal"
                            boxShadow="lg"
                            onClick={() => navigate('/build')}
                            _hover={{ transform: 'translateY(-2px)', boxShadow: 'xl' }}
                            transition="all 0.2s"
                        >
                            {t('apiKeysPage.viewFreeApiEndpoints')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            boxShadow="lg"
                            onClick={() => window.open('https://docs.google.com/document/d/1M-PHClFQsOjXq9AfVMGV_3F9MCMCx_s4-e2Mfv48TjM/edit?usp=sharing', '_blank')}
                            _hover={{ transform: 'translateY(-2px)', boxShadow: 'xl' }}
                            transition="all 0.2s"
                        >
                            {t('apiKeysPage.premiumApiDocumentation')}
                        </Button>
                        <Button
                            colorScheme="green"
                            boxShadow="lg"
                            onClick={onPointsOpen}
                            _hover={{ transform: 'translateY(-2px)', boxShadow: 'xl' }}
                            transition="all 0.2s"
                        >
                            {t('apiKeysPage.getFreePoints')}
                        </Button>
                    </Stack>

                    <MotionBox
                        w="full"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Box
                            bg={cardBgColor}
                            p={6}
                            borderRadius="xl"
                            boxShadow="xl"
                            border="1px solid"
                            borderColor={inputBorderColor}
                        >
                            <Flex justifyContent="space-between" alignItems="center" mb={4}>
                                <Text fontWeight="bold" fontSize="lg" color={textColor}>
                                    {t('apiKeysPage.availableApiKeys')}
                                </Text>
                                <Button
                                    leftIcon={<AddIcon />}
                                    colorScheme="blue"
                                    onClick={onCreateOpen}
                                    size="sm"
                                    boxShadow="md"
                                    _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                    transition="all 0.2s"
                                >
                                    {t('apiKeysPage.createNew')}
                                </Button>
                            </Flex>
                            <Box overflowX="auto">
                                <Table variant="simple" size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>{t('apiKeysPage.keyName')}</Th>
                                            <Th>{t('apiKeysPage.apiKey')}</Th>
                                            <Th>{t('apiKeysPage.actions')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <AnimatePresence>
                                            {apiKeys.map((key) => (
                                                <MotionBox
                                                    key={key.id}
                                                    as="tr"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    transition={{ duration: 0.3 }}
                                                >
                                                    <Td>{key.key_name}</Td>
                                                    <Td>
                                                        <HStack spacing={2}>
                                                            <Text>{key.api_key}</Text>
                                                            <CopyApiKeyButton apiKey={key.api_key} />
                                                        </HStack>
                                                    </Td>
                                                    <Td>
                                                        <HStack spacing={2}>
                                                            <IconButton
                                                                aria-label="View API key details"
                                                                icon={<ViewIcon />}
                                                                colorScheme="blue"
                                                                variant="outline"
                                                                onClick={() => handleViewApiKeyDetails(key.api_key)}
                                                                size="sm"
                                                                boxShadow="sm"
                                                                _hover={{ transform: 'translateY(-2px)', boxShadow: 'md' }}
                                                                transition="all 0.2s"
                                                            />
                                                            <IconButton
                                                                aria-label="Delete API key"
                                                                icon={<DeleteIcon />}
                                                                colorScheme="red"
                                                                variant="outline"
                                                                onClick={() => handleDeleteApiKey(key.id)}
                                                                size="sm"
                                                                boxShadow="sm"
                                                                _hover={{ transform: 'translateY(-2px)', boxShadow: 'md' }}
                                                                transition="all 0.2s"
                                                            />
                                                        </HStack>
                                                    </Td>
                                                </MotionBox>
                                            ))}
                                        </AnimatePresence>
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                    </MotionBox>
                </VStack>

                {/* API Key Details Modal */}
                <Modal isOpen={isOpen} onClose={onClose} size="full">
                    <ModalOverlay />
                    <ModalContent bg={cardBgColor}>
                        <ModalHeader color={textColor}>{t('apiKeysPage.apiKeyDetails')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack spacing={4} align="start">
                                <Text fontWeight="bold" color={textColor}>{t('apiKeysPage.keyName')}: {selectedApiKey?.keyName}</Text>
                                <Text fontWeight="bold" color={textColor}>{t('apiKeysPage.apiKey')}: {selectedApiKey?.apiKey}</Text>
                                <Box w="full" overflowX="auto">
                                    <Table variant="simple" size="sm">
                                        <Thead>
                                            <Tr>
                                                <Th>{t('apiKeysPage.timestamp')}</Th>
                                                <Th>{t('apiKeysPage.status')}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {selectedApiKey?.details.length > 0 ? (
                                                selectedApiKey.details.map((detail, index) => (
                                                    <Tr key={index}>
                                                        <Td>{new Date(detail.timestamp).toLocaleString()}</Td>
                                                        <Td>{detail.success ? t('apiKeysPage.success') : t('apiKeysPage.failure')}</Td>
                                                    </Tr>
                                                ))
                                            ) : (
                                                <Tr>
                                                    <Td colSpan={2}>{t('apiKeysPage.noRequests')}</Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                </Box>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose}>{t('apiKeysPage.close')}</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Free Points Modal */}
                <Modal isOpen={isPointsOpen} onClose={onPointsClose} isCentered>
                    <ModalOverlay />
                    <ModalContent
                        borderRadius="xl"
                        boxShadow="2xl"
                        bg={cardBgColor}
                        mx={[4, 0]}
                        maxW="400px"
                    >
                        <ModalHeader color={textColor}>{t('apiKeysPage.freePointsModalTitle')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text mb={4} color={textColor}>{t('apiKeysPage.freePointsInfo')}</Text>
                            <Text fontWeight="bold" mb={2} color={textColor}>{t('apiKeysPage.totalFreePoints')}: {freePoints}</Text>
                            <Input
                                placeholder={t('apiKeysPage.enterPoints')}
                                value={requestedPoints}
                                onChange={(e) => setRequestedPoints(e.target.value)}
                                bg={inputBgColor}
                                borderColor={inputBorderColor}
                                borderRadius="md"
                                boxShadow="sm"
                                color={textColor}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                colorScheme="green"
                                onClick={handleRequestFreePoints}
                                boxShadow="md"
                                _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                transition="all 0.2s"
                            >
                                {t('apiKeysPage.requestPoints')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Create API Key Drawer */}
                <Drawer isOpen={isCreateOpen} placement="right" onClose={onCreateClose}>
                    <DrawerOverlay />
                    <DrawerContent bg={cardBgColor}>
                        <DrawerCloseButton />
                        <DrawerHeader color={textColor}>{t('apiKeysPage.createApiKey')}</DrawerHeader>
                        <DrawerBody>
                            <VStack spacing={4}>
                                <Input
                                    placeholder={t('apiKeysPage.keyName')}
                                    value={keyName}
                                    onChange={(e) => setKeyName(e.target.value)}
                                    bg={inputBgColor}
                                    borderColor={inputBorderColor}
                                    borderRadius="md"
                                    boxShadow="sm"
                                    color={textColor}
                                />
                                <Button
                                    colorScheme="blue"
                                    onClick={handleCreateApiKey}
                                    w="full"
                                    boxShadow="md"
                                    _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                    transition="all 0.2s"
                                >
                                    {t('apiKeysPage.create')}
                                </Button>
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Container>
        </Box>
    );
};

const CopyApiKeyButton = ({ apiKey }) => {
    const { hasCopied, onCopy } = useClipboard(apiKey);
    const toast = useToast();

    const handleCopy = () => {
        onCopy();
        toast({
            title: 'API Key Copied',
            description: 'The API key has been copied to your clipboard.',
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    return (
        <Tooltip label={hasCopied ? 'Copied!' : 'Copy'} closeOnClick={false} hasArrow>
            <IconButton
                aria-label="Copy API key"
                icon={<CopyIcon />}
                colorScheme="teal"
                variant="outline"
                onClick={handleCopy}
                size="sm"
                boxShadow="sm"
                _hover={{ transform: 'translateY(-2px)', boxShadow: 'md' }}
                transition="all 0.2s"
            />
        </Tooltip>
    );
};

export default ApiKeysPage;