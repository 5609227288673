// MarketAnalysisPage.js
import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Container, VStack, Heading, Text, Flex, useToast,
    Button, Input, FormControl, FormLabel, Divider, useColorModeValue,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Badge,
    useBreakpointValue, ScaleFade, SlideFade, keyframes
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import SpinnerSvg from './Spinner';
import ErrorMessage from './ErrorSvg';
import CustomSelect from './CustomSelect';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
`;

const MarketAnalysisPage = () => {
    const [marketAnalysis, setMarketAnalysis] = useState({});
    const [loading, setLoading] = useState(false);
    const [userPair, setUserPair] = useState('');
    const [timeframe, setTimeframe] = useState('1h');
    const [isValidPair, setIsValidPair] = useState(false);
    const { t } = useTranslation();
    const toast = useToast();
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuggestModalOpen, setIsSuggestModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const resultRef = useRef(null);
    const [suggestLoading, setSuggestLoading] = useState(false);
    const [suggestResults, setSuggestResults] = useState(null);
    const [pairCategoryType, setPairCategoryType] = useState('type1');

    useEffect(() => {
        const pairPattern = /^[A-Z]{2,5}\/[A-Z]{2,5}$/;
        setIsValidPair(pairPattern.test(userPair.trim()));
    }, [userPair]);

    useEffect(() => {
        if (showAnalysis && resultRef.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [showAnalysis]);

    const handleAnalyze = async () => {
        if (userPair.trim() === '') {
            toast({
                title: t('emptyPairError'),
                description: t('enterValidPair'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);
        setError(null);
        setShowAnalysis(false);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/analyze-market`, {
                userPair,
                timeframe,
                pairCategoryType
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMarketAnalysis(response.data);
            setShowAnalysis(true);
        } catch (error) {
            console.error('Error fetching market analysis:', error);
            setError(t('errorFetchingAnalysis'));
        } finally {
            setLoading(false);
        }
    };

    const handleSuggest = async () => {
        setSuggestLoading(true);
        setError(null);
        setSuggestResults(null);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/trending-analysis`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });

            if (response.data) {
                setSuggestResults(response.data);
                setIsSuggestModalOpen(true);
            } else {
                console.error('Error fetching trending analysis:', response.data.message);
                setError(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching trending analysis:', error);
            setError('An error occurred while fetching trending analysis.');
        } finally {
            setSuggestLoading(false);
        }
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const closeSuggestModal = () => setIsSuggestModalOpen(false);

    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
    const accentColor = useColorModeValue('blue.500', 'blue.300');

    const getBadgeColorScheme = (riskLevel) => {
        switch (riskLevel) {
            case 'high': return 'red';
            case 'medium': return 'yellow';
            case 'low': return 'green';
            default: return 'gray';
        }
    };

    const timeframeOptions = [
        { value: '1h', label: t('tradingMinute') },
        { value: '2h', label: t('tradingHour') },
        { value: '4h', label: t('tradingDay') },
        { value: '6h', label: t('longTermTrader') },
    ];

    const pairCategoryOptions = [
        { value: 'type1', label: t('layer1Coins') },
        { value: 'type2', label: t('solanaMemeCoins') },
        { value: 'type3', label: t('aiMemeCoins') },
        { value: 'type4', label: t('telegramAppCoins') },
        { value: 'type5', label: t('layer2Coins') },
        { value: 'type6', label: t('generalCoins') },
        { value: 'type7', label: t('memeCoins') },
        { value: 'type8', label: t('layer3Coins') },
        { value: 'type9', label: t('gamingCoins') },
        { value: 'type10', label: t('defiCoins') },
        { value: 'type11', label: t('depinCoins') },
        { value: 'type12', label: t('metaverseCoins') },
    ];

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <ScaleFade initialScale={0.9} in={true}>
            <Container maxW="container.xl" py={8} px={4} bg={bg} boxShadow="2xl" minHeight="250vh">
                <VStack spacing={8} align="stretch">
                    <Flex justify="space-between" alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
                        <Heading size="xl" color={accentColor} fontWeight="extrabold" mb={isMobile ? 4 : 0}>
                            {t('marketAnalysisTitle')}
                        </Heading>
                        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                            <Button
                                onClick={handleSuggest}
                                isLoading={suggestLoading}
                                size="lg"
                                colorScheme="blue"
                                variant="solid"
                                boxShadow="lg"
                                borderRadius="full"
                                px={8}
                                _hover={{
                                    bg: 'blue.600',
                                    animation: `${pulseAnimation} 1s infinite`,
                                }}
                            >
                                {t('suggest')}
                            </Button>
                        </motion.div>
                    </Flex>

                    <Box
                        bg={cardBg}
                        p={8}
                        borderRadius="2xl"
                        boxShadow="xl"
                        transition="all 0.3s"
                        _hover={{ boxShadow: '2xl' }}
                    >
                        <VStack spacing={6} align="stretch">
                            <FormControl id="userPair" isRequired>
                                <FormLabel color={textColor} fontSize="lg" fontWeight="medium">{t('enterPair')}</FormLabel>
                                <Input
                                    placeholder="BTC/USDT"
                                    value={userPair}
                                    onChange={(e) => setUserPair(e.target.value.toUpperCase().trim())}
                                    bg={cardBg}
                                    color={textColor}
                                    _placeholder={{ color: 'gray.500' }}
                                    focusBorderColor={accentColor}
                                    borderRadius="full"
                                    size="lg"
                                    borderWidth="2px"
                                />
                            </FormControl>
                            <FormControl id="timeframe" isRequired>
                                <FormLabel color={textColor} fontSize="lg" fontWeight="medium">{t('selectTimeframe')}</FormLabel>
                                <CustomSelect
                                    options={timeframeOptions}
                                    selectedOption={timeframeOptions.find(option => option.value === timeframe)}
                                    setSelectedOption={(option) => setTimeframe(option.value)}
                                    label={t('selectTimeframe')}
                                />
                            </FormControl>
                            <FormControl id="pairCategoryType" isRequired>
                                <FormLabel color={textColor} fontSize="lg" fontWeight="medium">{t('selectPairCategory')}</FormLabel>
                                <CustomSelect
                                    options={pairCategoryOptions}
                                    selectedOption={pairCategoryOptions.find(option => option.value === pairCategoryType)}
                                    setSelectedOption={(option) => setPairCategoryType(option.value)}
                                    label={t('selectPairCategory')}
                                />
                            </FormControl>
                            <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                                <Button
                                    colorScheme="blue"
                                    onClick={handleAnalyze}
                                    isLoading={loading}
                                    size="lg"
                                    width="full"
                                    borderRadius="full"
                                    boxShadow="md"
                                    disabled={!isValidPair || loading || userPair.trim() === ''}
                                    _hover={{
                                        bg: 'blue.600',
                                        boxShadow: 'lg',
                                    }}
                                >
                                    {t('analyze')}
                                </Button>
                            </motion.div>
                        </VStack>
                    </Box>

                    {loading && (
                        <Flex justify="center" align="center" mt={8}>
                            <SpinnerSvg />
                        </Flex>
                    )}

                    {error && !loading && (
                        <SlideFade in={true} offsetY="20px">
                            <Box p={5} shadow="md" borderWidth="1px" borderRadius="xl" bg={cardBg}>
                                <ErrorMessage message={error} />
                            </Box>
                        </SlideFade>
                    )}

                    {showAnalysis && !loading && marketAnalysis.userPair && !error && (
                        <SlideFade in={true} offsetY="20px">
                            <Box ref={resultRef} p={8} shadow="xl" borderWidth="1px" borderRadius="2xl" bg={cardBg}>
                                <VStack spacing={6} align="stretch">
                                    <Text fontSize="2xl" color={accentColor} fontWeight="bold">
                                        {t('analysisFor')} {marketAnalysis.userPair.pair}
                                    </Text>
                                    <Text fontSize="xl" color={
                                        marketAnalysis.message.includes('long') ? 'green.400' :
                                        marketAnalysis.message.includes('short') ? 'red.400' :
                                        'yellow.400'
                                    }>
                                        {marketAnalysis.message}
                                    </Text>
                                    <Divider borderColor="gray.300" />
                                    <Text fontSize="lg" color={textColor}><b>{t('recommendation')}:</b> {marketAnalysis.userPair.recommendation}</Text>
                                    <Text fontSize="lg" color={textColor}><b>{t('currentPrice')}:</b> ${marketAnalysis.userPair.currentPrice}</Text>
                                    {marketAnalysis.userPair.high24h && (
                                        <>
                                            <Text fontSize="lg" color={textColor}><b>{t('high24h')}:</b> ${marketAnalysis.userPair.high24h}</Text>
                                            <Text fontSize="lg" color={textColor}><b>{t('low24h')}:</b> ${marketAnalysis.userPair.low24h}</Text>
                                        </>
                                    )}
                                    <Text fontSize="lg" color={textColor}><b>{t('leverage')}:</b> {marketAnalysis.userPair.leverage}x</Text>
                                    <Text fontSize="lg" color={textColor}><b>{t('riskLevel')}:</b>
                                        <Badge ml={2} fontSize="0.8em" colorScheme={getBadgeColorScheme(marketAnalysis.userPair.riskLevel)}>
                                            {marketAnalysis.userPair.riskLevel}
                                        </Badge>
                                    </Text>
                                    <Text fontSize="lg" color={textColor}><b>{t('riskPercentage')}:</b> {marketAnalysis.userPair.riskPercentage}%</Text>
                                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                        <Button onClick={openModal} colorScheme="blue" size="lg" width="full" borderRadius="full">
                                            {t('viewDetails')}
                                        </Button>
                                    </motion.div>
                                </VStack>
                            </Box>
                        </SlideFade>
                    )}
                </VStack>

                {marketAnalysis.userPair && (
                    <Modal isOpen={isModalOpen} onClose={closeModal} isCentered motionPreset="slideInBottom">
                        <ModalOverlay />
                        <ModalContent borderRadius="2xl" shadow="2xl" mx={[4, 0]} maxW="500px">
                            <ModalHeader>{t('tradingDetails')}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody overflowY="auto" maxHeight="60vh">
                                <VStack spacing={4} align="stretch">
                                <Text fontSize="lg" color={textColor}><b>{t('stopLossPercentage')}:</b> {marketAnalysis.userPair.stopLossPercentage}%</Text>
                                    <Text fontSize="lg" color={textColor}><b>{t('stopLossPrice')}:</b> ${marketAnalysis.userPair.stopLossPrice}</Text>
                                    <Text fontSize="lg" color={textColor}><b>{t('takeProfitPercentage')}:</b> {marketAnalysis.userPair.takeProfitPercentage}%</Text>
                                    <Text fontSize="lg" color={textColor}><b>{t('takeProfitPrice')}:</b> ${marketAnalysis.userPair.takeProfitPrice}</Text>
                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={closeModal} borderRadius="full">
                                    {t('close')}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                )}

                {suggestLoading && (
                    <Modal isOpen={suggestLoading} isCentered>
                        <ModalOverlay />
                        <ModalContent borderRadius="2xl" shadow="2xl" mx={[4, 0]} maxW="400px">
                            <ModalHeader>{t('loading')}</ModalHeader>
                            <ModalBody>
                                <VStack spacing={4}>
                                    <SpinnerSvg />
                                    <Text fontSize="md">{t('loadingMessage')}</Text>
                                </VStack>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                )}

                {!suggestLoading && suggestResults && (
                    <Modal isOpen={isSuggestModalOpen} onClose={closeSuggestModal} isCentered size="xl">
                        <ModalOverlay />
                        <ModalContent borderRadius="2xl" shadow="2xl" mx={[4, 0]} maxW="800px">
                            <ModalHeader>{t('suggestions')}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody overflowY="auto" maxHeight="70vh">
                                <VStack spacing={6} align="stretch">
                                    {Object.keys(suggestResults).map(timeframe => (
                                        <Box key={timeframe} p={6} shadow="md" borderWidth="1px" borderRadius="xl" bg={cardBg}>
                                            <Text fontSize="xl" fontWeight="bold" mb={4}>{t('tradingMinute')}</Text>
                                            <VStack spacing={4} align="stretch">
                                                {suggestResults[timeframe].map(result => (
                                                    <Box key={result.pair} p={4} borderWidth="1px" borderRadius="lg" bg={cardBg} shadow="md">
                                                        <Text fontSize="lg" fontWeight="bold" color={accentColor}>{result.pair}</Text>
                                                        <Text fontSize="md" color={textColor}><b>{t('riskPercentage')}:</b> {result.riskPercentage}%</Text>
                                                        <Text fontSize="md" color={textColor}><b>{t('riskLevel')}:</b>
                                                            <Badge ml={2} colorScheme={getBadgeColorScheme(result.riskLevel)}>
                                                                {result.riskLevel}
                                                            </Badge>
                                                        </Text>
                                                        <Text fontSize="md" color={textColor}><b>{t('message')}:</b> {result.message}</Text>
                                                    </Box>
                                                ))}
                                            </VStack>
                                        </Box>
                                    ))}
                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={closeSuggestModal} borderRadius="full">
                                    {t('close')}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                )}
            </Container>
        </ScaleFade>
    );
};

export default MarketAnalysisPage;