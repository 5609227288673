import React, { useState, useEffect } from 'react';
import {
    Box, Button, FormControl, FormLabel, useToast, Text,
    SimpleGrid, Container, Heading, Flex, InputGroup, InputLeftElement,
    Divider, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
    ModalFooter, ModalCloseButton, Input, useColorModeValue, 
    SlideFade, keyframes
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import CustomSelect from './CustomSelect';

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #3182ce; }
  50% { box-shadow: 0 0 20px #3182ce; }
  100% { box-shadow: 0 0 5px #3182ce; }
`;

const AlphaPage = () => {
    const { t } = useTranslation();
    const [coins, setCoins] = useState([]);
    const [filteredCoins, setFilteredCoins] = useState([]);
    const [sortBy, setSortBy] = useState('latest');
    const [blockchainFilter, setBlockchainFilter] = useState('all');
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentFilter, setCurrentFilter] = useState('Shills');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const toast = useToast();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    // const hoverBg = useColorModeValue('gray.100', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const textColor = useColorModeValue('gray.800', 'gray.100');

    useEffect(() => {
        const fetchCoins = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/coins`);
                const filteredData = response.data.filter(coin => coin);
                setCoins(filteredData);
                setFilteredCoins(filteredData);
            } catch (error) {
                console.error('Error fetching coins:', error);
                toast({
                    title: t('error'),
                    description: t('fetchCoinsError'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchCoins();

        const visitedBefore = localStorage.getItem('visitedBefore');
        if (!visitedBefore) {
            setShowModal(true);
            localStorage.setItem('visitedBefore', 'true');
        }
    }, [toast, t]);

    const handleCoinClick = (coinAddress) => {
        navigate(`/coin/${coinAddress}`);
    };

    const handleSortChange = (option) => {
        setSortBy(option.value);
        filterCoins(option.value, blockchainFilter, searchTerm, currentFilter);
    };

    const handleBlockchainFilterChange = (option) => {
        setBlockchainFilter(option.value);
        filterCoins(sortBy, option.value, searchTerm, currentFilter);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        filterCoins(sortBy, blockchainFilter, e.target.value, currentFilter);
    };

    const filterCoins = async (sortType, blockchain, searchTerm, currentFilter) => {
        setIsLoading(true);
        let sortedCoins = [...coins];

        if (blockchain !== 'all') {
            sortedCoins = sortedCoins.filter(coin => coin.blockchain === blockchain);
        }

        if (searchTerm) {
            sortedCoins = sortedCoins.filter(coin =>
                coin.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                coin.symbol.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (sortType === 'latest') {
            sortedCoins.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (sortType === 'oldest') {
            sortedCoins.sort((a, b) => new Date(a.date) - new Date(b.date));
        }

        if (currentFilter === 'Trending') {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/trending-coins`);
                sortedCoins = response.data;
            } catch (error) {
                console.error('Error fetching trending coins:', error);
                toast({
                    title: t('error'),
                    description: t('fetchTrendingCoinsError'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }

        setFilteredCoins(sortedCoins);
        setIsLoading(false);
    };

    const handleFilterShills = async () => {
        setCurrentFilter('Shills');
        filterCoins(sortBy, blockchainFilter, searchTerm, 'Shills');
    };

    const handleFilterTrending = async () => {
        setCurrentFilter('Trending');
        filterCoins(sortBy, blockchainFilter, searchTerm, 'Trending');
    };

    const handleFilterNewCoins = async () => {
        setCurrentFilter('NewCoins');
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/new-coins`);
            setFilteredCoins(response.data);
        } catch (error) {
            console.error('Error fetching new coins:', error);
            toast({
                title: t('error'),
                description: t('fetchNewCoinsError'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxW="container.xl" py={8} bg={bgColor} minH="100vh">
            <SlideFade in={true} offsetY="20px">
                <Heading mb={6} textAlign="center" size="xl" color={textColor}>
                    {t('alphaPageTitle')}
                </Heading>
            </SlideFade>
            <Flex flexWrap="wrap" justify="space-between" mb={8}>
                <FormControl flexBasis={{ base: '100%', md: '30%' }} mb={4}>
                    <FormLabel color={textColor}>{t('sortBy')}</FormLabel>
                    <Box
                        p={1}
                        bg={cardBg}
                        borderRadius="2xl"
                        boxShadow="lg"
                        cursor="pointer"
                        _hover={{ transform: 'translateY(-2px)', transition: 'all 0.2s' }}
                        border="1px"
                        borderColor={borderColor}
                    >
                        <CustomSelect
                            options={[
                                { value: 'latest', label: t('latest') },
                                { value: 'oldest', label: t('oldest') }
                            ]}
                            selectedOption={sortBy ? { value: sortBy, label: t(sortBy) } : null}
                            setSelectedOption={handleSortChange}
                            label={t('sortBy')}
                        />
                    </Box>
                </FormControl>

                <FormControl flexBasis={{ base: '100%', md: '30%' }} mb={4}>
                    <FormLabel color={textColor}>{t('blockchain')}</FormLabel>
                    <Box
                        p={1}
                        bg={cardBg}
                        borderRadius="2xl"
                        boxShadow="lg"
                        cursor="pointer"
                        _hover={{ transform: 'translateY(-2px)', transition: 'all 0.2s' }}
                        border="1px"
                        borderColor={borderColor}
                    >
                        <CustomSelect
                            options={[
                                { value: 'all', label: t('all') },
                                { value: 'ethereum', label: t('ethereum') },
                                { value: 'bsc', label: t('binance') },
                                { value: 'solana', label: t('solana') },
                                { value: 'base', label: t('base') }
                            ]}
                            selectedOption={blockchainFilter ? { value: blockchainFilter, label: t(blockchainFilter) } : null}
                            setSelectedOption={handleBlockchainFilterChange}
                            label={t('blockchain')}
                        />
                    </Box>
                </FormControl>

                <FormControl flexBasis={{ base: '100%', md: '30%' }} mb={4}>
                    <FormLabel color={textColor}>&nbsp;</FormLabel>
                    <Flex justify="space-between" align="center" wrap="wrap">
                        <Button
                            onClick={handleFilterShills}
                            colorScheme={currentFilter === 'Shills' ? 'blue' : 'gray'}
                            variant={currentFilter === 'Shills' ? 'solid' : 'outline'}
                            size="md"
                            mr={2}
                            mb={{ base: 2, md: 0 }}
                            borderRadius="full"
                            _hover={{ transform: 'scale(1.05)', transition: 'all 0.2s' }}
                            boxShadow="md"
                        >
                            {t('shills')}
                        </Button>
                        <Button
                            onClick={handleFilterTrending}
                            colorScheme={currentFilter === 'Trending' ? 'blue' : 'gray'}
                            variant={currentFilter === 'Trending' ? 'solid' : 'outline'}
                            size="md"
                            mr={2}
                            mb={{ base: 2, md: 0 }}
                            borderRadius="full"
                            _hover={{ transform: 'scale(1.05)', transition: 'all 0.2s' }}
                            boxShadow="md"
                        >
                            {t('trending')}
                        </Button>
                        <Button
                            onClick={handleFilterNewCoins}
                            colorScheme={currentFilter === 'NewCoins' ? 'blue' : 'gray'}
                            variant={currentFilter === 'NewCoins' ? 'solid' : 'outline'}
                            size="md"
                            mb={{ base: 2, md: 0 }}
                            borderRadius="full"
                            _hover={{ transform: 'scale(1.05)', transition: 'all 0.2s' }}
                            boxShadow="md"
                        >
                            {t('newCoins')}
                        </Button>
                    </Flex>
                </FormControl>

                <InputGroup
                    flexBasis="100%"
                    mt={4}
                    borderRadius="full"
                    overflow="hidden"
                    maxWidth="400px"
                    mx="auto"
                >
                    <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.300" />}
                    />
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder={t('searchPlaceholder')}
                        borderRadius="full"
                        _focus={{
                            borderColor: 'blue.300',
                            boxShadow: 'outline',
                        }}
                        pl={10}
                        pr={4}
                        bg={cardBg}
                        color={textColor}
                    />
                </InputGroup>
            </Flex>
            <Divider mb={6} />
            <AnimatePresence>
                {isLoading ? (
                    <Flex justify="center" align="center" height="200px">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Text fontSize="xl" color={textColor}>{t('loading')}</Text>
                        </motion.div>
                    </Flex>
                ) : filteredCoins.length === 0 ? (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <Text textAlign="center" fontSize="xl" color={textColor}>{t('noCoinFound')}</Text>
                    </motion.div>
                ) : (
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                        {filteredCoins.map((coin, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3, delay: index * 0.1 }}
                            >
                                <Box
                                    p={5}
                                    borderWidth="1px"
                                    borderRadius="2xl"
                                    cursor="pointer"
                                    onClick={() => handleCoinClick(coin.address)}
                                    _hover={{
                                        transform: 'translateY(-5px)',
                                        boxShadow: 'xl',
                                        transition: 'all 0.3s'
                                    }}
                                    position="relative"
                                    shadow="lg"
                                    bg={cardBg}
                                    borderColor={borderColor}
                                    overflow="hidden"
                                >
                                    {currentFilter === 'Trending' && (
                                        <Flex
                                            position="absolute"
                                            top={2}
                                            right={2}
                                            align="center"
                                            justify="center"
                                            bg="blue.500"
                                            color="white"
                                            borderRadius="full"
                                            w={10}
                                            h={10}
                                            fontWeight="bold"
                                            fontSize="lg"
                                            boxShadow="lg"
                                            animation={`${glowAnimation} 2s infinite`}
                                        >
                                            {index === 0 ? '🔥' : index === 1 ? '🚀' : index === 2 ? '🌟' : ''}
                                            {index + 1}
                                        </Flex>
                                    )}

                                    <Text fontWeight="bold" fontSize="xl" mb={3} color={textColor}>
                                        {coin.name}
                                    </Text>
                                    <Text fontSize="md" color={`${textColor}Alpha.700`} mb={2}>
                                        {t('Ticker')}: {coin.symbol}
                                    </Text>
                                    <Text fontSize="md" color={`${textColor}Alpha.700`} mb={2}>
                                        {t('blockchain')}: {coin.blockchain}
                                    </Text>
                                    <Text fontSize="md" color={`${textColor}Alpha.700`}>
                                        {t('exchange')}: {coin.dex}
                                    </Text>
                                </Box>
                            </motion.div>
                        ))}
                    </SimpleGrid>
                )}
            </AnimatePresence>

            {/* First-time visit modal */}
            <Modal
                isOpen={showModal}
                isCentered
                onClose={() => setShowModal(false)}
                motionPreset="slideInBottom"
            >
                <ModalOverlay
                    bg="blackAlpha.300"
                    backdropFilter="blur(10px)"
                />
                <ModalContent
                    borderRadius="2xl"
                    shadow="2xl"
                    mx={[4, 0]}
                    maxW="400px"
                    bg={cardBg}
                >
                    <ModalHeader color={textColor}>{t('welcome')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text color={textColor}>{t('firstTimeVisitMessage')}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            onClick={() => setShowModal(false)}
                            borderRadius="full"
                            _hover={{
                                transform: 'scale(1.05)',
                                transition: 'all 0.2s'
                            }}
                        >
                            {t('close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default AlphaPage;