import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Center,
    Text,
    Input,
    VStack,
    useColorModeValue,
    useToast,
    FormControl,
    FormLabel,
    FormHelperText,
    NumberInput,
    NumberInputField,
    Grid,
    GridItem,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faFlag, faDollarSign, faStar, faMoon, faSun, faMeteor } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import SpinnerSvg from './Spinner';
import CustomSelect from './CustomSelect';

const CountryCreationPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const [inputField, setInputField] = useState('');
    const [customInput, setCustomInput] = useState('');
    const [planets, setPlanets] = useState([]);
    const [selectedPlanet, setSelectedPlanet] = useState('');
    const [countryName, setCountryName] = useState('');
    const [governmentType, setGovernmentType] = useState('Democracy');
    const [capital, setCapital] = useState('');
    const [states, setStates] = useState(1);
    const [countrySize, setCountrySize] = useState(20);
    const [language, setLanguage] = useState('English');
    const [flag, setFlag] = useState(null);
    const [currencyName, setCurrencyName] = useState('');
    const [ticker, setTicker] = useState('');
    const [decimals, setDecimals] = useState(2);
    const [currencyImage, setCurrencyImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);

    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const accentColor = useColorModeValue('teal.500', 'teal.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.300', 'gray.500');
    const nightMode = useColorModeValue(false, true);

    useEffect(() => {
        const fetchPlanets = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/api/planets/planets`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setPlanets(response.data);
            } catch (error) {
                console.error('Error fetching planets:', error);
                toast({
                    title: t('Error fetching planets'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };

        fetchPlanets();
    }, [toast, t]);

    const handleSelectPlanet = (planetName) => {
        setSelectedPlanet(planetName);
        setStep(2);
    };

    const handleCreateCountry = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        const formData = new FormData();
        formData.append('planetName', selectedPlanet);
        formData.append('countryName', countryName);
        formData.append('governmentType', governmentType);
        formData.append('capital', capital);
        formData.append('states', states);
        formData.append('countrySize', countrySize);
        formData.append('language', language);
        formData.append('flag', flag);

        try {
            await axios.post(
                `${apiUrl}/api/planets/create-country`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            toast({
                title: t('Country created successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setStep(3); // Move to the next step
        } catch (error) {
            console.error('Error creating country:', error);
            toast({
                title: t('Error creating country'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateCurrency = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        const formData = new FormData();
        formData.append('countryName', countryName);
        formData.append('currencyName', currencyName);
        formData.append('ticker', ticker);
        formData.append('decimals', decimals);
        formData.append('image', currencyImage);

        try {
            await axios.post(
                `${apiUrl}/api/planets/create-currency`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            toast({
                title: t('Currency created successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            // Redirect to the dashboard page after successful creation
            navigate('/dashboard');
        } catch (error) {
            console.error('Error creating currency:', error);
            toast({
                title: t('Error creating currency'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getPlanetIcon = (index) => {
        const icons = [faStar, faMoon, faSun, faMeteor, faGlobe];
        return icons[index % icons.length];
    };

    const governmentOptions = [
        { value: 'Democracy', label: 'Democracy' },
        { value: 'Monarchy', label: 'Monarchy' },
        { value: 'Dictatorship', label: 'Dictatorship' },
        { value: 'Republic', label: 'Republic' },
        { value: 'Other', label: 'Other' },
    ];

    const languageOptions = [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'Russian', label: 'Russian' },
        { value: 'Chinese', label: 'Chinese' },
        { value: 'Other', label: 'Other' },
    ];

    const handleCustomInputSubmit = () => {
        if (inputField === 'government') {
            setGovernmentType(customInput);
        } else if (inputField === 'language') {
            setLanguage(customInput);
        }
        onClose();
    };

    return (
        <Box bg={bgColor} color={textColor} p={6} borderRadius="2xl" boxShadow="2xl" minH="300vh" overflow="hidden">
            {step === 1 && (
                <VStack spacing={5} alignItems="stretch">
                    <Center>
                        <Box as={FontAwesomeIcon} icon={faGlobe} boxSize="2rem" color={accentColor} />
                    </Center>
                    <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                        {t('Select a Planet')}
                    </Text>
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={6}>
                        {planets.length === 0 ? (
                            <Text>{t('No planets available')}</Text>
                        ) : (
                            planets.map((planet, index) => (
                                <GridItem
                                    key={planet.id}
                                    onClick={() => handleSelectPlanet(planet.name)}
                                    cursor="pointer"
                                >
                                    <Box
                                        p={5}
                                        shadow="lg"
                                        borderWidth="1px"
                                        borderRadius="lg"
                                        bgGradient={nightMode ? 'linear(to-r, teal.500, blue.500)' : 'gray.200'}
                                        color={nightMode ? 'white' : 'gray.800'}
                                        _hover={{ bgGradient: nightMode ? 'linear(to-r, teal.400, blue.400)' : 'gray.300', transform: 'scale(1.05)' }}
                                        transition="all 0.3s ease"
                                    >
                                        <Center mb={3}>
                                            <Box as={FontAwesomeIcon} icon={getPlanetIcon(index)} boxSize="2rem" />
                                        </Center>
                                        <Text fontSize="xl" fontWeight="bold" textAlign="center">
                                            {planet.name}
                                        </Text>
                                        <Text textAlign="center">{t('Total Surface Area')}: {planet.totalSurfaceArea.toLocaleString()} sq km</Text>
                                        <Text textAlign="center">{t('Land Area')}: {planet.landArea.toLocaleString()} sq km</Text>
                                        <Text textAlign="center">{t('Water Area')}: {planet.waterArea.toLocaleString()} sq km</Text>
                                        <Text textAlign="center">{t('Land Percentage')}: {planet.landPercentage}%</Text>
                                        <Text textAlign="center">{t('Water Percentage')}: {planet.waterPercentage}%</Text>
                                    </Box>
                                </GridItem>
                            ))
                        )}
                    </Grid>
                </VStack>
            )}

            {step === 2 && (
                <VStack spacing={5} alignItems="stretch">
                    <Center>
                        <Box as={FontAwesomeIcon} icon={faFlag} boxSize="2rem" color={accentColor} />
                    </Center>
                    <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                        {t('Create Your Country on')} {selectedPlanet}
                    </Text>
                    <FormControl>
                        <FormLabel>{t('Country Name')}</FormLabel>
                        <Input
                            placeholder={t('Enter country name')}
                            value={countryName}
                            onChange={(e) => setCountryName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Government Type')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="governmentType"
                                options={governmentOptions}
                                selectedOption={{ value: governmentType, label: governmentType }}
                                setSelectedOption={(option) => {
                                    if (option.value === 'Other') {
                                        setInputField('government');
                                        onOpen();
                                    } else {
                                        setGovernmentType(option.value);
                                    }
                                }}
                            />
                        </Box>
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Capital')}</FormLabel>
                        <Input
                            placeholder={t('Enter capital city')}
                            value={capital}
                            onChange={(e) => setCapital(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Number of States')}</FormLabel>
                        <NumberInput
                            min={1}
                            value={states}
                            onChange={(value) => setStates(parseInt(value))}
                        >
                            <NumberInputField />
                        </NumberInput>
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Country Size (sq km)')}</FormLabel>
                        <NumberInput
                            min={20}
                            max={1000}
                            value={countrySize}
                            onChange={(value) => setCountrySize(parseFloat(value))}
                        >
                            <NumberInputField />
                        </NumberInput>
                        <FormHelperText>{t('Choose a size between 20 and 1000 sq km')}</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Language')}</FormLabel>
                        <Box
                            p={1}
                            bg={cardBg}
                            borderRadius="lg"
                            boxShadow="md"
                            cursor="pointer"
                            border="1px"
                            borderColor={borderColor}
                        >
                            <CustomSelect
                                name="language"
                                options={languageOptions}
                                selectedOption={{ value: language, label: language }}
                                setSelectedOption={(option) => {
                                    if (option.value === 'Other') {
                                        setInputField('language');
                                        onOpen();
                                    } else {
                                        setLanguage(option.value);
                                    }
                                }}
                            />
                        </Box>
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Flag')}</FormLabel>
                        <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setFlag(e.target.files[0])}
                        />
                    </FormControl>
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faFlag} />}
                        onClick={handleCreateCountry}
                        colorScheme="blue"
                        variant="solid"
                        borderRadius="2xl"
                        isLoading={isLoading}
                    >
                        {t('Create Country')}
                    </Button>
                </VStack>
            )}

            {step === 3 && (
                <VStack spacing={5} alignItems="stretch">
                    <Center>
                        <Box as={FontAwesomeIcon} icon={faDollarSign} boxSize="2rem" color={accentColor} />
                    </Center>
                    <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                        {t('Create Your Currency for')} {selectedPlanet}
                    </Text>
                    <FormControl>
                        <FormLabel>{t('Currency Name')}</FormLabel>
                        <Input
                            placeholder={t('Enter currency name')}
                            value={currencyName}
                            onChange={(e) => setCurrencyName(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Ticker')}</FormLabel>
                        <Input
                            placeholder={t('Enter ticker')}
                            value={ticker}
                            onChange={(e) => setTicker(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Decimals')}</FormLabel>
                        <NumberInput
                            min={0}
                            max={10}
                            value={decimals}
                            onChange={(value) => setDecimals(parseInt(value))}
                        >
                            <NumberInputField />
                        </NumberInput>
                    </FormControl>
                    <FormControl>
                        <FormLabel>{t('Currency Image')}</FormLabel>
                        <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setCurrencyImage(e.target.files[0])}
                        />
                    </FormControl>
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faDollarSign} />}
                        onClick={handleCreateCurrency}
                        colorScheme="green"
                        variant="solid"
                        borderRadius="2xl"
                        isLoading={isLoading}
                    >
                        {t('Create Currency')}
                    </Button>
                </VStack>
            )}

            {isLoading && (
                <Center w="full" h="full">
                    <SpinnerSvg />
                </Center>
            )}

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent  mx={[4, 0]} maxW="400px" borderRadius="2xl" boxShadow="2xl" shadow="xl">
                    <ModalHeader>{t('Enter your custom input')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>{inputField === 'government' ? t('Government Type') : t('Language')}</FormLabel>
                            <Input
                                value={customInput}
                                onChange={(e) => setCustomInput(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" onClick={handleCustomInputSubmit}>
                            {t('Submit')}
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default CountryCreationPage;
