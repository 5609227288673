import React, { useEffect } from 'react';
import { ChakraProvider, extendTheme, VStack, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import BottomNavBar from './components/BottomNavBar';
import Chat from './components/chat';
import LandingPage from './components/LandingPage';
import SignUpPage from './components/SignUpPage';
import LoginPage from './components/LoginPage';
import WelcomePage from './components/WelcomePage';
import NotFoundPage from './components/404Page';
import AlertsPage from './components/AlertsPage';
import BuyPage from './components/BuyPage';
import AutoSwap from './components/AutoSwap';
import WalletPage from './components/WalletPage';
import ProfilePage from './components/ProfilePage';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './contexts/AuthContext';
import { emitter } from './contexts/eventEmitter';
import LogoutListener from './components/LogoutListener';
import BuyPointsPage from './components/BuyPointsPage';
import FAQPage from './components/FaqPage';
import AutoSwapGuide from './components/AutoSwapGuide';
import AlphaPage from './components/AlphaPage';
import CoinPage from './components/CoinPage';
// import DegenPage from './components/DegenPage';
import SettingsPage from './components/SettingsPage';
import MarketAnalysisPage from './components/MarketAnalysisPage';
import BuildPage from './components/BuildPage';
import PoolFinancePage from './components/PoolFinancePage';
import AudioChat from './components/AudioChat';
import ApiKeysPage from './components/ApiKeysPage';
import PublicApiDetailsPage from './components/PublicApiDetailsPage';
import ChatViewer from './components/ChatViewer';
import ReferralLandingPage from './components/ReferralLandingPage';
import MaximoCreatePage from './components/CreatePage';
import DocumentEditorPage from './components/DocumentEditorPage';
import PPTEeditorPage from './components/pptEditor';
import QuantumComputingPPT from './components/QuantumComputingPPT';
import PromptGenerationPage from './components/PromptGenerationModal';
import CountryCreationPage from './components/CountryCreationPage';
import UserCountriesDashboard from './components/UserCountriesDashboard';
import DashboardPage from './components/DashboardPage';
import BuildingCreationPage from './components/BuildingCreationPage';
import ExocrestWelcomePage from './components/ExocrestWelcomePage';
import ComingSoonPage from './components/ComingSoonPage';
import MaximoComingSoonPage from './components/MaximoComingSoonPage';
import LaunchPadPage from './components/LaunchPadPage';
import MiningStationPage from './components/MiningStationPage';
import EarnPage from './components/EarnPage';
import ExocrestTutorialPage from './components/ExocrestTutorialPage';
import CreateAlliancePage from './components/CreateAlliancePage';
import InventoryPage from './components/InventoryPage';
import CountryMapPage from './components/CountryMapPage';
import ChatHistoryPage from './components/ChatHistoryModal';
import ChatConversationPage from './components/ChatConversationPage';
import UserMenuPage from './components/UserMenuModal';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsOfUsePage from './components/TermsOfUsePage';
import MaximoZest from './components/MaximoZest';
import PlaygroundPage from './components/PlaygroundPage';
import WorkSpace from './components/WorkSpace';
import ReactGA from 'react-ga'; // Import react-ga
import './customFonts.css';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

function App() {
  const auth = useAuth();

  useEffect(() => {
    const handleLogout = () => {
      auth.logout();
    };

    emitter.on('logout', handleLogout);

    return () => {
      emitter.removeListener('logout', handleLogout);
    };
  }, [auth]);

  // Initialize Google Analytics with your Measurement ID
  useEffect(() => {
    ReactGA.initialize('G-F01PS29W21');
    // Track initial pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AuthProvider> {/* Wrap with AuthProvider */}
      <ChakraProvider theme={theme}>
        <Router>
          <LogoutListener /> {/* Place LogoutListener here */}
          <VStack spacing={0} align="stretch" height="100vh">
            <Header />
            <Box flex="1" overflowY="auto">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/welcome" element={<WelcomePage />} />
                <Route path="/alerts" element={<AlertsPage />} />
                <Route path="/swap" element={<BuyPage />} />
                <Route path="/auto-swap" element={<AutoSwap />} />
                <Route path="/wallet" element={<WalletPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/buypoints" element={<BuyPointsPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/autoswap-guide" element={<AutoSwapGuide />} />
                <Route path="/alpha" element={<AlphaPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/futures" element={<MarketAnalysisPage />} />
                <Route path="/build" element={<BuildPage />} />
                <Route path="/coin/:coinAddress" element={<CoinPage />} />
                <Route path="/pool" element={<PoolFinancePage />} />
                <Route path="/audio" element={<AudioChat />} />
                <Route path="/platform" element={<ApiKeysPage />} />
                <Route path="/dpublicapi" element={<PublicApiDetailsPage />} />
                <Route path="/view-shared-chat/:id" element={<ChatViewer />} />
                <Route path="/ref" element={<ReferralLandingPage />} />
                <Route path="/create" element={<MaximoCreatePage />} />
                <Route path="/document-editor" element={<DocumentEditorPage />} />
                <Route path="/ppt-editor" element={<PPTEeditorPage />} />
                <Route path="/quantum-computing-ppt" element={<QuantumComputingPPT />} />
                <Route path="/prompt-generation" element={<PromptGenerationPage />} />
                <Route path="/create-country" element={<CountryCreationPage />} />
                <Route path="/countries" element={<UserCountriesDashboard />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/create-buildings" element={<BuildingCreationPage />} />
                <Route path="/exocrest" element={<ExocrestWelcomePage />} />
                <Route path="/exocrest-comingsoon" element={<ComingSoonPage />} />
                <Route path="/comingsoon" element={<MaximoComingSoonPage />} />
                <Route path="/launchpad" element={<LaunchPadPage />} />
                <Route path="/mining" element={<MiningStationPage />} />
                <Route path="/exocrest-tutorial" element={<ExocrestTutorialPage />} />
                <Route path="/earn" element={<EarnPage />} />
                <Route path="/create-alliance" element={<CreateAlliancePage />} />
                <Route path="/inventory" element={<InventoryPage />} />
                <Route path="/country" element={<CountryMapPage />} />
                <Route path="/chat-history" element={<ChatHistoryPage />} />
                <Route path="/chat-conversation" element={<ChatConversationPage />} />
                <Route path="/menu" element={<UserMenuPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/terms-of-use" element={<TermsOfUsePage />} />
                <Route path="/playground" element={<PlaygroundPage />} />
                <Route path="/zest" element={<MaximoZest />} />
                <Route path="/workspace" element={<WorkSpace />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Box>
            <ConditionalBottomNavBar />
          </VStack>
        </Router>
      </ChakraProvider>
    </AuthProvider>
  );
}

// A separate component for conditionally rendering the BottomNavBar
function ConditionalBottomNavBar() {
  const location = useLocation();

  // Define an array of paths where the BottomNavBar should not render
  const excludedPaths = ['/chat', '/login', '/signup', '/', '/welcome', '/prompt-generation', '/privacy-policy', '/terms-of-use', '/zest', '/playground', '*'];

  // Check if the current path is in the excludedPaths array
  if (excludedPaths.includes(location.pathname)) {
    return null; // Don't render the BottomNavBar on excluded pages
  }

  return <BottomNavBar />;
}

export default App;
