import React from 'react';
import { Box, Container, Heading, Text, Link, Code, VStack, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const BuildPage = () => {
    const { t } = useTranslation();
    const codeBg = useColorModeValue('gray.100', 'gray.900');
    const codeColor = useColorModeValue('gray.800', 'gray.100');

    return (
        <Container maxW="container.md" py={8} minHeight="300vh">
            <Heading mb={6} textAlign="center" size="lg">
                {t('buildPage.title')}
            </Heading>
            <VStack spacing={6} align="start">
                <Text>{t('buildPage.overview')}</Text>
                <Box>
                    <Text fontWeight="bold">{t('buildPage.availableAPIs')}</Text>
                    <Text>
                        <strong>1. </strong>
                        <Link href="https://rad.huddlz.xyz/api/coins" isExternal color="blue.500">
                            {t('buildPage.coinsAPI')}
                        </Link>
                        : {t('buildPage.coinsAPIDescription')}
                    </Text>
                    <Text>
                        <strong>2. </strong>
                        <Link href="https://rad.huddlz.xyz/api/new-coins" isExternal color="blue.500">
                            {t('buildPage.newCoinsAPI')}
                        </Link>
                        : {t('buildPage.newCoinsAPIDescription')}
                    </Text>
                    <Text>
                        <strong>3. </strong>
                        <Link href="https://rad.huddlz.xyz/api/trending-coins" isExternal color="blue.500">
                            {t('buildPage.trendingCoinsAPI')}
                        </Link>
                        : {t('buildPage.trendingCoinsAPIDescription')}
                    </Text>
                </Box>
                <Text fontWeight="bold">{t('buildPage.howToUse')}</Text>
                <Box>
                    <Text>
                        <strong>1. {t('buildPage.coinsAPI')}</strong>: {t('buildPage.endpoint')}
                        <Link href="https://rad.huddlz.xyz/api/coins" isExternal color="blue.500">
                            https://rad.huddlz.xyz/api/coins
                        </Link>
                        . {t('buildPage.coinsAPIDescription')}
                    </Text>
                    <Text>
                        <strong>2. {t('buildPage.newCoinsAPI')}</strong>: {t('buildPage.endpoint')}
                        <Link href="https://rad.huddlz.xyz/api/new-coins" isExternal color="blue.500">
                            https://rad.huddlz.xyz/api/new-coins
                        </Link>
                        . {t('buildPage.newCoinsAPIDescription')}
                    </Text>
                    <Text>
                        <strong>3. {t('buildPage.trendingCoinsAPI')}</strong>: {t('buildPage.endpoint')}
                        <Link href="https://rad.huddlz.xyz/api/trending-coins" isExternal color="blue.500">
                            https://rad.huddlz.xyz/api/trending-coins
                        </Link>
                        . {t('buildPage.trendingCoinsAPIDescription')}
                    </Text>
                </Box>
                <Text fontWeight="bold">{t('buildPage.exampleResponse')}</Text>
                <Box bg={codeBg} p={4} borderRadius="md" w="full">
                    <Code p={4} bg={codeBg} color={codeColor} borderRadius="md" whiteSpace="pre-wrap">
                        {`{
    "blockchain": "solana",
    "dex": "raydium",
    "url": "https://dexscreener.com/solana/frhb8l7y9qq41qzxyltc2nw8an1rjfllxrf2x9rwllmo",
    "name": "Popcat",
    "symbol": "POPCAT",
    "address": "7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr",
    "tokenInfo": {
        "imageUrl": "https://dd.dexscreener.com/ds-data/tokens/solana/7GCihgDB8fe6KNjn2MYtkzZcRjQy3t9GHdC8uHYmW2hr.png",
        "websites": [
            {
                "label": "Website",
                "url": "https://www.popcatsolana.xyz/"
            }
        ],
        "socials": [
            {
                "type": "telegram",
                "url": "https://t.me/popcatsolana"
            },
            {
                "type": "twitter",
                "url": "https://twitter.com/POPCATSOLANA/"
            }
        ]
    },
    "textAnalysis": null,
    "date": 1714145573
},
{
  "blockchain": "bsc",
  "dex": "pancakeswap",
  "url": "https://dexscreener.com/bsc/0x3f3d34bff22619e5b64173b80c84a16fbe6c3b60",
  "name": "HuddlzToken",
  "symbol": "HUDL",
  "address": "0x1928D99f8A13F851Ec9E5BD87181988722EefcD8",
  "textAnalysis": {
    "Tickers": "HUDL",
    "Name": "HuddlzToken",
    "Contract Address": "0x1928d99f8a13f851ec9e5bd87181988722eefcd8",
    "Blockchain": "Binance Smart Chain (BSC)"
  },
  "date": 1714012841
}`}
                    </Code>
                </Box>
            </VStack>
        </Container>
    );
};

export default BuildPage;
