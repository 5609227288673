import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    IconButton,
    useMediaQuery,
    Button,
    Flex,
    useColorModeValue,
    useToast,
    Text,
    HStack,
    VStack,
    Select,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye, faCode, faCopy, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const AnimatedNoPreviewSVG = () => (
    <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <style>
            {`
        @keyframes rotate {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes pulse {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.1); }
        }
        .rotate { animation: rotate 10s linear infinite; }
        .pulse { animation: pulse 2s ease-in-out infinite; }
      `}
        </style>
        <g className="rotate">
            <circle cx="100" cy="100" r="80" fill="none" stroke="#718096" strokeWidth="4" strokeDasharray="25 25" />
        </g>
        <g className="pulse">
            <path d="M100 60 L120 100 L100 140 L80 100 Z" fill="#4A5568" />
            <circle cx="100" cy="100" r="10" fill="#4A5568" />
        </g>
    </svg>
);

const CodePlayground = ({ files }) => {
    const [preview, setPreview] = useState('');

    useEffect(() => {
        if (files.length > 0) {
            const htmlFile = files.find(file => file.name.endsWith('.html'));
            if (htmlFile) {
                let htmlContent = htmlFile.content;
                files.forEach(file => {
                    if (file.name.endsWith('.js')) {
                        htmlContent = htmlContent.replace(`<script src="${file.name}"></script>`, `<script>${file.content}</script>`);
                    }
                    if (file.name.endsWith('.css')) {
                        htmlContent = htmlContent.replace(`<link rel="stylesheet" href="${file.name}">`, `<style>${file.content}</style>`);
                    }
                });
                setPreview(htmlContent);
            } else {
                setPreview(`<pre>${files[0].content}</pre>`);
            }
        }
    }, [files]);

    return (
        <Box width="100%" height="100%" overflow="auto" borderRadius="md" boxShadow="md">
            {preview ? (
                <iframe
                    srcDoc={preview}
                    title="Code Preview"
                    width="100%"
                    height="100%"
                    style={{ border: 'none', borderRadius: 'md' }}
                />
            ) : (
                <Flex direction="column" align="center" justify="center" height="100%" p={4}>
                    <AnimatedNoPreviewSVG />
                    <Text mt={4} fontSize="lg" fontWeight="bold">No preview available</Text>
                </Flex>
            )}
        </Box>
    );
};

const ModernCodeEditor = ({ code, language, onChange }) => {
    const lineNumbers = code.split('\n').map((_, index) => index + 1);
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const editorRef = useRef(null);
    const lineNumbersRef = useRef(null);
    const highlighterRef = useRef(null);

    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;
            const newCode = code.substring(0, start) + '  ' + code.substring(end);
            onChange({ target: { value: newCode } });
            setTimeout(() => {
                e.target.selectionStart = e.target.selectionEnd = start + 2;
            }, 0);
        }
    };

    const handleScroll = (e) => {
        if (lineNumbersRef.current) {
            lineNumbersRef.current.scrollTop = e.target.scrollTop;
        }
        if (highlighterRef.current) {
            highlighterRef.current.scrollTop = e.target.scrollTop;
            highlighterRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    useEffect(() => {
        if (editorRef.current && highlighterRef.current) {
            highlighterRef.current.style.width = `${editorRef.current.scrollWidth}px`;
        }
    }, [code]);

    return (
        <Flex height="100%" overflow="hidden" border="1px solid" borderColor={borderColor} borderRadius="md">
            <Box
                ref={lineNumbersRef}
                fontFamily="monospace"
                p={2}
                color="gray.500"
                bg={useColorModeValue('gray.100', 'gray.800')}
                borderRight="1px solid"
                borderColor={borderColor}
                userSelect="none"
                textAlign="right"
                overflowY="hidden"
                height="100%"
                minWidth="50px"
            >
                {lineNumbers.map((num) => (
                    <div key={num}>{num}</div>
                ))}
            </Box>
            <Box flex={1} position="relative" overflow="hidden">
                <Box
                    ref={highlighterRef}
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    overflow="auto"
                    pointerEvents="none"
                >
                    <SyntaxHighlighter
                        language={language.toLowerCase()}
                        style={vscDarkPlus}
                        customStyle={{
                            margin: 0,
                            padding: '8px',
                            backgroundColor: 'transparent',
                        }}
                    >
                        {code}
                    </SyntaxHighlighter>
                </Box>
                <textarea
                    ref={editorRef}
                    value={code}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onScroll={handleScroll}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        padding: '8px',
                        border: 'none',
                        background: 'transparent',
                        color: 'transparent',
                        caretColor: useColorModeValue('black', 'white'),
                        fontFamily: 'monospace',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        resize: 'none',
                        outline: 'none',
                        overflow: 'auto',
                        whiteSpace: 'pre',
                    }}
                />
            </Box>
        </Flex>
    );
};

const PlaygroundPopup = ({ isOpen, onClose, code: initialCode, language: initialLanguage }) => {
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    const [files, setFiles] = useState([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [isPreviewMode, setIsPreviewMode] = useState(true);

    const bgColor = useColorModeValue('white', 'gray.800');
    const buttonColorScheme = useColorModeValue('teal', 'cyan');

    const toast = useToast();

    useEffect(() => {
        const parseFiles = (code, defaultLanguage) => {
            const fileRegex = /### (\w+(?:\.\w+)?)\s*```(\w+)?\n([\s\S]+?)```/g;
            const parsedFiles = [];
            let match;

            while ((match = fileRegex.exec(code)) !== null) {
                parsedFiles.push({
                    name: match[1],
                    language: match[2] || defaultLanguage,
                    content: match[3].trim(),
                });
            }

            return parsedFiles.length > 0 ? parsedFiles : [{
                name: 'main.' + defaultLanguage,
                language: defaultLanguage,
                content: code.trim(),
            }];
        };

        const parsedFiles = parseFiles(initialCode, initialLanguage);
        setFiles(parsedFiles);
        setCurrentFileIndex(0);
    }, [initialCode, initialLanguage]);

    const handleCodeChange = (event) => {
        const newFiles = [...files];
        newFiles[currentFileIndex].content = event.target.value;
        setFiles(newFiles);
    };

    const handleCopyCode = () => {
        const currentCode = files[currentFileIndex].content;
        navigator.clipboard.writeText(currentCode).then(() => {
            toast({
                title: "Code copied",
                description: "The code has been copied to your clipboard.",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        }).catch((err) => {
            console.error('Failed to copy code: ', err);
            toast({
                title: "Copy failed",
                description: "Failed to copy the code. Please try again.",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        });
    };

    const nextFile = () => {
        setCurrentFileIndex((prevIndex) => (prevIndex + 1) % files.length);
    };

    const prevFile = () => {
        setCurrentFileIndex((prevIndex) => (prevIndex - 1 + files.length) % files.length);
    };

    return (
        <Drawer
            isOpen={isOpen}
            placement={isLargerThan768 ? "right" : "bottom"}
            onClose={onClose}
            size={isLargerThan768 ? "md" : "full"}
        >
            <DrawerOverlay />
            <DrawerContent borderLeftRadius={isLargerThan768 ? "xl" : "0"} bg={bgColor}>
                <DrawerHeader borderBottomWidth="1px" p={4}>
                    <Flex justify="space-between" align="center">
                        <Box fontWeight="bold">Maximo AI Code Playground</Box>
                        <IconButton
                            icon={<FontAwesomeIcon icon={faTimes} />}
                            onClick={onClose}
                            aria-label="Close Playground"
                            size="sm"
                            borderRadius="full"
                        />
                    </Flex>
                </DrawerHeader>
                <DrawerBody p={4}>
                    <VStack spacing={4}>
                        <HStack width="100%" justifyContent="space-between">
                            <Button
                                leftIcon={<FontAwesomeIcon icon={faEye} />}
                                onClick={() => setIsPreviewMode(true)}
                                colorScheme={buttonColorScheme}
                                variant={isPreviewMode ? "solid" : "outline"}
                                borderRadius="full"
                            >
                                Preview
                            </Button>
                            <Button
                                leftIcon={<FontAwesomeIcon icon={faCode} />}
                                onClick={() => setIsPreviewMode(false)}
                                colorScheme={buttonColorScheme}
                                variant={!isPreviewMode ? "solid" : "outline"}
                                borderRadius="full"
                            >
                                Edit Code
                            </Button>
                            <IconButton
                                icon={<FontAwesomeIcon icon={faCopy} />}
                                onClick={handleCopyCode}
                                aria-label="Copy Code"
                                colorScheme={buttonColorScheme}
                                borderRadius="full"
                            />
                        </HStack>
                        {files.length > 1 && (
                            <HStack width="100%" justifyContent="space-between">
                                <IconButton
                                    icon={<FontAwesomeIcon icon={faChevronLeft} />}
                                    onClick={prevFile}
                                    aria-label="Previous File"
                                    size="sm"
                                />
                                <Select value={currentFileIndex} onChange={(e) => setCurrentFileIndex(Number(e.target.value))}>
                                    {files.map((file, index) => (
                                        <option key={index} value={index}>{file.name}</option>
                                    ))}
                                </Select>
                                <IconButton
                                    icon={<FontAwesomeIcon icon={faChevronRight} />}
                                    onClick={nextFile}
                                    aria-label="Next File"
                                    size="sm"
                                />
                            </HStack>
                        )}
                    </VStack>
                    <Box height="calc(100vh - 250px)" overflow="hidden" mt={4}>
                        {isPreviewMode ? (
                            <CodePlayground files={files} />
                        ) : (
                            <ModernCodeEditor
                                code={files[currentFileIndex]?.content || ''}
                                language={files[currentFileIndex]?.language || ''}
                                onChange={handleCodeChange}
                            />
                        )}
                    </Box>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default PlaygroundPopup;