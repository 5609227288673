import React, { useState, useEffect } from 'react';
import {
    Box, VStack, Text, Avatar, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
    ModalBody, ModalFooter, Link, Image, FormControl, FormLabel, Input, useDisclosure, Flex, IconButton,
    useToast, SimpleGrid, Divider, HStack, useColorModeValue, Container, Heading, Grid
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ChevronRightIcon, EditIcon } from '@chakra-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import defaultProfileImage from '../images/user-avatar.png';
import { useNavigate } from 'react-router-dom';
import companyLogo from '../images/huddlz-logo-circle.png';
import DecryptWalletModal from './DecryptWalletModal';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faCog, faBell, faExchangeAlt, faRocket, faChartLine, faHammer, faTachometerAlt, faPlus, faSignal, faQuestionCircle, faCopy, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@chakra-ui/react';

const MotionBox = motion(Box);

const WalletAddressSection = ({ address, onCopy }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const bgGradient = useColorModeValue("linear(to-r, blue.400, purple.500)", "linear(to-r, blue.700, purple.800)");
    const textColor = useColorModeValue("white", "gray.100");

    const handleCopy = () => {
        onCopy();
        toast({
            title: t('copied'),
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    return (
        <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            bgGradient={bgGradient}
            p={6}
            shadow="2xl"
            borderRadius="2xl"
            w="full"
        >
            <Flex direction="column" align="start">
                <Flex justify="space-between" align="center" w="full" mb={2}>
                    <Text color={textColor} fontSize="sm" fontWeight="bold">
                        {t('userProfile.walletAddress')}
                    </Text>
                    <HStack>
                        <CopyToClipboard text={address} onCopy={handleCopy}>
                            {/* <Tooltip label={t('userProfile.copyWalletAddress')} hasArrow> */}
                            <IconButton
                                icon={<FontAwesomeIcon icon={faCopy} />}
                                colorScheme="whiteAlpha"
                                size="sm"
                                aria-label={t('userProfile.copyWalletAddress')}
                                _hover={{ bg: 'whiteAlpha.300' }}
                            />
                            {/* </Tooltip> */}
                        </CopyToClipboard>
                        <DecryptWalletModal onDecryptSuccess={(privateKey) => console.log(privateKey)} />
                    </HStack>
                </Flex>
                <Divider borderColor="whiteAlpha.400" mb={2} />
                <Text color={textColor} wordBreak="break-word" fontSize="xs">
                    {address}
                </Text>
            </Flex>
        </MotionBox>
    );
};

const MenuButton = ({ icon, label, onClick }) => {
    const bgColor = useColorModeValue("white", "gray.800");
    const hoverBgColor = useColorModeValue("gray.100", "gray.700");
    const iconColor = useColorModeValue("blue.500", "blue.300");
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

    return (
        <MotionBox
            as={Button}
            leftIcon={<FontAwesomeIcon icon={icon} color={iconColor} />}
            rightIcon={isLargerThan768 ? <ChevronRightIcon /> : null}
            justifyContent="space-between"
            variant="outline"
            w="full"
            onClick={onClick}
            bg={bgColor}
            _hover={{ bg: hoverBgColor }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
            borderRadius="xl"
            boxShadow="md"
            p={6}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
        >
            {label}
        </MotionBox>
    );
};

const UserProfileContent = ({ closeModal }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userProfile, setUserProfile] = useState({
        username: '',
        walletAddress: '',
        profilePhoto: '',
    });
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();
    const apiUrl = process.env.REACT_APP_API_URL;

    const bgColor = useColorModeValue("gray.50", "gray.700");
    const textColor = useColorModeValue("gray.800", "gray.100");
    const borderColor = useColorModeValue("gray.200", "gray.700");

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setUserProfile({
                    username: response.data.username,
                    walletAddress: response.data.walletAddress,
                    profilePhoto: response.data.profilePhoto ? `${apiUrl}/${response.data.profilePhoto}` : defaultProfileImage,
                });
            } catch (error) {
                console.error('Failed to fetch user profile:', error);
                toast({
                    title: t('fetchError.title'),
                    description: t('fetchError.description'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchUserProfile();
    }, [toast, t, apiUrl]);

    const createWallet = async () => {
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`${apiUrl}/create-wallet`, { password }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setUserProfile((prevState) => ({
                ...prevState,
                walletAddress: response.data.walletAddress,
            }));
            onClose();
        } catch (error) {
            console.error('Failed to create wallet:', error);
        }
    };

    const handleCopy = () => {
        // Your logic for handling copy event
    };

    const logout = () => {
        localStorage.clear();
        navigate('/');
    };

    const closeModalAndNavigate = (path) => {
        closeModal();
        navigate(path);
    };

    const Footer = () => {
        return (
            <Flex align="center" justify="center" p={4} direction="column">
                <Text color="gray.500" fontSize="xs">App Version 0.35.02</Text>
                <HStack spacing={4} mt={2}>
                    <Link href="/terms-of-use" isExternal fontSize="xs" color={textColor}>{t('Terms of Use')}</Link>
                    <Link href="/privacy-policy" isExternal fontSize="xs" color={textColor}>{t('Privacy Policy')}</Link>
                </HStack>
            </Flex>
        );
    };

    return (
        <Box minH="100vh">
            <Container maxW="container.xl" py={8}>
                <Grid templateColumns={{ base: "1fr", md: "300px 1fr" }} gap={8}>
                    <VStack spacing={6} align="center">
                        <Flex align="center" justify="left" w="full" mb={4}>
                            <Image src={companyLogo} boxSize="50px" alt="Company Logo" mr={3} />
                            <Heading fontSize="2xl" fontFamily="Lexend" color={textColor}>HuddlzAI</Heading>
                        </Flex>

                        <AnimatePresence>
                            <MotionBox
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.9 }}
                                transition={{ duration: 0.5 }}
                                position="relative"
                            >
                                <Avatar
                                    size="2xl"
                                    src={userProfile.profilePhoto}
                                    borderRadius="full"
                                    border="4px solid"
                                    borderColor={borderColor}
                                />
                                <IconButton
                                    aria-label="Edit profile"
                                    icon={<EditIcon />}
                                    size="sm"
                                    position="absolute"
                                    bottom={0}
                                    right={0}
                                    colorScheme="blue"
                                    rounded="full"
                                    onClick={() => navigate('/settings')}
                                />
                            </MotionBox>
                        </AnimatePresence>

                        <Text fontWeight="bold" fontSize="xl" color={textColor}>@{userProfile.username || t('userProfile.defaultUsername')}</Text>

                        {userProfile.walletAddress ? (
                            <WalletAddressSection
                                address={userProfile.walletAddress}
                                onCopy={handleCopy}
                            />
                        ) : (
                            <Button
                                onClick={onOpen}
                                colorScheme="blue"
                                w="full"
                                size="lg"
                                borderRadius="full"
                                fontWeight="bold"
                                boxShadow="0 0 15px rgba(66, 153, 225, 0.5)"
                                _hover={{ boxShadow: "0 0 20px rgba(66, 153, 225, 0.7)" }}
                                transition="all 0.3s"
                            >
                                {t('userProfile.createWallet')}
                            </Button>
                        )}

                        <Button
                            colorScheme="red"
                            leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
                            onClick={() => {
                                closeModal();
                                logout();
                            }}
                            w="full"
                            mt={6}
                            borderRadius="full"
                        >
                            {t('logout')}
                        </Button>
                    </VStack>

                    <Box>
                        <SimpleGrid columns={{ base: 2, md: 3 }} spacing={6}>
                            <MenuButton icon={faUser} label={t('userProfile.profile')} onClick={() => closeModalAndNavigate('/profile')} />
                            <MenuButton icon={faCog} label={t('userProfile.settings')} onClick={() => closeModalAndNavigate('/settings')} />
                            <MenuButton icon={faWallet} label={t('userProfile.wallet')} onClick={() => closeModalAndNavigate('/wallet')} />
                            <MenuButton icon={faPlus} label={t('CreateN')} onClick={() => closeModalAndNavigate('/create')} />
                            <MenuButton icon={faBell} label={t('userProfile.alerts')} onClick={() => closeModalAndNavigate('/alerts')} />
                            <MenuButton icon={faChartLine} label={t('userProfile.futures')} onClick={() => closeModalAndNavigate('/futures')} />
                            <MenuButton icon={faExchangeAlt} label={t('swap')} onClick={() => closeModalAndNavigate('/swap')} />
                            <MenuButton icon={faExchangeAlt} label={t('Auto Swap')} onClick={() => closeModalAndNavigate('/auto-swap')} />
                            <MenuButton icon={faRocket} label={t('alphas')} onClick={() => closeModalAndNavigate('/alpha')} />
                            {/* <MenuButton icon={faWater} label={t('pool')} onClick={() => closeModalAndNavigate('/pool')} /> */}
                            <MenuButton icon={faSignal} label={t('Signals')} onClick={() => closeModalAndNavigate('/comingsoon')} />
                            <MenuButton icon={faHammer} label={t('Build')} onClick={() => closeModalAndNavigate('/platform')} />
                            <MenuButton icon={faRocket} label={t('LaunchPad')} onClick={() => closeModalAndNavigate('/launchpad')} />
                            <MenuButton icon={faTachometerAlt} label={t('ExocrestN')} onClick={() => closeModalAndNavigate('/dashboard')} />
                            <MenuButton icon={faQuestionCircle} label={t('FAQ')} onClick={() => closeModalAndNavigate('/faq')} />
                        </SimpleGrid>
                    </Box>
                </Grid>

                <Footer />
            </Container>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay backdropFilter="blur(5px)" />
                <ModalContent borderRadius="xl" bg={bgColor}>
                    <ModalHeader color={textColor}>{t('userProfile.createWallet')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl isRequired>
                            <FormLabel color={textColor}>{t('password')}</FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                borderRadius="full"
                                bg={useColorModeValue("white", "gray.700")}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={createWallet}
                            borderRadius="full"
                        >
                            {t('create')}
                        </Button>
                        <Button variant="ghost" onClick={onClose} borderRadius="full">{t('cancel')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default UserProfileContent;