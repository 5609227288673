import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Box,
    VStack,
    Text,
    useColorModeValue,
    Button,
    Heading,
    Container,
    Flex,
    Icon,
    useBreakpointValue,
    keyframes,
    ScaleFade,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    IconButton,
    Tooltip,
    Divider,
    Input,
    InputGroup,
    InputLeftElement
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendarAlt, faClock, faComments, faTrash, faExclamationTriangle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import debounce from 'lodash/debounce';
import SpinnerSvg from './Spinner';

const MotionBox = motion(Box);

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ChatHistoryPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [chatHistory, setChatHistory] = useState(location.state?.chatHistory || []);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [sessionToDelete, setSessionToDelete] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const toast = useToast();
    const cancelRef = React.useRef();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const boxBgColor = useColorModeValue('white', 'gray.800');
    const boxShadow = useColorModeValue('lg', 'dark-lg');
    const accentColor = useColorModeValue('teal.500', 'teal.300');
    const noTitleColor = useColorModeValue('gray.500', 'gray.400');
    const modalBgColor = useColorModeValue('white', 'gray.800');
    const modalTextColor = useColorModeValue('gray.800', 'white');
    const deleteButtonColor = useColorModeValue('red.500', 'red.300');
    const inputBgColor = useColorModeValue('white', 'gray.700');

    const isMobile = useBreakpointValue({ base: true, md: false });

    const fetchChatHistory = useCallback(async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const authToken = localStorage.getItem('token');
            const response = await axios.get(`${apiUrl}/api/chat-histories`, {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            setChatHistory(response.data.chatHistories);
            setFilteredHistory(response.data.chatHistories);
        } catch (error) {
            console.error('Error fetching chat histories:', error);
            toast({
                title: t('error'),
                description: t('errorFetchingChatHistories'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [t, toast]);

    useEffect(() => {
        fetchChatHistory();
    }, [fetchChatHistory]);

    const handleChatClick = useCallback((session) => {
        navigate('/chat-conversation', { state: { session } });
    }, [navigate]);

    const getSessionTitle = useCallback((session) => {
        if (session.title && session.title.trim() !== '') {
            return session.title;
        }
        return t('untitledChat');
    }, [t]);

    const handleDeleteClick = useCallback((e, session) => {
        e.stopPropagation();
        setSessionToDelete(session);
        setIsDeleting(true);
    }, []);

    const handleDeleteConfirm = useCallback(async () => {
        if (!sessionToDelete) return;

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const authToken = localStorage.getItem('token');

            await axios.delete(`${apiUrl}/api/chat-history/${sessionToDelete.session_id}`, {
                headers: { Authorization: `Bearer ${authToken}` }
            });

            setChatHistory(prevHistory => prevHistory.filter(session => session.session_id !== sessionToDelete.session_id));
            setFilteredHistory(prevFiltered => prevFiltered.filter(session => session.session_id !== sessionToDelete.session_id));

            toast({
                title: t('chatDeleted'),
                description: t('chatDeletedDescription'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting chat history:', error);
            toast({
                title: t('error'),
                description: t('errorDeletingChat'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsDeleting(false);
            setSessionToDelete(null);
        }
    }, [sessionToDelete, t, toast]);

    const handleDeleteCancel = useCallback(() => {
        setIsDeleting(false);
        setSessionToDelete(null);
    }, []);

    const debouncedSearch = useMemo(
        () =>
            debounce(async (query) => {
                setIsSearching(true);
                setSearchQuery(query);

                if (query.trim() === '') {
                    setFilteredHistory(chatHistory);
                    setIsSearching(false);
                    return;
                }

                try {
                    const apiUrl = process.env.REACT_APP_API_URL;
                    const authToken = localStorage.getItem('token');
                    const response = await axios.get(`${apiUrl}/api/search-chat-history?query=${query}`, {
                        headers: { Authorization: `Bearer ${authToken}` }
                    });
                    setFilteredHistory(response.data.results);
                } catch (error) {
                    console.error('Error searching chat history:', error);
                    toast({
                        title: t('error'),
                        description: t('errorSearchingChatHistory'),
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                } finally {
                    setIsSearching(false);
                }
            }, 300),
        [chatHistory, t, toast]
    );

    const handleSearch = useCallback((e) => {
        debouncedSearch(e.target.value);
    }, [debouncedSearch]);

    return (
        <Box bg={bgColor} color={textColor} minH="100vh" py={8} px={4}>
            <Container maxW="container.md">
                <ScaleFade initialScale={0.9} in={true}>
                    <Flex direction="column" align="stretch">
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                            onClick={() => navigate(-1)}
                            mb={8}
                            alignSelf="flex-start"
                            colorScheme="teal"
                            variant="outline"
                            borderRadius="full"
                            px={6}
                            py={3}
                            _hover={{ bg: accentColor, color: 'white' }}
                            transition="all 0.3s"
                        >
                            {t('goBack')}
                        </Button>

                        <Heading mb={6} fontSize={{ base: '2xl', md: '3xl' }} textAlign="center">
                            {t('chatHistory')}
                        </Heading>

                        <InputGroup mb={6}>
                            <InputLeftElement pointerEvents="none">
                                <Icon as={FontAwesomeIcon} icon={faSearch} color={accentColor} />
                            </InputLeftElement>
                            <Input
                                placeholder={t('searchChatHistory')}
                                onChange={handleSearch}
                                bg={inputBgColor}
                                borderRadius="full"
                                borderColor={accentColor}
                                _focus={{ borderColor: accentColor, boxShadow: `0 0 0 1px ${accentColor}` }}
                            />
                        </InputGroup>

                        <AnimatePresence>
                            {isSearching ? (
                                <Flex justify="center" align="center" height="200px">
                                    <SpinnerSvg size="xl" color={accentColor} />
                                </Flex>
                            ) : filteredHistory.length === 0 ? (
                                <MotionBox
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Text
                                        textAlign="center"
                                        fontSize="lg"
                                        fontStyle="italic"
                                        animation={`${fadeIn} 1s ease-in`}
                                    >
                                        {searchQuery ? t('noSearchResults') : t('noChatHistoryAvailable')}
                                    </Text>
                                </MotionBox>
                            ) : (
                                <VStack spacing={6} align="stretch">
                                    {filteredHistory.map((session) => (
                                        <MotionBox
                                            key={session.session_id}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -20 }}
                                            transition={{ duration: 0.3 }}
                                            layout
                                        >
                                            <Box
                                                p={6}
                                                bg={boxBgColor}
                                                borderRadius="2xl"
                                                boxShadow={boxShadow}
                                                cursor="pointer"
                                                onClick={() => handleChatClick(session)}
                                                _hover={{ transform: 'translateY(-4px)', boxShadow: '2xl' }}
                                                transition="all 0.3s"
                                                position="relative"
                                            >
                                                <Flex direction={isMobile ? 'column' : 'row'} justify="space-between" align={isMobile ? 'flex-start' : 'center'}>
                                                    <Flex align="center" mb={isMobile ? 2 : 0}>
                                                        <Icon as={FontAwesomeIcon} icon={faComments} mr={2} color={accentColor} />
                                                        <Text
                                                            fontWeight="bold"
                                                            fontSize="xl"
                                                            color={!session.title || session.title.trim() === '' ? noTitleColor : textColor}
                                                        >
                                                            {getSessionTitle(session)}
                                                        </Text>
                                                    </Flex>
                                                    <Flex align="center" fontSize="sm" color={accentColor} flexWrap="wrap">
                                                        <Flex align="center" mr={4} mb={isMobile ? 2 : 0}>
                                                            <Icon as={FontAwesomeIcon} icon={faCalendarAlt} mr={2} />
                                                            <Text>
                                                                {new Date(session.last_activity).toLocaleDateString()}
                                                            </Text>
                                                        </Flex>
                                                        <Flex align="center">
                                                            <Icon as={FontAwesomeIcon} icon={faClock} mr={2} />
                                                            <Text>
                                                                {new Date(session.last_activity).toLocaleTimeString()}
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Tooltip label={t('deleteChat')} placement="top">
                                                    <IconButton
                                                        icon={<FontAwesomeIcon icon={faTrash} />}
                                                        onClick={(e) => handleDeleteClick(e, session)}
                                                        position="absolute"
                                                        top={2}
                                                        right={2}
                                                        colorScheme="red"
                                                        variant="ghost"
                                                        size="sm"
                                                        borderRadius="full"
                                                        aria-label={t('deleteChat')}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        </MotionBox>
                                    ))}
                                </VStack>
                            )}
                        </AnimatePresence>
                    </Flex>
                </ScaleFade>
            </Container>

            <AlertDialog
                isOpen={isDeleting}
                leastDestructiveRef={cancelRef}
                onClose={handleDeleteCancel}
                motionPreset="scale"
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg={modalBgColor} color={modalTextColor} borderRadius="xl" boxShadow="2xl" mx={[4, 0]} maxW="400px">
                        <AlertDialogHeader fontSize="2xl" fontWeight="bold" textAlign="center" pt={6}>
                            <Icon as={FontAwesomeIcon} icon={faExclamationTriangle} color={deleteButtonColor} mr={3} />
                            {t('deleteChat')}
                        </AlertDialogHeader>
                        <Divider />
                        <AlertDialogBody fontSize="lg" py={6}>
                            {t('deleteChatConfirm')}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={handleDeleteCancel} variant="ghost" borderRadius="full" px={6}>
                                {t('cancel')}
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={handleDeleteConfirm}
                                ml={3}
                                borderRadius="full"
                                px={6}
                                _hover={{ bg: deleteButtonColor }}
                            >
                                {t('delete')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default ChatHistoryPage;