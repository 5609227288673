import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Text, VStack, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, Input, Button, Select, FormControl, FormLabel,
    Container, HStack, IconButton, SimpleGrid, Tooltip, Image, useColorModeValue,
    useDisclosure, ScaleFade, SlideFade, Tabs, TabList, TabPanels, Tab, TabPanel,
    Skeleton, Badge
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiSend, FiCopy, FiDollarSign, FiRefreshCw } from 'react-icons/fi';
import axios from 'axios';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ERC20_ABI from './abis/ERC20ABI.json';
import CryptoJS from 'crypto-js';
import defaultImage from '../images/default-coin.png';

const WalletPage = () => {
    const { t } = useTranslation();
    const [tokenBalances, setTokenBalances] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [recipientAddress, setRecipientAddress] = useState('');
    const [sendAmount, setSendAmount] = useState('');
    const [selectedToken, setSelectedToken] = useState('');
    const [walletPassword, setWalletPassword] = useState('');
    const [userId, setUserId] = useState('');
    const [userWalletAddress, setUserWalletAddress] = useState('');
    const [totalBalanceUSD, setTotalBalanceUSD] = useState(0);
    const navigate = useNavigate();

    const { isOpen: isSendModalOpen, onOpen: onSendModalOpen, onClose: onSendModalClose } = useDisclosure();
    const { isOpen: isPasswordModalOpen, onOpen: onPasswordModalOpen, onClose: onPasswordModalClose } = useDisclosure();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const primaryColor = '#00A4D3';
    const secondaryColor = '#00FFB9';

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const authToken = localStorage.getItem('token');
                if (!authToken) {
                    navigate('/login');
                    return;
                }
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setUserId(response.data.id);
                setUserWalletAddress(response.data.walletAddress);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, [navigate]);

    const fetchTokenPrices = async (walletAddress, chain) => {
        const moralisApiUrl = `https://deep-index.moralis.io/api/v2.2/wallets/${walletAddress}/tokens?chain=${chain}`;
        const moralisApiKey = process.env.REACT_APP_MORALIS_API_KEY;
        const headers = { 'accept': 'application/json', 'X-API-Key': moralisApiKey };

        try {
            const response = await axios.get(moralisApiUrl, { headers });
            return response.data.result;
        } catch (error) {
            console.error('Failed to fetch token prices:', error);
            return [];
        }
    };

    const fetchWalletBalances = useCallback(async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) return;

            const userProfileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const walletAddress = userProfileResponse.data.walletAddress;
            setUserWalletAddress(walletAddress);

            const supportedChains = {
                'eth': 'Ethereum',
                'bsc': 'Binance Smart Chain',
                'polygon': 'Polygon',
                'base': 'Base',
            };

            const balancesPromises = Object.keys(supportedChains).map(async (chainId) => {
                try {
                    const tokens = await fetchTokenPrices(walletAddress, chainId);
                    return tokens.map(token => ({
                        ...token,
                        balance: Web3.utils.fromWei(token.balance, 'ether'),
                        network: supportedChains[chainId],
                        usdPrice: token.usd_price || 0,
                        isNative: token.native_token || false,
                        possibleSpam: token.possible_spam || false,
                    }));
                } catch (error) {
                    console.error(`Failed to fetch balances for ${supportedChains[chainId]}:`, error);
                    return [];
                }
            });

            const balances = (await Promise.all(balancesPromises)).flat();

            setTokenBalances(balances);

            const totalBalance = balances.reduce((acc, token) => acc + (parseFloat(token.balance) * token.usdPrice), 0);
            setTotalBalanceUSD(totalBalance.toFixed(2));

            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch wallet balances:', error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchWalletBalances();
    }, [fetchWalletBalances]);

    const handleSendTokens = () => {
        if (!sendAmount || !selectedToken || !recipientAddress) {
            console.error('All fields are required.');
            return;
        }
        onSendModalClose();
        onPasswordModalOpen();
    };

    const rpcUrls = {
        Ethereum: process.env.REACT_APP_ETH_RPC_URL || "https://mainnet.infura.io/v3/8dc36f09afad40ef9084206ce9ddae5c",
        "Binance Smart Chain": process.env.REACT_APP_BSC_RPC_URL || "https://bsc-dataseed.binance.org/",
        Polygon: process.env.REACT_APP_POLYGON_RPC_URL || "https://polygon-rpc.com/",
        Base: process.env.REACT_APP_BASE_RPC_URL || "https://mainnet.base.org/",
    };

    const eip1559SupportedNetworks = {
        Ethereum: true,
        Polygon: true,
        "Binance Smart Chain": false,
    };

    async function fetchGasParameters(network, web3Instance) {
        if (eip1559SupportedNetworks[network]) {
            return {
                maxPriorityFeePerGas: web3Instance.utils.toWei('2', 'gwei'),
                maxFeePerGas: web3Instance.utils.toWei('100', 'gwei'),
            };
        } else {
            return {
                gasPrice: web3Instance.utils.toWei('20', 'gwei'),
            };
        }
    }

    const handleDecryptAndSend = async () => {
        onPasswordModalClose();

        if (!walletPassword || !userId) {
            console.error('Wallet password and user ID are required.');
            return;
        }

        try {
            const authToken = localStorage.getItem('token');
            if (!authToken) {
                console.error('Authentication token not found.');
                return;
            }

            const decryptResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/decrypt-private-key-for-frontend`, {
                password: walletPassword,
                userId,
            }, {
                headers: { Authorization: `Bearer ${authToken}` },
            });

            if (!decryptResponse.data || !decryptResponse.data.reEncryptedPrivateKey) {
                throw new Error("Failed to get the encrypted private key.");
            }

            const FRONTEND_ENCRYPTION_KEY = process.env.REACT_APP_FRONTEND_ENCRYPTION_KEY;
            const bytes = CryptoJS.AES.decrypt(decryptResponse.data.reEncryptedPrivateKey, FRONTEND_ENCRYPTION_KEY);
            const decryptedPrivateKey = bytes.toString(CryptoJS.enc.Utf8);

            if (!decryptedPrivateKey) {
                throw new Error("Failed to decrypt the private key.");
            }

            const privateKeyWithPrefix = decryptedPrivateKey.startsWith('0x') ? decryptedPrivateKey : `0x${decryptedPrivateKey}`;
            const privateKeyWithPrefixLower = privateKeyWithPrefix.toLowerCase();

            if (!selectedToken) {
                throw new Error("Selected token is undefined.");
            }

            if (!recipientAddress) {
                throw new Error("Recipient address is undefined.");
            }
            const recipientAddressLower = recipientAddress ? recipientAddress.toLowerCase() : '';

            let isNativeToken = false;
            let tokenToSend = {};
            if (selectedToken === 'ETH' || selectedToken === 'BNB' || selectedToken === 'MATIC') {
                isNativeToken = true;
            } else {
                tokenToSend = tokenBalances.find(token => token.symbol === selectedToken);
                if (!tokenToSend) {
                    throw new Error("Token not found.");
                }
            }

            const network = isNativeToken ? 'Ethereum' : tokenToSend.network;
            const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrls[network]));
            const account = web3.eth.accounts.privateKeyToAccount(privateKeyWithPrefixLower);
            web3.eth.accounts.wallet.add(account);

            const gasParameters = await fetchGasParameters(network, web3);

            const nonce = await web3.eth.getTransactionCount(account.address, 'latest');

            let transactionConfig = {};
            if (isNativeToken) {
                transactionConfig = {
                    from: account.address,
                    to: recipientAddressLower,
                    value: web3.utils.toWei(sendAmount.toString(), 'ether'),
                    nonce: nonce,
                    ...gasParameters,
                };
            } else {
                const tokenAddressLower = tokenToSend.token_address.toLowerCase();

                transactionConfig = {
                    from: account.address,
                    to: tokenAddressLower,
                    data: new web3.eth.Contract(ERC20_ABI, tokenAddressLower)
                        .methods.transfer(recipientAddressLower, web3.utils.toWei(sendAmount.toString(), 'ether'))
                        .encodeABI(),
                    nonce: nonce,
                    ...gasParameters,
                };
            }

            const signedTx = await web3.eth.accounts.signTransaction(transactionConfig, privateKeyWithPrefixLower);
            const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

            console.log('Transaction receipt:', receipt);
            setWalletPassword('');
        } catch (error) {
            console.error('Error decrypting the private key or sending the transaction:', error);
        }
    };

    const TopSection = () => (
        <ScaleFade in={true} initialScale={0.9}>
            <Box
                p={6}
                bgGradient={`linear(to-r, ${primaryColor}, ${secondaryColor})`}
                borderRadius="3xl"
                w="full"
                shadow="2xl"
                color="white"
                mb={6}
                position="relative"
                overflow="hidden"
            >
                <Box
                    position="absolute"
                    top="-50%"
                    left="-50%"
                    width="200%"
                    height="200%"
                    backgroundImage="radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)"
                    animation="pulse 4s infinite"
                />
                <VStack spacing={3} align="stretch">
                    <Text fontSize="sm" opacity={0.8}>{t('totalBalance')}</Text>
                    <Text fontSize="4xl" fontWeight="bold">{`$${totalBalanceUSD}`}</Text>
                    <HStack justifyContent="space-between" alignItems="center">
                        <Text fontSize="xs" opacity={0.8} isTruncated maxWidth="70%">
                            {userWalletAddress}
                        </Text>
                        <Tooltip label={t('copy')}>
                            <IconButton
                                icon={<FiCopy />}
                                variant="solid"
                                colorScheme="whiteAlpha"
                                onClick={() => navigator.clipboard.writeText(userWalletAddress)}
                                aria-label={t('copy')}
                                size="sm"
                            />
                        </Tooltip>
                    </HStack>
                </VStack>
            </Box>
        </ScaleFade>
    );

    const ActionButtons = () => (
        <SlideFade in={true} offsetY="20px">
            <SimpleGrid columns={3} spacing={4} w="full" mt={4} mb={6}>
                <VStack>
                    <IconButton
                        icon={<FiSend />}
                        color="white"
                        bg={primaryColor}
                        size="lg"
                        onClick={onSendModalOpen}
                        borderRadius="full"
                        shadow="md"
                        _hover={{ transform: 'scale(1.05)', shadow: 'lg', bg: secondaryColor }}
                        transition="all 0.2s"
                    />
                    <Text fontSize="sm">{t('send')}</Text>
                </VStack>
                <VStack>
                    <IconButton
                        icon={<FiDollarSign />}
                        color="white"
                        bg={primaryColor}
                        size="lg"
                        borderRadius="full"
                        shadow="md"
                        _hover={{ transform: 'scale(1.05)', shadow: 'lg', bg: secondaryColor }}
                        transition="all 0.2s"
                    />
                    <Text fontSize="sm">{t('buy')}</Text>
                </VStack>
                <VStack>
                    <IconButton
                        icon={<FiRefreshCw />}
                        color="white"
                        bg={primaryColor}
                        size="lg"
                        onClick={() => navigate('/swap')}
                        borderRadius="full"
                        shadow="md"
                        _hover={{ transform: 'scale(1.05)', shadow: 'lg', bg: secondaryColor }}
                        transition="all 0.2s"
                    />
                    <Text fontSize="sm">{t('swap')}</Text>
                </VStack>
            </SimpleGrid>
        </SlideFade>
    );

    const truncateSymbol = (symbol) => {
        return symbol.length > 6 ? symbol.slice(0, 6) + '...' : symbol;
    };

    const TokenList = () => (
        <AnimatePresence>
            <VStack spacing={4} w="full" align="stretch" mb={20}>
                {tokenBalances.map((token, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3, delay: index * 0.05 }}
                    >
                        <Box
                            p={4}
                            bg={cardBgColor}
                            borderRadius="2xl"
                            w="full"
                            shadow="lg"
                            position="relative"
                            overflow="hidden"
                            _hover={{ transform: 'translateY(-2px)', shadow: 'xl' }}
                            transition="all 0.2s"
                        >
                            <HStack justifyContent="space-between" alignItems="center">
                                <HStack spacing={4} flex={1}>
                                    <Image
                                        boxSize="40px"
                                        src={token.logo || defaultImage}
                                        alt={`${token.name} logo`}
                                        borderRadius="full"
                                        shadow="md"
                                    />
                                    <VStack align="flex-start" spacing={0.5} maxWidth="60%">
                                        <HStack>
                                            <Tooltip label={token.symbol}>
                                                <Text fontWeight="bold" fontSize="lg" color={textColor} isTruncated>
                                                    {truncateSymbol(token.symbol)}
                                                </Text>
                                            </Tooltip>
                                            {/* <Badge colorScheme="blue" fontSize="xs">{token.network}</Badge> */}
                                        </HStack>
                                        <Text fontSize="xs" color="gray.500" isTruncated>
                                            {token.name}
                                        </Text>
                                        <Badge colorScheme="blue" fontSize="xs">{truncateSymbol(token.network)}</Badge>
                                    </VStack>
                                </HStack>
                                <VStack align="flex-end" spacing={0.5}>
                                    <Text fontSize="lg" color={textColor} fontWeight="semibold">
                                        {`$${(parseFloat(token.balance) * token.usdPrice).toFixed(2)}`}
                                    </Text>
                                    <Text fontSize="xs" color="gray.500">
                                        {`${parseFloat(token.balance).toFixed(4)} ${truncateSymbol(token.symbol)}`}
                                    </Text>
                                </VStack>
                            </HStack>
                        </Box>
                    </motion.div>
                ))}
            </VStack>
        </AnimatePresence>
    );

    const AnimatedNFTComingSoon = () => (
        <Box w="full" h="200px" position="relative">
            <motion.svg
                width="100%"
                height="100%"
                viewBox="0 0 400 200"
                initial="hidden"
                animate="visible"
            >
                <motion.circle
                    cx="200"
                    cy="100"
                    r="50"
                    fill={primaryColor}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 1, yoyo: Infinity, ease: "easeInOut" }}
                />
                <motion.text
                    x="200"
                    y="105"
                    textAnchor="middle"
                    fill="white"
                    fontSize="16"
                    fontWeight="bold"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}
                >
                    {t('NFT Coming Soon')}
                </motion.text>
                <motion.text
                    x="200"
                    y="180"
                    textAnchor="middle"
                    fill={textColor}
                    fontSize="24"
                    fontWeight="bold"
                    initial={{ y: 220 }}
                    animate={{ y: 180 }}
                    transition={{ delay: 1, duration: 0.5, type: "spring", stiffness: 120 }}
                >
                    Coming Soon
                </motion.text>
            </motion.svg>
        </Box>
    );

    return (
        <Box minHeight="100vh" bg={bgColor} pb={20}>
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <TopSection />
                    <ActionButtons />

                    <Tabs isFitted variant="soft-rounded" colorScheme="blue">
                        <TabList mb="1em">
                            <Tab _selected={{ color: 'white', bg: primaryColor }}>{t('tokens')}</Tab>
                            <Tab _selected={{ color: 'white', bg: primaryColor }}>{t('nfts')}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {isLoading ? (
                                    <VStack spacing={4}>
                                        {[...Array(5)].map((_, i) => (
                                            <Skeleton key={i} height="80px" width="100%" borderRadius="2xl" />
                                        ))}
                                    </VStack>
                                ) : (
                                    <TokenList />
                                )}
                            </TabPanel>
                            <TabPanel>
                                <AnimatedNFTComingSoon />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <Modal isOpen={isSendModalOpen} onClose={onSendModalClose} isCentered>
                        <ModalOverlay />
                        <ModalContent borderRadius="xl" bg={cardBgColor} color={textColor} shadow="xl"
                            mx={[4, 0]}
                            maxW="400px">
                            <ModalHeader>{t('sendTokens.header')}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <VStack spacing={4}>
                                    <FormControl>
                                        <FormLabel>{t('sendTokens.selectToken')}</FormLabel>
                                        <Select
                                            placeholder={t('sendTokens.selectToken')}
                                            value={selectedToken}
                                            onChange={(e) => setSelectedToken(e.target.value)}
                                        >
                                            {tokenBalances.map((token, index) => (
                                                <option key={index} value={token.symbol}>
                                                    {`${truncateSymbol(token.symbol)} (${token.network})`}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>{t('sendTokens.recipientAddress')}</FormLabel>
                                        <Input
                                            placeholder={t('sendTokens.recipientAddress')}
                                            value={recipientAddress}
                                            onChange={(e) => setRecipientAddress(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>{t('sendTokens.amount')}</FormLabel>
                                        <Input
                                            placeholder={t('sendTokens.amount')}
                                            value={sendAmount}
                                            onChange={(e) => setSendAmount(e.target.value)}
                                        />
                                    </FormControl>
                                </VStack>
                            </ModalBody>
                            <ModalFooter>
                                <Button bg={primaryColor} color="white" mr={3} onClick={handleSendTokens} _hover={{ bg: secondaryColor }}>
                                    {t('sendTokens.send')}
                                </Button>
                                <Button variant="ghost" onClick={onSendModalClose}>{t('sendTokens.cancel')}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <Modal isOpen={isPasswordModalOpen} onClose={onPasswordModalClose} isCentered>
                        <ModalOverlay />
                        <ModalContent borderRadius="xl" bg={cardBgColor} color={textColor} shadow="xl"
                            mx={[4, 0]}
                            maxW="400px">
                            <ModalHeader>{t('enterWalletPassword')}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl>
                                    <FormLabel>{t('walletPassword')}</FormLabel>
                                    <Input
                                        type="password"
                                        value={walletPassword}
                                        onChange={(e) => setWalletPassword(e.target.value)}
                                        placeholder={t('enterYourWalletPassword')}
                                    />
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button bg={primaryColor} color="white" mr={3} onClick={handleDecryptAndSend} _hover={{ bg: secondaryColor }}>
                                    {t('submit')}
                                </Button>
                                <Button variant="ghost" onClick={onPasswordModalClose}>{t('cancel')}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </VStack>
            </Container>
        </Box>
    );
};

export default WalletPage;