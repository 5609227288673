import React from 'react';
import {
    Box,
    Text,
    Button,
    VStack,
    useColorModeValue
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const bgColor = useColorModeValue('gray.50', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'gray.100');

    return (
        <Box bg={bgColor} color={textColor} height="100%" display="flex" justifyContent="center" alignItems="center">
            <VStack spacing={4}>
                <Text fontSize="6xl" fontWeight="bold" textAlign="center">{t('not_found_title')}</Text>
                <Text fontSize="xl" textAlign="center">{t('not_found_message')}</Text>
                <Button colorScheme="blue" onClick={() => navigate('/')}>{t('go_home')}</Button>
            </VStack>
        </Box>
    );
};

export default NotFoundPage;
