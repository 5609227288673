import React from 'react';

const QuantumComputingPPT = () => (
  <div>
    <section>
      <h1 style={{ color: 'blue', fontSize: '36px', fontWeight: 'bold' }}>Introduction to Quantum Computing</h1>
    </section>

    <section>
      <h2 style={{ fontSize: '28px', fontWeight: 'bold' }}>What is Quantum Computing?</h2>
      <p style={{ fontSize: '20px' }}>
        Quantum computing is a type of computation that harnesses the collective properties of quantum states, such as superposition, interference, and entanglement, to perform calculations.
      </p>
    </section>

    <section>
      <h2 style={{ fontSize: '28px', fontWeight: 'bold' }}>Key Concepts</h2>
      <ul style={{ fontSize: '20px' }}>
        <li>1. Superposition</li>
        <li>2. Entanglement</li>
        <li>3. Quantum Gates</li>
      </ul>
    </section>

    <section>
      <h2 style={{ fontSize: '28px', fontWeight: 'bold' }}>Applications of Quantum Computing</h2>
      <ul style={{ fontSize: '20px' }}>
        <li>- Cryptography</li>
        <li>- Drug Discovery</li>
        <li>- Optimization Problems</li>
      </ul>
    </section>

    <section>
      <h2 style={{ fontSize: '28px', fontWeight: 'bold' }}>Challenges</h2>
      <ul style={{ fontSize: '20px' }}>
        <li>- Error Rates</li>
        <li>- Decoherence</li>
        <li>- Scalability</li>
      </ul>
    </section>

    <section>
      <h2 style={{ fontSize: '28px', fontWeight: 'bold' }}>Conclusion</h2>
      <p style={{ fontSize: '20px' }}>
        Quantum computing holds great promise for the future, but significant challenges remain. Continued research and development are crucial.
      </p>
    </section>
  </div>
);

export default QuantumComputingPPT;
