// ErrorSvg.js
import React from 'react';

const ErrorSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="100"
        height="100"
        fill="currentColor"
    >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </svg>
);

const ErrorMessage = ({ message }) => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <ErrorSvg />
        <p style={{ color: 'red', fontSize: '18px', marginTop: '10px' }}>{message}</p>
    </div>
);

export default ErrorMessage;
