import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
    Box, Button, Text, VStack, FormControl, FormLabel, SimpleGrid, Stack, Image, useToast, Flex, Heading, ScaleFade, SlideFade,
    Container, useMediaQuery, HStack, IconButton
} from '@chakra-ui/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer, faMap, faArrowUp, faRocket, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CustomSelect from './CustomSelect';
import HouseSVG from '../svg/House.svg';
import FactorySVG from '../svg/Factory.svg';
import FarmSVG from '../svg/Farm.svg';
import HospitalSVG from '../svg/Hospital.svg';
import UniversitySVG from '../svg/University.svg';
import MilitaryBaseSVG from '../svg/MilitaryBase.svg';
import logo from '../images/exocrest-logo.png';
import '../styles/styles.css';

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: '#0a0e17',
                color: '#e0e0e0',
            },
        },
    },
    components: {
        Button: {
            baseStyle: {
                borderRadius: 'full',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                _hover: {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 0 15px rgba(66, 220, 219, 0.5)',
                },
                _active: {
                    transform: 'translateY(0)',
                },
            },
            variants: {
                solid: {
                    bg: 'rgba(66, 220, 219, 0.2)',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.4)',
                    },
                },
                outline: {
                    bg: 'transparent',
                    color: '#42dcdb',
                    border: '2px solid #42dcdb',
                    _hover: {
                        bg: 'rgba(66, 220, 219, 0.1)',
                    },
                },
            },
        },
    },
});

const MotionBox = motion(Box);

const BuildingCreationPage = () => {
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [buildingType, setBuildingType] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const [resources, setResources] = useState([]);
    const [currencyBalance, setCurrencyBalance] = useState(null);
    const [currencyTicker, setCurrencyTicker] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
                setShowScrollButton(scrollTop > 100 && scrollTop + clientHeight < scrollHeight - 100);
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const fetchUserId = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUserId(response.data.id);
            } catch (error) {
                toast({
                    title: t('Error fetching user ID'),
                    description: error.response?.data?.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };
        fetchUserId();
    }, [toast, t]);

    useEffect(() => {
        const fetchCountries = async () => {
            if (!userId) return;
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${apiUrl}/api/planets/user-countries/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setCountries(response.data);
                if (response.data.length > 0) {
                    setSelectedCountry({ value: response.data[0].id, label: response.data[0].name });
                }
            } catch (error) {
                toast({
                    title: t('Error fetching countries'),
                    description: error.response?.data?.message || t('An error occurred'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        };
        fetchCountries();
    }, [toast, t, userId]);

    const fetchResources = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/resources`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setResources(response.data);
        } catch (error) {
            toast({
                title: t('Error fetching resources'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    const fetchCurrencyDetails = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/planets/currencies/${countryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.length > 0) {
                const currency = response.data[0];
                setCurrencyBalance(currency.totalBalance.toFixed(3));
                setCurrencyTicker(currency.ticker);
            }
        } catch (error) {
            toast({
                title: t('Error fetching currency details'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    const handleCreateBuilding = async () => {
        if (!selectedCountry || !buildingType) {
            toast({
                title: t('Missing Fields'),
                description: t('Please select both a country and a building type'),
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            await axios.post(
                `${apiUrl}/api/planets/create-building`,
                { countryId: selectedCountry.value, type: buildingType.value },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast({
                title: t('Building created successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchBuildings(selectedCountry.value);
            fetchResources(selectedCountry.value);
            fetchCurrencyDetails(selectedCountry.value);
        } catch (error) {
            toast({
                title: t('Error creating building'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpgradeBuilding = async (buildingId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            await axios.post(
                `${apiUrl}/api/planets/upgrade-building`,
                { buildingId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast({
                title: t('Building upgraded successfully'),
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            fetchBuildings(selectedCountry.value);
            fetchResources(selectedCountry.value);
            fetchCurrencyDetails(selectedCountry.value);
        } catch (error) {
            toast({
                title: t('Error upgrading building'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpgradeMaxBuilding = async (buildingId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        let upgradeCount = 0;
        let canUpgrade = true;

        while (canUpgrade) {
            try {
                await axios.post(
                    `${apiUrl}/api/planets/upgrade-building`,
                    { buildingId },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                upgradeCount++;
            } catch (error) {
                canUpgrade = false;
                if (error.response && error.response.status === 400) {
                    toast({
                        title: t('Max upgrade reached'),
                        description: t(`Upgraded ${upgradeCount} times. ${error.response.data.message}`),
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: t('Error during max upgrade'),
                        description: error.response?.data?.message || t('An error occurred'),
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            }
        }

        fetchBuildings(selectedCountry.value);
        fetchResources(selectedCountry.value);
        fetchCurrencyDetails(selectedCountry.value);
        setIsLoading(false);
    };

    const fetchBuildings = useCallback(async (countryId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        setIsLoading(true);

        try {
            const response = await axios.get(`${apiUrl}/api/planets/countries/${countryId}/buildings`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setBuildings(response.data);
        } catch (error) {
            toast({
                title: t('Error fetching buildings'),
                description: error.response?.data?.message || t('An error occurred'),
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [toast, t]);

    useEffect(() => {
        if (selectedCountry && selectedCountry.value) {
            fetchBuildings(selectedCountry.value);
            fetchResources(selectedCountry.value);
            fetchCurrencyDetails(selectedCountry.value);
        }
    }, [selectedCountry, fetchBuildings, fetchResources, fetchCurrencyDetails]);

    const buildingOptions = useMemo(() => [
        { value: 'House', label: 'House', icon: HouseSVG },
        { value: 'Factory', label: 'Factory', icon: FactorySVG },
        { value: 'Farm', label: 'Farm', icon: FarmSVG },
        { value: 'Hospital', label: 'Hospital', icon: HospitalSVG },
        { value: 'University', label: 'University', icon: UniversitySVG },
        { value: 'Military Base', label: 'Military Base', icon: MilitaryBaseSVG },
    ], []);

    useEffect(() => {
        if (buildingOptions.length > 0) {
            setBuildingType(buildingOptions[0]);
        }
    }, [buildingOptions]);

    const excrBalance = (resources.find(resource => resource.type === 'EXCR')?.quantity || 0).toFixed(3);

    const Header = () => (
        <ScaleFade initialScale={0.9} in={true}>
            <Flex alignItems="center" justifyContent="space-between" w="full" mb={6} flexDirection={isLargerThan768 ? "row" : "column"}>
                <Flex alignItems="center" mb={isLargerThan768 ? 0 : 4}>
                    <Image src={logo} alt="Logo" boxSize="50px" mr={2} />
                    <Text fontSize="2xl" fontWeight="bold" color="#42dcdb">
                        {t('Exocrest')}
                    </Text>
                </Flex>
                <HStack spacing={4} flexWrap="wrap" justifyContent={isLargerThan768 ? "flex-end" : "center"}>
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faMap} />}
                        onClick={() => navigate('/country')}
                        size="sm"
                    >
                        {t('Country Map')}
                    </Button>
                    <Button
                        onClick={() => navigate('/dashboard')}
                        size="sm"
                        variant="outline"
                    >
                        {t('Back to Dashboard')}
                    </Button>
                </HStack>
            </Flex>
        </ScaleFade>
    );

    const BuildingCard = ({ building }) => {
        let icon;
        switch (building.type) {
            case 'House': icon = HouseSVG; break;
            case 'Factory': icon = FactorySVG; break;
            case 'Farm': icon = FarmSVG; break;
            case 'Hospital': icon = HospitalSVG; break;
            case 'University': icon = UniversitySVG; break;
            case 'Military Base': icon = MilitaryBaseSVG; break;
            default: icon = HouseSVG;
        }

        return (
            <MotionBox
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                bg="rgba(66, 220, 219, 0.1)"
                borderRadius="xl"
                p={4}
                boxShadow="0 4px 6px rgba(66, 220, 219, 0.1)"
                border="1px solid rgba(66, 220, 219, 0.3)"
            >
                <VStack spacing={2} align="stretch">
                    <Image src={icon} alt={building.type} boxSize="50px" mb={2} />
                    <Text fontWeight="bold" color="#42dcdb">{t('Building Type')}: {building.type}</Text>
                    <Text color="gray.300">{t('Level')}: {building.level}</Text>
                    <Text color="gray.300">{t('Country')}: {building.countryName}</Text>
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faArrowUp} />}
                        onClick={() => handleUpgradeBuilding(building.id)}
                        isLoading={isLoading}
                        size="sm"
                        mt={2}
                    >
                        {t('Upgrade')}
                    </Button>
                    <Button
                        leftIcon={<FontAwesomeIcon icon={faRocket} />}
                        onClick={() => handleUpgradeMaxBuilding(building.id)}
                        isLoading={isLoading}
                        size="sm"
                        variant="outline"
                    >
                        {t('Upgrade Max')}
                    </Button>
                </VStack>
            </MotionBox>
        );
    };

    const ResourceCard = ({ resource }) => (
        <MotionBox
            whileHover={{ scale: 1.05 }}
            bg="rgba(66, 220, 219, 0.1)"
            borderRadius="xl"
            p={4}
            boxShadow="0 4px 6px rgba(66, 220, 219, 0.1)"
            border="1px solid rgba(66, 220, 219, 0.3)"
        >
            <Text fontWeight="bold" color="#42dcdb">{t('Resource Type')}: {resource.type}</Text>
            <Text color="gray.300">{t('Quantity')}: {parseFloat(resource.quantity).toFixed(3)}</Text>
        </MotionBox>
    );

    return (
        <ChakraProvider theme={theme}>
            <Box
                ref={containerRef}
                bg="linear-gradient(135deg, #0d0d19 0%, #1e1e2e 100%)"
                minHeight="100vh"
                overflowY="auto"
                position="relative"
                px={4}
                py={8}
            >
                <Container maxW={isLargerThan768 ? "container.xl" : "100%"}>
                    <VStack spacing={8} align="stretch">
                        <Header />
                        <SlideFade in={true} offsetY="20px">
                            <Heading fontSize="3xl" fontWeight="bold" textAlign="center" mb={4} color="#42dcdb">
                                {t('Create or Upgrade Buildings')}
                            </Heading>
                        </SlideFade>
                        <MotionBox
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <FormControl>
                                <FormLabel color="#42dcdb">{t('Select Country')}</FormLabel>
                                <CustomSelect
                                    options={countries.map((country) => ({ value: country.id, label: country.name }))}
                                    selectedOption={selectedCountry}
                                    setSelectedOption={setSelectedCountry}
                                    placeholder={t('Select a country')}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            background: "rgba(66, 220, 219, 0.1)",
                                            borderColor: "#42dcdb",
                                            borderRadius: "full",
                                            padding: "2px",
                                            boxShadow: "none",
                                            "&:hover": {
                                                borderColor: "#42dcdb",
                                            },
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            background: "rgba(10, 14, 23, 0.9)",
                                            borderRadius: "xl",
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            background: state.isSelected ? "rgba(66, 220, 219, 0.2)" : "transparent",
                                            color: state.isSelected ? "#42dcdb" : "#e0e0e0",
                                            "&:hover": {
                                                background: "rgba(66, 220, 219, 0.1)",
                                            },
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#42dcdb",
                                        }),
                                    }}
                                />
                            </FormControl>
                        </MotionBox>
                        <MotionBox
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            <SimpleGrid columns={[1, null, 2]} spacing={4}>
                                <Box
                                    p={4}
                                    bg="rgba(66, 220, 219, 0.1)"
                                    borderRadius="xl"
                                    border="1px solid rgba(66, 220, 219, 0.3)"
                                >
                                    <Text fontSize="xl" fontWeight="bold" color="#42dcdb">
                                        {t('EXCR Balance')}: {excrBalance}
                                    </Text>
                                </Box>
                                {currencyTicker && currencyBalance && (
                                    <Box
                                        p={4}
                                        bg="rgba(66, 220, 219, 0.1)"
                                        borderRadius="xl"
                                        border="1px solid rgba(66, 220, 219, 0.3)"
                                    >
                                        <Text fontSize="xl" fontWeight="bold" color="#42dcdb">
                                            {currencyTicker} {t('Balance')}: {currencyBalance}
                                        </Text>
                                    </Box>
                                )}
                            </SimpleGrid>
                        </MotionBox>
                        <MotionBox
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <FormControl>
                                <FormLabel color="#42dcdb">{t('Select Building Type')}</FormLabel>
                                <CustomSelect
                                    options={buildingOptions.map(option => ({
                                        value: option.value,
                                        label: (
                                            <Stack direction="row" alignItems="center">
                                                <Image src={option.icon} alt={option.label} boxSize="1.5rem" />
                                                <Text>{option.label}</Text>
                                            </Stack>
                                        ),
                                    }))}
                                    selectedOption={buildingType}
                                    setSelectedOption={setBuildingType}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            background: "rgba(66, 220, 219, 0.1)",
                                            borderColor: "#42dcdb",
                                            borderRadius: "full",
                                            padding: "2px",
                                            boxShadow: "none",
                                            "&:hover": {
                                                borderColor: "#42dcdb",
                                            },
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            background: "rgba(10, 14, 23, 0.9)",
                                            borderRadius: "xl",
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            background: state.isSelected ? "rgba(66, 220, 219, 0.2)" : "transparent",
                                            color: state.isSelected ? "#42dcdb" : "#e0e0e0",
                                            "&:hover": {
                                                background: "rgba(66, 220, 219, 0.1)",
                                            },
                                        }),
                                        singleValue: (provided) => ({
                                            ...provided,
                                            color: "#42dcdb",
                                        }),
                                    }}
                                />
                            </FormControl>
                        </MotionBox>
                        <MotionBox
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.6 }}
                        >
                            <Button
                                leftIcon={<FontAwesomeIcon icon={faHammer} />}
                                onClick={handleCreateBuilding}
                                isLoading={isLoading}
                                w="full"
                                size="lg"
                            >
                                {t('Create Building')}
                            </Button>
                        </MotionBox>
                        {resources.length > 0 && (
                            <MotionBox
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                            >
                                <Text fontSize="2xl" fontWeight="bold" color="#42dcdb" mb={4}>
                                    {t('Country Resources')}
                                </Text>
                                <SimpleGrid columns={[1, null, 2, 3]} spacing={4}>
                                    <AnimatePresence>
                                        {resources.map((resource, index) => (
                                            <MotionBox
                                                key={resource.type}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.3, delay: index * 0.1 }}
                                            >
                                                <ResourceCard resource={resource} />
                                            </MotionBox>
                                        ))}
                                    </AnimatePresence>
                                </SimpleGrid>
                            </MotionBox>
                        )}
                        {buildings.length > 0 ? (
                            <MotionBox
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 1 }}
                            >
                                <Text fontSize="2xl" fontWeight="bold" color="#42dcdb" mb={4}>
                                    {t('Existing Buildings')}
                                </Text>
                                <SimpleGrid columns={[1, null, 2, 3]} spacing={4}>
                                    <AnimatePresence>
                                        {buildings.map((building, index) => (
                                            <MotionBox
                                                key={building.id}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.3, delay: index * 0.1 }}
                                            >
                                                <BuildingCard building={building} />
                                            </MotionBox>
                                        ))}
                                    </AnimatePresence>
                                </SimpleGrid>
                            </MotionBox>
                        ) : (
                            <Text mt={6} fontSize="lg" textAlign="center" color="gray.500">
                                {t('No buildings available.')}
                            </Text>
                        )}
                    </VStack>
                </Container>
                <AnimatePresence>
                    {showScrollButton && (
                        <MotionBox
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            position="fixed"
                            bottom="20px"
                            right="20px"
                            zIndex={1000}
                        >
                            <IconButton
                                icon={<FontAwesomeIcon icon={faChevronDown} />}
                                onClick={scrollToBottom}
                                colorScheme="blue"
                                size="lg"
                                isRound
                                aria-label="Scroll to bottom"
                                _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0 0 15px rgba(66, 220, 219, 0.5)',
                                }}
                            />
                        </MotionBox>
                    )}
                </AnimatePresence>
            </Box>
        </ChakraProvider>
    );
};

export default BuildingCreationPage;
