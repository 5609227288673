import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Link, Text, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TutorialModal = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const hasSeen = localStorage.getItem('hasSeen');
        if (!hasSeen) {
            setIsOpen(true);
            localStorage.setItem('hasSeen', 'true');
        }
    }, []);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay />
            <ModalContent borderRadius="lg"
                shadow="xl"
                mx={[4, 0]}
                maxW="400px" >
                <ModalHeader>{t('tutorialModal.title')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text mb={4}>{t('tutorialModal.intro')}</Text>
                    <Box mb={2}>
                        <Text as="span" fontWeight="bold">{t('tutorialModal.stepOne.title')}</Text>
                        <Text as="span"> {t('tutorialModal.stepOne.description')}</Text>
                    </Box>
                    <Box mb={2}>
                        <Text as="span" fontWeight="bold">{t('tutorialModal.stepTwo.title')}</Text>
                        <Text as="span"> {t('tutorialModal.stepTwo.description')}</Text>
                    </Box>
                    <Text mb={4}>
                        {t('tutorialModal.conclusion')}
                        <Link color="teal.500" href="https://accounts.binance.info/register?ref=35903685" isExternal> Binance</Link>
                        {` ${t('or')} `}
                        <Link color="teal.500" href="https://www.bybit.com/invite?ref=RED9X4" isExternal>ByBit</Link>.
                    </Text>
                    {/* New instruction for trade pairs */}
                    <Text>
                        {t('tradePairNote')}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        {t('tutorialModal.button')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default TutorialModal;
