import React, { useState } from 'react';
import {
    Container,
    Heading,
    VStack,
    Box,
    Flex,
    Text,
    Icon
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons'; // Import the ChevronDownIcon from Chakra UI

import MenuListDropdown from './MenuListDropdown';

const AutoSwapGuide = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(0); // Initialize with the index of the first FAQ item

    const handleClick = (index) => {
        if (open === index) {
            setOpen(null);
        } else {
            setOpen(index);
        }
    };

    return (
        <Container maxW="container.md" py={4} centerContent overflowY="auto" height="100%">
            <Flex
                direction="row"
                justify="space-between"
                align="center"
                width="100%"
                mb="5"
            >
                <Heading as="h1">{t('autoSwapPageTitle')}</Heading>
                <MenuListDropdown />
            </Flex>
            <Box maxW="md" width="100%" overflowY="auto">
                <VStack spacing={4} align="start" width="100%">
                    {Array.from(Array(5).keys()).map((index) => (
                        <Box key={index} width="100%">
                            <Flex
                                align="center"
                                onClick={() => handleClick(index)}
                                cursor="pointer"
                                _hover={{ textDecoration: 'underline' }}
                            >
                                <Heading as="h2" size="md" mb={2}>
                                    {t(`faqQuestion${index + 1}`)}
                                </Heading>
                                <Icon as={ChevronDownIcon} ml={2} color={open === index ? 'blue.500' : 'gray.500'} />
                            </Flex>
                            {open === index && (
                                <Text>{t(`faqAnswer${index + 1}`)}</Text>
                            )}
                        </Box>
                    ))}
                </VStack>
            </Box>
        </Container>
    );
};

export default AutoSwapGuide;
