import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Box, Button, VStack, HStack, Icon, useColorModeValue } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FaApple, FaAndroid, FaGlobe } from 'react-icons/fa';

const MotionBox = motion(Box);

const InstallPWA = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [deviceType, setDeviceType] = useState('');

    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const buttonColorScheme = useColorModeValue('teal', 'cyan');
    const borderG = useColorModeValue('gray.200', 'gray.700');

    useEffect(() => {
        const hasVisited = localStorage.getItem('pwaInstructionsShown');
        const userAgent = navigator.userAgent;

        if (!hasVisited) {
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setDeviceType('iOS');
            } else if (/Android/.test(userAgent)) {
                setDeviceType('Android');
            } else {
                setDeviceType('generic');
            }

            setIsOpen(true);
            localStorage.setItem('pwaInstructionsShown', 'true');
        }
    }, []);

    const onClose = () => {
        setIsOpen(false);
    };

    const getDeviceIcon = () => {
        switch (deviceType) {
            case 'iOS':
                return FaApple;
            case 'Android':
                return FaAndroid;
            default:
                return FaGlobe;
        }
    };

    const modalVariants = {
        hidden: { opacity: 0, y: 50, scale: 0.95 },
        visible: { opacity: 1, y: 0, scale: 1, transition: { duration: 0.3, ease: 'easeOut' } },
        exit: { opacity: 0, y: 50, scale: 0.95, transition: { duration: 0.2, ease: 'easeIn' } },
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
                    <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
                    <MotionBox
                        as={ModalContent}
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        borderRadius="2xl"
                        shadow="2xl"
                        mx={[4, 0]}
                        maxW="400px"
                        bg={bgColor}
                        color={textColor}
                        overflow="hidden"
                    >
                        <ModalHeader
                            borderBottom="1px"
                            borderColor={borderG}
                            pb={4}
                        >
                            <HStack spacing={3}>
                                <Icon as={getDeviceIcon()} w={6} h={6} color={buttonColorScheme} />
                                <Text fontSize="xl" fontWeight="bold">{t('installModal.title')}</Text>
                            </HStack>
                        </ModalHeader>
                        <ModalCloseButton size="lg" />
                        <ModalBody py={6}>
                            <VStack spacing={6} align="stretch">
                                <Text fontSize="md" lineHeight="tall">
                                    {deviceType === 'iOS' && t('installModal.iOSInstructions')}
                                    {deviceType === 'Android' && t('installModal.androidInstructions')}
                                    {deviceType === 'generic' && t('installModal.genericInstructions')}
                                </Text>
                                <Box>
                                    <Button
                                        colorScheme={buttonColorScheme}
                                        size="lg"
                                        width="full"
                                        borderRadius="full"
                                        onClick={onClose}
                                        _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                        transition="all 0.2s"
                                    >
                                        {t('gotIt')}
                                    </Button>
                                </Box>
                            </VStack>
                        </ModalBody>
                    </MotionBox>
                </Modal>
            )}
        </AnimatePresence>
    );
};

export default InstallPWA;