import React, { useState, useEffect } from 'react';
import {
    Box, Container, VStack, Heading, Text, Flex, useToast,
    Button, Input, FormControl, FormLabel, useColorModeValue,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
    ModalBody, ModalCloseButton, Link
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CustomSelect from './CustomSelect'; // Import the CustomSelect component

const PoolFinancePage = () => {
    const [amount, setAmount] = useState('');
    const [duration, setDuration] = useState({ value: '1 week', label: '1 Week' });
    const [network, setNetwork] = useState({ value: 'Base', label: 'Base' });
    const [userId, setUserId] = useState(null);
    const [usdtBalance, setUsdtBalance] = useState(0); // USDT balance state
    const [investments, setInvestments] = useState([]);
    const [batchDetails, setBatchDetails] = useState([]);
    const [userProfits, setUserProfits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [welcomeModalOpen, setWelcomeModalOpen] = useState(true); // Welcome modal state
    const [error, setError] = useState(null);
    const toast = useToast();
    const { t } = useTranslation();
    const isValidAmount = amount > 0;

    const durationOptions = [
        { value: '1 week', label: t('oneWeek') },
        // { value: '2 weeks', label: t('twoWeeks') },
        // { value: '1 month', label: t('oneMonth') },
    ];

    const networkOptions = [
        { value: 'Base', label: t('base') },
        { value: 'Binance Smart Chain', label: t('bsc') },
        { value: 'OneLedger', label: t('oneLedger') },
    ];

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-profile`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setUserId(response.data.id);
            } catch (error) {
                console.error('Error fetching user profile:', error);
                setError(t('errorFetchingUserProfile'));
            }
        };
        fetchUserId();
    }, [t]);

    const fetchUsdtBalance = async (selectedNetwork) => {
        if (!userId || !selectedNetwork) return;
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/pooled-finance/usdt-balance`, {
                params: {
                    userId,
                    network: selectedNetwork
                },
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setUsdtBalance(parseFloat(response.data.balance).toFixed(3));
        } catch (error) {
            console.error('Error fetching USDT balance:', error);
            setError(t('errorFetchingUsdtBalance'));
        }
    };

    const handleSubscribe = async () => {
        if (!isValidAmount || amount < 25) {
            toast({
                title: t('invalidAmount'),
                description: t('enterValidAmount'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const selectedBatch = batchDetails.find(batch => batch.duration === duration.value);
        if (selectedBatch && (new Date(selectedBatch.end) - new Date()) / (new Date(selectedBatch.end) - new Date(selectedBatch.start)) < 0.8) {
            toast({
                title: t('tooLateToSubscribe'),
                description: t('tooLateToSubscribeDescription'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (investments.some(investment => investment.duration === duration.value && investment.network === network.value && new Date(investment.batchEnd) > new Date())) {
            toast({
                title: t('alreadySubscribed'),
                description: t('alreadySubscribedDescription'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);
        setError(null);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/pooled-finance/invest`, {
                userId,
                amount,
                duration: duration.value,
                network: network.value
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            toast({
                title: t('investmentSuccessful'),
                description: t('investmentProcessed'),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            fetchDashboardData();
            setModalOpen(false);
        } catch (error) {
            console.error('Error processing investment:', error);
            setError(error.response?.data?.message || t('errorProcessingInvestment'));
        } finally {
            setLoading(false);
        }
    };

    const handleWithdraw = async (investmentId, network) => {
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/pooled-finance/withdraw`, {
                userId,
                investmentId,
                network,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            toast({
                title: t('withdrawalSuccessful'),
                description: t('withdrawalProcessed'),
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            fetchDashboardData();
        } catch (error) {
            console.error('Error processing withdrawal:', error);
            toast({
                title: t('errorProcessingWithdrawal'),
                description: error.response?.data?.message || t('withdrawalError'),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchDashboardData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/pooled-finance/dashboard/${userId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setInvestments(response.data.userInvestments);
            setBatchDetails(response.data.batchDetails.map(batch => ({
                ...batch,
                start: new Date(batch.start),
                end: new Date(batch.end)
            })));
            setUserProfits(response.data.userProfits);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            setError(t('errorFetchingDashboard'));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userId) {
            fetchDashboardData();
            const interval = setInterval(fetchDashboardData, 60000); // Refresh every minute
            return () => clearInterval(interval);
        }
    }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const interval = setInterval(() => {
            setBatchDetails((prevDetails) => prevDetails.map(batch => ({
                ...batch,
                timeRemaining: getTimeRemaining(batch.end)
            })));
        }, 1000);
        return () => clearInterval(interval);
    }, [batchDetails]);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => setError(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const bg = useColorModeValue('gray.50', 'gray.800');
    const cardBg = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
    const accentColor = useColorModeValue('teal.400', 'teal.200');

    const getTimeRemaining = (end) => {
        const total = Date.parse(end) - Date.now();
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <React.Fragment>
            <style>{`
                .glow-button {
                    animation: glow 1.5s infinite alternate;
                    box-shadow: 0 0 10px #3c9, 0 0 20px #3c9, 0 0 30px #3c9;
                }

                @keyframes glow {
                    from {
                        box-shadow: 0 0 10px #3c9, 0 0 20px #3c9, 0 0 30px #3c9;
                    }
                    to {
                        box-shadow: 0 0 20px #3c9, 0 0 40px #3c9, 0 0 60px #3c9;
                    }
                }

                .usdt-balance {
                    font-size: 1.25rem;
                    font-weight: bold;
                    color: #00ff7f;
                    background: #2D3748;
                    padding: 10px;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0, 255, 127, 0.5);
                    text-align: center;
                }
            `}</style>
            <Container maxW="container.lg" py={8} bg={bg} borderRadius="lg" boxShadow="2xl" minHeight="300vh">
                <Flex justify="space-between" alignItems="center" mb={4}>
                    <Heading size="lg" color={accentColor} fontWeight="bold">{t('pooledFinanceTitle')}</Heading>
                </Flex>
                <VStack spacing={8} align="stretch">
                    <Heading size="md" color={accentColor} fontWeight="bold" mb={4}>{t('yourInvestments')}</Heading>
                    {investments.length > 0 ? investments.map((investment, index) => (
                        <Box key={`${investment.id}-${index}`} p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBg} mb={4} bgGradient="linear(to-r, #1a202c, #2d3748)">
                            <VStack spacing={4} align="stretch">
                                <Text fontSize="md" color={textColor}><b>{t('amount')}:</b> ${investment.amount}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('duration')}:</b> {investment.duration}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('network')}:</b> {investment.network}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('transactionHash')}:</b>
                                    <Link href={`${blockchainExplorers[investment.network]}/tx/${investment.transactionHash}`} isExternal color="teal.300">
                                        {investment.transactionHash}
                                    </Link>
                                </Text>
                                {new Date(investment.batchEnd) < new Date() && (
                                    <Button
                                        colorScheme="teal"
                                        size="sm"
                                        onClick={() => handleWithdraw(investment.id, investment.network)}
                                    >
                                        {t('withdraw')}
                                    </Button>
                                )}
                            </VStack>
                        </Box>
                    )) : (
                        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBg} mb={4} textAlign="center"
                            borderColor="gray.700"
                            bgGradient="linear(to-r, gray.800, gray.900)">
                            <VStack spacing={4} align="center">
                                <svg width="64" height="64" fill="currentColor" viewBox="0 0 16 16" className="bi bi-info-circle">
                                    <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm0 13A6 6 0 1 1 8 2a6 6 0 0 1 0 12zm.93-8.412-.73-.416a.58.58 0 0 0-.808.21.552.552 0 0 0 .11.832l.517.294v3.654a.5.5 0 0 0 1 0V5.387a.5.5 0 0 0-.09-.299.552.552 0 0 0-.292-.161zm-.77 4.825a.5.5 0 0 0-.49.6c.02.11.09.2.184.247a.552.552 0 0 0 .832-.11.582.582 0 0 0-.11-.832l-.416-.23zM7 4.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                                </svg>
                                <Text fontSize="lg" color="white">{t('noInvestments')}</Text>
                            </VStack>
                        </Box>
                    )}

                    <Button
                        className="glow-button"
                        colorScheme="teal"
                        onClick={() => setModalOpen(true)}
                        size="lg"
                        width="full"
                        borderRadius="lg"
                        boxShadow="md"
                        mt={4}
                    >
                        {t('subscribe')}
                    </Button>

                    <Heading size="md" color={accentColor} fontWeight="bold" mb={4}>{t('yourProfits')}</Heading>
                    {userProfits.length > 0 ? userProfits.map((profit, index) => (
                        <Box key={`${profit.network}-${index}`} p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBg} mb={4} bgGradient="linear(to-r, #1a202c, #2d3748)">
                            <VStack spacing={4} align="stretch">
                                <Text fontSize="md" color={textColor}><b>{t('network')}:</b> {profit.network}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('amountInvested')}:</b> ${profit.amount.toFixed(2)}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('currentBalance')}:</b> ${profit.currentBalance.toFixed(2)}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('profitOrLoss')}:</b> ${profit.profitOrLoss.toFixed(2)}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('profitOrLossPercentage')}:</b> {profit.profitOrLossPercentage.toFixed(2)}%</Text>
                                <Text fontSize="md" color={textColor}><b>{t('batch')}:</b> {profit.batch}</Text>
                            </VStack>
                        </Box>
                    )) : (
                        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBg} mb={4} textAlign="center"
                            borderColor="gray.700"
                            bgGradient="linear(to-r, gray.800, gray.900)">
                            <VStack spacing={4} align="center">
                                <svg width="64" height="64" fill="currentColor" viewBox="0 0 16 16" className="bi bi-info-circle">
                                    <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm0 13A6 6 0 1 1 8 2a6 6 0 0 1 0 12zm.93-8.412-.73-.416a.58.58 0 0 0-.808.21.552.552 0 0 0 .11.832l.517.294v3.654a.5.5 0 0 0 1 0V5.387a.5.5 0 0 0-.09-.299.552.552 0 0 0-.292-.161zm-.77 4.825a.5.5 0 0 0-.49.6c.02.11.09.2.184.247a.552.552 0 0 0 .832-.11.582.582 0 0 0-.11-.832l-.416-.23zM7 4.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                                </svg>
                                <Text fontSize="lg" color="white">{t('noProfits')}</Text>
                            </VStack>
                        </Box>
                    )}

                    <Heading size="md" color={accentColor} fontWeight="bold" mb={4}>{t('batchDetails')}</Heading>
                    {batchDetails.length > 0 ? batchDetails.map((batch, index) => (
                        <Box key={`${batch.duration}-${index}`} p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={cardBg} mb={4} rounded="lg"
                            borderColor="gray.700"
                            bgGradient="linear(to-r, gray.800, gray.900)">
                            <VStack spacing={4} align="stretch">
                                <Text fontSize="lg" color={accentColor} fontWeight="bold">{t(batch.duration)}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('startDate')}:</b> {batch.start.toLocaleDateString()}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('endDate')}:</b> {batch.end.toLocaleDateString()}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('timeRemaining')}:</b> {batch.timeRemaining}</Text>
                                <Text fontSize="md" color={textColor}><b>{t('totalInvested')}:</b> ${batch.totalAmount}</Text>
                            </VStack>
                        </Box>
                    )) : (
                        <Text color={textColor} fontSize="lg">{t('noBatchDetails')}</Text>
                    )}
                </VStack>
            </Container>

            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent borderRadius="lg"
                    shadow="xl"
                    mx={[4, 0]}
                    maxW="400px">
                    <ModalHeader>{t('subscribeToInvestment')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {error && (
                            <Box mb={4} p={4} bg="red.50" borderRadius="md" color="red.700">
                                <Text>{error}</Text>
                            </Box>
                        )}
                        {usdtBalance > 0 && (
                            <Box mb={4} p={1} className="usdt-balance">
                                <Text>
                                    {t('usdtBalance')}: ${usdtBalance}
                                </Text>
                            </Box>
                        )}
                        <FormControl id="network" isRequired>
                            <FormLabel>{t('selectNetwork')}</FormLabel>
                            <CustomSelect
                                options={networkOptions}
                                selectedOption={network}
                                setSelectedOption={(selectedOption) => {
                                    setNetwork(selectedOption);
                                    fetchUsdtBalance(selectedOption.value);
                                }}
                            />
                        </FormControl>
                        <FormControl id="amount" isRequired mt={4}>
                            <FormLabel>{t('enterAmount')}</FormLabel>
                            <Input
                                placeholder={t('amountPlaceholder')}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="duration" isRequired mt={4}>
                            <FormLabel>{t('selectDuration')}</FormLabel>
                            <CustomSelect
                                options={durationOptions}
                                selectedOption={duration}
                                setSelectedOption={setDuration}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" onClick={handleSubscribe} isLoading={loading}>
                            {t('subscribe')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={welcomeModalOpen} onClose={() => setWelcomeModalOpen(false)} isCentered>
                <ModalOverlay />
                <ModalContent borderRadius="lg"
                    shadow="xl"
                    mx={[4, 0]}
                    maxW="400px">
                    <ModalHeader>{t('welcomePoolTitle')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={4}>{t('welcomeMessage')}</Text>
                        <Text mb={4}>{t('welcomeDescription')}</Text>
                        <Text mb={4}>{t('profitDistribution')}</Text>
                        <Text>{t('disclaimer')}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" onClick={() => setWelcomeModalOpen(false)}>
                            {t('gotIt')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
};

const blockchainExplorers = {
    'Base': 'https://explorer.base.org',
    'Binance Smart Chain': 'https://bscscan.com',
    'OneLedger': 'https://explorer.oneledger.network'
};

export default PoolFinancePage;
