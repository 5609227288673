import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Stack,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useToast,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCopy, 
  faRedo, 
  faPlusCircle, 
  faWandMagicSparkles, 
  faEnvelope, 
  faCode, 
  faFileAlt, 
  faShareAlt,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import SpinnerSvg from './Spinner';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';

const PromptGenerationPage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [description, setDescription] = useState('');
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Logo colors
  const primaryColor = '#00FFB9';
  const secondaryColor = '#00A4D3';

  // Ultra-modern color palette using useColorModeValue and logo colors
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const accentColor = useColorModeValue(secondaryColor, primaryColor);
  const generatedPromptBg = useColorModeValue('white', 'gray.800');
  const buttonBgColor = useColorModeValue('white', 'gray.700');
  const buttonHoverBgColor = useColorModeValue('gray.100', 'gray.600');

  // Responsive design
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const iconButtonSize = useBreakpointValue({ base: 'md', md: 'lg' });

  const handleGeneratePrompt = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${apiUrl}/api/generate-prompt`,
        { taskDescription: description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGeneratedPrompt(response.data.highQualityPrompt);
      toast({
        title: t('Prompt generated successfully'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error generating prompt:', error);
      toast({
        title: t('Error generating prompt'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyPrompt = () => {
    navigator.clipboard.writeText(generatedPrompt);
    toast({
      title: t('Prompt copied to clipboard'),
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleRegeneratePrompt = () => {
    setDescription(generatedPrompt);
    setGeneratedPrompt('');
    handleGeneratePrompt();
  };

  const handleGenerateNewPrompt = () => {
    setDescription('');
    setGeneratedPrompt('');
  };

  const handleQuickInput = (text) => {
    setDescription(text);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const CustomButton = ({ icon, children, onClick, colorScheme }) => (
    <Button
      leftIcon={<FontAwesomeIcon icon={icon} />}
      onClick={onClick}
      bg={buttonBgColor}
      color={textColor}
      _hover={{ bg: buttonHoverBgColor, transform: 'translateY(-2px)' }}
      _active={{ transform: 'translateY(0)' }}
      boxShadow="md"
      transition="all 0.2s"
      borderRadius="full"
      px={4}
      py={2}
      size={buttonSize}
    >
      {children}
    </Button>
  );

  return (
    <Box bg={bgColor} color={textColor} p={{ base: 4, md: 8 }} boxShadow="2xl" minH="150vh" overflow="hidden">
      <Flex justify="space-between" align="center" mb={6}>
        <IconButton
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={handleGoBack}
          variant="ghost"
          size={iconButtonSize}
          aria-label={t('Back')}
          _hover={{ bg: buttonHoverBgColor }}
          color={accentColor}
        />
        <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold" textAlign="center" flex={1}>
          {generatedPrompt ? t('Your generated prompt') : t('Generate a High-Quality Prompt')}
        </Text>
      </Flex>

      {generatedPrompt ? (
        <VStack spacing={7} alignItems="stretch">
          <Center>
            <Box as={FontAwesomeIcon} icon={faWandMagicSparkles} boxSize={{ base: '2rem', md: '3rem' }} color={accentColor} />
          </Center>
          <Box
            bg={generatedPromptBg}
            borderRadius="2xl"
            p={6}
            w="full"
            maxH="60vh"
            overflowY="auto"
            boxShadow="inner"
          >
            <ReactMarkdown>{generatedPrompt}</ReactMarkdown>
          </Box>
          <Text textAlign="center" fontSize={{ base: 'md', md: 'lg' }}>
            {t('You can use this prompt, or generate a new one.')}
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="full" justify="center">
            <CustomButton icon={faCopy} onClick={handleCopyPrompt}>
              {t('Copy Prompt')}
            </CustomButton>
            <CustomButton icon={faRedo} onClick={handleRegeneratePrompt}>
              {t('Regenerate Prompt')}
            </CustomButton>
            <CustomButton icon={faPlusCircle} onClick={handleGenerateNewPrompt}>
              {t('Generate New Prompt')}
            </CustomButton>
          </Stack>
        </VStack>
      ) : (
        <VStack spacing={7} alignItems="stretch">
          <Text fontSize={{ base: 'lg', md: 'xl' }} textAlign="center" maxW="3xl" mx="auto">
            {t('Turn a task description into a high-quality prompt. For best results, describe your task in as much detail as possible.')}
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="full" justify="center" mb={6}>
            <CustomButton icon={faEnvelope} onClick={() => handleQuickInput('Draft an email responding to a customer complaint and offer a resolution.')}>
              {t('Write an Email')}
            </CustomButton>
            {/* <CustomButton icon={faCheckCircle} onClick={() => handleQuickInput('Develop a content moderation guideline for a social media platform.')}>
              {t('Content Moderation')}
            </CustomButton> */}
            <CustomButton icon={faCode} onClick={() => handleQuickInput('Translate this piece of code from Python to JavaScript.')}>
              {t('Translate Code')}
            </CustomButton>
            <CustomButton icon={faFileAlt} onClick={() => handleQuickInput('Summarize the following document in a concise manner.')}>
              {t('Summarize a Document')}
            </CustomButton>
            <CustomButton icon={faShareAlt} onClick={() => handleQuickInput('Create a social media post promoting our new product.')}>
              {t('Create Social Media Content')}
            </CustomButton>
          </Stack>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('Enter your task description here...')}
            size="lg"
            borderRadius="2xl"
            resize="none"
            minH={{ base: '10rem', md: '24rem' }}
            maxH="60vh"
            overflowY="auto"
            _focus={{ borderColor: accentColor, boxShadow: 'outline' }}
            onInput={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
            bg={generatedPromptBg}
            boxShadow="inner"
            p={4}
          />
          {isLoading && (
            <Center w="full" h="full">
              <SpinnerSvg />
            </Center>
          )}
          {!isLoading && (
            <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="full" justify="center">
              <Button
                onClick={handleGeneratePrompt}
                borderRadius="full"
                bg={secondaryColor}
                color="white"
                _hover={{ bg: accentColor, transform: 'translateY(-2px)' }}
                _active={{ transform: 'translateY(0)' }}
                boxShadow="lg"
                transition="all 0.2s"
                px={8}
                py={4}
                fontSize={{ base: 'md', md: 'lg' }}
              >
                {t('Generate')}
              </Button>
            </Stack>
          )}
        </VStack>
      )}
    </Box>
  );
};

export default PromptGenerationPage;