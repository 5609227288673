// src/contexts/AuthContext.js
import React, { createContext, useContext, useCallback } from 'react';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const logout = useCallback(() => {
        // Clear user token or any auth related data here
        localStorage.removeItem('token');
        // Redirect to login page or do any additional cleanup
        window.location.href = '/login';
    }, []);

    return (
        <AuthContext.Provider value={{ logout }}>
            {children}
        </AuthContext.Provider>
    );
};
