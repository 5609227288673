import React, { useState, useRef } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Textarea,
    useToast
} from '@chakra-ui/react';
import { IconContext } from "react-icons";
import { FaMicrophone } from "react-icons/fa";

const AudioChat = () => {
    const [recording, setRecording] = useState(false);
    const [message, setMessage] = useState("");
    const [audioUrl, setAudioUrl] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const toast = useToast();

    const handleRecord = () => {
        setRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.start();
                mediaRecorderRef.current.ondataavailable = event => {
                    audioChunksRef.current.push(event.data);
                };
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                toast({
                    title: "Microphone access denied.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    const handleStopRecording = async () => {
        setRecording(false);
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.onstop = async () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
            audioChunksRef.current = [];
            const formData = new FormData();
            formData.append('audio', audioBlob, 'recording.wav');

            const response = await fetch('http://localhost:4000/api/audio-to-text', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                setMessage(data.text);
                const audioResponse = await fetch('http://localhost:4000/api/message-to-audio', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: 'yourUserId',
                        message: data.text
                    })
                });

                if (audioResponse.ok) {
                    const audioData = await audioResponse.json();
                    const audioBlob = new Blob([new Uint8Array(audioData.audio.data)], { type: 'audio/mp3' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioUrl(audioUrl);
                    playAudio(audioUrl);
                }
            } else {
                console.error('Error converting audio to text:', response.statusText);
                toast({
                    title: "Error converting audio to text.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        };
    };

    const playAudio = (url) => {
        const audio = new Audio(url);
        audio.play();
    };

    return (
        <Box>
            <Flex direction="column" align="center" justify="center" m={4}>
                <Textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                    size="md"
                    w="80%"
                    h="100px"
                    mb={4}
                />
                <Flex w="80%" justify="space-between" align="center">
                    <Input
                        type="text"
                        placeholder="Enter your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        w="85%"
                    />
                    <Button
                        onClick={recording ? handleStopRecording : handleRecord}
                        bg="blue.500"
                        color="white"
                        _hover={{ bg: "blue.600" }}
                        ml={4}
                        p={4}
                        borderRadius="50%"
                    >
                        <IconContext.Provider value={{ size: "1.5em" }}>
                            <FaMicrophone />
                        </IconContext.Provider>
                    </Button>
                </Flex>
                {audioUrl && <audio src={audioUrl} controls autoPlay />}
                <Button mt={4} onClick={handleStopRecording}>
                    Stop Recording
                </Button>
            </Flex>
        </Box>
    );
};

export default AudioChat;
