import React, { useState, useEffect } from 'react';
import {
    Box, Image, Text, Button, VStack, Flex, useDisclosure, IconButton, useToast, Container,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    useClipboard, List, ListItem, Input, FormControl, FormLabel, InputGroup, InputRightElement,
    Heading, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
    useBreakpointValue
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye, faEyeSlash, faCopy, faChevronRight, faCog, faStar, faWallet
} from '@fortawesome/free-solid-svg-icons';
import defaultProfileImage from '../images/user-avatar.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MotionBox = motion(Box);

const ProfilePage = () => {
    const { isOpen: isEmailVisible, onToggle: toggleEmailVisibility } = useDisclosure();
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isWalletOpen, onOpen: onWalletOpen, onClose: onWalletClose } = useDisclosure();
    const { isOpen: isReferralListOpen, onOpen: onReferralListOpen, onClose: onReferralListClose } = useDisclosure();
    const [userProfile, setUserProfile] = useState({ username: '', email: '', profilePic: '', pointsBalance: 0 });
    const [pointsBalance, setPointsBalance] = useState(0);
    const [referralCode, setReferralCode] = useState('');
    const [numReferrals, setNumReferrals] = useState(0);
    const [referrals, setReferrals] = useState([]);
    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();
    const referralLink = `https://maximo.huddlz.xyz/ref?ref=${referralCode}`;
    const { onCopy: onCopyCode } = useClipboard(referralCode);
    const { onCopy: onCopyLink } = useClipboard(referralLink);
    const [privateKey, setPrivateKey] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const isMobile = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                toast({
                    title: t('authenticationError.title'),
                    description: t('authenticationError.description'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }

            try {
                const apiUrl = process.env.REACT_APP_API_URL;

                const profileResponse = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setUserProfile(prevState => ({
                    ...prevState,
                    ...profileResponse.data,
                    profilePic: profileResponse.data.profilePhoto ? `${apiUrl}/${profileResponse.data.profilePhoto}` : defaultProfileImage
                }));

                const referralResponse = await axios.get(`${apiUrl}/api/referral`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setReferralCode(referralResponse.data.referral_code);
                setNumReferrals(referralResponse.data.referral_count);

                const alertsResponse = await axios.get(`${apiUrl}/user-alerts`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setPointsBalance(alertsResponse.data.points);

            } catch (error) {
                console.error('Error fetching user data:', error);
                toast({
                    title: t('fetchError.title'),
                    description: t('fetchError.description'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchUserData();
    }, [toast, t]);

    const fetchReferrals = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            toast({
                title: t('authenticationError.title'),
                description: t('authenticationError.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/api/referral-list`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setReferrals(response.data.referrals);
        } catch (error) {
            console.error('Error fetching referrals:', error);
            toast({
                title: t('fetchError.title'),
                description: t('fetchError.description'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleButtonClick = () => {
        navigate('/buypoints');
    };

    const handleImportWallet = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                toast({
                    title: t('authenticationError.title'),
                    description: t('authenticationError.description'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }

            if (!privateKey || !password) {
                toast({
                    title: t('importWallet.validationError'),
                    description: t('importWallet.validationErrorDescription'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/import-wallet`, {
                privateKey,
                password,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200) {
                toast({
                    title: t('importWallet.success'),
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
                onWalletClose();
            } else {
                toast({
                    title: t('importWallet.error'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error importing wallet:', error);
            toast({
                title: t('importWallet.error'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const ProfileInfo = () => (
        <Flex direction="column" align="center" mb={6}>
            <MotionBox
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.3 }}
            >
                <Image
                    src={userProfile.profilePic || defaultProfileImage}
                    alt="Profile picture"
                    boxSize={["120px", "150px"]}
                    borderRadius="full"
                    border="4px solid"
                    borderColor="#00FFB9"
                    mb={4}
                />
            </MotionBox>
            <Heading as="h2" size="xl" color="white" mb={2}>@{userProfile.username || 'User'}</Heading>
            <Flex alignItems="center" mb={2}>
                <Text fontSize="md" color="#00FFB9" mr={2}>
                    {isEmailVisible ? userProfile.email : '**********'}
                </Text>
                <IconButton
                    aria-label={t('profile.showEmail')}
                    icon={<FontAwesomeIcon icon={isEmailVisible ? faEyeSlash : faEye} />}
                    onClick={toggleEmailVisibility}
                    size="sm"
                    variant="ghost"
                    color="#00FFB9"
                    _hover={{ bg: 'rgba(0, 255, 185, 0.2)' }}
                />
            </Flex>
            <Text color="#00A4D3" fontSize="2xl" fontWeight="bold">
                {t('pointsBalance')} {pointsBalance}
            </Text>
        </Flex>
    );

    const ActionButtons = () => (
        <VStack spacing={4} align="stretch" width="full" mb={isMobile ? 20 : 0}>
            <Button
                leftIcon={<FontAwesomeIcon icon={faStar} />}
                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                onClick={onDrawerOpen}
                bg="#00A4D3"
                color="white"
                _hover={{ bg: 'rgba(0, 164, 211, 0.8)' }}
            >
                {t('profile.getReferralCodes')}
            </Button>
            <Button
                leftIcon={<FontAwesomeIcon icon={faWallet} />}
                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                onClick={onWalletOpen}
                bg="#00A4D3"
                color="white"
                _hover={{ bg: 'rgba(0, 164, 211, 0.8)' }}
            >
                {t('importWallet.title')}
            </Button>
            <Button
                leftIcon={<FontAwesomeIcon icon={faCog} />}
                rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                onClick={handleButtonClick}
                bg="#00A4D3"
                color="white"
                _hover={{ bg: 'rgba(0, 164, 211, 0.8)' }}
            >
                {t('BuyPoints')}
            </Button>
        </VStack>
    );

    return (
        <Box bg="gray.900" minHeight="100vh" pb={isMobile ? 20 : 0}>
            <Container maxW="container.xl" pt={8}>
                <MotionBox
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {isMobile ? (
                        <VStack spacing={8} align="stretch">
                            <ProfileInfo />
                            <ActionButtons />
                        </VStack>
                    ) : (
                        <Flex>
                            <Box width="30%" pr={8}>
                                <ProfileInfo />
                            </Box>
                            <Box width="70%">
                                <ActionButtons />
                            </Box>
                        </Flex>
                    )}
                </MotionBox>
            </Container>

            {/* Referral Drawer */}
            <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="md">
                <DrawerOverlay />
                <DrawerContent bg="gray.800" color="white">
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth="1px" borderColor="gray.600">{t('profile.referralInfo')}</DrawerHeader>
                    <DrawerBody>
                        <VStack align="stretch" spacing={6} mt={4}>
                            <Box>
                                <Text fontWeight="bold" mb={2}>{t('profile.referralCode')}:</Text>
                                <Flex align="center" justify="space-between" bg="gray.700" p={2} borderRadius="md">
                                    <Text>{referralCode}</Text>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faCopy} />}
                                        onClick={onCopyCode}
                                        aria-label={t('copy')}
                                        size="sm"
                                        variant="ghost"
                                        color="#00FFB9"
                                        _hover={{ bg: 'rgba(0, 255, 185, 0.2)' }}
                                    />
                                </Flex>
                            </Box>
                            <Box>
                                <Text fontWeight="bold" mb={2}>{t('profile.referralLink')}:</Text>
                                <Flex align="center" justify="space-between" bg="gray.700" p={2} borderRadius="md">
                                    <Text isTruncated maxW="200px">{referralLink}</Text>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faCopy} />}
                                        onClick={onCopyLink}
                                        aria-label={t('copy')}
                                        size="sm"
                                        variant="ghost"
                                        color="#00FFB9"
                                        _hover={{ bg: 'rgba(0, 255, 185, 0.2)' }}
                                    />
                                </Flex>
                            </Box>
                            <Box>
                                <Text fontWeight="bold" mb={2}>{t('profile.numReferrals')}: {numReferrals}</Text>
                                <Button
                                    onClick={() => {
                                        fetchReferrals();
                                        onReferralListOpen();
                                    }}
                                    bg="#00A4D3"
                                    color="white"
                                    _hover={{ bg: 'rgba(0, 164, 211, 0.8)' }}
                                    width="full"
                                >
                                    {t('profile.viewReferrals')}
                                </Button>
                            </Box>
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            {/* Wallet Modal */}
            <Modal isOpen={isWalletOpen} onClose={onWalletClose} isCentered>
                <ModalOverlay />
                <ModalContent bg="gray.800" color="white" shadow="xl"
                    mx={[4, 0]}
                    maxW="400px">
                    <ModalHeader>{t('importWallet.title')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>{t('importWallet.privateKey')}</FormLabel>
                                <Input
                                    type="password"
                                    value={privateKey}
                                    onChange={(e) => setPrivateKey(e.target.value)}
                                    placeholder={t('importWallet.enterPrivateKey')}
                                    bg="gray.700"
                                    border="none"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t('importWallet.password')}</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder={t('importWallet.enterPassword')}
                                        bg="gray.700"
                                        border="none"
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            variant="ghost"
                                            aria-label={t('togglePasswordVisibility')}
                                            icon={<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />}
                                            onClick={() => setShowPassword(!showPassword)}
                                            color="#00FFB9"
                                            _hover={{ bg: 'rgba(0, 255, 185, 0.2)' }}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            bg="#00A4D3"
                            color="white"
                            _hover={{ bg: 'rgba(0, 164, 211, 0.8)' }}
                            mr={3}
                            onClick={handleImportWallet}
                        >
                            {t('importWallet.import')}
                        </Button>
                        <Button variant="ghost" onClick={onWalletClose}>{t('close')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Referrals List Modal */}
            <Modal isOpen={isReferralListOpen} onClose={onReferralListClose} isCentered>
                <ModalOverlay />
                <ModalContent bg="gray.800" color="white" shadow="xl"
                    mx={[4, 0]}
                    maxW="400px">
                    <ModalHeader>{t('profile.referralsListTitle')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <List spacing={3}>
                            {referrals.length > 0 ? (
                                referrals.map((referral, index) => (
                                    <ListItem key={index} p={2} bg="gray.700" borderRadius="md">
                                        <Text fontWeight="medium">@{referral}</Text>
                                    </ListItem>
                                ))
                            ) : (
                                <Text>{t('profile.noReferrals')}</Text>
                            )}
                        </List>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            bg="#00A4D3"
                            color="white"
                            _hover={{ bg: 'rgba(0, 164, 211, 0.8)' }}
                            onClick={onReferralListClose}
                        >
                            {t('close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ProfilePage;