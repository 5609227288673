import React, { useEffect, useState, useRef } from 'react';
import {
    Box, VStack, Text, Image, Flex, Center, useColorModeValue, Container, Heading,
    Avatar, Tooltip, IconButton, Code, Link, UnorderedList, OrderedList, ListItem
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faUser, faRobot } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import SpinnerSvg from './Spinner';
import userAvatar from '../images/user-avatar.png';
import maximoAvatar from '../images/maximo-ai-logo-circle.png';

const MotionBox = motion(Box);

const ChatViewer = () => {
    const { id } = useParams();
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const messagesEndRef = useRef(null);

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const boxShadow = useColorModeValue('lg', 'dark-lg');
    const accentColor = useColorModeValue('teal.500', 'teal.300');
    const userBgColor = useColorModeValue('blue.50', 'blue.900');
    const aiBgColor = useColorModeValue('gray.50', 'gray.800');
    const codeBgColor = useColorModeValue('gray.100', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/view-shared-chat/${id}`);
                setMessages(response.data);
            } catch (error) {
                setError('Failed to fetch messages');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMessages();
    }, [id]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            // You can add a toast notification here if you want
        });
    };

    const CodeBlock = ({ language, value }) => {
        return (
            <Box position="relative" my={4}>
                <SyntaxHighlighter
                    language={language}
                    style={vscDarkPlus}
                    customStyle={{
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        backgroundColor: codeBgColor,
                        fontSize: '0.9em',
                    }}
                >
                    {value}
                </SyntaxHighlighter>
                <Tooltip label="Copy code" placement="top">
                    <IconButton
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={() => handleCopy(value)}
                        position="absolute"
                        top={2}
                        right={2}
                        colorScheme="teal"
                        size="sm"
                        borderRadius="full"
                        aria-label="Copy code"
                    />
                </Tooltip>
            </Box>
        );
    };

    const MarkdownComponents = {
        p: (props) => <Text mb={4} {...props} />,
        h1: (props) => <Heading as="h1" size="xl" mt={6} mb={4} {...props} />,
        h2: (props) => <Heading as="h2" size="lg" mt={5} mb={3} {...props} />,
        h3: (props) => <Heading as="h3" size="md" mt={4} mb={2} {...props} />,
        ul: (props) => <UnorderedList mt={2} mb={4} pl={4} {...props} />,
        ol: (props) => <OrderedList mt={2} mb={4} pl={4} {...props} />,
        li: (props) => <ListItem mb={1} {...props} />,
        a: (props) => <Link color={accentColor} isExternal {...props} />,
        blockquote: (props) => (
            <Box
                borderLeftWidth={4}
                borderLeftColor={accentColor}
                pl={4}
                py={2}
                my={4}
                fontStyle="italic"
                {...props}
            />
        ),
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
                <CodeBlock
                    language={match[1]}
                    value={String(children).replace(/\n$/, '')}
                    {...props}
                />
            ) : (
                <Code fontSize="sm" px={2} py={1} {...props}>
                    {children}
                </Code>
            );
        },
    };

    const renderMessageContent = (message) => {
        try {
            const parsedMessage = JSON.parse(message);
            if (typeof parsedMessage === 'object' && parsedMessage !== null) {
                if (parsedMessage.text) {
                    const textContent = typeof parsedMessage.text === 'string' 
                        ? parsedMessage.text 
                        : parsedMessage.text.text || '';
                    return (
                        <>
                            <ReactMarkdown components={MarkdownComponents}>
                                {textContent}
                            </ReactMarkdown>
                            {parsedMessage.imageUrl && (
                                <Image
                                    src={parsedMessage.imageUrl}
                                    alt="Chat Image"
                                    mt={4}
                                    borderRadius="md"
                                    maxW="100%"
                                    h="auto"
                                />
                            )}
                        </>
                    );
                } else if (parsedMessage.imageUrl) {
                    return (
                        <Image
                            src={parsedMessage.imageUrl}
                            alt="Chat Image"
                            mt={4}
                            borderRadius="md"
                            maxW="100%"
                            h="auto"
                        />
                    );
                } else if (parsedMessage.pair) {
                    return (
                        <>
                            <Text><strong>{parsedMessage.pair}</strong></Text>
                            <Text>Current Price: {parsedMessage.currentPrice}</Text>
                            <Text>Recommendation: {parsedMessage.recommendation}</Text>
                            <Text>Rationale: {parsedMessage.rationale}</Text>
                        </>
                    );
                }
            }
            return <Text>{message}</Text>;
        } catch (e) {
            return <Text>{message}</Text>;
        }
    };

    const handleDuplicateMessages = (messages) => {
        const seenMessages = new Set();
        return messages.filter(msg => {
            const messageContent = JSON.stringify(msg.message);
            if (seenMessages.has(messageContent)) {
                return false;
            } else {
                seenMessages.add(messageContent);
                return true;
            }
        });
    };

    const uniqueMessages = handleDuplicateMessages(messages);

    return (
        <Box bg={bgColor} color={textColor} minH="100vh">
            <Container maxW="container.xl" py={{ base: 4, md: 8 }} px={{ base: 4, md: 6 }}>
                {isLoading ? (
                    <Center flex="1">
                        <SpinnerSvg size="xl" color="teal.400" />
                    </Center>
                ) : error ? (
                    <Text color="red.500" textAlign="center" mt={4}>{error}</Text>
                ) : (
                    <VStack spacing={6} align="stretch">
                        {uniqueMessages.map((msg, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                                layout
                            >
                                <Box
                                    alignSelf={msg.is_user_message ? "flex-end" : "flex-start"}
                                    maxW={{ base: '100%', md: '80%' }}
                                    bg={msg.is_user_message ? userBgColor : aiBgColor}
                                    borderRadius="2xl"
                                    boxShadow={boxShadow}
                                    borderWidth="1px"
                                    borderColor={borderColor}
                                    position="relative"
                                >
                                    <Flex p={4} alignItems="center">
                                        <Avatar
                                            src={msg.is_user_message ? userAvatar : maximoAvatar}
                                            icon={<FontAwesomeIcon icon={msg.is_user_message ? faUser : faRobot} />}
                                            bg={msg.is_user_message ? 'blue.500' : 'green.500'}
                                            color="white"
                                            mr={3}
                                        />
                                        <Text fontWeight="bold">
                                            {msg.is_user_message ? 'Anonymous' : 'Maximo AI'}
                                        </Text>
                                        <Tooltip label="Copy message" placement="top">
                                            <IconButton
                                                icon={<FontAwesomeIcon icon={faCopy} />}
                                                onClick={() => handleCopy(msg.message)}
                                                position="absolute"
                                                top={2}
                                                right={2}
                                                colorScheme="teal"
                                                variant="ghost"
                                                size="sm"
                                                borderRadius="full"
                                                aria-label="Copy message"
                                            />
                                        </Tooltip>
                                    </Flex>
                                    <Box px={4} pb={4}>
                                        <Box className="markdown-content">
                                            {renderMessageContent(msg.message)}
                                        </Box>
                                    </Box>
                                    <Text fontSize="xs" color="gray.500" textAlign="right" mt={2} mr={2}>
                                        {new Date(msg.timestamp).toLocaleString()}
                                    </Text>
                                </Box>
                            </MotionBox>
                        ))}
                        <div ref={messagesEndRef} />
                    </VStack>
                )}
            </Container>
        </Box>
    );
};

export default ChatViewer;