import React from 'react';
import {
    Box,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    Image,
    Button,
    Container,
    Flex,
    keyframes,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faRocket } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../images/exocrest-logo.png';

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const ComingSoonPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const accentColor = useColorModeValue('teal.500', 'teal.300');
    const cardBg = useColorModeValue('white', 'gray.800');

    return (
        <Box
            bg={bgColor}
            color={textColor}
            minH="100vh"
            py={{ base: 16, md: 24 }}
            px={4}
            overflow="hidden"
        >
            <Container maxW="container.xl">
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                    justify="space-between"
                >
                    <VStack
                        spacing={8}
                        textAlign={{ base: 'center', md: 'left' }}
                        align={{ base: 'center', md: 'flex-start' }}
                        flex={1}
                    >
                        <MotionBox
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Image src={logo} alt="Exocrest Logo" boxSize="120px" />
                        </MotionBox>
                        <MotionHeading
                            size="3xl"
                            color={accentColor}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.2, duration: 0.5 }}
                        >
                            {t('Coming Soon')}
                        </MotionHeading>
                        <MotionText
                            fontSize="xl"
                            maxW="600px"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.4, duration: 0.5 }}
                        >
                            {t('Exciting new features are under development and will be available soon! Stay tuned for updates as we expand the Exocrest universe to bring you more thrilling adventures and challenges.')}
                        </MotionText>
                        <MotionBox
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ delay: 0.6, duration: 0.5 }}
                        >
                            <FontAwesomeIcon icon={faTools} size="3x" color={accentColor} />
                        </MotionBox>
                        <MotionText
                            fontSize="2xl"
                            fontWeight="bold"
                            color={accentColor}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.8, duration: 0.5 }}
                        >
                            {t('We appreciate your patience and support!')}
                        </MotionText>
                        <MotionButton
                            colorScheme="teal"
                            size="lg"
                            rightIcon={<FontAwesomeIcon icon={faRocket} />}
                            onClick={() => navigate('/dashboard')}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1, duration: 0.5 }}
                            _hover={{
                                transform: 'translateY(-5px)',
                                boxShadow: 'xl',
                            }}
                        >
                            {t('Go to Dashboard')}
                        </MotionButton>
                    </VStack>
                    <MotionBox
                        flex={1}
                        display={{ base: 'none', md: 'block' }}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.5, duration: 0.8 }}
                    >
                        <Box
                            w="full"
                            h="400px"
                            bg={cardBg}
                            borderRadius="2xl"
                            boxShadow="2xl"
                            p={8}
                            position="relative"
                            overflow="hidden"
                        >
                            <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                                animation={`${float} 3s ease-in-out infinite`}
                            >
                                <FontAwesomeIcon icon={faRocket} size="6x" color={accentColor} />
                            </Box>
                            {[...Array(5)].map((_, index) => (
                                <Box
                                    key={index}
                                    position="absolute"
                                    top={`${Math.random() * 100}%`}
                                    left={`${Math.random() * 100}%`}
                                    w={`${Math.random() * 10 + 5}px`}
                                    h={`${Math.random() * 10 + 5}px`}
                                    borderRadius="full"
                                    bg={accentColor}
                                    opacity={0.6}
                                    animation={`${float} ${Math.random() * 2 + 2}s ease-in-out infinite`}
                                />
                            ))}
                        </Box>
                    </MotionBox>
                </Flex>
            </Container>
        </Box>
    );
};

export default ComingSoonPage;