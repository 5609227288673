import React, { useState, useEffect } from "react";
import { Box, Flex, IconButton, Avatar, Text, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FaHome, FaWallet, FaBell, FaRobot, FaChartLine, FaBuilding, FaHammer, FaBoxes } from "react-icons/fa";
import axios from "axios";
import { useTranslation } from "react-i18next";
import userAvatar from "../images/user-avatar.png";

const BottomNavBar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [userProfile, setUserProfile] = useState({
    username: "",
    walletAddress: "",
    image: userAvatar,
  });

  // Color mode values
  const bg = useColorModeValue("rgba(255, 255, 255, 0.8)", "rgba(26, 32, 44, 0.8)");
  const btnBg = useColorModeValue("white", "gray.800");
  const btnColor = useColorModeValue("gray.600", "gray.300");
  const activeColor = useColorModeValue("teal.500", "teal.300");
  const shadowColor = useColorModeValue("rgba(0, 0, 0, 0.1)", "rgba(255, 255, 255, 0.1)");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const activeBg = useColorModeValue("gray.200", "gray.600");

  // Responsive design
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const navbarPosition = isDesktop ? "fixed" : "fixed";
  const navbarPlacement = isDesktop ? { bottom: 4, left: 4, right: 4 } : { bottom: 0, left: 0, right: 0 };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/user-profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUserProfile(prevState => ({
          ...prevState,
          ...response.data,
          image: response.data.profilePhoto ? `${apiUrl}/${response.data.profilePhoto}` : userAvatar
        }));
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const handleProfileClick = () => {
    navigate('/menu');
  };

  const truncatePlaceholder = (text) => {
    const maxLength = {
      en: 6,
      ru: 5,
      es: 5,
      zh: 3
    };
    const currentLanguage = i18n.language;
    const limit = maxLength[currentLanguage] || 6;
    return text.length > limit ? text.substring(0, limit) + '.' : text;
  };

  const getMenuOptions = () => {
    const paths = ["/dashboard", "/create-buildings", "/exocrest", "/create-country", "/countries", "/mining", "/earn", "/exocrest-tutorial", "/exocrest-comingsoon", "/create-alliance", "/inventory", "/country"];
    
    const optionsForPaths = [
      { path: "/chat", icon: FaHome, label: "Chat" },
      { path: "/inventory", icon: FaBoxes, label: "InventoryN" },
      { path: "/mining", icon: FaHammer, label: "Mining" },
      { path: "/dashboard", icon: FaChartLine, label: "Dashboard" },
      { path: "/create-buildings", icon: FaBuilding, label: "BuildingsN" },
    ];

    const defaultOptions = [
      { path: "/chat", icon: FaHome, label: "Chat" },
      { path: "/wallet", icon: FaWallet, label: "Wallet" },
      { path: "/alerts", icon: FaBell, label: "Alerts" },
      { path: "/futures", icon: FaChartLine, label: "Futures" },
      { path: "/auto-swap", icon: FaRobot, label: "Swap" },
    ];

    const options = paths.includes(location.pathname) ? optionsForPaths : defaultOptions;

    return options.map(option => ({
      ...option,
      label: truncatePlaceholder(t(option.label)),
    }));
  };

  const menuOptions = getMenuOptions();

  const MotionBox = motion(Box);
  const MotionFlex = motion(Flex);

  return (
    <MotionBox
      as="nav"
      bg={bg}
      backdropFilter="blur(10px)"
      position={navbarPosition}
      {...navbarPlacement}
      zIndex={10}
      width={isDesktop ? "auto" : "100%"}
      borderRadius={isDesktop ? "2xl" : "2xl 2xl 0 0"}
      boxShadow={`0 0 20px ${shadowColor}`}
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 260, damping: 20 }}
    >
      <AnimatePresence>
        <MotionFlex
          justify="space-around"
          align="center"
          py={3}
          px={isDesktop ? 6 : 3}
        >
          {menuOptions.map(({ path, icon: Icon, label }) => (
            <MotionBox
              key={path}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to={path}>
                <IconButton
                  aria-label={t(label)}
                  icon={<Icon />}
                  variant="ghost"
                  size="lg"
                  color={location.pathname === path ? activeColor : btnColor}
                  bg={btnBg}
                  borderRadius="xl"
                  boxShadow={`0 4px 6px ${shadowColor}`}
                  _hover={{ 
                    bg: hoverBg,
                    transform: 'translateY(-2px)',
                    boxShadow: `0 6px 8px ${shadowColor}`
                  }}
                  _active={{
                    bg: activeBg,
                    transform: 'translateY(0)',
                    boxShadow: `0 2px 4px ${shadowColor}`
                  }}
                />
                <Text
                  fontSize="xs"
                  fontWeight="medium"
                  textAlign="center"
                  mt={1}
                  color={location.pathname === path ? activeColor : btnColor}
                >
                  {label}
                </Text>
              </Link>
            </MotionBox>
          ))}
          <MotionBox
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <IconButton
              icon={
                <Avatar
                  size="sm"
                  src={userProfile.image}
                  borderWidth="2px"
                  borderColor={location.pathname === "/menu" ? activeColor : "transparent"}
                />
              }
              aria-label={t("Profile")}
              variant="ghost"
              onClick={handleProfileClick}
              size="lg"
              borderRadius="xl"
              bg={btnBg}
              boxShadow={`0 4px 6px ${shadowColor}`}
              _hover={{ 
                bg: hoverBg,
                transform: 'translateY(-2px)',
                boxShadow: `0 6px 8px ${shadowColor}`
              }}
              _active={{
                bg: activeBg,
                transform: 'translateY(0)',
                boxShadow: `0 2px 4px ${shadowColor}`
              }}
            />
            <Text
              fontSize="xs"
              fontWeight="medium"
              textAlign="center"
              mt={1}
              color={btnColor}
            >
              {truncatePlaceholder(t('Profile'))}
            </Text>
          </MotionBox>
        </MotionFlex>
      </AnimatePresence>
    </MotionBox>
  );
};

export default BottomNavBar;