import React, { useRef } from 'react';
import {
    Box, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent,
    DrawerCloseButton, Button, Text, useDisclosure, useColorModeValue, Divider, IconButton
} from '@chakra-ui/react';
import { keyframes, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';

const popUpAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const popDownAnimation = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  }
`;

const CustomSelect = ({ options, selectedOption, setSelectedOption, label }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const bg = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
    const accentColor = useColorModeValue('teal.400', 'teal.200');
    const hoverBg = useColorModeValue('gray.100', 'gray.600');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const bg2 = useColorModeValue('white', 'gray.800');
    const { t } = useTranslation();
    const listRef = useRef(null);
    const scrollButtonRef = useRef(null);

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        onClose();
    };

    const scrollDown = () => {
        if (listRef.current) {
            listRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    return (
        <Box>
            <Button
                width="full"
                bg={bg}
                color={textColor}
                _hover={{ bg: hoverBg }}
                onClick={onOpen}
                borderRadius="lg"
                border="1px"
                borderColor={borderColor}
            >
                {selectedOption ? selectedOption.label : label}
            </Button>
            <Drawer
                isOpen={isOpen}
                placement="bottom"
                onClose={onClose}
                motionPreset="slideInBottom"
                borderRadius="lg"
            >
                <DrawerOverlay />
                <DrawerContent
                    height="55vh"
                    borderTopRadius="2xl"
                    bg={bg2}
                    css={css`
                        animation: ${isOpen ? popUpAnimation : popDownAnimation} 0.4s ease-out forwards;
                    `}
                >
                    <DrawerCloseButton />
                    <DrawerHeader borderBottom="1px" borderColor={borderColor}>{label}</DrawerHeader>
                    <DrawerBody position="relative" overflowY="auto" p={0} ref={listRef}>
                        <Box position="absolute" top="0" left="0" right="0" height="8px" background={`linear-gradient(to bottom, ${bg}, transparent)`} zIndex="1" />
                        {options.map((option, index) => (
                            <Box key={option.value}>
                                <Text
                                    onClick={() => handleSelectOption(option)}
                                    css={css`
                                        cursor: pointer;
                                        padding: 16px;
                                        padding-left: 24px; /* Add padding-left */
                                        transition: all 0.2s ease-in-out;
                                        &:hover {
                                            background-color: ${accentColor};
                                            color: white;
                                            animation: ${glowAnimation} 1.5s infinite;
                                            transform: scale(1.05);
                                            padding-left: 72px; /* Increase padding-left on hover */
                                        }
                                    `}
                                    bg={bg2}
                                    color={textColor}
                                >
                                    {option.label}
                                </Text>
                                {index < options.length - 1 && <Divider />}
                            </Box>
                        ))}
                        <Box position="absolute" bottom="0" left="0" right="0" height="8px" background={`linear-gradient(to top, ${bg}, transparent)`} zIndex="1" />
                    </DrawerBody>
                    <DrawerFooter justifyContent="space-between">
                        <Button variant="outline" mr={3} onClick={onClose} borderRadius="lg">
                            {t('cancel')}
                        </Button>
                        <IconButton
                            icon={<ChevronDownIcon />}
                            onClick={scrollDown}
                            borderRadius="full"
                            ref={scrollButtonRef}
                        />
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default CustomSelect;
