// Chat.js
import React from 'react';
import ChatV1 from './ChatV1';
import ChatV2 from './ChatV2';
import MaximoZest from './MaximoZest';
import useLocalStorage from './useLocalStorage'; // Adjust the import path as needed

const Chat = () => {
    const [version] = useLocalStorage('endpoint', 'v1');

    switch (version) {
        case 'v1':
            return <ChatV1 />;
        case 'v2':
            return <ChatV2 />;
        case 'z1':
            return <MaximoZest />;
        default:
            return <ChatV1 />; // Default to ChatV1 if an unknown version is stored
    }
};

export default Chat;
