import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ReferralLandingPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referralCode = params.get('ref');
        if (referralCode) {
            console.log('Referral code found:', referralCode); // Debug log
            localStorage.setItem('referralCode', referralCode);
        } else {
            console.log('No referral code found'); // Debug log
        }
        // Redirect to the desired page after saving the referral code
        setTimeout(() => {
            navigate('/');
        }, 100); // Slight delay to ensure localStorage is updated
    }, [location, navigate]);

    return null; // Or display a loading indicator if you prefer
};

export default ReferralLandingPage;
