// Spinner.js

import React from 'react';

const SpinnerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80px"
    height="80px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#00FFB9" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
      <circle cx="50" cy="50" r="23" strokeWidth="8" stroke="#00A4D3" strokeDasharray="36.12831551628262 36.12831551628262" strokeDashoffset="36.12831551628262" fill="none" strokeLinecap="round">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="360 50 50;0 50 50" keyTimes="0;1"></animateTransform>
      </circle>
      <circle cx="50" cy="50" r="14" strokeWidth="8" stroke="#00FFB9" strokeDasharray="21.991148575128552 21.991148575128552" strokeDashoffset="21.991148575128552" fill="none" strokeLinecap="round">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </g>
  </svg>
);

export default SpinnerSvg;