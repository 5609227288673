import React, { useState } from "react";
import {
    Box,
    Text,
    Button,
    FormControl,
    FormLabel,
    Container,
    Heading,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Input,
    Textarea,
    useDisclosure,
    useColorModeValue,
    useToast,
    Flex,
    IconButton,
    useBreakpointValue,
    SimpleGrid,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileWord, faSearch, faPaperPlane, faTimes, faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomSelect from "./CustomSelect";

const MotionBox = motion(Box);

const LoadingAnimation = () => {
    const { t } = useTranslation();
    const textColor = useColorModeValue("gray.800", "white");

    return (
        <Flex direction="column" align="center" justify="center" h="300px">
            <Box as="svg" width="150px" height="150px" viewBox="0 0 100 100">
                <motion.circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="none"
                    stroke="#3182CE"
                    strokeWidth="5"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 2, repeat: Infinity }}
                />
                <motion.path
                    d="M25 50 L40 65 L75 30"
                    fill="none"
                    stroke="#3182CE"
                    strokeWidth="5"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 1.5, delay: 0.5, repeat: Infinity }}
                />
            </Box>
            <Text fontSize="xl" fontWeight="bold" color={textColor} mt={4}>
                {t("creatingDocument")}
            </Text>
            <Text fontSize="md" color={textColor} mt={2}>
                {t("pleaseWait")}
            </Text>
        </Flex>
    );
};

const MaximoCreatePage = () => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isViewDocOpen, onOpen: onViewDocOpen, onClose: onViewDocClose } = useDisclosure();
    const toast = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        documentType: "doc",
        topic: "",
        tone: "Fun",
        language: "English",
        slides: "2",
        moreDetails: "",
    });
    const [characterCount, setCharacterCount] = useState(0);
    const maxCharacters = 24000;

    const handleSelectChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === "moreDetails") {
            setCharacterCount(value.length);
        }
    };

    const handleSubmit = async () => {
        const { documentType, topic, tone, language, slides } = formData;

        if (!documentType || !topic || !tone || !language || !slides) {
            toast({
                title: "Error",
                description: "Please fill in all the required fields.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);

        try {
            let response;
            if (documentType === "ppt") {
                response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/create-ppt-document`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
            } else {
                response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/create-document`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
            }

            localStorage.setItem('selectedDocumentId', response.data.documentId);
            toast({
                title: "Success",
                description: "Document created successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            if (documentType === "ppt") {
                navigate("/ppt-editor", { state: { response: response.data.response } });
            } else {
                navigate("/document-editor", { state: { response: response.data.response } });
            }
        } catch (error) {
            console.error("Error creating document:", error);
            toast({
                title: "Error",
                description: "An error occurred while creating the document.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }

        setLoading(false);
        onClose();
    };

    const handleViewDocuments = (type) => {
        onViewDocClose();
        if (type === "word") {
            navigate("/document-editor");
        } else if (type === "ppt") {
            navigate("/ppt-editor");
        }
    };

    const bg = useColorModeValue("gray.50", "gray.900");
    const textColor = useColorModeValue("gray.800", "white");
    const cardBg = useColorModeValue("white", "gray.800");
    const buttonBg = useColorModeValue("teal.500", "teal.300");
    const buttonHoverBg = useColorModeValue("teal.600", "teal.400");
    const inputBg = useColorModeValue("gray.100", "gray.700");

    const isMobile = useBreakpointValue({ base: true, md: false });

    const MotionButton = motion(Button);

    return (
        <Box bg={bg} minH="100vh" py={{ base: 6, md: 12 }} px={4}>
            <Container maxW="container.lg">
                <VStack spacing={{ base: 6, md: 10 }} align="stretch">
                    <MotionBox
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Heading
                            as="h1"
                            fontSize={{ base: "3xl", md: "5xl" }}
                            fontWeight="extrabold"
                            textAlign="center"
                            bgGradient="linear(to-r, teal.400, blue.500)"
                            bgClip="text"
                        >
                            {t("maximoCreate.headerTitle")}
                        </Heading>
                        <Text
                            fontSize={{ base: "md", md: "lg" }}
                            textAlign="center"
                            mt={4}
                            color={textColor}
                        >
                            {t("maximoCreate.description")}
                        </Text>
                    </MotionBox>

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                        {[
                            {
                                icon: faFileAlt,
                                text: t("maximoCreate.createSocialMediaContent"),
                                onClick: () => navigate("/comingsoon"),
                            },
                            {
                                icon: faFileWord,
                                text: t("maximoCreate.createDocuments"),
                                onClick: onOpen,
                            },
                            {
                                icon: faSearch,
                                text: t("viewDocuments"),
                                onClick: onViewDocOpen,
                            },
                        ].map((item, index) => (
                            <MotionButton
                                key={index}
                                leftIcon={<FontAwesomeIcon icon={item.icon} />}
                                onClick={item.onClick}
                                size="md"
                                fontSize="sm"
                                fontWeight="semibold"
                                bg={buttonBg}
                                color="white"
                                px={4}
                                py={2}
                                _hover={{ bg: buttonHoverBg }}
                                _active={{ bg: buttonHoverBg }}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                transition={{ duration: 0.2 }}
                            >
                                {item.text}
                            </MotionButton>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>

            <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? "full" : "xl"} motionPreset="slideInBottom">
                <ModalOverlay />
                <ModalContent bg={cardBg} color={textColor}>
                    <ModalHeader fontSize="xl" fontWeight="bold" py={4}>
                        {t("maximoCreate.createDocuments")}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AnimatePresence>
                            {loading ? (
                                <LoadingAnimation />
                            ) : (
                                <MotionBox
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <VStack spacing={4} align="stretch">
                                        <FormControl isRequired>
                                            <FormLabel fontSize="sm">{t("maximoCreate.documentType")}</FormLabel>
                                            <CustomSelect
                                                name="documentType"
                                                options={[
                                                    { value: "doc", label: "DOC" },
                                                    { value: "ppt", label: "PPT" },
                                                ]}
                                                selectedOption={{ value: formData.documentType, label: formData.documentType.toUpperCase() }}
                                                setSelectedOption={(option) => handleSelectChange("documentType", option.value)}
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel fontSize="sm">{t("maximoCreate.topic")}</FormLabel>
                                            <Input
                                                name="topic"
                                                value={formData.topic}
                                                onChange={handleChange}
                                                bg={inputBg}
                                                border="none"
                                                _focus={{ boxShadow: `0 0 0 1px ${buttonBg}` }}
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel fontSize="sm">{t("maximoCreate.writingTone")}</FormLabel>
                                            <CustomSelect
                                                name="tone"
                                                options={[
                                                    { value: "Fun", label: "Fun" },
                                                    { value: "Creative", label: "Creative" },
                                                    { value: "Casual", label: "Casual" },
                                                    { value: "Professional", label: "Professional" },
                                                    { value: "Formal", label: "Formal" },
                                                ]}
                                                selectedOption={{ value: formData.tone, label: formData.tone }}
                                                setSelectedOption={(option) => handleSelectChange("tone", option.value)}
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel fontSize="sm">{t("maximoCreate.language")}</FormLabel>
                                            <CustomSelect
                                                name="language"
                                                options={[
                                                    { value: "English", label: "English" },
                                                    { value: "Spanish", label: "Spanish" },
                                                    { value: "Russian", label: "Russian" },
                                                    { value: "Chinese", label: "Chinese" },
                                                ]}
                                                selectedOption={{ value: formData.language, label: formData.language }}
                                                setSelectedOption={(option) => handleSelectChange("language", option.value)}
                                            />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel fontSize="sm">{t("maximoCreate.numberOfSlides")}</FormLabel>
                                            <CustomSelect
                                                name="slides"
                                                options={[
                                                    { value: "2", label: "2" },
                                                    { value: "4", label: "4" },
                                                    { value: "6", label: "6" },
                                                    { value: "8", label: "8" },
                                                    { value: "10", label: "10" },
                                                    { value: "12", label: "12" },
                                                ]}
                                                selectedOption={{ value: formData.slides, label: formData.slides }}
                                                setSelectedOption={(option) => handleSelectChange("slides", option.value)}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontSize="sm">{t("maximoCreate.moreDetails")}</FormLabel>
                                            <Textarea
                                                name="moreDetails"
                                                value={formData.moreDetails}
                                                onChange={handleChange}
                                                maxLength={24000}
                                                bg={inputBg}
                                                border="none"
                                                _focus={{ boxShadow: `0 0 0 1px ${buttonBg}` }}
                                                rows={4}
                                            />
                                            <Text fontSize="sm" mt={1} color={characterCount > maxCharacters ? "red.500" : "gray.500"}>
                                                {characterCount}/{maxCharacters} {t("charactersUsed")}
                                            </Text>
                                        </FormControl>
                                    </VStack>
                                </MotionBox>
                            )}
                        </AnimatePresence>
                    </ModalBody>
                    {!loading && (
                        <ModalFooter>
                            <Button
                                leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                onClick={handleSubmit}
                                bg={buttonBg}
                                color="white"
                                _hover={{ bg: buttonHoverBg }}
                                size="md"
                                mr={3}
                            >
                                {t("maximoCreate.submit")}
                            </Button>
                            <IconButton
                                icon={<FontAwesomeIcon icon={faTimes} />}
                                onClick={onClose}
                                variant="ghost"
                                size="md"
                            />
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={isViewDocOpen} isCentered onClose={onViewDocClose} size="sm" motionPreset="slideInBottom">
                <ModalOverlay />
                <ModalContent bg={cardBg} color={textColor} mx={[4, 0]} maxW="400px">
                    <ModalHeader fontSize="xl" fontWeight="bold" py={4}>
                        {t("viewDocuments")}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Button
                                leftIcon={<FontAwesomeIcon icon={faFileWord} />}
                                onClick={() => handleViewDocuments("word")}
                                size="md"
                                fontSize="sm"
                                fontWeight="semibold"
                                bg={buttonBg}
                                color="white"
                                _hover={{ bg: buttonHoverBg }}
                                width="100%"
                            >
                                {t("wordDocuments")}
                            </Button>
                            <Button
                                leftIcon={<FontAwesomeIcon icon={faFilePowerpoint} />}
                                onClick={() => handleViewDocuments("ppt")}
                                size="md"
                                fontSize="sm"
                                fontWeight="semibold"
                                bg={buttonBg}
                                color="white"
                                _hover={{ bg: buttonHoverBg }}
                                width="100%"
                            >
                                {t("pptDocuments")}
                            </Button>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default MaximoCreatePage;