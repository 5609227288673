import React, { useEffect } from 'react';
import { Box, Button, Text, Image, Heading, Flex, useColorModeValue, useBreakpointValue, Container, Grid, GridItem, Icon, chakra, Stack, VStack, Link, IconButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { FaRobot, FaCode, FaFileAlt, FaInstagram, FaYoutube, FaTwitter, FaExchangeAlt, FaComments, FaBell, FaUsers, FaTelegramPlane } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import logo from '../images/maximo-ai-logo-circle.png';
import InstallPWA from './InstallPWA';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);
const MotionHeading = motion(Heading);
const MotionImage = motion(Image); // Add motion for the image

const LandingPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'white');
    const cardBg = useColorModeValue('white', 'gray.800');
    const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/chat');
        }
    }, [navigate]);

    const FeatureCard = ({ icon, title, description }) => (
        <MotionBox
            bg={cardBg}
            p={6}
            borderRadius="xl"
            boxShadow="xl"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Icon as={icon} w={10} h={10} color="teal.500" mb={4} />
            <Heading as="h3" size="md" mb={2} fontFamily="Lexend">
                {title}
            </Heading>
            <Text fontSize="sm" fontFamily="Lexend">
                {description}
            </Text>
        </MotionBox>
    );

    const Section = ({ children }) => {
        const controls = useAnimation();
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.1,
        });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <MotionBox
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 50 }
                }}
                transition={{ duration: 0.5 }}
            >
                {children}
            </MotionBox>
        );
    };

    return (
        <Box bg={bgColor} minH="100vh">
            <InstallPWA />
            <Container maxW="container.xl" py={8}>
                <MotionFlex
                    justify="space-between"
                    align="center"
                    mb={16}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Flex align="center">
                        <MotionImage 
                            src={logo} 
                            boxSize="50px" 
                            alt="Maximo AI Logo" 
                            mr={4} 
                            whileHover={{ rotate: 360 }} // Add rotation animation on hover
                            transition={{ duration: 1 }} // Transition duration for rotation
                        />
                        <Heading as="h1" fontFamily="Lexend" fontSize="2xl" fontWeight="bold" color={textColor}>
                            MAXIMO AI
                        </Heading>
                    </Flex>
                    <Button colorScheme="teal" size={buttonSize} onClick={() => navigate('/login')} borderRadius="full">
                        {t('login')}
                    </Button>
                </MotionFlex>

                <Section>
                    <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={12} mb={20}>
                        <GridItem>
                            <MotionHeading
                                as="h2"
                                fontSize={{ base: "4xl", md: "5xl" }}
                                fontWeight="bold"
                                mb={4}
                                fontFamily="Lexend"
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: 0.2 }}
                            >
                                {t('landing_title_new')}
                            </MotionHeading>
                            <MotionText
                                fontSize="xl"
                                mb={8}
                                fontFamily="Lexend"
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.5, delay: 0.4 }}
                            >
                                {t('landing_subtitle_new')}
                            </MotionText>
                            <MotionBox
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                            >
                                <Button colorScheme="teal" size={buttonSize} onClick={() => navigate('/signup')} mb={4} borderRadius="full" width={{ base: "full", md: "auto" }}>
                                    {t('get_started')}
                                </Button>
                                <Text fontFamily="Lexend" fontSize="sm">
                                    {t('already_have_account')}{' '}
                                    <chakra.span
                                        color="teal.500"
                                        cursor="pointer"
                                        _hover={{ textDecoration: 'underline' }}
                                        onClick={() => navigate('/login')}
                                    >
                                        {t('log_in')}
                                    </chakra.span>
                                </Text>
                            </MotionBox>
                        </GridItem>
                        <GridItem display={{ base: "none", md: "block" }}>
                            {/* Add an illustration or image here */}
                        </GridItem>
                    </Grid>
                </Section>

                <Section>
                    <Heading as="h3" fontSize="3xl" fontWeight="bold" mb={12} fontFamily="Lexend" textAlign="center">
                        {t('maximo_capabilities')}
                    </Heading>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8}>
                        <FeatureCard
                            icon={FaRobot}
                            title={t('ai_trading_bot')}
                            description={t('ai_trading_bot_desc')}
                        />
                        <FeatureCard
                            icon={FaInstagram}
                            title={t('social_media_content')}
                            description={t('social_media_content_desc')}
                        />
                        <FeatureCard
                            icon={FaFileAlt}
                            title={t('document_creation')}
                            description={t('document_creation_desc')}
                        />
                    </Grid>
                </Section>

                <Section>
                    <Heading as="h3" fontSize="3xl" fontWeight="bold" my={12} fontFamily="Lexend" textAlign="center">
                        {t('maximo_create')}
                    </Heading>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }} gap={8}>
                        <FeatureCard
                            icon={SiTiktok}
                            title={t('tiktok_content')}
                            description={t('tiktok_content_desc')}
                        />
                        <FeatureCard
                            icon={FaInstagram}
                            title={t('instagram_content')}
                            description={t('instagram_content_desc')}
                        />
                        <FeatureCard
                            icon={FaYoutube}
                            title={t('youtube_content')}
                            description={t('youtube_content_desc')}
                        />
                        <FeatureCard
                            icon={FaTwitter}
                            title={t('twitter_content')}
                            description={t('twitter_content_desc')}
                        />
                    </Grid>
                </Section>

                <Section>
                    <Heading as="h3" fontSize="3xl" fontWeight="bold" my={12} fontFamily="Lexend" textAlign="center">
                        {t('trading_features')}
                    </Heading>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8}>
                        <FeatureCard
                            icon={FaExchangeAlt}
                            title={t('autoswap_title')}
                            description={t('autoswap_desc')}
                        />
                        <FeatureCard
                            icon={FaComments}
                            title={t('chat_with_maximo')}
                            description={t('chat_with_maximo_desc')}
                        />
                        <FeatureCard
                            icon={FaBell}
                            title={t('alerts_title')}
                            description={t('alerts_desc')}
                        />
                    </Grid>
                </Section>

                <Section>
                    <Flex direction={{ base: "column", md: "row" }} align="center" justify="space-between" mt={20}>
                        <Box flex={1} mr={{ base: 0, md: 12 }} mb={{ base: 8, md: 0 }}>
                            <Heading as="h3" fontSize="3xl" fontWeight="bold" mb={4} fontFamily="Lexend">
                                {t('code_playground')}
                            </Heading>
                            <Text fontSize="lg" mb={6} fontFamily="Lexend">
                                {t('code_playground_desc')}
                            </Text>
                            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                                <Button colorScheme="purple" size={buttonSize} borderRadius="full">
                                    {t('try_code_playground')}
                                </Button>
                                <Button colorScheme="blue" size={buttonSize} borderRadius="full">
                                    {t('access_claude')}
                                </Button>
                            </Stack>
                        </Box>
                        <Box flex={1}>
                            <FeatureCard
                                icon={FaCode}
                                title={t('ai_powered_coding')}
                                description={t('ai_powered_coding_desc')}
                            />
                        </Box>
                    </Flex>
                </Section>

                <Section>
                    <VStack spacing={8} mt={20}>
                        <Heading as="h3" fontSize="3xl" fontWeight="bold" fontFamily="Lexend" textAlign="center">
                            {t('additional_features')}
                        </Heading>
                        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8} width="full">
                            <FeatureCard
                                icon={FaExchangeAlt}
                                title={t('easy_token_swaps')}
                                description={t('easy_token_swaps_desc')}
                            />
                            <FeatureCard
                                icon={(FaUsers)}
                                title={t('for_all_traders')}
                                description={t('for_all_traders_desc')}
                            />
                        </Grid>
                    </VStack>
                </Section>

                <MotionBox
                    textAlign="center"
                    mt={20}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Heading as="h3" fontSize="3xl" fontWeight="bold" mb={6} fontFamily="Lexend">
                        {t('ready_to_start')}
                    </Heading>
                    <Text fontSize="xl" mb={8} fontFamily="Lexend">
                        {t('join_maximo_community')}
                    </Text>
                    <Button colorScheme="teal" size={buttonSize} onClick={() => navigate('/signup')} borderRadius="full">
                        {t('get_started_now')}
                    </Button>
                </MotionBox>
            </Container>

            <Box bg={bgColor} py={8} mt={20}>
                <Container maxW="container.xl">
                    <Flex direction={{ base: "column", md: "row" }} align="center" justify="space-between">
                        <Flex align="center">
                            <MotionImage 
                                src={logo} 
                                boxSize="40px" 
                                alt="Maximo AI Logo" 
                                mr={4}
                                whileHover={{ rotate: 360 }} // Add rotation animation on hover
                                transition={{ duration: 1 }} // Transition duration for rotation
                            />
                            <Text fontSize="lg" fontWeight="bold" color={textColor} fontFamily="Lexend">
                                Maximo AI
                            </Text>
                        </Flex>
                        <Stack direction="row" spacing={4} mt={{ base: 4, md: 0 }}>
                            <Link href="https://x.com/maximoai_co" isExternal>
                                <IconButton
                                    icon={<FaTwitter />}
                                    aria-label="Twitter"
                                    size="lg"
                                    colorScheme="twitter"
                                    variant="ghost"
                                />
                            </Link>
                            <Link href="https://t.me/maximoai" isExternal>
                                <IconButton
                                    icon={<FaTelegramPlane />}
                                    aria-label="Telegram"
                                    size="lg"
                                    colorScheme="telegram"
                                    variant="ghost"
                                />
                            </Link>
                            <Link href="https://instagram.com/yourinstagram" isExternal>
                                <IconButton
                                    icon={<FaInstagram />}
                                    aria-label="Instagram"
                                    size="lg"
                                    colorScheme="pink"
                                    variant="ghost"
                                />
                            </Link>
                        </Stack>
                    </Flex>
                    <Text mt={4} fontSize="sm" color={textColor} textAlign={{ base: 'center', md: 'left' }} fontFamily="Lexend">
                        &copy; {new Date().getFullYear()} Maximo AI. All rights reserved.
                    </Text>
                </Container>
            </Box>
        </Box>
    );
};

export default LandingPage;