import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
    Box,
    Heading,
    VStack,
    IconButton,
    useToast,
    Spinner,
    Flex,
    Text,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useColorModeValue,
    Tooltip,
    useMediaQuery,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    RadioGroup,
    Radio,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeftIcon, DownloadIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faDownload, faTrashAlt, faFolderOpen, faEdit, faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import PptxGenJS from 'pptxgenjs';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import saveAs from 'file-saver';

const MotionBox = motion(Box);

const EmptyStateAnimation = () => (
    <Box as="svg" width="200px" height="200px" viewBox="0 0 100 100">
        <motion.circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="#3182CE"
            strokeWidth="2"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, repeat: Infinity }}
        />
        <motion.path
            d="M30 50 L45 65 L70 40"
            fill="none"
            stroke="#3182CE"
            strokeWidth="2"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1, delay: 1 }}
        />
    </Box>
);

const PPTEditorPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const { response } = location.state || { response: { text: '' } };
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDownloadOpen, onOpen: onDownloadOpen, onClose: onDownloadClose } = useDisclosure();
    const [savedPresentations, setSavedPresentations] = useState([]);
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    const [isEditing, setIsEditing] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [downloadFormat, setDownloadFormat] = useState('pptx');
    const iframeRef = useRef(null);
    const initialLoadRef = useRef(false);

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const cardBg = useColorModeValue('white', 'gray.800');
    const hoverBg = useColorModeValue('gray.100', 'gray.700');
    const mutedColor = useColorModeValue('gray.600', 'gray.400');
    const accentColor = useColorModeValue('teal.500', 'teal.300');

    const extractHtmlContent = (rawContent) => {
        if (!rawContent) {
            return '';
        }

        let parsedContent = rawContent;

        try {
            parsedContent = JSON.parse(rawContent);
            if (typeof parsedContent === 'string') {
                parsedContent = JSON.parse(parsedContent);
            }
        } catch (error) {
            // If parsing fails, keep the original rawContent
        }

        if (typeof parsedContent === 'string') {
            parsedContent = parsedContent.replace(/\\"/g, '"');
        }

        const htmlMatch = parsedContent.match(/```html\n([\s\S]*?)```/);
        if (htmlMatch) {
            return htmlMatch[1];
        }

        return parsedContent;
    };

    const loadDocumentById = useCallback(async (documentId) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/ppt-documents/${documentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            const document = response.data.document;
            console.log('Loaded document:', document);
            setSelectedDocument(document);
            const htmlContent = extractHtmlContent(document.content);
            console.log('Parsed HTML content:', htmlContent);
            setContent(htmlContent);
        } catch (error) {
            console.error('Error loading document:', error);
            // toast({
            //     title: 'Error',
            //     description: 'Failed to load the document.',
            //     status: 'error',
            //     duration: 3000,
            //     isClosable: true,
            // });
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!initialLoadRef.current) {
            initialLoadRef.current = true;
            const savedDocumentId = localStorage.getItem('selectedDocumentId');
            if (savedDocumentId) {
                loadDocumentById(savedDocumentId);
            } else if (response && response.documents && response.documents.length > 0) {
                const htmlContent = extractHtmlContent(response.documents[0].content);
                setContent(htmlContent);
                setSelectedDocument(response.documents[0]);
            }
        }
    }, [response, loadDocumentById]);

    const handleSave = useCallback(async () => {
        if (!selectedDocument) {
            toast({
                title: 'Error',
                description: 'No document selected.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);
        try {
            const documentData = {
                documentId: selectedDocument.documentId,
                content: extractHtmlContent(content),
                document_type: selectedDocument.document_type,
                topic: selectedDocument.topic,
                tone: selectedDocument.tone,
                language: selectedDocument.language,
                slides: selectedDocument.slides,
                more_details: selectedDocument.more_details,
            };

            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/save-ppt-document`,
                documentData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            toast({
                title: 'Success',
                description: 'Presentation saved successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error saving presentation:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while saving the presentation.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setLoading(false);
    }, [content, selectedDocument, toast]);

    const handleDownload = async () => {
        setLoading(true);
        try {
            switch (downloadFormat) {
                case 'pptx':
                    const pptx = new PptxGenJS();
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(content, 'text/html');

                    // Extract styles
                    const styles = doc.getElementsByTagName('style');
                    let cssRules = '';
                    for (let style of styles) {
                        cssRules += style.textContent;
                    }

                    // Create slides
                    const slides = doc.getElementsByClassName('slide');
                    for (let slide of slides) {
                        const slideObj = pptx.addSlide();

                        // Add title
                        const titleElement = slide.querySelector('h1');
                        if (titleElement) {
                            slideObj.addText(titleElement.textContent, { 
                                x: 0.5, 
                                y: 0.5, 
                                w: '90%', 
                                fontSize: 44, 
                                bold: true,
                                color: '007BFF'
                            });
                        }

                        // Add content
                        const contentElement = slide.querySelector('p');
                        if (contentElement) {
                            slideObj.addText(contentElement.textContent, { 
                                x: 0.5, 
                                y: 2, 
                                w: '90%', 
                                fontSize: 20 
                            });
                        }

                        // Add SVG as image
                        const svgElement = slide.querySelector('svg');
                        if (svgElement) {
                            const svgString = new XMLSerializer().serializeToString(svgElement);
                            const svgBlob = new Blob([svgString], {type: 'image/svg+xml;charset=utf-8'});
                            const svgUrl = URL.createObjectURL(svgBlob);

                            slideObj.addImage({ 
                                path: svgUrl, 
                                x: 7, 
                                y: 0.5, 
                                w: 2, 
                                h: 2 
                            });
                        }
                    }

                    // Add a notes slide with CSS
                    const notesSlide = pptx.addSlide();
                    notesSlide.addText('CSS Styles', { x: 0.5, y: 0.5, fontSize: 24, bold: true });
                    notesSlide.addText(cssRules, { x: 0.5, y: 1, w: '90%', fontSize: 12 });

                    await pptx.writeFile({ fileName: 'Presentation.pptx' });
                    break;
                case 'docx':
                    const docx = new Document();
                    docx.addSection({
                        children: [
                            new Paragraph({
                                children: [new TextRun(content)],
                            }),
                        ],
                    });
                    const buffer = await Packer.toBuffer(docx);
                    saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }), 'Presentation.docx');
                    break;
                case 'html':
                    const blobHtml = new Blob([content], { type: 'text/html' });
                    saveAs(blobHtml, 'Presentation.html');
                    break;
                case 'txt':
                    const plainText = content.replace(/<\/?[^>]+(>|$)/g, "");
                    const blobTxt = new Blob([plainText], { type: 'text/plain' });
                    saveAs(blobTxt, 'Presentation.txt');
                    break;
                default:
                    break;
            }
            toast({
                title: 'Success',
                description: 'Presentation downloaded successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error downloading presentation:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while downloading the presentation.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setLoading(false);
        onDownloadClose();
    };

    const fetchSavedPresentations = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/ppt-documents`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            setSavedPresentations(response.data.documents ? response.data.documents.reverse() : []);
        } catch (error) {
            console.error('Error fetching saved presentations:', error);
            toast({
                title: 'Error',
                description: 'Failed to fetch saved presentations.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setLoading(false);
    }, [toast]);

    const handleDocumentSelect = async (document) => {
        await loadDocumentById(document.documentId);
        localStorage.setItem('selectedDocumentId', document.documentId);
        onClose();
    };

    const deletePresentation = async (documentId) => {
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/api/ppt-documents/${documentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            setSavedPresentations(savedPresentations.filter(doc => doc.documentId !== documentId));
            if (selectedDocument && selectedDocument.documentId === documentId) {
                localStorage.removeItem('selectedDocumentId');
                setContent('');
                setSelectedDocument(null);
            }
            toast({
                title: 'Success',
                description: 'Document deleted successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting presentation:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while deleting the presentation.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const contentVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 }
    };

    const renderContent = () => {
        if (content) {
            if (isEditing) {
                return (
                    <Box
                        as="textarea"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        h="100%"
                        w="100%"
                        p={2}
                        borderRadius="lg"
                        border="1px solid"
                        borderColor="gray.200"
                        _dark={{ borderColor: "gray.600" }}
                        resize="none"
                    />
                );
            } else {
                return (
                    <Box
                        as="iframe"
                        ref={iframeRef}
                        srcDoc={content}
                        width="100%"
                        height="100%"
                        borderRadius="lg"
                        border="none"
                        sandbox="allow-scripts"
                    />
                );
            }
        } else {
            return (
                <VStack spacing={4} justify="center" align="center" h="100%">
                    <EmptyStateAnimation />
                    <Text fontSize="xl" fontWeight="bold" textAlign="center">
                        No document found
                    </Text>
                    <Text textAlign="center">
                        Check your saved documents or create a new one
                    </Text>
                    <Flex>
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                            onClick={() => { fetchSavedPresentations(); onOpen(); }}
                            mr={2}
                        >
                            Saved Documents
                        </Button>
                        <Button
                            as={Link}
                            to="/create"
                            leftIcon={<FontAwesomeIcon icon={faPlus} />}
                        >
                            Create New
                        </Button>
                    </Flex>
                </VStack>
            );
        }
    };

    return (
        <Box bg={bgColor} color={textColor} minH={{ base: "100vh", md: "95vh" }} p={4}>
            <VStack spacing={4} align="stretch" h="calc(85vh - 32px)">
                <Flex justifyContent="space-between" alignItems="center">
                    <IconButton
                        aria-label="Go Back"
                        icon={<ChevronLeftIcon />}
                        onClick={() => navigate(-1)}
                        variant="ghost"
                        size="lg"
                        borderRadius="full"
                    />
                    <Heading as="h1" size="lg" fontWeight="extrabold">
                        {t("PPT Editor")}
                    </Heading>
                    <Flex>
                        <Tooltip label={t("Load Presentation")}>
                            <IconButton
                                aria-label="Load Presentation"
                                icon={<FontAwesomeIcon icon={faFolderOpen} />}
                                onClick={() => { fetchSavedPresentations(); onOpen(); }}
                                variant="ghost"
                                size="lg"
                                mr={2}
                                borderRadius="full"
                            />
                        </Tooltip>
                        <Tooltip label={t("Save")}>
                            <IconButton
                                aria-label="Save Presentation"
                                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                                onClick={handleSave}
                                variant="ghost"
                                size="lg"
                                isLoading={loading}
                                mr={2}
                                borderRadius="full"
                            />
                        </Tooltip>
                        <Tooltip label={t("Download")}>
                            <IconButton
                                aria-label="Download Presentation"
                                icon={<FontAwesomeIcon icon={faDownload} />}
                                onClick={onDownloadOpen}
                                variant="ghost"
                                size="lg"
                                mr={2}
                                borderRadius="full"
                            />
                        </Tooltip>
                        {content && (
                            <Tooltip label={isEditing ? t("View") : t("Edit")}>
                                <IconButton
                                    aria-label={isEditing ? "View Presentation" : "Edit Presentation"}
                                    icon={<FontAwesomeIcon icon={isEditing ? faEye : faEdit} />}
                                    onClick={() => setIsEditing(!isEditing)}
                                    variant="ghost"
                                    size="lg"
                                    borderRadius="full"
                                />
                            </Tooltip>
                        )}
                    </Flex>
                </Flex>

                <AnimatePresence>
                    {loading ? (
                        <MotionBox
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            h="75vh"
                        >
                            <Spinner size="xl" color={accentColor} />
                        </MotionBox>
                    ) : (
                        <MotionBox
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={contentVariants}
                            transition={{ type: 'tween', duration: 0.3 }}
                            bg={cardBg}
                            borderRadius="xl"
                            boxShadow="xl"
                            p={4}
                            h="100%"
                            overflow="hidden"
                        >
                            {renderContent()}
                        </MotionBox>
                    )}
                </AnimatePresence>
            </VStack>

            <Drawer
                isOpen={isOpen}
                placement={isLargerThan768 ? "right" : "bottom"}
                onClose={onClose}
                size={isLargerThan768 ? "md" : "full"}
            >
                <DrawerOverlay />
                <DrawerContent bg={cardBg} color={textColor}>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth="1px">{t("Saved Presentations")}</DrawerHeader>
                    <DrawerBody>
                        <AnimatePresence>
                            {savedPresentations.length === 0 ? (
                                <MotionBox
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <Text>{t("No saved presentations found.")}</Text>
                                </MotionBox>
                            ) : (
                                <VStack align="stretch" spacing={4}>
                                    {savedPresentations.map((document) => (
                                        <MotionBox
                                            key={document.documentId}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -20 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Flex
                                                p={4}
                                                borderWidth="1px"
                                                borderRadius="md"
                                                _hover={{ bg: hoverBg }}
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Box onClick={() => handleDocumentSelect(document)} cursor="pointer" flex={1}>
                                                    <Text fontWeight="bold">{document.topic || document.documentId}</Text>
                                                    <Text fontSize="sm" color={mutedColor}>
                                                        {new Date(document.created_at).toLocaleString()}
                                                    </Text>
                                                </Box>
                                                <IconButton
                                                    aria-label="Delete presentation"
                                                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                                    onClick={() => deletePresentation(document.documentId)}
                                                    size="sm"
                                                    variant="ghost"
                                                    colorScheme="red"
                                                    borderRadius="full"
                                                />
                                            </Flex>
                                        </MotionBox>
                                    ))}
                                </VStack>
                            )}
                        </AnimatePresence>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isDownloadOpen} onClose={onDownloadClose} isCentered motionPreset="scale">
                <ModalOverlay backdropFilter="blur(10px)" />
                <ModalContent bg={cardBg} color={textColor} borderRadius="xl" boxShadow="2xl">
                    <ModalHeader>{t("Select Download Format")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <RadioGroup onChange={setDownloadFormat} value={downloadFormat}>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                {['pptx', 'docx', 'html', 'txt'].map((format) => (
                                    <GridItem key={format}>
                                        <Box
                                            as="label"
                                            htmlFor={format}
                                            cursor="pointer"
                                            borderWidth="1px"
                                            borderRadius="md"
                                            p={3}
                                            textAlign="center"
                                            _hover={{ bg: hoverBg }}
                                            bg={downloadFormat === format ? hoverBg : 'transparent'}
                                        >
                                            <Radio id={format} value={format} display="none" />
                                            <Text fontWeight="bold">{format.toUpperCase()}</Text>
                                        </Box>
                                    </GridItem>
                                ))}
                            </Grid>
                        </RadioGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            onClick={handleDownload} 
                            colorScheme="teal" 
                            mr={3}
                            borderRadius="full"
                            _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                            transition="all 0.2s"
                            leftIcon={<DownloadIcon />}
                        >
                            {t("Download")}
                        </Button>
                        <Button 
                            onClick={onDownloadClose}
                            variant="ghost"
                            borderRadius="full"
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default PPTEditorPage;