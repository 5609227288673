import React, { useState, useEffect, useCallback } from 'react';
import {
    Box, Button, FormControl, FormLabel, Input, VStack, Heading, useToast,
    InputGroup, InputRightElement, IconButton, Link, Text, Checkbox,
    Container, useColorModeValue, ScaleFade, useMediaQuery, Flex, Image,
    Divider, Stack, Modal, ModalOverlay, ModalContent, ModalBody, keyframes, useClipboard
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ViewIcon, ViewOffIcon, CopyIcon, CheckIcon } from '@chakra-ui/icons';
import { FaTwitter, FaGoogle } from 'react-icons/fa';
import logo from '../images/maximo-ai-logo-circle.png';

const MotionBox = motion(Box);

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const DomainChangePopup = ({ isOpen }) => {
    const { t } = useTranslation();
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const { hasCopied, onCopy } = useClipboard("https://maximoai.co");

    return (
        <Modal isOpen={isOpen} onClose={() => { }} isCentered>
            <ModalOverlay />
            <ModalContent
                bg="gray.800"
                borderRadius="2xl"
                boxShadow="dark-lg"
                p={8}
                maxW={isMobile ? "90%" : "500px"}
                m={4}
            >
                <ModalBody>
                    <VStack spacing={6}>
                        <Box as="svg" width="100%" height="150px" viewBox="0 0 200 100">
                            <motion.circle
                                cx="100"
                                cy="50"
                                r="40"
                                fill="#4FD1C5"
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.5, ease: "easeOut" }}
                            />
                            <motion.path
                                d="M85 50 L100 65 L115 50"
                                stroke="#1A202C"
                                strokeWidth="4"
                                fill="none"
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: 1 }}
                                transition={{ duration: 0.5, delay: 0.5 }}
                            />
                        </Box>
                        <Heading as="h2" size="xl" textAlign="center" color="teal.300">
                            {t('important_update')}
                        </Heading>
                        <Text fontSize="lg" textAlign="center" color="white">
                            {t('new_domain_message')}
                        </Text>
                        <Flex alignItems="center">
                            <Link
                                href="https://maximoai.co"
                                isExternal
                                color="teal.300"
                                fontWeight="bold"
                                fontSize="xl"
                                mr={2}
                                _hover={{ textDecoration: 'underline' }}
                            >
                                https://maximoai.co
                            </Link>
                            <IconButton
                                icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
                                onClick={onCopy}
                                aria-label="Copy domain"
                                colorScheme="teal"
                                size="sm"
                            />
                        </Flex>
                        <Text fontSize="md" textAlign="center" color="gray.300">
                            {t('web_app_update_message')}
                        </Text>
                        <Button
                            as="a"
                            href="https://maximoai.co"
                            colorScheme="teal"
                            size="lg"
                            width="full"
                            height="60px"
                            fontSize="xl"
                            borderRadius="xl"
                            boxShadow="md"
                            _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                            animation={`${pulseAnimation} 2s infinite`}
                        >
                            {t('go_to_new_domain')}
                        </Button>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const SignUpPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();
    const [isMobile] = useMediaQuery("(max-width: 480px)");

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        twitterUsername: '',
        password: '',
        referralCode: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showDomainChangePopup] = useState(true);

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBgColor = useColorModeValue('white', 'gray.800');

    const handleAuthMessage = useCallback((event) => {
        if (event.origin !== process.env.REACT_APP_API_URL) return;
        if (event.data.token) {
            localStorage.setItem('token', event.data.token);
            toast({
                title: t('login_successful'),
                description: t('welcome_back'),
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate('/chat');
        }
    }, [navigate, toast, t]);

    useEffect(() => {
        window.addEventListener('message', handleAuthMessage);

        const token = localStorage.getItem('token');
        if (token) {
            navigate('/chat');
        }

        const params = new URLSearchParams(location.search);
        const referralCode = params.get('ref');
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
            setFormData(prevState => ({
                ...prevState,
                referralCode: referralCode
            }));
        } else {
            const storedReferralCode = localStorage.getItem('referralCode');
            if (storedReferralCode) {
                setFormData(prevState => ({
                    ...prevState,
                    referralCode: storedReferralCode
                }));
            }
        }

        return () => {
            window.removeEventListener('message', handleAuthMessage);
        };
    }, [navigate, location, handleAuthMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.username.trim().length === 0) {
            toast({
                title: t('error'),
                description: t('empty_username_error'),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (formData.username.trim().length > 12) {
            toast({
                title: t('error'),
                description: t('max_length_username_error'),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (!acceptedTerms) {
            toast({
                title: t('error'),
                description: t('must_accept_terms'),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const ipAddress = ipResponse.data.ip;

            const signupData = {
                ...formData,
                ipAddress: ipAddress
            };

            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/signup`, signupData);

            console.log(response.data);
            toast({
                title: t('account_created'),
                description: t('account_created_description'),
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            setFormData({
                username: '',
                email: '',
                twitterUsername: '',
                password: '',
                referralCode: ''
            });
            localStorage.setItem('token', response.data.token);
            navigate('/welcome');
        } catch (error) {
            toast({
                title: t('error'),
                description: error.response ? error.response.data.message : t('an_error_occurred'),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleShowClick = () => setShowPassword(!showPassword);

    const handleSocialSignIn = (provider) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
            `${apiUrl}/auth/${provider}`,
            'Auth',
            `width=${width},height=${height},left=${left},top=${top}`
        );
    };

    return (
        <Box minHeight="100vh" bg={bgColor} py={isMobile ? 0 : 12} px={0}>
            <Container maxW="lg" px={0} h={isMobile ? "100vh" : "auto"}>
                <ScaleFade initialScale={0.9} in={true}>
                    <MotionBox
                        bg={cardBgColor}
                        p={isMobile ? 6 : 8}
                        boxShadow={isMobile ? "none" : "2xl"}
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        h={isMobile ? "100vh" : "auto"}
                        overflowY={isMobile ? "auto" : "visible"}
                    >
                        <Flex direction="column" align="center" mb={8}>
                            <Image src={logo} boxSize={isMobile ? "80px" : "60px"} alt="Maximo AI Logo" mb={4} />
                            <Heading as="h1" fontFamily="Lexend" fontSize={isMobile ? "3xl" : "2xl"} fontWeight="bold" textAlign="center">
                                {t('sign_up')}
                            </Heading>
                        </Flex>
                        <VStack spacing={6} as="form" onSubmit={handleSubmit}>
                            <Stack direction={isMobile ? "column" : "row"} spacing={4} width="100%">
                                <Button
                                    leftIcon={<FaTwitter />}
                                    colorScheme="twitter"
                                    onClick={() => handleSocialSignIn('twitter')}
                                    width="full"
                                    fontFamily="Lexend"
                                    size={isMobile ? "lg" : "md"}
                                    borderRadius="2xl"
                                >
                                    {t('sign_up_with_twitter')}
                                </Button>
                                <Button
                                    leftIcon={<FaGoogle />}
                                    colorScheme="red"
                                    onClick={() => handleSocialSignIn('google')}
                                    width="full"
                                    fontFamily="Lexend"
                                    size={isMobile ? "lg" : "md"}
                                    borderRadius="2xl"
                                >
                                    {t('sign_up_with_google')}
                                </Button>
                            </Stack>
                            <Divider />
                            <FormControl isRequired>
                                <FormLabel fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"}>{t('username')}</FormLabel>
                                <Input
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    placeholder={t('username')}
                                    fontFamily="Lexend"
                                    size={isMobile ? "lg" : "md"}
                                    borderRadius="2xl"
                                    _focus={{ borderColor: 'teal.500', boxShadow: '0 0 0 1px teal.500' }}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"}>{t('email_address')}</FormLabel>
                                <Input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder={t('email_address')}
                                    fontFamily="Lexend"
                                    size={isMobile ? "lg" : "md"}
                                    borderRadius="2xl"
                                    _focus={{ borderColor: 'teal.500', boxShadow: '0 0 0 1px teal.500' }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"}>{t('twitter_username_optional')}</FormLabel>
                                <Input
                                    name="twitterUsername"
                                    value={formData.twitterUsername}
                                    onChange={handleChange}
                                    placeholder={t('twitter_username_optional')}
                                    fontFamily="Lexend"
                                    size={isMobile ? "lg" : "md"}
                                    borderRadius="2xl"
                                    _focus={{ borderColor: 'teal.500', boxShadow: '0 0 0 1px teal.500' }}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"}>{t('password')}</FormLabel>
                                <InputGroup size={isMobile ? "lg" : "md"}>
                                    <Input
                                        pr="4.5rem"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder={t('password')}
                                        fontFamily="Lexend"
                                        borderRadius="2xl"
                                        _focus={{ borderColor: 'teal.500', boxShadow: '0 0 0 1px teal.500' }}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <IconButton
                                            h="1.75rem"
                                            size="sm"
                                            onClick={handleShowClick}
                                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                            borderRadius="xl"
                                        />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"}>{t('referral_code')}</FormLabel>
                                <Input
                                    name="referralCode"
                                    value={formData.referralCode}
                                    onChange={handleChange}
                                    placeholder={t('enter_referral_code')}
                                    fontFamily="Lexend"
                                    size={isMobile ? "lg" : "md"}
                                    borderRadius="2xl"
                                    _focus={{ borderColor: 'teal.500', boxShadow: '0 0 0 1px teal.500' }}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <Checkbox
                                    isChecked={acceptedTerms}
                                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                                    fontFamily="Lexend"
                                    fontSize={isMobile ? "md" : "sm"}
                                >
                                    {t('accept_terms_1')}{' '}
                                    <Link as={RouterLink} to="/terms-of-use" color="teal.500">{t('terms_of_use')}</Link>
                                    {' '}{t('and')}{' '}
                                    <Link as={RouterLink} to="/privacy-policy" color="teal.500">{t('privacy_policy')}</Link>
                                    {' '}{t('accept_terms_2')}
                                </Checkbox>
                            </FormControl>
                            <Button
                                mt={4}
                                colorScheme="teal"
                                type="submit"
                                fontFamily="Lexend"
                                size={isMobile ? "lg" : "md"}
                                width="full"
                                borderRadius="2xl"
                                _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                                transition="all 0.2s"
                            >
                                {t('create_account')}
                            </Button>
                            <Box pt={6}>
                                <Text align="center" fontFamily="Lexend" fontSize={isMobile ? "lg" : "md"}>
                                    {t('already_have_account')}{' '}
                                    <Link as={RouterLink} to="/login" color="teal.500" fontWeight="medium" _hover={{ textDecoration: 'underline' }}>
                                        {t('login')}
                                    </Link>
                                </Text>
                            </Box>
                        </VStack>
                    </MotionBox>
                </ScaleFade>
            </Container>
            <DomainChangePopup isOpen={showDomainChangePopup} />
        </Box>
    );
};

export default SignUpPage;