// src/utils/eventEmitter.js
class EventEmitter {
    constructor() {
        this.events = {};
    }

    on(eventName, listener) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(listener);
    }

    emit(eventName, payload) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(listener => listener(payload));
        }
    }

    removeListener(eventName, listenerToRemove) {
        if (this.events[eventName]) {
            this.events[eventName] = this.events[eventName].filter(listener => listener !== listenerToRemove);
        }
    }
}

export const emitter = new EventEmitter();
