import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
    Box,
    Heading,
    VStack,
    IconButton,
    useToast,
    Spinner,
    Switch,
    FormControl,
    HStack,
    Flex,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    RadioGroup,
    Radio,
    useColorModeValue,
    Tooltip,
    Badge,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useBreakpointValue,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { ChevronLeftIcon, DownloadIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faFolderOpen, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);

const EmptyStateAnimation = () => (
    <Box as="svg" width="200px" height="200px" viewBox="0 0 100 100">
        <motion.circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="#3182CE"
            strokeWidth="2"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, repeat: Infinity }}
        />
        <motion.path
            d="M30 50 L45 65 L70 40"
            fill="none"
            stroke="#3182CE"
            strokeWidth="2"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1, delay: 1 }}
        />
    </Box>
);

const DocumentEditorPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();
    const { response } = location.state || { response: { text: '' } };
    const [content, setContent] = useState(formatContent(response.text));
    const [loading, setLoading] = useState(false);
    const [autoSave, setAutoSave] = useState(false);
    const [autoSaveStatus, setAutoSaveStatus] = useState('not-in-sync');
    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDownloadOpen, onOpen: onDownloadOpen, onClose: onDownloadClose } = useDisclosure();
    const [downloadFormat, setDownloadFormat] = useState('html');

    const bgColor = useColorModeValue("gray.50", "gray.900");
    const textColor = useColorModeValue("gray.800", "gray.100");
    const cardBg = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.700");
    const hoverBg = useColorModeValue("gray.100", "gray.700");
    const mutedColor = useColorModeValue("gray.600", "gray.400");
    const accentColor = useColorModeValue("teal.500", "teal.300");

    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleContentChange = (value) => {
        setContent(value);
        if (autoSave) {
            setAutoSaveStatus('not-saved');
        }
    };

    const handleSave = useCallback(async () => {
        if (!selectedDocument) {
            toast({
                title: 'Error',
                description: 'No document selected.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);
        try {
            const formattedContent = formatContent(content);
            const documentData = {
                documentId: selectedDocument.documentId,
                content: formattedContent,
                document_type: selectedDocument.document_type,
                topic: selectedDocument.topic,
                tone: selectedDocument.tone,
                language: selectedDocument.language,
                slides: selectedDocument.slides,
                more_details: selectedDocument.more_details,
            };

            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/save-document`,
                documentData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            toast({
                title: 'Success',
                description: 'Document saved successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            setAutoSaveStatus('saved');
        } catch (error) {
            console.error('Error saving document:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while saving the document.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            setAutoSaveStatus('not-saved');
        }
        setLoading(false);
    }, [content, selectedDocument, toast]);

    const handleDownload = () => {
        const formattedContent = formatContent(content);
        const element = document.createElement('a');
        let file;
        let filename = 'document';

        switch (downloadFormat) {
            case 'html':
                file = new Blob([formattedContent], { type: 'text/html' });
                filename += '.html';
                break;
            case 'pdf':
                const printWindow = window.open('', '_blank');
                printWindow.document.write(formattedContent);
                printWindow.document.close();
                printWindow.print();
                return;
            case 'doc':
                const docxContent = htmlDocx.asBlob(formattedContent);
                file = docxContent;
                filename += '.doc';
                break;
            case 'txt':
                file = new Blob([formattedContent.replace(/<[^>]+>/g, '')], { type: 'text/plain' });
                filename += '.txt';
                break;
            default:
                return;
        }

        element.href = URL.createObjectURL(file);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
        onDownloadClose();
    };

    const handleAutoSaveToggle = () => {
        setAutoSave(!autoSave);
    };

    const fetchDocuments = useCallback(async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/documents`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            setDocuments(response.data.documents.reverse()); // Reverse to list the most recent first
        } catch (error) {
            console.error('Error fetching documents:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while fetching the documents.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }, [toast]);

    const handleDocumentSelect = (document) => {
        setSelectedDocument(document);
        setContent(formatContent(document.content));
        localStorage.setItem('selectedDocumentId', document.documentId);
        onClose();
    };

    const deleteDocument = async (documentId) => {
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/api/documents/${documentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            setDocuments(documents.filter(doc => doc.documentId !== documentId));
            if (selectedDocument && selectedDocument.documentId === documentId) {
                localStorage.removeItem('selectedDocumentId');
                setContent('');
                setSelectedDocument(null);
            }
            toast({
                title: 'Success',
                description: 'Document deleted successfully',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error deleting document:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while deleting the document.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        let autoSaveInterval;
        if (autoSave) {
            autoSaveInterval = setInterval(() => {
                handleSave();
            }, 15000);
        }
        return () => clearInterval(autoSaveInterval);
    }, [autoSave, handleSave]);

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    useEffect(() => {
        const savedDocumentId = localStorage.getItem('selectedDocumentId');
        if (savedDocumentId) {
            const loadDocument = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL}/api/documents/${savedDocumentId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`,
                            },
                        }
                    );
                    const document = response.data.document;
                    setSelectedDocument(document);
                    setContent(formatContent(document.content));
                } catch (error) {
                    console.error('Error loading document:', error);
                    // toast({
                    //     title: 'Error',
                    //     description: 'An error occurred while loading the document.',
                    //     status: 'error',
                    //     duration: 3000,
                    //     isClosable: true,
                    // });
                }
            };
            loadDocument();
        }
    }, []);

    const modules = {
        toolbar: [
            [{ 'font': ['sans-serif', 'serif', 'monospace', 'times-new-roman', 'verdana', 'lexend', 'roboto'] }, { 'size': [] }],
            [{ 'header': '1' }, { 'header': '2' }, 'bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    const formats = [
        'font', 'size', 'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'color', 'background', 'align',
        'link', 'image', 'video',
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case 'saved':
                return 'green.500';
            case 'not-saved':
                return 'red.500';
            case 'not-in-sync':
                return 'yellow.500';
            default:
                return 'gray.500';
        }
    };

    const contentVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -50 }
    };

    const renderContent = () => {
        if (content) {
            return (
                <MotionBox
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={contentVariants}
                    transition={{ type: 'tween', duration: 0.3 }}
                    bg={cardBg}
                    borderRadius="xl"
                    boxShadow="xl"
                    p={4}
                    h="100%"
                    overflow="hidden"
                >
                    <style>
                        {`
                            .ql-toolbar {
                                background-color: white !important;
                                border-top-left-radius: 0.75rem;
                                border-top-right-radius: 0.75rem;
                                border-bottom: 1px solid ${borderColor} !important;
                            }
                            .ql-container {
                                border-bottom-left-radius: 0.75rem;
                                border-bottom-right-radius: 0.75rem;
                                border: none !important;
                            }
                        `}
                    </style>
                    <ReactQuill
                        value={content}
                        onChange={handleContentChange}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        style={{ 
                            height: 'calc(75vh - 42px)',
                            borderRadius: '0.75rem',
                        }}
                    />
                </MotionBox>
            );
        } else {
            return (
                <VStack spacing={4} justify="center" align="center" h="100%">
                    <EmptyStateAnimation />
                    <Text fontSize="xl" fontWeight="bold" textAlign="center">
                        No document found
                    </Text>
                    <Text textAlign="center">
                        Check your saved documents or create a new one
                    </Text>
                    <Flex>
                        <Button
                            leftIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                            onClick={() => { fetchDocuments(); onOpen(); }}
                            mr={2}
                        >
                            Saved Documents
                        </Button>
                        <Button
                            as={Link}
                            to="/create"
                            leftIcon={<FontAwesomeIcon icon={faPlus} />}
                        >
                            Create New
                        </Button>
                    </Flex>
                </VStack>
            );
        }
    };

    return (
        <Box bg={bgColor} color={textColor} minH={{ base: "110vh", md: "100vh" }} p={4}>
            <VStack spacing={4} align="stretch">
                <Flex justifyContent="space-between" alignItems="center" width="100%">
                    <IconButton
                        aria-label="Go Back"
                        icon={<ChevronLeftIcon />}
                        onClick={() => navigate(-1)}
                        variant="ghost"
                        size="lg"
                        borderRadius="full"
                    />
                    <Heading as="h1" size="lg" fontWeight="extrabold" textAlign="center" flex={1}>
                        {t("Document Editor")}
                    </Heading>
                    <HStack spacing={2}>
                        <Tooltip label={t("Auto Save")}>
                            <FormControl display="flex" alignItems="center" w="auto">
                                <Switch
                                    id="auto-save"
                                    isChecked={autoSave}
                                    onChange={handleAutoSaveToggle}
                                    size="sm"
                                    colorScheme="teal"
                                />
                                <Badge ml={2} colorScheme={getStatusColor(autoSaveStatus)}>
                                    {autoSaveStatus}
                                </Badge>
                            </FormControl>
                        </Tooltip>
                        <Tooltip label={t("Save Document")}>
                            <IconButton
                                aria-label="Save Document"
                                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                                onClick={handleSave}
                                variant="ghost"
                                size="lg"
                                isLoading={loading}
                                borderRadius="full"
                                color={accentColor}
                            />
                        </Tooltip>
                        <Tooltip label={t("Download Document")}>
                            <IconButton
                                aria-label="Download Document"
                                icon={<DownloadIcon />}
                                onClick={onDownloadOpen}
                                variant="ghost"
                                size="lg"
                                borderRadius="full"
                                color={accentColor}
                            />
                        </Tooltip>
                        <Tooltip label={t("Open Documents")}>
                            <IconButton
                                aria-label="Open Documents"
                                icon={<FontAwesomeIcon icon={faFolderOpen} />}
                                onClick={onOpen}
                                variant="ghost"
                                size="lg"
                                borderRadius="full"
                                color={accentColor}
                            />
                        </Tooltip>
                    </HStack>
                </Flex>

                <AnimatePresence>
                    {loading ? (
                        <MotionBox
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            h="75vh"
                        >
                            <Spinner size="xl" color={accentColor} />
                        </MotionBox>
                    ) : (
                        renderContent()
                    )}
                </AnimatePresence>
            </VStack>

            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={isMobile ? "full" : "md"}>
                <DrawerOverlay backdropFilter="blur(5px)" />
                <DrawerContent bg={cardBg} color={textColor}>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth="1px" borderColor={borderColor}>
                        {t("Saved Documents")}
                    </DrawerHeader>
                    <DrawerBody>
                        <AnimatePresence>
                            {documents.length === 0 ? (
                                <MotionBox
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <Text>{t("No saved documents found.")}</Text>
                                </MotionBox>
                            ) : (
                                <VStack align="stretch" spacing={4}>
                                    {documents.map((document) => (
                                        <MotionBox
                                            key={document.documentId}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -20 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Flex
                                                p={4}
                                                borderWidth="1px"
                                                borderRadius="md"
                                                _hover={{ bg: hoverBg }}
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Box onClick={() => handleDocumentSelect(document)} cursor="pointer" flex={1}>
                                                    <Text fontWeight="bold">{document.topic || document.documentId}</Text>
                                                    <Text fontSize="sm" color={mutedColor}>
                                                        {new Date(document.created_at).toLocaleString()}
                                                    </Text>
                                                </Box>
                                                <IconButton
                                                    aria-label="Delete document"
                                                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                                    onClick={() => deleteDocument(document.documentId)}
                                                    size="sm"
                                                    variant="ghost"
                                                    colorScheme="red"
                                                    borderRadius="full"
                                                />
                                            </Flex>
                                        </MotionBox>
                                    ))}
                                </VStack>
                            )}
                        </AnimatePresence>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            <Modal isOpen={isDownloadOpen} onClose={onDownloadClose} isCentered motionPreset="scale">
                <ModalOverlay backdropFilter="blur(10px)" />
                <ModalContent bg={cardBg} color={textColor} borderRadius="xl" boxShadow="2xl">
                    <ModalHeader>{t("Select Download Format")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <RadioGroup onChange={setDownloadFormat} value={downloadFormat}>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                {['html', 'pdf', 'doc', 'txt'].map((format) => (
                                    <GridItem key={format}>
                                        <Box
                                            as="label"
                                            htmlFor={format}
                                            cursor="pointer"
                                            borderWidth="1px"
                                            borderRadius="md"
                                            p={3}
                                            textAlign="center"
                                            _hover={{ bg: hoverBg }}
                                            bg={downloadFormat === format ? hoverBg : 'transparent'}
                                        >
                                            <Radio id={format} value={format} display="none" />
                                            <Text fontWeight="bold">{format.toUpperCase()}</Text>
                                        </Box>
                                    </GridItem>
                                ))}
                            </Grid>
                        </RadioGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            onClick={handleDownload} 
                            colorScheme="teal" 
                            mr={3}
                            borderRadius="full"
                            _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
                            transition="all 0.2s"
                            leftIcon={<DownloadIcon />}
                        >
                            {t("Download")}
                        </Button>
                        <Button 
                            onClick={onDownloadClose}
                            variant="ghost"
                            borderRadius="full"
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

const formatContent = (text) => {
    if (!text) return '';

    let formattedText = text.trim();

    // Create a regex pattern to match any header level or bold markers followed by "Slide 1:"
    const slideStartPatterns = [/^# Slide 1:/im, /^## Slide 1:/im, /^### Slide 1:/im, /^#### Slide 1:/im, /^\*\* Slide 1:/im];
    let slidesStartIndex = -1;

    for (const pattern of slideStartPatterns) {
        const index = formattedText.search(pattern);
        if (index !== -1) {
            slidesStartIndex = index;
            break;
        }
    }

    if (slidesStartIndex !== -1) {
        formattedText = formattedText.substring(slidesStartIndex);
    }

    // Apply the necessary formatting
    formattedText = formattedText.replace(/^## (.*?)(\n|$)/gm, '<h2>$1</h2>');
    formattedText = formattedText.replace(/^### (.*?)(\n|$)/gm, '<h3>$1</h3>');
    formattedText = formattedText.replace(/^# (.*?)(\n|$)/gm, '<strong>$1</strong>');
    formattedText = formattedText.replace(/^#### (.*?)(\n|$)/gm, '<h4>$1</h4>');
    formattedText = formattedText.replace(/^\*\* (.*?)(\n|$)/gm, '<strong>$1</strong>'); // Bold headers

    // Additional formatting for bold text and unordered lists
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // Bold text
    formattedText = formattedText.replace(/^\* (.*?)(\n|$)/gm, '<ul><li>$1</li></ul>'); // Bullet points
    formattedText = formattedText.replace(/---/g, '<hr>'); // Horizontal rules

    // Links, code blocks, and line breaks
    formattedText = formattedText.replace(/\bhttps?:\/\/\S+\.\S+/gi, '<a href="$&" target="_blank">$&</a>');
    formattedText = formattedText.replace(/```(.*?)```/gs, '<pre>$1</pre>');
    formattedText = formattedText.replace(/`(.*?)`/g, '<code>$1</code>');
    formattedText = formattedText.replace(/(?<!<\/h2>|<\/h3>|<\/strong>|<\/h4>)\n/g, '<br>');

    return formattedText;
};

export default DocumentEditorPage;
