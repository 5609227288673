import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box, VStack, Button, Text, useToast, Flex, Image, Center, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure,
    Avatar, IconButton, ModalCloseButton, ModalFooter, InputRightElement, InputGroup, InputLeftElement, ScaleFade, ModalHeader,
    useColorModeValue, Container, Heading, Grid, keyframes, Collapse, Input, List, ListItem, Checkbox, Tag, TagLabel
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import maximoAvatar from '../images/maximo-ai-logo-circle.png';
import userAvatar from '../images/user-avatar.png';
import companyLogo from '../images/maximo-ai-logo-circle.png';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faStop, faPaperclip, faPaperPlane, faCopy, faShareAlt, faRedo, faChevronRight, faTimes, faDownload, faCode, faChevronDown, faChevronUp, faFolder } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PlaygroundPopup from './PlaygroundPopup';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const styles = `
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-out;
}

.markdown-content {
    font-family: 'Lexend', sans-serif;
    line-height: 1.6;
}

.markdown-content h1 {
    font-size: 1.8em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.markdown-content h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 0.8em;
    margin-bottom: 0.4em;
}

.markdown-content h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0.6em;
    margin-bottom: 0.3em;
}

.markdown-content p {
    margin-bottom: 1em;
}

.markdown-content ul, .markdown-content ol {
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.markdown-content li {
    margin-bottom: 0.5em;
}

.markdown-content blockquote {
    border-left: 4px solid #718096;
    padding-left: 1em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    color: #718096;
}

.markdown-content code {
    font-family: 'Fira Code', monospace;
    background-color: #2D3748;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-size: 0.9em;
}

.markdown-content pre {
    background-color: #2D3748;
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
    margin-bottom: 1em;
}

.markdown-content a {
    color: #4299E1;
    text-decoration: underline;
}

.markdown-content table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
}

.markdown-content th, .markdown-content td {
    border: 1px solid #4A5568;
    padding: 0.5em;
    text-align: left;
}

.markdown-content th {
    background-color: #2D3748;
    font-weight: bold;
}

.code-block-wrapper {
    position: relative;
}

.copy-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 10;
}

.chat-image {
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.chat-image:hover {
    transform: scale(1.05);
}

@media (max-width: 480px) {
    .markdown-content {
        font-size: 0.9em;
    }

    .markdown-content h1 {
        font-size: 1.6em;
    }

    .markdown-content h2 {
        font-size: 1.3em;
    }

    .markdown-content h3 {
        font-size: 1.1em;
    }
}
`;

const LoadingMessage = () => {
    const { t } = useTranslation();
    return (
        <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
        >
            <Flex
                alignItems="center"
                justifyContent="center"
                borderRadius="lg"
                p={4}
                boxShadow="lg"
                maxWidth="300px"
                margin="0 auto"
                background="linear-gradient(45deg, #00FFB9, #00A4D3)"
                backgroundSize="200% 200%"
                animation={`${gradientAnimation} 3s ease infinite`}
            >
                <MotionBox
                    animate={{ scale: [1, 1.2, 1] }}
                    transition={{ duration: 1.5, repeat: Infinity }}
                >
                    <Image src={companyLogo} boxSize="40px" alt="Company Logo" />
                </MotionBox>
                <Text ml={4} fontWeight="bold" color="white">
                    {t('Thinking')}
                </Text>
            </Flex>
        </MotionBox>
    );
};

const CodeBlock = ({ language, value }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="code-block-wrapper">
            <CopyToClipboard text={value} onCopy={handleCopy}>
                <IconButton
                    aria-label="Copy code"
                    icon={<FontAwesomeIcon icon={faCopy} />}
                    size="sm"
                    colorScheme={copied ? "green" : "blue"}
                    className="copy-button"
                />
            </CopyToClipboard>
            <SyntaxHighlighter language={language} style={atomDark}>
                {value}
            </SyntaxHighlighter>
        </div>
    );
};

const Message = ({ text, author, avatar, chartUrl, currentPrice, recommendations, isGPT, isLoading, image, supportLevel, resistanceLevel, onRegenerate, hasMultipleResponses, onSwitchResponse, currentResponseIndex, totalResponses, setReplyingToMessage, onOpenPlayground, replyTo, newPrompt, extractedTextInfo }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const { t } = useTranslation();
    const chatRef = useRef();
    const shareModal = useDisclosure();
    const [shareableLink, setShareableLink] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(true);

    const selectedModel = localStorage.getItem('selectedModel') || 'maximo';

    const handleCopySuccess = () => {
        toast({
            title: t('copied'),
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

    const openDownloadModal = (imageUrl) => {
        setSelectedImage(imageUrl);
        setDownloadModalOpen(true);
    };

    const closeDownloadModal = () => {
        setDownloadModalOpen(false);
    };

    const downloadImage = () => {
        const link = document.createElement('a');
        link.href = selectedImage;
        link.download = 'maximo-image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    let contentToCopy = text;
    if (recommendations && recommendations.length > 0) {
        const recommendationText = recommendations.map(rec => `\n${rec.pair}: ${rec.recommendation}`).join('');
        contentToCopy += recommendationText;
    }
    if (currentPrice) {
        contentToCopy += `\n${t('current_price')}: ${currentPrice}`;
    }

    const fetchCurrentSessionId = async () => {
        const authToken = localStorage.getItem('token');
        if (!authToken) {
            toast({
                title: 'Error',
                description: 'Authentication token is missing.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/current-session`, {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            return response.data.session_id;
        } catch (error) {
            console.error('Error fetching current session ID:', error);
            toast({
                title: 'Error',
                description: 'Unable to fetch the current session ID.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const onShare = async () => {
        try {
            const sessionId = await fetchCurrentSessionId();
            if (!sessionId) {
                return;
            }

            const apiUrl = process.env.REACT_APP_API_URL;
            const shareResponse = await axios.post(`${apiUrl}/share-chat`, { session_id: sessionId });
            setShareableLink(`https://maximo.huddlz.xyz/view-shared-chat/${shareResponse.data.shareableLink.split('/').pop()}`);
            shareModal.onOpen();
        } catch (error) {
            console.error('Error generating shareable link:', error);
            toast({
                title: 'Error',
                description: 'Unable to generate shareable link.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex
            alignSelf="center"
            alignItems={author === 'user' || recommendations || isGPT ? 'flex-start' : 'flex-end'}
            direction="column"
            maxW="100%"
            my={3}
            ref={chatRef}
            className={author === 'maximo' ? 'fade-in' : ''}
            css={styles}
            position="relative"
        >
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                >
                    <ScaleFade initialScale={0.9} in={true}>
                        <Flex alignItems="center">
                            <Image borderRadius="full" boxSize="30px" src={avatar} alt={t('avatar_alt', { author })} />
                            <Text ml={2} fontWeight="bold" fontSize="md">
                                {author === 'maximo'
                                    ? (selectedModel === 'claude' ? 'Maximo AI (with Claude 3.5 Sonnet)' : 'Maximo AI')
                                    : 'You'}
                            </Text>
                        </Flex>
                        {replyTo && (
                            <Box mt={2} mb={2} p={2} borderLeft="4px solid" borderColor="blue.500" bg="gray.700" borderRadius="md">
                                <Flex justifyContent="space-between" alignItems="center" mb={2}>
                                    <Text fontWeight="bold" fontSize="sm">{t('Your Last Answer')}</Text>
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />}
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => setIsCollapsed(!isCollapsed)}
                                        aria-label={isCollapsed ? "Expand" : "Collapse"}
                                    />
                                </Flex>
                                <Collapse in={!isCollapsed} animateOpacity>
                                    <Text fontSize="sm" noOfLines={3}>
                                        {replyTo.text}
                                    </Text>
                                </Collapse>
                            </Box>
                        )}
                        {author === 'user' && replyTo && newPrompt && (
                            <Box mt={2} mb={2}>
                                <Text fontWeight="bold" fontSize="sm">{t('New Prompt')}</Text>
                                <Text fontSize="sm">{newPrompt}</Text>
                            </Box>
                        )}
                        <Box
                            textAlign={isGPT ? 'normal' : 'left'}
                            overflowWrap="break-word"
                            overflowY="auto"
                            flex="1"
                            maxWidth="100%"
                            position="relative"
                        >
                            {image && (
                                <Image
                                    src={image}
                                    alt={author === 'user' ? "Sent Image" : "Maximo AI Generated Image"}
                                    mt={2}
                                    mb={2}
                                    width="100%"
                                    maxHeight="400px"
                                    objectFit="contain"
                                    cursor="pointer"
                                    onClick={() => openDownloadModal(image)}
                                    className="chat-image"
                                />
                            )}
                            {author === 'user' && text && !replyTo && (
                                <Text mt={2} mb={1} fontStyle="normal" fontFamily="'Lexend', sans-serif" fontWeight="light">
                                    {text}
                                </Text>
                            )}
                            {extractedTextInfo && author === 'user' && (
                                <Tag size="md" variant="subtle" colorScheme="blue" mt={2} mb={2}>
                                    <TagLabel>
                                        <FontAwesomeIcon icon={faFolder} /> {extractedTextInfo.folderName}
                                    </TagLabel>
                                </Tag>
                            )}
                            {!image && text && author === 'maximo' && (
                                <Box className="markdown-content">
                                    <ReactMarkdown
                                        components={{
                                            code({ node, inline, className, children, ...props }) {
                                                const match = /language-(\w+)/.exec(className || '')
                                                return !inline && match ? (
                                                    <CodeBlock
                                                        language={match[1]}
                                                        value={String(children).replace(/\n$/, '')}
                                                        {...props}
                                                    />
                                                ) : (
                                                    <code className={className} {...props}>
                                                        {children}
                                                    </code>
                                                )
                                            }
                                        }}
                                    >
                                        {text}
                                    </ReactMarkdown>
                                </Box>
                            )}
                            {recommendations && (
                                <Box mt={1}>
                                    {recommendations.map((rec, index) => (
                                        <Text key={index} fontWeight="bold">{rec.pair}: {rec.recommendation}</Text>
                                    ))}
                                </Box>
                            )}
                            {supportLevel && <Text mt={1}><strong>Support Level:</strong> {supportLevel}</Text>}
                            {resistanceLevel && <Text mt={1}><strong>Resistance Level:</strong> {resistanceLevel}</Text>}
                            {chartUrl && (
                                <Image
                                    cursor="pointer"
                                    src={chartUrl}
                                    mt={1}
                                    width="100%"
                                    maxWidth="400px"
                                    height="auto"
                                    onClick={onOpen}
                                    className="chat-image"
                                />
                            )}
                        </Box>
                        <Box mt={2}>{isLoading && <LoadingMessage />}</Box>
                        {author === 'maximo' && !image && (
                            <Flex justifyContent="flex-end" mt={1}>
                                <IconButton aria-label="Open playground" icon={<FontAwesomeIcon icon={faCode} />} onClick={() => onOpenPlayground(text)} size="sm" isRound mr={1} />
                                <CopyToClipboard text={contentToCopy} onCopy={handleCopySuccess}>
                                    <IconButton aria-label="Copy chat" icon={<FontAwesomeIcon icon={faCopy} />} size="sm" isRound mr={1} />
                                </CopyToClipboard>
                                <IconButton aria-label="Share chat" icon={<FontAwesomeIcon icon={faShareAlt} />} onClick={onShare} size="sm" isRound mr={1} />
                                <IconButton aria-label="Regenerate response" icon={<FontAwesomeIcon icon={faRedo} />} onClick={onRegenerate} size="sm" isRound mr={1} />
                                {hasMultipleResponses && (
                                    <>
                                        <IconButton aria-label="Next response" icon={<FontAwesomeIcon icon={faChevronRight} />} onClick={() => onSwitchResponse('next')} size="sm" isRound ml={1} mr={1} />
                                    </>
                                )}
                                <IconButton aria-label="Reply to message" icon={<FontAwesomeIcon icon={faReply} />} size="sm" isRound onClick={() => setReplyingToMessage({ text, author })} />
                            </Flex>
                        )}
                    </ScaleFade>
                </motion.div>
            </AnimatePresence>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="lg" shadow="xl" mx={[4, 0]} maxW="400px">
                    <ModalBody>
                        <Image src={chartUrl} className="chat-image" />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <ShareModal isOpen={shareModal.isOpen} onClose={shareModal.onClose} shareableLink={shareableLink} />
            <Modal isOpen={isDownloadModalOpen} isCentered onClose={closeDownloadModal} size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="lg" shadow="xl" mx={[4, 0]} maxW="400px">
                    <ModalBody>
                        <Image src={selectedImage} alt="Maximo AI Generated Image" className="chat-image" />
                    </ModalBody>
                    <ModalFooter>
                        <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} onClick={downloadImage} colorScheme="blue" mr={3}>
                            {t('Download')}
                        </Button>
                        <ModalCloseButton />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

const ShareModal = ({ isOpen, onClose, shareableLink }) => {
    const { t } = useTranslation();
    const toast = useToast();

    const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(t('share_message'))}: ${encodeURIComponent(shareableLink)}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(t('share_message'))}&url=${encodeURIComponent(shareableLink)}`;

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareableLink);
        toast({
            title: t('copied'),
            status: 'success',
            duration: 2000,
            isClosable: true,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="scale">
            <ModalOverlay />
            <ModalContent borderRadius="lg" shadow="xl" mx={[4, 0]} maxW="400px" bg="gray.800" color="white">
                <ModalHeader fontSize="md" fontWeight="bold">{t('share_this_chat_session')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <VStack spacing={4}>
                        <Text fontSize="sm">{t('share_via')}:</Text>
                        <Button as="a" href={whatsappShareUrl} target="_blank" colorScheme="whatsapp" leftIcon={<FontAwesomeIcon icon={['fab', 'whatsapp']} />}>
                            {t('share_on_whatsapp')}
                        </Button>
                        <Button as="a" href={twitterShareUrl} target="_blank" colorScheme="twitter" leftIcon={<FontAwesomeIcon icon={['fab', 'twitter']} />}>
                            {t('share_on_twitter')}
                        </Button>
                        <Button leftIcon={<FontAwesomeIcon icon={faCopy} />} onClick={handleCopyLink}>
                            {t('copy_link')}
                        </Button>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>{t('close')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const MessagesContainer = ({ messages, onRegenerate, onSwitchResponse, setReplyingToMessage, onOpenPlayground }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Box overflowY="auto" flex="1">
            <AnimatePresence>
                {messages && messages.filter(msg => msg.isLoading || (msg.text && !msg.text.startsWith('Make better:'))).map((message, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Message
                            {...message}
                            isLoading={message.isLoading}
                            onRegenerate={() => onRegenerate(message, index)}
                            hasMultipleResponses={message.responses && message.responses.length > 1}
                            onSwitchResponse={(direction) => onSwitchResponse(index, direction)}
                            currentResponseIndex={message.currentResponseIndex || 0}
                            totalResponses={message.responses ? message.responses.length : 1}
                            setReplyingToMessage={setReplyingToMessage}
                            onOpenPlayground={onOpenPlayground}
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
            <div ref={messagesEndRef} />
        </Box>
    );
};

const quickSendKeys = [
    "quickSendMessages.dogeUsdt24Hours",
    "quickSendMessages.ethUsdt24Hours",
    "quickSendMessages.mxEth1Hour",
    "quickSendMessages.flokiUsdt24Hours",
    "quickSendMessages.oltUsdt24Hours",
    "quickSendMessages.whatCoinsShouldISell",
    "quickSendMessages.whatCoinsShouldIBuy",
    "quickSendMessages.whatCanMaximoAiDo",
    "quickSendMessages.funFact",
    "quickSendMessages.recipe",
    "quickSendMessages.webPage"
];

function ChatV2() {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileURL, setSelectedFileURL] = useState(null);
    const [replyingToMessage, setReplyingToMessage] = useState(null);
    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showQuickSend, setShowQuickSend] = useState(false);
    const btnRef = useRef();
    const [inputText, setInputText] = useState('');
    const [rows, setRows] = useState(1);
    const [chatStarted, setChatStarted] = useState(() => {
        return localStorage.getItem('chatStarted') === 'true';
    });
    const [playgroundContent, setPlaygroundContent] = useState(null);
    const [showFolderSearch, setShowFolderSearch] = useState(false);
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [extractedTextInfo, setExtractedTextInfo] = useState(null);

    const getRandomQuickSendMessages = useCallback(() => {
        let shuffled = quickSendKeys.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, 2).map(key => ({ text: t(key), command: t(key) }));
    }, [t]);

    useEffect(() => {
        if (messages && messages.length === 0 && localStorage.getItem('chatStarted') === 'true') {
            setShowQuickSend(true);
            setQuickSendMessages(getRandomQuickSendMessages());
        } else {
            setShowQuickSend(false);
        }
    }, [messages, t, getRandomQuickSendMessages]);

    const [quickSendMessages, setQuickSendMessages] = useState(getRandomQuickSendMessages());

    const [userProfile, setUserProfile] = useState({
        username: '',
        walletAddress: '',
        profilePhoto: userAvatar,
    });

    const handleQuickSendMessage = (message) => {
        sendMessage(message);
        setShowQuickSend(false);
        setChatStarted(true);
        localStorage.setItem('chatStarted', 'true');
    };

    useEffect(() => {
        const newRows = Math.min(Math.max(Math.floor(inputText?.length / 50), 1), 4);
        setRows(newRows);
    }, [inputText]);

    const handleChange = (e) => {
        setInputText(e.target.value);

        const newRows = Math.min(Math.max(Math.floor(e.target.value?.length / 50), 1), 4);
        setRows(newRows);

        if (showQuickSend) {
            setShowQuickSend(false);
        }

        const buffer = 20;
        const input = e.target;
        const sendButton = input.nextElementSibling;
        const inputWidth = input.getBoundingClientRect().width;
        const sendButtonLeft = sendButton.getBoundingClientRect().left;

        if (inputWidth + buffer >= sendButtonLeft) {
            input.style.width = `${sendButtonLeft - input.getBoundingClientRect().left - buffer}px`;
        } else {
            input.style.width = '100%';
        }

        if (e.target.value.endsWith('#')) {
            setShowFolderSearch(true);
            fetchFolders();
        } else {
            setShowFolderSearch(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        const fetchUserProfile = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const response = await axios.get(`${apiUrl}/user-profile`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUserProfile(prevState => ({
                    ...prevState,
                    ...response.data,
                    profilePhoto: response.data.profilePhoto ? `${apiUrl}/${response.data.profilePhoto}` : userAvatar
                }));
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, [navigate]);

    console.log(errorMessage);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && inputText?.trim() && window.innerWidth <= 368) {
            event.preventDefault();
            sendMessage(inputText);
            setInputText('');
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setSelectedFileURL(URL.createObjectURL(file));
    };

    const removeSelectedFile = () => {
        setSelectedFile(null);
        setSelectedFileURL(null);
    };

    const cancelTokenSource = useRef(null);

    const sendMessage = async (userInput) => {
        setIsLoading(true);
        setChatStarted(true);
        setErrorMessage('');

        const replyText = replyingToMessage ? replyingToMessage.text : '';
        const selectedModel = localStorage.getItem('selectedModel') || 'maximo';
        let combinedMessage = replyText + userInput;

        if (selectedModel === 'claude') {
            combinedMessage = `Hey Claude, ${combinedMessage}`;
        }

        const userMessage = {
            author: 'user',
            text: combinedMessage,
            avatar: userProfile.profilePhoto || userAvatar,
            language: localStorage.getItem('language') || 'en',
            image: selectedFileURL,
            replyTo: replyingToMessage,
            newPrompt: replyingToMessage ? userInput : null,
            extractedTextInfo: extractedTextInfo,
        };
        const loaderMessage = {
            author: 'maximo',
            text: '',
            avatar: maximoAvatar,
            isLoading: true,
        };
        setMessages(prevMessages => [...prevMessages, userMessage, loaderMessage]);

        setSelectedFile(null);
        setSelectedFileURL(null);
        setReplyingToMessage(null);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Authentication token not found.');
            }
            const apiUrl = process.env.REACT_APP_API_URL;
            const formData = new FormData();
            formData.append('question', combinedMessage);
            formData.append('language', userMessage.language);
            if (selectedFile) {
                formData.append('file', selectedFile);
            }
            if (extractedTextInfo) {
                formData.append('extractedText', extractedTextInfo.text);
            }

            cancelTokenSource.current = axios.CancelToken.source();

            const response = await axios.post(`${apiUrl}/api/ask-maximo-v2`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
                    cancelToken: cancelTokenSource.current.token
                }
            );

            const { message, botResponse, currentPrice, chartUrl, technicalAnalysis } = response.data;
            const { supportLevel, resistanceLevel } = technicalAnalysis || {};

            if (message && message.image) {
                const imageMessage = {
                    author: 'maximo',
                    avatar: maximoAvatar,
                    image: message.image.url,
                    text: message.text,
                    isGPT: true,
                };
                setMessages(prevMessages => [...prevMessages.filter(msg => !msg.isLoading), imageMessage]);
            } else if (message && !botResponse) {
                const simpleMessage = {
                    author: 'maximo',
                    text: message.text || message,
                    avatar: maximoAvatar,
                    isGPT: true,
                };
                setMessages(prevMessages => [...prevMessages.filter(msg => !msg.isLoading), simpleMessage]);
            } else {
                const maximoMessage = {
                    author: 'maximo',
                    text: botResponse,
                    avatar: maximoAvatar,
                    chartUrl: chartUrl,
                    currentPrice: currentPrice,
                    supportLevel: supportLevel,
                    resistanceLevel: resistanceLevel,
                    responses: [botResponse],
                    currentResponseIndex: 0,
                };
                setMessages(prevMessages => [...prevMessages.filter(msg => !msg.isLoading), maximoMessage]);
            }

            setInputText('');
            setIsLoading(false);
            setRows(1);
            setExtractedTextInfo(null);
            setSelectedFolder(null);
            setSelectedDocuments([]);
            removeSelectedFile();
        } catch (error) {
            if (axios.isCancel(error)) {
                toast({
                    title: t('request_cancelled_title'),
                    description: t('request_cancelled_description'),
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                });
                setMessages(prevMessages => [...prevMessages.filter(msg => !msg.isLoading), { author: 'maximo', text: t('request_cancelled_message'), avatar: maximoAvatar }]);
            } else if (error.response && error.response.status === 403) {
                localStorage.clear();
                toast({ title: 'Session expired', description: 'Please log in again.', status: 'warning', duration: 3000, isClosable: true });
                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000);
            } else {
                let errorMessage = 'Sorry, I encountered an error trying to analyze.';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (error.message) {
                    errorMessage = error.message;
                }
                toast({ title: 'Error', description: errorMessage, status: 'error', duration: 5000, isClosable: true });
                setMessages(prevMessages => [...prevMessages.filter(msg => !msg.isLoading), { author: 'maximo', text: errorMessage, avatar: maximoAvatar }]);
                setErrorMessage(errorMessage);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleQuickStart = () => {
        sendMessage(t('quick_start_message'));
        setChatStarted(true);
        localStorage.setItem('chatStarted', 'true');
    };

    const handleOpenChat = () => {
        setChatStarted(true);
        localStorage.setItem('chatStarted', 'true');
    };

    const stopRequest = () => {
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Request cancelled by user');
        }
    };

    const regenerateResponse = async (message, index) => {
        const newMessage = { ...message, text: `Make better: ${message.text}` };
        await sendMessage(newMessage.text);
        setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            newMessages[index].responses = newMessages[index].responses || [];
            newMessages[index].responses.push(newMessages[newMessages.length - 1].text);
            newMessages[index].text = newMessages[index].responses[newMessages[index].responses.length - 1];
            newMessages[index].currentResponseIndex = newMessages[index].responses.length - 1;
            newMessages.pop();
            return newMessages;
        });
    };

    const switchResponse = (index, direction) => {
        setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            const message = newMessages[index];
            if (message.responses && message.responses.length > 1) {
                const currentResponseIndex = message.currentResponseIndex;
                if (direction === 'next') {
                    newMessages[index].currentResponseIndex = (currentResponseIndex + 1) % message.responses.length;
                } else {
                    newMessages[index].currentResponseIndex = (currentResponseIndex - 1 + message.responses.length) % message.responses.length;
                }
                newMessages[index].text = message.responses[newMessages[index].currentResponseIndex];
            }
            return newMessages;
        });
    };

    const handleOpenPlayground = (messageText) => {
        if (messageText) {
            const codeMatch = messageText.match(/```(\w+)?\n([\s\S]+?)\n```/);
            if (codeMatch) {
                setPlaygroundContent({
                    language: codeMatch[1] || '',
                    code: codeMatch[2]
                });
            }
        }
    };

    const fetchFolders = async () => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${apiUrl}/api/document-folders`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setFolders(response.data);
        } catch (error) {
            console.error('Error fetching folders:', error);
            toast({
                title: 'Error',
                description: 'Failed to fetch folders',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleFolderSelect = (folder) => {
        setSelectedFolder(folder);
        setSelectedDocuments([]);
    };

    const handleDocumentSelect = (documentId) => {
        setSelectedDocuments(prev =>
            prev.includes(documentId)
                ? prev.filter(id => id !== documentId)
                : [...prev, documentId]
        );
    };

    const extractDocumentText = async () => {
        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(
                `${apiUrl}/api/extract-document-text`,
                {
                    folderId: selectedFolder.id,
                    documentIds: selectedDocuments.length > 0 ? selectedDocuments : undefined
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setExtractedTextInfo({
                folderName: selectedFolder.name,
                text: response.data.extractedText
            });
            setShowFolderSearch(false);
            toast({
                title: 'Document selected',
                description: `Document selected from ${selectedFolder.name}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error selecting document:', error);
            toast({
                title: 'Error',
                description: 'Failed to select document',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const cardBgColor = useColorModeValue('gray.700', 'gray.800');
    const textColor = useColorModeValue('white', 'gray.100');
    const borderColor = useColorModeValue('gray.600', 'gray.700');

    const handleNavigateToMenu = () => {
        navigate('/menu');
    };

    const truncatePlaceholder = (text) => {
        return text.length > 10 ? text.substring(0, 10) + '...' : text;
    };
    const truncatePlaceholderN = (text) => {
        return text.length > 23 ? text.substring(0, 23) + '...' : text;
    };

    return (
        <Flex direction="column" height="100%" p={4} bg="gray.900" color="white">
            {!chatStarted ? (
                <Container maxW="container.xl" centerContent py={10} px={4}>
                    <MotionFlex
                        direction="column"
                        align="center"
                        justify="center"
                        height="100%"
                        width="100%"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <MotionBox
                            animate={{ scale: [1, 1.1, 1] }}
                            transition={{ duration: 2, repeat: Infinity }}
                        >
                            <Image src={companyLogo} boxSize={{ base: "120px", md: "180px" }} alt="Company Logo" mb={8} />
                        </MotionBox>
                        <Heading
                            as="h1"
                            size={{ base: "md", md: "xl" }}
                            textAlign="center"
                            fontFamily="'Lexend'"
                            fontWeight="bold"
                            color={textColor}
                            mb={8}
                            animation={`${fadeIn} 1s ease-out`}
                        >
                            {t('welcome_message_with_username', { username: userProfile.username })}
                        </Heading>
                        <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                            gap={6}
                            width="100%"
                            maxWidth="800px"
                            mb={8}
                        >
                            {quickSendMessages.map((msg, index) => (
                                <MotionBox
                                    key={index}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.3, delay: index * 0.1 }}
                                >
                                    <Button
                                        onClick={() => handleQuickSendMessage(msg.command)}
                                        height="120px"
                                        fontSize={{ base: "md", md: "lg" }}
                                        fontWeight="bold"
                                        borderRadius="2xl"
                                        bg={cardBgColor}
                                        color={textColor}
                                        border="1px solid"
                                        borderColor={borderColor}
                                        boxShadow="lg"
                                        _hover={{ boxShadow: "xl", bg: "gray.600" }}
                                        transition="all 0.3s"
                                        width="100%"
                                    >
                                        {truncatePlaceholderN(msg.text)}
                                    </Button>
                                </MotionBox>
                            ))}
                        </Grid>
                        <Flex
                            direction={{ base: "column", md: "row" }}
                            gap={4}
                            width="100%"
                            maxWidth="800px"
                        >
                            <MotionBox
                                flex={1}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: 0.4 }}
                            >
                                <Button
                                    onClick={handleQuickStart}
                                    height="60px"
                                    fontSize={{ base: "md", md: "lg" }}
                                    fontWeight="bold"
                                    borderRadius="2xl"
                                    colorScheme="teal"
                                    boxShadow="lg"
                                    _hover={{ boxShadow: "xl" }}
                                    transition="all 0.3s"
                                    width="100%"
                                >
                                    {t('Thoughts on Bitcoin')}
                                </Button>
                            </MotionBox>
                            <MotionBox
                                flex={1}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: 0.5 }}
                            >
                                <Button
                                    onClick={handleOpenChat}
                                    height="60px"
                                    fontSize={{ base: "md", md: "lg" }}
                                    fontWeight="bold"
                                    borderRadius="2xl"
                                    colorScheme="blue"
                                    boxShadow="lg"
                                    _hover={{ boxShadow: "xl" }}
                                    transition="all 0.3s"
                                    width="100%"
                                >
                                    {t('open_chat')}
                                </Button>
                            </MotionBox>
                        </Flex>
                    </MotionFlex>
                </Container>
            ) : (
                <>
                    <MessagesContainer
                        messages={messages}
                        onRegenerate={regenerateResponse}
                        onSwitchResponse={switchResponse}
                        setReplyingToMessage={setReplyingToMessage}
                        onOpenPlayground={handleOpenPlayground}
                    />
                    {replyingToMessage && (
                        <Box
                            position="relative"
                            bg="gray.700"
                            color="white"
                            p={2}
                            borderRadius="lg"
                            mb={2}
                            maxWidth="100%"
                            textAlign="left"
                        >
                            <Text fontWeight="bold" mb={1}>
                                {replyingToMessage.author === 'maximo' ? 'Maximo AI' : 'You'}
                            </Text>
                            <Text fontSize="sm" isTruncated noOfLines={3}>
                                {replyingToMessage.text}
                            </Text>
                            <IconButton
                                aria-label="Cancel reply"
                                icon={<FontAwesomeIcon icon={faTimes} />}
                                size="xs"
                                variant="ghost"
                                colorScheme="red"
                                onClick={() => setReplyingToMessage(null)}
                                position="absolute"
                                top={1}
                                right={1}
                            />
                        </Box>
                    )}
                    <Flex align="center" justify="space-between" pt={4} position="relative">
                        {(selectedFileURL || extractedTextInfo) && (
                            <Box
                                position="absolute"
                                top="-60px"
                                left="0"
                                right="0"
                                p={2}
                                bg="gray.700"
                                borderRadius="md"
                                border="1px solid"
                                borderColor="gray.600"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                                zIndex="1"
                            >
                                {selectedFileURL && (
                                    <Image
                                        src={selectedFileURL}
                                        alt="Selected File"
                                        boxSize="50px"
                                        objectFit="cover"
                                        borderRadius="md"
                                        mr={2}
                                        className="chat-image"
                                    />
                                )}
                                {extractedTextInfo && (
                                    <Tag size="md" variant="subtle" colorScheme="blue" mr={2}>
                                        <TagLabel>
                                            <FontAwesomeIcon icon={faFolder} /> {extractedTextInfo.folderName}
                                        </TagLabel>
                                    </Tag>
                                )}
                                <IconButton
                                    aria-label="Remove file"
                                    icon={<FontAwesomeIcon icon={faTimes} />}
                                    size="sm"
                                    colorScheme="red"
                                    onClick={() => {
                                        removeSelectedFile();
                                        setExtractedTextInfo(null);
                                    }}
                                />
                            </Box>
                        )}
                        <IconButton
                            icon={<Avatar size="sm" src={userProfile.profilePhoto || userAvatar} />}
                            aria-label="Open menu"
                            variant="ghost"
                            onClick={handleNavigateToMenu}
                            ref={btnRef}
                            size="lg"
                            boxShadow="0 0 8px 2px #00FFB9"
                            borderRadius="full"
                            mr={4}
                        />
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="auto"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                            >
                                <IconButton
                                    icon={<FontAwesomeIcon size="lg" icon={faPaperclip} />}
                                    aria-label="Attach file"
                                    variant="ghost"
                                    colorScheme="gray"
                                    size="sm"
                                    onClick={() => document.getElementById('file-upload-input').click()}
                                    borderRadius="full"
                                />
                                <input
                                    id="file-upload-input"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </InputLeftElement>
                            <TextareaAutosize
                                minRows={rows}
                                maxRows={7}
                                value={inputText}
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                                placeholder={truncatePlaceholder(replyingToMessage ? `Replying to: ${replyingToMessage.text}` : t('ask_me_anything'))}
                                style={{
                                    width: '100%',
                                    padding: '0.75rem 3rem',
                                    paddingRight: '3.5rem',
                                    borderRadius: '1.5rem',
                                    border: '1px solid',
                                    borderColor: 'rgba(0, 164, 211, 0.3)',
                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                    color: 'white',
                                    resize: 'none',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    transition: 'all 0.3s ease',
                                }}
                            />
                            <InputRightElement
                                width="1.5rem"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                mr={4}
                                pointerEvents="auto"
                            >
                                {inputText?.trim() ? (
                                    isLoading ? (
                                        <IconButton
                                            aria-label="Stop request"
                                            icon={<FontAwesomeIcon icon={faStop} size="lg" />}
                                            colorScheme="red"
                                            size="sm"
                                            onClick={stopRequest}
                                            borderRadius="full"
                                        />
                                    ) : (
                                        <IconButton
                                            aria-label="Send message"
                                            icon={<FontAwesomeIcon icon={faPaperPlane} size="lg" />}
                                            colorScheme="blue"
                                            variant="ghost"
                                            size="md"
                                            onClick={() => {
                                                if (inputText?.trim()) {
                                                    sendMessage(inputText);
                                                    setInputText('');
                                                }
                                                removeSelectedFile();
                                                setExtractedTextInfo(null);
                                            }}
                                            borderRadius="full"
                                        />
                                    )
                                ) : (
                                    isLoading ? (
                                        <IconButton
                                            aria-label="Stop request"
                                            icon={<FontAwesomeIcon icon={faStop} size="lg" />}
                                            colorScheme="red"
                                            size="sm"
                                            onClick={stopRequest}
                                            borderRadius="full"
                                        />
                                    ) : (
                                        <IconButton
                                            aria-label="Inactive send icon"
                                            icon={<FontAwesomeIcon icon={faPaperPlane} opacity="0.5" size="lg" />}
                                            colorScheme="blue"
                                            size="sm"
                                            isDisabled
                                            borderRadius="full"
                                        />
                                    )
                                )}
                            </InputRightElement>
                        </InputGroup>
                    </Flex>
                    <PlaygroundPopup
                        isOpen={!!playgroundContent}
                        onClose={() => setPlaygroundContent(null)}
                        code={playgroundContent?.code || ''}
                        language={playgroundContent?.language || ''}
                    />
                </>
            )}

            <Center fontSize="sm" color="gray.500" mt={2.5}>
                {t('maximo_mistakes_disclaimerN')}
            </Center>
            {showQuickSend && (
                <Box position="absolute" bottom="155px" left="0" right="0" px={4} transition="all 0.3s ease-out" zIndex="1">
                    <VStack spacing={4}>
                        {quickSendMessages.map((msg, index) => (
                            <Button key={index} onClick={() => handleQuickSendMessage(msg.command)} width="100%">
                                {truncatePlaceholderN(msg.text)}
                            </Button>
                        ))}
                    </VStack>
                </Box>
            )}
            {showQuickSend && (
                <Center position="absolute" top="40%" left="50%" transform="translate(-50%, -50%)" zIndex="1" flexDirection="column">
                    <Image src={companyLogo} boxSize="70px" alt="Company Logo" />
                    <Text fontSize="md" fontWeight="bold" mt={2}>{t('how_can_i_help')}</Text>
                </Center>
            )}
            <Modal isOpen={showFolderSearch} onClose={() => setShowFolderSearch(false)} isCentered size="xl">
                <ModalOverlay />
                <ModalContent bg="gray.800" color="white">
                    <ModalHeader>Select Folder and Documents</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <Input
                                placeholder="Search folders..."
                                onChange={(e) => {
                                    const searchTerm = e.target.value.toLowerCase();
                                    setFolders(folders.filter(folder =>
                                        folder.name.toLowerCase().includes(searchTerm)
                                    ));
                                }}
                                bg="gray.700"
                                border="none"
                                _focus={{ boxShadow: "0 0 0 1px #4299E1" }}
                            />
                            <List spacing={3}>
                                {folders.map(folder => (
                                    <ListItem key={folder.id} p={2} bg={selectedFolder?.id === folder.id ? "blue.700" : "gray.700"} borderRadius="md">
                                        <Flex justifyContent="space-between" alignItems="center">
                                            <Text onClick={() => handleFolderSelect(folder)} cursor="pointer">
                                                <FontAwesomeIcon icon={faFolder} /> {folder.name}
                                            </Text>
                                            <Checkbox
                                                isChecked={selectedFolder?.id === folder.id}
                                                onChange={() => handleFolderSelect(folder)}
                                                colorScheme="blue"
                                            />
                                        </Flex>
                                        {selectedFolder?.id === folder.id && (
                                            <List pl={4} mt={2}>
                                                {folder.documents.map(doc => (
                                                    <ListItem key={doc.id}>
                                                        <Checkbox
                                                            isChecked={selectedDocuments.includes(doc.id)}
                                                            onChange={() => handleDocumentSelect(doc.id)}
                                                            colorScheme="blue"
                                                        >
                                                            {doc.name}
                                                        </Checkbox>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={extractDocumentText}>
                            {t('Select Document')}
                        </Button>
                        <Button variant="ghost" onClick={() => setShowFolderSearch(false)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
}

export default ChatV2;